import { createToolRoi } from "./createToolRoi";
import TOOLTYPES from "./ToolTypes";

export function getContourDataWithToolRoi(rawPoints, xKey, yKey, toolRoi) {
  const points = generatePoints(rawPoints, xKey, yKey);

  return {
    active: false,
    area: undefined,
    canComplete: false,
    color: undefined,
    handles: {
      invalidHandlePlacement: false,
      points,
      textBox: {
        active: false,
        hasMoved: false,
        hasBoundingBox: true,
        drawnIndependently: true,
        movesIndependently: false,
        allowedOutsideImage: true,
      },
    },
    highlight: false,
    invalidated: true,
    meanStdDev: undefined,
    meanStdDevSUV: undefined,
    polyBoundingBox: {},
    roi: toolRoi,
    visible: true,
  };
}

export function getContourData(rawPoints, xKey, yKey) {
  const toolRoi = createToolRoi(1, TOOLTYPES.FREEHANDROITOOL, 1);
  return getContourDataWithToolRoi(rawPoints, xKey, yKey, toolRoi);
}

function generatePoints(rawPoints, xKey, yKey) {
  return rawPoints.map((rawPoint, index) => {
    const nextRawPoint = rawPoints[(index + 1) % rawPoints.length];
    const lines = [{ x: nextRawPoint[xKey], y: nextRawPoint[yKey] }];

    return {
      x: rawPoint[xKey],
      y: rawPoint[yKey],
      lines,
      active: true,
      highlight: true,
    };
  });
}
