import { createSelector } from "reselect";
import { RECIST_1_1 } from "shared-api";

export const getSeriesIds = (state) => state.annotationPage.series.allIds;
export const getSeries = (state) => state.annotationPage.series.byId;
export const getInstances = (state) =>
  state.annotationPage.series.instancesById;
export const getSelectedCaseAssignment = (state) =>
  state.annotationPage.case.selectedCaseAssignment;
export const getSelectedRecistSeriesIds = (state) =>
  state.annotationPage.recists.recistSeries.selectedSeries;

export const seriesSelector = createSelector(
  getSeries,
  getSeriesIds,
  (series, seriesIds) => seriesIds.map((id) => series[id])
);

export const instancesByIdSelector = createSelector(
  getInstances,
  (instances) => instances
);

export const seriesWithFollowUpSelector = createSelector(
  seriesSelector,
  (series) => {
    return series.filter((series) => series.followUpOrder !== undefined);
  }
);

export const seriesFollowUpExistsInRecistCaseSelector = createSelector(
  seriesWithFollowUpSelector,
  getSelectedCaseAssignment,
  (seriesWithFollowUp, selectedCaseAssignment) => {
    let seriesFollowUpExists = true;
    if (selectedCaseAssignment) {
      seriesFollowUpExists =
        seriesWithFollowUp.length > 0 ||
        selectedCaseAssignment.actionType !== RECIST_1_1;
    }
    return seriesFollowUpExists;
  }
);

export const seriesIdsWithFollowUpSelector = createSelector(
  seriesWithFollowUpSelector,
  (seriesWithFollowUp) => {
    return seriesWithFollowUp.map((series) => series.id);
  }
);

export const selectedRecistSeriesWithFollowUpSelector = createSelector(
  seriesWithFollowUpSelector,
  getSelectedRecistSeriesIds,
  (seriesWithFollowUp, selectedSeries) => {
    return selectedSeries.filter((selectedSeriesId) => {
      return seriesWithFollowUp.some(
        (series) => series.id === selectedSeriesId
      );
    });
  }
);
