import { lesionTypes } from "shared-api";
import { jumpToSlice } from "../viewers";

export function handleJumpToLesionSlice(lesionId, dispatch, getState) {
  let {
    annotationPage: {
      rois: roisById,
      lesions: { byId: lesionsById },
      series: { instancesById },
    },
  } = getState();

  if (!lesionsById.hasOwnProperty(lesionId)) {
    throw new Error("There is no lesion for id: " + lesionId);
  }

  let lesion = lesionsById[lesionId];
  let rois = lesion.rois.map((id) => roisById.byId[id]);
  let isLesionOrgan = lesion.type === lesionTypes.ORGAN;

  // Given the data above, jump to the slice (calculated below) ==================
  rois.forEach((roi) => {
    let imageIdToJumpTo = null;

    let useEllipse = !isLesionOrgan && roi.ellipse.slice?.imageId != null;
    if (useEllipse) {
      imageIdToJumpTo = roi.ellipse.slice?.imageId;
    } else if (roi.contours.length > 0) {
      const { instanceId: firstContourInstanceId } = roi.contours[0];
      const { wadoUrl } = instancesById[firstContourInstanceId];
      imageIdToJumpTo = wadoUrl;
    }

    if (imageIdToJumpTo) {
      dispatch(jumpToSlice(imageIdToJumpTo));
    }
  });
}
