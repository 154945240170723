import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { LesionStatusType } from "../types/LesionStatusType";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertLesionStatus($lesion_id: Int!, $status: String!) {
    insert_lesion_status_one(
      object: { lesion_id: $lesion_id, status: $status }
      on_conflict: { constraint: lesion_status_pkey, update_columns: [] }
    ) {
      status
    }
  }
`;

type Input = {
  lesionId: number;
  status: LesionStatusType;
};

export async function insertLesionStatus({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ lesionId: lesion_id, status }) => ({
      lesion_id,
      status,
    }),
    parseData: () => undefined,
  });
}
