import { Menu } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { createLesion } from "../../actions/actionCreators/annotationPage/thunks";
import {
  setActiveTool,
  setDetectMode,
} from "../../actions/actionCreators/annotationPage/tools";
import { lesionsNamesSelector } from "../../store/annotationPage/lesions/selectors";
import { ANNOTATE } from "../../tools/Tools";
import SubMenuItem from "./SubMenuItem";
import { seriesFollowUpExistsInRecistCaseSelector } from "../../store/annotationPage/series/selectors";
import { selectEnabledAnatomicalStructures } from "../../store/annotationPage/organs/selectors";
import { lesionTypes } from "shared-api";

CreateNewOrganButton.propTypes = {
  setActiveTool: PropTypes.func.isRequired,
  createLesion: PropTypes.func.isRequired,
  setContourDetectMode: PropTypes.func.isRequired,
  lesionsNames: PropTypes.array,
  organs: PropTypes.array,
};

function CreateNewOrganButton({
  setActiveTool,
  createLesion,
  setContourDetectMode,
  lesionsNames,
}) {
  const menuId = "organ-menu";
  const [menuAnchors, setMenuAnchors] = useState({});
  const seriesFollowUpExists = useSelector(
    seriesFollowUpExistsInRecistCaseSelector
  );

  const organs = useSelector(selectEnabledAnatomicalStructures);

  const setMenuAnchor = (id, value) => {
    setMenuAnchors({
      ...menuAnchors,
      [id]: value,
    });
  };

  const handleOrganClick = (organ) => {
    if (!seriesFollowUpExists) {
      throw new Error("Prohibited to create organ in series with no follow up");
    }
    setContourDetectMode(false);
    setActiveTool(ANNOTATE);

    const { id, displayName } = organ;
    createLesion(displayName, id);
    setMenuAnchors({});
  };

  return (
    <>
      <Chip
        variant="outlined"
        style={{ marginLeft: "15px" }}
        label={"Label"}
        icon={<AddCircle />}
        onClick={(e) => setMenuAnchor(menuId, e.currentTarget)}
        disabled={!seriesFollowUpExists}
      />
      <Menu
        id={menuId}
        anchorEl={menuAnchors[menuId]}
        keepMounted
        open={Boolean(menuAnchors[menuId])}
        onClose={(e) => setMenuAnchor(menuId, null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div>
          {organs.map((organ) => {
            return (
              <SubMenuItem
                key={`menu-item-${organ.displayName}`}
                node={organ}
                onClick={handleOrganClick}
                menuAnchors={menuAnchors}
                setMenuAnchor={setMenuAnchor}
                disabledNodes={lesionsNames}
              />
            );
          })}
        </div>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  lesionsNames: lesionsNamesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTool: (tool) => dispatch(setActiveTool(tool)),
  createLesion: (name, locationId) =>
    dispatch(createLesion(name, locationId, lesionTypes.ORGAN)),
  setContourDetectMode: (isDetectMode) => dispatch(setDetectMode(isDetectMode)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CreateNewOrganButton);
