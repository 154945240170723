import { gql } from "@apollo/client/core";

export const INSTANCE_FRAGMENT = gql`
  fragment Instance on instance {
    id: instance_id
    seriesId: series_id
    sopInstanceUid: sop_instance_uid
    instanceNumber: instance_number
  }
`;
