import { normalize, schema } from "normalizr";

let getSchema = () => {
  const roi = new schema.Entity("rois", {}, { idAttribute: "id" });

  const lesion = new schema.Entity(
    "lesions",
    {
      rois: [roi],
    },
    { idAttribute: "id" }
  );

  return lesion;
};

function normalizeLesions(lesions) {
  let schema = getSchema();
  return normalize(lesions, [schema]);
}

function normalizeLesion(lesion) {
  let schema = getSchema();
  return normalize(lesion, schema);
}

export { normalizeLesion, normalizeLesions };
