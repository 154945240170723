import React from "react";
import { useSelector } from "react-redux";
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import PropTypes from "prop-types";
import { selectAnatomicalStructures } from "../../../../store/annotationPage/organs/selectors";

const StyledAutocomplete = styled(AutoComplete)`
  margin-top: 8px;
`;

const StyledDiv = styled("div")`
  padding-left: ${(props) => `${props.level * 8}px`};
`;

const LocationSelect = ({ handleLocationChange, disabled }) => {
  const locations = useSelector(selectAnatomicalStructures);

  const flattener = (acc, row) => {
    const flattenedRows = [];
    flattenedRows.push({
      level: 0,
      id: row.id,
      anatomy: row.displayName,
    });
    row.children.forEach((organ) => {
      flattenedRows.push({
        level: 1,
        id: organ.id,
        anatomy: organ.displayName,
        parents: [row.displayName],
      });
      if (organ.children) {
        organ.children.forEach((segment) => {
          flattenedRows.push({
            level: 2,
            id: segment.id,
            anatomy: segment.displayName,
            parents: [row.displayName, organ.displayName],
          });
        });
      }
    });
    return acc.concat(flattenedRows);
  };
  const flattenedLocations = locations.reduce(flattener, []);

  return (
    <StyledAutocomplete
      disabled={disabled}
      fullWidth
      options={flattenedLocations}
      getOptionLabel={(location) => location.anatomy}
      renderOption={(option) => {
        return <StyledDiv level={option.level}>{option.anatomy}</StyledDiv>;
      }}
      getOptionSelected={(option, value) => {
        return option.anatomy === value.displayName;
      }}
      filterOptions={(options, state) => {
        const input = state.inputValue.toLowerCase();
        const filteredOptions = [];
        options.forEach((option) => {
          if (option.anatomy.toLowerCase().includes(input)) {
            return filteredOptions.push(option);
          }
          if (option.parents) {
            option.parents.some((parent) => {
              if (parent.toLowerCase().includes(input)) {
                filteredOptions.push(option);
                return true;
              }
              return false;
            });
          }
        });

        return filteredOptions;
      }}
      getOptionDisabled={(option) => option.level === 0}
      onChange={handleLocationChange}
      renderInput={(params) => {
        return <TextField {...params} label="Location" variant="outlined" />;
      }}
    />
  );
};

LocationSelect.propTypes = {
  handleLocationChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default LocationSelect;
