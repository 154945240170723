import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import TrackChangesTwoToneIcon from "@material-ui/icons/TrackChangesTwoTone";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { COMPLETED, lesionTypes } from "shared-api";
import { deleteDetectionData } from "../../actions/actionCreators/annotationPage/rois";
import { ANNOTATIONS_DELETE } from "../../auth0/AuthRules";
import Can from "../Can";
import OkCancelTextDialog from "../Dialogs/Base/OkCancelTextDialog";

DetectionListItem.propTypes = {
  lesion: PropTypes.object.isRequired,
  roi: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  hasPendingEdits: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  deleteDetectionData: PropTypes.func.isRequired,
};

function DetectionListItem({
  lesion,
  roi,
  label,
  isSelected,
  hasPendingEdits,
  onClick,
  deleteDetectionData,
}) {
  const [pendingDeleteRoi, setPendingDeleteRoi] = React.useState(null);
  const handleDelete = (roi) => {
    setPendingDeleteRoi(roi);
  };

  const onConfirmDelete = (roi) => {
    deleteDetectionData(roi, roi.ellipse.slice);
    setPendingDeleteRoi(null);
  };

  const onCancelDelete = () => {
    setPendingDeleteRoi(null);
  };

  let detectIconColor = hasPendingEdits
    ? { color: yellow[500] }
    : { color: green[500] };

  return (
    <div>
      <OkCancelTextDialog
        title={
          lesion.type === lesionTypes.LESION
            ? "Delete Detection"
            : "Clear All Contours"
        }
        message={
          lesion.type === lesionTypes.LESION
            ? "Deleting a detection will also remove all related contours and attributes. Are you sure you want to delete this detection?"
            : "Are you sure you want to delete all contours for this roi?"
        }
        open={pendingDeleteRoi !== null}
        onOk={() => onConfirmDelete(pendingDeleteRoi)}
        onCancel={() => onCancelDelete()}
      />
      <ListItem dense button onClick={() => onClick(roi)} selected={isSelected}>
        {lesion.type === lesionTypes.LESION && (
          <IconButton size="small" disabled={!roi.ellipse.slice}>
            <TrackChangesTwoToneIcon fontSize="small" style={detectIconColor} />
          </IconButton>
        )}

        <ListItemText secondary={`${label}`} />

        <ListItemSecondaryAction>
          <Can
            yes={() => (
              <IconButton
                aria-label="delete-contour"
                size="medium"
                onClick={() => handleDelete(roi)}
                disabled={lesion.status === COMPLETED}
              >
                <DeleteIcon color="disabled" fontSize="small" />
              </IconButton>
            )}
            action={ANNOTATIONS_DELETE}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  deleteDetectionData: (roi, slice) =>
    dispatch(deleteDetectionData(roi.id, slice)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetectionListItem);
