import { SET_SERIES } from "../../actions/types/annotationPage/series";

let defaultState = {
  byId: {},
  allIds: [],
  instancesById: {},
  followUpOrder: [],
};

export const series = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SERIES:
      return setSeries(state, action);
    default:
      return state;
  }
};

const setSeries = (state, action) => {
  let { series, instances } = action.series.entities;
  return {
    byId: series,
    allIds: action.series.result,
    instancesById: instances,
    followUpOrder: state.followUpOrder,
  };
};
