import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertCaseFlagEntry(
    $created_by: Int
    $reason: String!
    $details: String!
    $case_id: Int!
  ) {
    insert_case_flag_log_one(
      object: {
        created_by: $created_by
        reason: $reason
        details: $details
        case_id: $case_id
      }
    ) {
      case_flag_log_id
    }
  }
`;

type Input = {
  caseId: number;
  createdBy: number;
  reason: string;
  details: string;
};

export async function insertCaseFlagEntry({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      caseId: case_id,
      createdBy: created_by,
      reason,
      details,
    }) => ({
      case_id,
      created_by,
      reason,
      details,
    }),
    parseData: () => undefined,
  });
}
