import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CornerstoneViewport from "@altis-labs/react-cornerstone-viewport";
import { connect } from "react-redux";
import ToolController from "../tools/ToolController";
import { ClipboardDataTypes } from "shared-api";
import { isContourPasteAvailable } from "../tools/pasteUtils/isContourPasteAvailable";
import { isUserMaskPasteAvailable } from "../tools/pasteUtils/isUserMaskPasteAvailable";
import {
  deleteContourToolThunk,
  onEllipseProbeCreated,
  updateContourThunk,
  updateDetectionData,
  updateUserMaskThunk,
  copyContourToClipboardThunk,
  pasteContourFromClipboardThunk,
  copyUserMaskToClipboardThunk,
  pasteUserMaskFromClipboardThunk,
  selectLesionFromContourData,
} from "../actions/actionCreators/annotationPage/rois";
import {
  queueViewerDraw,
  queueViewerMeasure,
  setViewerImageIdIndex,
  queueViewerCancelActiveTool,
  queueViewerWindowLevelPreset,
  setViewerElement,
} from "../actions/actionCreators/annotationPage/viewers";
import { setBrushRadius } from "../actions/actionCreators/annotationPage/tools";
import { ImageViewerSynchronizer } from "../tools/ImageViewerSyncrhonizer";
import { maskGroupsSelector } from "../store/annotationPage/masks/selectors";
import {
  ANNOTATE,
  ELLIPTICAL_PROBE_TOOL,
  BRUSH,
  MEASURE,
  PAN,
  ROI_SCULPTOR,
  WINDOWLEVEL,
  ZOOM,
  FREEHAND_SCISSORS,
} from "../tools/Tools";
import {
  visibleToolRoiIdsSelector,
  roisSelector,
  visibleRoiIdsSelector,
} from "../store/annotationPage/rois/selectors";
import { selectedLesionSelector } from "../store/annotationPage/lesions/selectors";
import ContourContextMenu from "./ImageViewer/ContourContextMenu";
import PasteContextMenu from "./ImageViewer/PasteContextMenu";
import { formatSeriesLabel } from "../utils/formatSeriesLabel";
import labelmapProvider from "../labelmapUtils/initLabelmapProvider";
import { canRoleUseComponent } from "../actions/actionCreators/app";
import { ANNOTATIONS_CREATE } from "../auth0/AuthRules";

function ImageViewer(props) {
  const {
    viewer,
    series,
    roi,
    clipboardData,
    setViewportActive,
    setViewerElement,
    selectedLesion,
    copyContourToClipboard,
    pasteContourFromClipboard,
    copyUserMaskToClipboard,
    pasteUserMaskFromClipboard,
    urlViewerData,
    selectLesionFromContourData,
    canRoleUseComponent,
  } = props;
  const {
    isDetectMode,
    activeTool,
    dequeueViewerWindowLevelPreset,
    brushRadiusValues,
    setBrushRadius,
  } = props;
  const {
    updateDetection,
    updateContours,
    deleteContourToolData,
    updateUserMasks,
  } = props;
  const {
    setViewerImageIdIndex,
    dequeueViewerDraw,
    dequeueViewerMeasure,
    dequeueViewerCancelActiveTool,
  } = props;
  const {
    toolRoiSavedStatuses,
    selectedContourToolRoiId,
    visibleToolRoiIds,
    visibleRoiIds,
  } = props;
  const {
    instancesById,
    seriesById,
    maskGroups,
    maskInstancesById,
    rois,
    maskSeriesById,
  } = props;
  const { onEllipseProbeCreated } = props;

  const [toolController, setToolController] = useState(null);
  const [element, setElement] = useState(null);
  const [lastActiveTool, setLastActiveTool] = useState(null);
  const [mouseState, setMouseState] = useState({
    mouseX: null,
    mouseY: null,
  });
  const [contourPasteMenuMouseState, setContourPasteMenuMouseState] = useState({
    mouseX: null,
    mouseY: null,
  });
  const [
    userMaskPasteMenuMouseState,
    setUserMaskPasteMenuMouseState,
  ] = useState({
    mouseX: null,
    mouseY: null,
  });
  const [selectedContourToolData, setSelectedContourToolData] = useState(null);
  const [urlViewerStateConfigured, setUrlViewerStateConfgiured] = useState(
    false
  );

  const [tools] = useState([
    // Mouse
    {
      name: "Wwwc",
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: "Zoom",
      mode: "active",
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: "Pan",
      mode: "active",
      modeOptions: { mouseButtonMask: 4 },
    },
    {
      name: MEASURE,
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: "EllipticalRoi",
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: ELLIPTICAL_PROBE_TOOL,
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: "FreehandRoi",
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
      props: {
        configuration: { alwaysShowHandles: true },
      },
    },
    {
      name: "FreehandRoiSculptor",
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
      props: {
        configuration: {
          minSpacing: 0.025,
          dragColor: "rgb(20, 20, 255)",
          hoverColor: "rgb(0, 0, 255)",
        },
      },
    },
    // Scroll
    { name: "StackScrollMouseWheel", mode: "active" },
    // // Touch
    { name: "PanMultiTouch", mode: "active" },
    { name: "ZoomTouchPinch", mode: "active" },
    { name: "StackScrollMultiTouch", mode: "active" },
    { name: BRUSH, mode: "active", modeOptions: { mouseButtonMask: 1 } },
    {
      name: FREEHAND_SCISSORS,
      mode: "active",
      modeOptions: { mouseButtonMask: 1 },
    },
  ]);

  let viewerId = viewer.id;
  let imageIdIndex = viewer.imageIdIndex;
  let isActive = viewer.isActive;
  let isDrawQueued = viewer.isDrawQueued;
  let isMeasureQueued = viewer.isMeasureQueued;
  let isCancelActiveToolQueued = viewer.isCancelActiveToolQueued;
  let queuedWindowLevelPreset = viewer.queuedWindowLevelPreset;
  let imageIds = series.instances.map(
    (instanceId) => instancesById[instanceId].wadoUrl
  );
  let currentImageWadoUrl = imageIds[imageIdIndex];

  let contourPasteAvailable = isContourPasteAvailable(
    clipboardData,
    roi,
    currentImageWadoUrl,
    selectedLesion,
    activeTool
  );

  let userMaskPasteAvailable = isUserMaskPasteAvailable(
    clipboardData,
    roi,
    currentImageWadoUrl,
    selectedLesion,
    activeTool
  );

  const handleElementOnLoad = (element) => {
    setViewerElement(viewerId, element);
    labelmapProvider.deleteAllLabelmaps(element);
    setElement(element);
  };

  const handleViewportRightClicked = (event) => {
    if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
      return;
    }

    let { client } = event.detail.currentPoints;

    let pasteMenuMouseState = {
      mouseX: client.x + 2,
      mouseY: client.y - 4,
    };
    if (contourPasteAvailable) {
      setContourPasteMenuMouseState(pasteMenuMouseState);
    } else if (userMaskPasteAvailable) {
      setUserMaskPasteMenuMouseState(pasteMenuMouseState);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const onContourRightClicked = (data) => {
    if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
      return;
    }

    handleCloseContourPasteContextMenu();
    handleCloseUserMaskPasteContextMenu();
    let { toolData, event } = data;
    let { clientX, clientY } = event;

    let mouseState = {
      mouseX: clientX + 2,
      mouseY: clientY - 4,
    };
    setMouseState(mouseState);
    setSelectedContourToolData(toolData);

    selectLesionFromContourData(toolData.roi.id, toolData);
  };

  const onContourDoubleClicked = (data) => {
    let { toolData } = data;
    selectLesionFromContourData(toolData.roi.id, toolData);
  };

  const handleCloseViewerContextMenu = () => {
    setMouseState({
      mouseX: null,
      mouseY: null,
    });
    setSelectedContourToolData(null);
  };

  const handleCloseUserMaskPasteContextMenu = () => {
    setUserMaskPasteMenuMouseState({
      mouseX: null,
      mouseY: null,
    });
  };

  const handleCloseContourPasteContextMenu = () => {
    setContourPasteMenuMouseState({
      mouseX: null,
      mouseY: null,
    });
  };

  const onContourDeleteClicked = () => {
    if (!selectedContourToolData) {
      throw new Error(
        "selectedContourToolData should not be null in onContourDeleteClicked"
      );
    }

    //close the popup first to prevent visual delay
    let toolData = selectedContourToolData;
    handleCloseViewerContextMenu();

    deleteContourToolData(toolData);
  };

  const onContourChanged = (eventData) => {
    const { roiId, imageId, toolData } = eventData;
    updateContours(roiId, imageId, toolData);
  };

  const onContourPasteClicked = () => {
    handleCloseContourPasteContextMenu();
    pasteContourFromClipboard(roi, currentImageWadoUrl, activeTool);
  };

  const onUserMaskPasteClicked = () => {
    handleCloseUserMaskPasteContextMenu();
    pasteUserMaskFromClipboard(roi, currentImageWadoUrl, element, activeTool);
  };

  const onContourCopyClicked = () => {
    if (!selectedContourToolData) {
      throw new Error(
        "selectedContourToolData should not be null in onContourCopyClicked"
      );
    }

    let toolData = [];
    let roiId = roi.id;

    toolData.push(selectedContourToolData);

    handleCloseViewerContextMenu();

    copyContourToClipboard(
      roiId,
      currentImageWadoUrl,
      toolData,
      ClipboardDataTypes.CONTOUR
    );
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    let imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    imageViewerSynchronizer.add(element);

    let toolController = new ToolController(element);
    toolController.subscribeToEllipseChanged((data) => updateDetection(data));
    toolController.subscribeToContourChanged(onContourChanged);
    toolController.subscribeToLabelmapChanged((eventData) => {
      const { roiId, imageId, toolData, shape } = eventData;
      updateUserMasks(roiId, imageId, toolData, shape);
    });
    toolController.subscribeToContourRightClicked((toolData) =>
      onContourRightClicked(toolData)
    );
    toolController.subscribeToContourDoubleClicked((toolData) =>
      onContourDoubleClicked(toolData)
    );
    toolController.subscribeToContourCopyHotKeyPressed((eventData) => {
      if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
        return;
      }

      const { roiId, toolData, imageId } = eventData;
      copyContourToClipboard(
        roiId,
        imageId,
        toolData,
        ClipboardDataTypes.CONTOUR
      );
    });
    toolController.subscribeToContourPasteHotKeyPressed((eventData) => {
      if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
        return;
      }

      const { roi, imageId } = eventData;
      pasteContourFromClipboard(roi, imageId);
    });
    toolController.subscribeToUserMaskCopyHotKeyPressed((eventData) => {
      if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
        return;
      }

      const { roiId, toolData, imageId, shape } = eventData;
      copyUserMaskToClipboard(
        roiId,
        imageId,
        toolData,
        shape,
        ClipboardDataTypes.USER_MASK
      );
    });
    toolController.subscribeToUserMaskPasteHotKeyPressed((eventData) => {
      if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
        return;
      }

      const { roi, imageId, element } = eventData;
      pasteUserMaskFromClipboard(roi, imageId, element);
    });
    toolController.subscribeToBrushSizeChanged((newBrushSize) => {
      setBrushRadius(newBrushSize);
    });
    toolController.subscribeToMeasureChanged(() => dequeueViewerMeasure());
    toolController.subscribeToEllipseProbeCreated(onEllipseProbeCreated);

    setToolController(toolController);

    return () => {
      if (!toolController) {
        return;
      }

      toolController.unsubscribeToEllipseChanged((data) =>
        updateDetection(data)
      );
      toolController.unsubscribeToContourChanged(onContourChanged);
      toolController.unsubscribeFromContourRightClicked((toolData) =>
        onContourRightClicked(toolData)
      );
      toolController.unsubscribeFromContourDoubleClicked((toolData) =>
        onContourDoubleClicked(toolData)
      );
      toolController.unsubscribeToLabelmapChanged((e) =>
        updateUserMasks(e.roi.id, e.data)
      );
      toolController.unsubscribeToContourCopyHotKeyPressed((eventData) => {
        const { roiId, toolData, imageId } = eventData;
        copyContourToClipboard(roiId, imageId, toolData);
      });
      toolController.unsubscribeToContourPasteHotKeyPressed((eventData) => {
        const { roi, imageId } = eventData;
        pasteContourFromClipboard(roi, imageId);
      });
      toolController.unsubscribeToUserMaskCopyHotKeyPressed((eventData) => {
        const { roiId, toolData, imageId, shape } = eventData;
        copyUserMaskToClipboard(
          roiId,
          imageId,
          toolData,
          shape,
          ClipboardDataTypes.USER_MASK
        );
      });
      toolController.unsubscribeToUserMaskPasteHotKeyPressed((eventData) => {
        const { roi, imageId, element } = eventData;
        pasteUserMaskFromClipboard(roi, imageId, element);
      });
      toolController.unsubscribeToBrushSizeChanged((newBrushSize) => {
        setBrushRadius(newBrushSize);
      });
      toolController.unsubscribeToMeasureChanged(() => dequeueViewerMeasure());
      toolController.unsubscribeToEllipseProbeCreated(onEllipseProbeCreated);

      setToolController(null);
    };
  }, [element]);

  useEffect(() => {
    if (
      urlViewerStateConfigured ||
      !element ||
      !toolController ||
      !urlViewerData
    ) {
      return;
    }

    toolController.setViewerViewport(urlViewerData);
    setUrlViewerStateConfgiured(true);
  }, [element, toolController, urlViewerData]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    toolController.setBrushRadiusValues(brushRadiusValues);
  }, [toolController]);

  useEffect(() => {
    if (!element) {
      return;
    }

    let currentSeriesId = series.id;

    rois.forEach((roi) => {
      let { id, labelmapId, userMasks, seriesId } = roi;
      if (currentSeriesId === seriesId) {
        let series = seriesById[seriesId];
        let instanceIds = series.instances;
        let seriesInstances = instanceIds.map(
          (instanceId) => instancesById[instanceId]
        );
        let roiVisible = visibleRoiIds.includes(id);
        labelmapProvider.setLabelmap(
          seriesId,
          element,
          labelmapId,
          seriesInstances,
          userMasks,
          roiVisible
        );
      }
    });
  }, [element, series, visibleRoiIds]);

  useEffect(() => {
    if (!element) {
      return;
    }

    let currentSeriesId = series.id;

    maskGroups.forEach((maskGroup) => {
      let { isVisible, series } = maskGroup;
      series.forEach((seriesId) => {
        if (seriesId === currentSeriesId) {
          let maskSeries = maskSeriesById[seriesId];

          let series = seriesById[seriesId];
          let instanceIds = series.instances;
          let seriesInstances = instanceIds.map(
            (instanceId) => instancesById[instanceId]
          );

          let { instances, labelmapId } = maskSeries;
          let maskInstances = instances.map((id) => maskInstancesById[id]);
          labelmapProvider.setLabelmap(
            seriesId,
            element,
            labelmapId,
            seriesInstances,
            maskInstances,
            isVisible
          );
        }
      });
    });
  }, [element, series, instancesById, maskGroups, maskInstancesById]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    if (!queuedWindowLevelPreset) {
      return;
    }

    let windowLevel = queuedWindowLevelPreset.windowLevel;
    toolController.setWindowLevel(windowLevel);

    dequeueViewerWindowLevelPreset(viewer.id);
  }, [toolController, queuedWindowLevelPreset]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    if (isDrawQueued) {
      toolController.forceRedraw();
      dequeueViewerDraw();
    }
  }, [toolController, viewer, isDrawQueued]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    let alwaysOnTools = [ZOOM, PAN, MEASURE];
    switch (activeTool) {
      case ANNOTATE:
        if (lastActiveTool) {
          if (!alwaysOnTools.includes(lastActiveTool)) {
            toolController.disableTool(lastActiveTool);
          }
        }
        break;
      case WINDOWLEVEL:
      case ZOOM:
      case PAN:
      case ROI_SCULPTOR:
      case ELLIPTICAL_PROBE_TOOL:
      case BRUSH:
      case FREEHAND_SCISSORS:
        toolController.setActiveTool(activeTool);
        break;
      case MEASURE:
        //do nothing since handled in 'handleMeasureTool'
        break;
      default:
        throw new Error(`tool: ${activeTool} not supported`);
    }

    if (!isMeasureQueued) {
      toolController.setPassiveTool(MEASURE);
    } else {
      if (activeTool === MEASURE) {
        toolController.setActiveTool(activeTool);
      } else {
        toolController.setPassiveTool(MEASURE);
        dequeueViewerMeasure();
      }
    }

    setLastActiveTool(activeTool);
  }, [
    toolController,
    activeTool,
    isMeasureQueued,
    visibleRoiIds,
    selectedLesion,
  ]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    toolController.selectToolMode(isDetectMode);
  }, [toolController, isDetectMode]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    let toolRoiInfo = {
      toolRoiSavedStatuses,
      selectedContourToolRoiId,
      visibleToolRoiIds,
    };

    toolController.setToolRoiInfo(toolRoiInfo);
  }, [
    toolController,
    toolRoiSavedStatuses,
    selectedContourToolRoiId,
    visibleToolRoiIds,
  ]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    toolController.selectRoi(roi, activeTool, visibleRoiIds, selectedLesion);
  }, [
    toolController,
    activeTool,
    isDetectMode,
    roi,
    visibleRoiIds,
    selectedLesion,
  ]);

  useEffect(() => {
    if (!toolController) {
      return;
    }

    if (isCancelActiveToolQueued) {
      toolController.cancelActiveTool();
      dequeueViewerCancelActiveTool(viewerId);
    }
  }, [toolController, isCancelActiveToolQueued]);

  if (!series) {
    return <p>No series selected</p>;
  }

  let seriesDateLabel = series.seriesDate ? formatSeriesLabel(series) : null;

  return (
    <span onContextMenu={handleContextMenu}>
      <ContourContextMenu
        mouseState={mouseState}
        handleClose={handleCloseViewerContextMenu}
        onDeleteClicked={onContourDeleteClicked}
        onCopyClicked={onContourCopyClicked}
      />
      <PasteContextMenu
        mouseState={contourPasteMenuMouseState}
        handleClose={handleCloseContourPasteContextMenu}
        onPasteClicked={onContourPasteClicked}
        isPasteAvailable={contourPasteAvailable}
      />
      <PasteContextMenu
        mouseState={userMaskPasteMenuMouseState}
        handleClose={handleCloseUserMaskPasteContextMenu}
        onPasteClicked={onUserMaskPasteClicked}
        isPasteAvailable={userMaskPasteAvailable}
      />
      <CornerstoneViewport
        isStackPrefetchEnabled={true}
        tools={tools}
        imageIds={imageIds}
        imageIdIndex={imageIdIndex}
        imageIdIndexChange={(imageIdIndex) =>
          setViewerImageIdIndex(viewerId, imageIdIndex, currentImageWadoUrl)
        }
        initialLoad={(element) => handleElementOnLoad(element)}
        className={isActive ? "active" : ""}
        setViewportActive={() => setViewportActive(viewerId)}
        handleRightClick={(event) => handleViewportRightClicked(event)}
        seriesDateLabel={seriesDateLabel}
      />
    </span>
  );
}

ImageViewer.propTypes = {
  viewer: PropTypes.object.isRequired,
  series: PropTypes.object,
  roi: PropTypes.object,
  setViewportActive: PropTypes.func.isRequired,
  isDetectMode: PropTypes.bool.isRequired,
  activeTool: PropTypes.string.isRequired,
  updateDetection: PropTypes.func.isRequired,
  updateContours: PropTypes.func.isRequired,
  updateUserMasks: PropTypes.func.isRequired,
  setViewerImageIdIndex: PropTypes.func.isRequired,
  dequeueViewerDraw: PropTypes.func.isRequired,
  dequeueViewerMeasure: PropTypes.func.isRequired,
  dequeueViewerCancelActiveTool: PropTypes.func.isRequired,
  toolRoiSavedStatuses: PropTypes.object,
  selectedContourToolRoiId: PropTypes.number,
  instancesById: PropTypes.object.isRequired,
  maskGroups: PropTypes.array.isRequired,
  maskInstancesById: PropTypes.object,
  maskSeriesById: PropTypes.object,
  seriesById: PropTypes.object,
  setViewerElement: PropTypes.func.isRequired,
  visibleToolRoiIds: PropTypes.array.isRequired,
  deleteContourToolData: PropTypes.func.isRequired,
  onEllipseProbeCreated: PropTypes.func.isRequired,
  visibleRoiIds: PropTypes.array.isRequired,
  clipboardData: PropTypes.object,
  copyContourToClipboard: PropTypes.func.isRequired,
  pasteContourFromClipboard: PropTypes.func.isRequired,
  copyUserMaskToClipboard: PropTypes.func.isRequired,
  pasteUserMaskFromClipboard: PropTypes.func.isRequired,
  brushRadiusValues: PropTypes.object.isRequired,
  setBrushRadius: PropTypes.func.isRequired,
  selectedLesion: PropTypes.object,
  rois: PropTypes.array,
  dequeueViewerWindowLevelPreset: PropTypes.func.isRequired,
  urlViewerData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isDetectMode: state.annotationPage.tools.isDetectMode,
  brushRadiusValues: state.annotationPage.tools.brushRadiusValues,
  activeTool: state.annotationPage.tools.activeTool,
  toolRoiSavedStatuses: state.annotationPage.rois.toolRoiSavedStatuses,
  selectedContourToolRoiId: state.annotationPage.rois.selectedContourToolRoiId,
  instancesById: state.annotationPage.series.instancesById,
  seriesById: state.annotationPage.series.byId,
  maskGroups: maskGroupsSelector(state),
  maskInstancesById: state.annotationPage.masks.instancesById,
  maskSeriesById: state.annotationPage.masks.seriesById,
  visibleToolRoiIds: visibleToolRoiIdsSelector(state),
  rois: roisSelector(state),
  visibleRoiIds: visibleRoiIdsSelector(state),
  selectedLesion: selectedLesionSelector(state),
  clipboardData: state.annotationPage.rois.clipboardData,
  selectLesionFromContourData: PropTypes.func.isRequired,
});

const mapDispatchToProps = (dispatch) => ({
  setViewerImageIdIndex: (viewerId, imageIdIndex, imageId) =>
    dispatch(setViewerImageIdIndex(viewerId, imageIdIndex, imageId)),
  updateDetection: (data) => dispatch(updateDetectionData(data)),
  updateContours: (roiId, imageId, toolData) =>
    dispatch(updateContourThunk(roiId, imageId, toolData)),
  onEllipseProbeCreated: (toolData, roiId) =>
    dispatch(onEllipseProbeCreated(toolData, roiId)),
  updateUserMasks: (roiId, imageId, toolData, shape) =>
    dispatch(updateUserMaskThunk(roiId, imageId, toolData, shape)),
  dequeueViewerDraw: () => dispatch(queueViewerDraw(false)),
  dequeueViewerMeasure: () => dispatch(queueViewerMeasure(false)),
  dequeueViewerCancelActiveTool: (viewerId) =>
    dispatch(queueViewerCancelActiveTool(viewerId, false)),
  dequeueViewerWindowLevelPreset: (viewerId) =>
    dispatch(queueViewerWindowLevelPreset(viewerId, null)),
  setViewerElement: (viewerId, elementUid) =>
    dispatch(setViewerElement(viewerId, elementUid)),
  deleteContourToolData: (toolData) =>
    dispatch(deleteContourToolThunk(toolData)),
  copyContourToClipboard: (roiId, imageId, toolData, dataType) =>
    dispatch(copyContourToClipboardThunk(roiId, imageId, toolData, dataType)),
  copyUserMaskToClipboard: (roiId, imageId, toolData, shape, dataType) =>
    dispatch(
      copyUserMaskToClipboardThunk(roiId, imageId, toolData, shape, dataType)
    ),
  pasteContourFromClipboard: (roi, imageId, activeTool) =>
    dispatch(pasteContourFromClipboardThunk(roi, imageId, activeTool)),
  pasteUserMaskFromClipboard: (roi, imageId, element, activeTool) =>
    dispatch(
      pasteUserMaskFromClipboardThunk(roi, imageId, element, activeTool)
    ),
  setBrushRadius: (newRadius) => dispatch(setBrushRadius(newRadius)),
  selectLesionFromContourData: (roiId, toolData) =>
    dispatch(selectLesionFromContourData(roiId, toolData)),
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);
