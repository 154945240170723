import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation DeleteDiagnosis($diagnosis_id: Int!) {
    delete_diagnosis(where: { id: { _eq: $diagnosis_id } }) {
      affected_rows
    }
  }
`;

type Input = {
  diagnosisId: number;
};

export async function deleteDiagnosis({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ diagnosisId: diagnosis_id }) => ({ diagnosis_id }),
    parseData: () => undefined,
  });
}
