import { LesionColorMapType } from "../types/LesionColorMapType";
import { LesionTypeType } from "../types/LesionTypeType";

export function getLesionColor(
  locationId: number | null,
  type: LesionTypeType,
  lesionColorMap: LesionColorMapType
): string {
  const entry = lesionColorMap.find(
    ({ anatomicalStructureId, type: mapType }) =>
      anatomicalStructureId === locationId && type === mapType
  );
  // if (!entry) {
  //   console.warn(
  //     `No color defined for lesion with anatomicalStructureId: ${locationId} and type: ${type}`
  //   );
  // }
  const { color } = entry ?? { color: "red" };
  return color;
}
