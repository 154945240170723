import { createSelector } from "reselect";
import { lesionTypes, lesionOrigins } from "shared-api";

const getLesionIds = (state) => state.annotationPage.lesions.allIds;
const getLesions = (state) => state.annotationPage.lesions.byId;
const getSelectedLesionId = (state) =>
  state.annotationPage.lesions.selectedLesionId;

const baseName = "LESION ";

export const lesionsSelector = createSelector(
  getLesions,
  getLesionIds,
  (lesions, lesionIds) =>
    lesionIds
      .map((id) => lesions[id])
      .filter((lesion) => lesion.origin === lesionOrigins.USER)
      .sort((a, b) => {
        let numberA = getLesionNumber(a.name, 0);
        let numberB = getLesionNumber(b.name, 0);

        return numberA - numberB;
      })
);

const getRois = (state) => state.annotationPage.rois.byId;
export const lesionsWithRoisSelector = createSelector(
  lesionsSelector,
  getRois,
  (lesions, roisByID) =>
    lesions.map((lesion) => {
      let rois = lesion.rois.map((roiId) => roisByID[roiId]);
      return {
        ...lesion,
        rois,
      };
    })
);

export const displayedLesionsSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    return lesions.filter((lesion) => lesion.type === lesionTypes.LESION);
  }
);

export const displayedOrgansSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    return lesions.filter((lesion) => lesion.type === lesionTypes.ORGAN);
  }
);

export const selectedLesionSelector = createSelector(
  getLesions,
  getSelectedLesionId,
  (lesions, selectedLesionId) =>
    lesions.hasOwnProperty(selectedLesionId) ? lesions[selectedLesionId] : null
);

export const selectedLesionTypeSelector = createSelector(
  selectedLesionSelector,
  (selectedLesion) => (selectedLesion ? selectedLesion.type : null)
);

export const visibleLesionsSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    return lesions.filter((lesion) => lesion.visible);
  }
);

export const nextLesionNameSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    let newLesionNumber = 1;

    const nonOrganLesions = lesions.filter(
      (lesion) => lesion.type === lesionTypes.LESION
    );
    let existingNames = nonOrganLesions.map((lesion) => lesion.name);
    if (existingNames.length > 0) {
      let existingLesionNumbers = existingNames.map((name) =>
        getLesionNumber(name, nonOrganLesions.length)
      );
      newLesionNumber = Math.max(...existingLesionNumbers);
    }

    let getLesionName = (number) => {
      return baseName + number;
    };

    let lesionName = getLesionName(newLesionNumber);

    while (existingNames.includes(lesionName)) {
      newLesionNumber = newLesionNumber + 1;
      lesionName = getLesionName(newLesionNumber);
    }

    return lesionName;
  }
);

export const organExistsSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    return lesions.some((lesion) => lesion.type === lesionTypes.ORGAN);
  }
);

export const lesionsNamesSelector = createSelector(
  lesionsSelector,
  (lesions) => {
    return lesions.map((lesion) => lesion.name);
  }
);

let getLesionNumber = (lesionName, defaultNumber) => {
  if (!lesionName || !lesionName.includes(baseName)) {
    return defaultNumber;
  }

  let tryParseInt = (str, defaultValue) => {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  };

  let number = defaultNumber;
  let numberString = lesionName.replace(baseName, "");
  if (numberString) {
    number = tryParseInt(numberString, defaultNumber);
  }
  return number;
};
