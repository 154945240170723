class rulesEnforcer {
  constructor() {
    this._rules = null;
  }

  set rules(rules) {
    this._rules = this._availableRules[rules];
  }

  get rules() {
    return this._rules;
  }

  checkRules(rule, componentState, reduxState) {
    return this._rules[rule](componentState, reduxState);
  }
}

const ruleEnforcerInstance = new rulesEnforcer();

export default ruleEnforcerInstance;
