import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setSelectedLesion } from "../../../actions/actionCreators/annotationPage/thunks";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import {
  StyledCell,
  StyledCellContentsWrapper,
} from "./StyledComponents/StyledTableCellContainer";

import LesionHeader from "./LesionRowHeader";
import RecistCell from "./RecistCell";
import RecistFollowUpCell from "./RecistFollowUpCell";

const StyledCellWrapper = styled(Paper)`
  padding: 3px;
`;

const RecistRow = ({
  lesion,
  lesionEvaluation,
  rois,
  roiRecistEvaluations,
  selectedSeries,
  selected,
  viewerSeriesIds,
  currentSeriesId,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [pendingDeleteLesion, setPendingDeleteLesion] = React.useState(null);
  const [roiCells, setRoiCells] = useState([]);
  const [maxHeight, setMaxHeight] = useState(200);
  const [headerHeight, setHeaderHeight] = useState(52);
  const [lock, setLocked] = useState(false);
  const divRef = useRef();
  const headerRef = useRef();

  const handleClick = () => {
    if (pendingDeleteLesion || lock) {
      return;
    }
    setOpen(!open);
    setLocked(true);
    setTimeout(() => {
      setLocked(false);
    }, 300);

    dispatch(setSelectedLesion(lesion.id));
  };

  useEffect(() => {
    if (open) {
      // This function sets the max-height property to allow for a CSS transition
      // for the table row, since the contents of the row have an auto height.
      // More info here: https://css-tricks.com/using-css-transitions-auto-dimensions/
      let currentHeight = divRef.current.getBoundingClientRect().height;
      const currentHeaderHeight = headerRef.current.getBoundingClientRect()
        .height;

      // Checks if the header has been resized larger (due to overflow), and adds that to the
      // previously calculated currentheight. Since we have previously set the max height
      // at a lower value, our currentHeight calculated from above will reflect the max-height
      // set in that css, and cut off the rest of the cell's content.
      if (currentHeaderHeight > headerHeight) {
        currentHeight += currentHeaderHeight - headerHeight;
      }
      setHeaderHeight(currentHeaderHeight);
      setMaxHeight(currentHeight);
    } else {
      setMaxHeight("100%");
    }
  }, [open]);

  useEffect(() => {
    let roiJSX = [];
    selectedSeries.forEach((series) => {
      let roiRecistEvaluation = roiRecistEvaluations.find(
        (roi) => roi.seriesId === series
      );
      if (roiRecistEvaluation) {
        roiJSX.push(roiRecistEvaluation);
      } else {
        let roiId = rois.find((roi) => roi.seriesId === series).id;
        let pendingEvaluation = { isPending: true, seriesId: series, roiId };
        roiJSX.push(pendingEvaluation);
      }
    });
    setRoiCells(roiJSX);
  }, [selectedSeries, roiRecistEvaluations, rois]);

  return (
    <TableRow>
      <StyledCell colSpan={selectedSeries.length + 1} $container>
        <StyledCellWrapper elevation={3}>
          <StyledCellContentsWrapper
            $open={open}
            ref={divRef}
            $maxHeight={maxHeight}
          >
            <LesionHeader
              lesion={lesion}
              lesionEvaluation={lesionEvaluation}
              pendingDeleteLesion={pendingDeleteLesion}
              setPendingDeleteLesion={setPendingDeleteLesion}
              expanded={open}
              selected={selected}
              onSelected={handleClick}
              ref={headerRef}
            />
            <Table padding="checkbox">
              <TableBody>
                <TableRow>
                  {roiCells.map((roiEval, index) => {
                    let inViewer = viewerSeriesIds.includes(roiEval.seriesId);
                    let highlight = roiEval.seriesId === currentSeriesId;

                    return roiEval.isPending ? (
                      <RecistFollowUpCell
                        open={open}
                        width={`${100 / roiCells.length}%`}
                        key={index}
                        lesionId={lesion.id}
                        roiId={roiEval.roiId}
                        seriesId={roiEval.seriesId}
                        inViewer={inViewer}
                        highlight={highlight}
                      />
                    ) : (
                      <RecistCell
                        lesionSeriesData={roiEval}
                        open={open}
                        key={index}
                        highlight={highlight}
                        width={`${100 / roiCells.length}%`}
                      />
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </StyledCellContentsWrapper>
        </StyledCellWrapper>
      </StyledCell>
    </TableRow>
  );
};

RecistRow.propTypes = {
  lesion: PropTypes.object.isRequired,
  lesionEvaluation: PropTypes.object.isRequired,
  rois: PropTypes.array.isRequired,
  roiRecistEvaluations: PropTypes.array.isRequired,
  selectedSeries: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  viewerSeriesIds: PropTypes.array.isRequired,
  currentSeriesId: PropTypes.number,
};

export default RecistRow;
