import {
  GenericRestApiConsumer,
  getUserInfo,
  getActionTypeRulesAndAttributes,
  getUserRoles,
} from "shared-api";
import {
  IS_AUTH_LOADED,
  SET_API,
  SET_USER,
  SET_USER_CASE_ACTION_TYPE_ROLES,
  SET_GLOBAL_APPLICATION_ERROR,
  SET_CASE_ACTION_TYPE_ATTRIBUTE,
  SET_CASE_ACTION_TYPE_RULE,
  SET_USER_ROLES,
} from "../types/app";
import { setActiveTool } from "./annotationPage/tools";
import { WINDOWLEVEL } from "../../tools/Tools";
import { ANNOTATIONS_CREATE } from "../../auth0/AuthRules";
import { canUseComponent } from "../../components/Can";
import { initAutoSegmentApi } from "../../api/autoSegmentApi";
import NotaExporter from "../../notaExporter/NotaExporterInstance";
import getClient from "../../apollo/getClient";

export function initApp(apiUrls, token, user) {
  return async (dispatch) => {
    dispatch(isAuthLoaded(false));

    function onError(error) {
      dispatch(setGlobalApplicationError(error));
    }

    const { notaApiUrl, autoSegmentApiUrl } = apiUrls;

    let restApiConsumer = new GenericRestApiConsumer(
      notaApiUrl,
      token,
      onError
    );

    const client = getClient();
    const { rules, attributes } = await getActionTypeRulesAndAttributes({
      client,
    });

    dispatch(setCaseActionTypeAttribute(attributes));
    dispatch(setCaseActionTypeRule(rules));

    const { sub: auth0UserId } = user;

    const userInfo = await getUserInfo({ client, auth0UserId });
    if (!userInfo) {
      throw new Error(
        "UserInfo could not be grabbed for this user. Ensure Auth0UserId is populated."
      );
    }
    const { userId, actionTypeRoles } = userInfo;

    dispatch(setApi(restApiConsumer));
    dispatch(setUser(userId));
    dispatch(setUserRoles(actionTypeRoles));
    dispatch(setUserCaseActionTypeRoles(actionTypeRoles));

    const userRoles = await getUserRoles({ client, userId });
    dispatch(setUserRoles(userRoles));

    //if the user is not a annotator, set their default tool to something else
    if (!canUseComponent(userRoles, ANNOTATIONS_CREATE)) {
      dispatch(setActiveTool(WINDOWLEVEL));
    }

    dispatch(isAuthLoaded(true));

    initAutoSegmentApi(autoSegmentApiUrl, token, onError);
    NotaExporter.init(restApiConsumer);
  };
}

function setApi(api) {
  return {
    type: SET_API,
    api,
  };
}

function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

function isAuthLoaded(isLoaded) {
  return {
    type: IS_AUTH_LOADED,
    isLoaded,
  };
}

function setUserCaseActionTypeRoles(roles) {
  return {
    type: SET_USER_CASE_ACTION_TYPE_ROLES,
    roles,
  };
}

function setUserRoles(roles) {
  return {
    type: SET_USER_ROLES,
    roles,
  };
}

function setGlobalApplicationError(error) {
  return {
    type: SET_GLOBAL_APPLICATION_ERROR,
    error,
  };
}

export function canRoleUseComponent(action) {
  return (dispatch, getState) => {
    const state = getState();
    let { roles } = state.app;

    return canUseComponent(roles, action);
  };
}

function setCaseActionTypeAttribute(caseActionTypeAttribute) {
  return {
    type: SET_CASE_ACTION_TYPE_ATTRIBUTE,
    caseActionTypeAttribute: caseActionTypeAttribute,
  };
}

function setCaseActionTypeRule(caseActionTypeRule) {
  return {
    type: SET_CASE_ACTION_TYPE_RULE,
    caseActionTypeRule: caseActionTypeRule,
  };
}
