import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { setSelectedRecistSeries } from "../../actions/actionCreators/recistSeries";
import { seriesIdsWithFollowUpSelector } from "../../../store/annotationPage/series/selectors";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import styled from "styled-components";
import RecistTable from "./RecistTable";
import useRuleCheck from "../TumourResponseRulesValidators/tumourResponseRulesValidator";
import RuleViolationDialog from "../TumourResponseRulesValidators/RuleViolationDialog";
import { RECONSTRUCTION_PLANE_CHECK } from "../../tumourResponseRules/ruleNames";

const StyledGridSidebar = styled(Grid)`
  flex-grow: 1;
  height: 100%;
  width: 24%;
  position: relative;
`;

const StyledPaperContainer = styled(Paper)`
  position: absolute;
  right: 0;
  width: ${(props) => (props.$expanded ? "50vw" : "100%")};
  height: inherit;
  transition: width 0.375s, position 0.375s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  padding: 3px;
  height: 100%;
`;

const StyledPaperButton = styled(Paper)`
  position: absolute;
  right: 100%;
  border-radius: 2rem 0rem 0rem 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledGridInherit = styled(Grid)`
  height: inherit;
  width: inherit;
`;

const RecistSidebar = () => {
  const seriesIds = useSelector(seriesIdsWithFollowUpSelector);
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    if (!drawerOpen) {
      dispatch(setSelectedRecistSeries(seriesIds));
    } else {
      dispatch(
        setSelectedRecistSeries([seriesIds[0], seriesIds[seriesIds.length - 1]])
      );
    }
    setDrawerOpen(!drawerOpen);
  };

  const reconstructionCheckFail = useRuleCheck(RECONSTRUCTION_PLANE_CHECK);
  let drawerEnabled = seriesIds.length > 2;

  return (
    <StyledGridSidebar container>
      <RuleViolationDialog
        message={reconstructionCheckFail}
        show={reconstructionCheckFail !== null}
        showDeleteLesionButton={false}
        onAccept={() => dispatch(push("/studies"))}
        acceptText="Go back to studies page."
      />
      <StyledPaperContainer $expanded={drawerOpen}>
        <StyledGridInherit container wrap="nowrap" alignItems="center">
          {drawerEnabled ? (
            <StyledPaperButton elevation={0}>
              <IconButton onClick={toggleDrawer}>
                {drawerOpen ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </StyledPaperButton>
          ) : null}
          <StyledGridInherit item>
            <RecistTable expanded={drawerOpen} />
          </StyledGridInherit>
        </StyledGridInherit>
      </StyledPaperContainer>
    </StyledGridSidebar>
  );
};

export default RecistSidebar;
