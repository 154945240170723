import Select from "../Select";
import React from "react";

interface MultiSelectProps {
  options: string[];
  value: string[];
  onChange: (data: string[]) => void;
  placeholder?: string;
  components?: any;
}

interface OptionValueProps {
  value: string;
  label: string;
}

export default function MultiSelect({
  value,
  options,
  onChange,
  placeholder,
  components,
}: MultiSelectProps): React.ReactElement {
  const demapForSelect = (data: OptionValueProps[]): string[] =>
    data.map((datum: OptionValueProps) => datum.value);

  const mapForSelect = (data: string[]): OptionValueProps[] =>
    data.map((datum: string) => ({
      value: datum,
      label: datum,
    }));

  return (
    <Select
      isMulti
      value={mapForSelect(value)}
      options={mapForSelect(options)}
      onChange={(data: any) => {
        onChange(data ? demapForSelect(data) : []);
      }}
      placeholder={placeholder}
      components={components}
    />
  );
}
