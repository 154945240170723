import React from "react";
import PropTypes from "prop-types";

import MuiTableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { StyledTableCellIcon } from "../StyledComponents/StyledTableCellIcon";
import { StyledTableCellContainer } from "../StyledComponents/StyledTableCellContainer";

const TableFooter = ({ selectedSeries }) => {
  return (
    <MuiTableFooter>
      <TableRow>
        <StyledTableCellIcon style={{ width: "24px" }}>
          <AddIcon />
        </StyledTableCellIcon>
        <StyledTableCellContainer colSpan={selectedSeries.length + 1}>
          <Typography variant="h5">Notes (0)</Typography>
        </StyledTableCellContainer>
      </TableRow>
    </MuiTableFooter>
  );
};

TableFooter.propTypes = {
  selectedSeries: PropTypes.array.isRequired,
};

export default TableFooter;
