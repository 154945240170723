import cornerstoneTools from "@altis-labs/cornerstone-tools";

class CornerstoneLabelmapWrapper {
  setColorLUT(labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.colorLUT(labelmapIndex);
  }

  getSegmentVisibility(element, segmentIndex, labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { getters } = segmentationModule;
    return getters.isSegmentVisible(element, segmentIndex, labelmapIndex);
  }

  setSegmentVisibility(element, segmentIndex, labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.toggleSegmentVisibility(element, segmentIndex, labelmapIndex);
  }

  setActiveLabelmap(element, labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.activeLabelmapIndex(element, labelmapIndex);
  }

  setActiveSegment(element, segmentIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.activeSegmentIndex(element, segmentIndex);
  }

  getLabelmap2D(element) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { getters } = segmentationModule;
    return getters.labelmap2D(element);
  }

  assignColorLUTToLabelmap(labelmap3D, labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.colorLUTIndexForLabelmap3D(labelmap3D, labelmapIndex);
  }

  setSegmentColor(labelmap3D, segmentIndex, color) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.colorForSegmentIndexOfColorLUT(labelmap3D, segmentIndex, color);
  }

  getLabelmap2DByImageId(labelmap3D, imageId, height, width) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { getters } = segmentationModule;
    return getters.labelmap2DByImageIdIndex(labelmap3D, imageId, height, width);
  }

  setSegmentsOnLabelmap(labelmap2D) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.updateSegmentsOnLabelmap2D(labelmap2D);
  }

  clearLabelmaps(element) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.deleteAllLabelmaps(element);
  }

  deleteSegment(element, segmentIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.deleteSegment(element, segmentIndex);
  }

  deleteLabelmap(element, labelmapIndex) {
    let segmentationModule = cornerstoneTools.getModule("segmentation");
    let { setters } = segmentationModule;
    setters.deleteLabelmap3D(element, labelmapIndex);
  }
}

export default new CornerstoneLabelmapWrapper();
