import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlagIcon from "@material-ui/icons/Flag";
import IconButton from "@material-ui/core/IconButton";
import FlagCaseDialog from "./FlagCaseDialog";
import { addCaseFlagEntry } from "../actions/actionCreators/annotationPage/case";
import { push } from "connected-react-router";

FlagCaseButton.propTypes = {
  caseId: PropTypes.number.isRequired,
  addCaseFlagEntry: PropTypes.func.isRequired,
  goToStudies: PropTypes.func.isRequired,
};

function FlagCaseButton({ caseId, addCaseFlagEntry, goToStudies }) {
  const [showDialog, setShowDialog] = useState(false);

  let handleOnClick = () => {
    setShowDialog(true);
  };

  let submitFindings = (report) => {
    let { reason, details } = report;
    addCaseFlagEntry(caseId, reason, details);
    setShowDialog(false);
    goToStudies();
  };

  return (
    <>
      <FlagCaseDialog
        caseId={caseId}
        show={showDialog}
        onOk={submitFindings}
        onCancel={() => setShowDialog(false)}
      />
      <IconButton color="secondary" size="small" onClick={handleOnClick}>
        <FlagIcon fontSize="small" />
      </IconButton>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addCaseFlagEntry: (caseId, reason, details) =>
    dispatch(addCaseFlagEntry(caseId, reason, details)),
  goToStudies: () => dispatch(push("/studies")),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlagCaseButton);
