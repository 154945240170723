import {
  SET_STUDY_NOTES,
  UPDATE_STUDY_NOTE,
} from "../../../types/annotationPage/studyNotes";
import getClient from "../../../../apollo/getClient";
import { insertStudyNote } from "shared-api";

export function setStudyNotes(studyNotes) {
  return {
    type: SET_STUDY_NOTES,
    studyNotes,
  };
}

export function updateStudyNote(studyNote) {
  return {
    type: UPDATE_STUDY_NOTE,
    studyNote,
  };
}

export function updateStudyNotes(studyId, notes) {
  return async (dispatch, getState) => {
    let userId = getState().app.userId;
    const client = getClient();

    const studyNote = await insertStudyNote({ client, studyId, notes, userId });

    dispatch(updateStudyNote(studyNote));
  };
}
