import {
  ADD_TUMOUR_TO_DIAGNOSIS,
  CLEAR_DIAGNOSES,
  DiagnosesActionType,
  INSERT_DIAGNOSIS,
  REMOVE_TUMOUR_FROM_DIAGNOSIS,
  SET_DIAGNOSES,
} from "../../actions/types/diagnosis/diagnoses";
import produce from "immer";
import { normalizeDiagnoses } from "./normalizeDiagnoses";
import { NormalizedDiagnosisType } from "../../hooks/NormalizedDiagnosisType";

export type DiagnosesStateType = {
  allIds: number[];
  byId: Record<string, NormalizedDiagnosisType>;
};

const defaultState: DiagnosesStateType = { allIds: [], byId: {} };

export const diagnoses = (
  state = defaultState,
  action: { type: DiagnosesActionType; payload: any }
): DiagnosesStateType => {
  switch (action.type) {
    case SET_DIAGNOSES: {
      const diagnoses = action.payload;
      const { allIds, byId } = normalizeDiagnoses(diagnoses);
      return produce<DiagnosesStateType>(state, (draftState) => {
        draftState.allIds = allIds;
        draftState.byId = byId;
      });
    }
    case CLEAR_DIAGNOSES: {
      return produce<DiagnosesStateType>(state, (draftState) => {
        draftState.allIds = [];
        draftState.byId = {};
      });
    }
    case INSERT_DIAGNOSIS: {
      const diagnosis = action.payload;
      const { id } = diagnosis;

      const { allIds, byId } = normalizeDiagnoses([diagnosis]);

      return produce<DiagnosesStateType>(state, (draftState) => {
        const existingDiagnosis = draftState.byId[id];
        if (!existingDiagnosis) {
          draftState.allIds.push(allIds[0]);
        }
        draftState.byId[id] = byId[id];
      });
    }
    case REMOVE_TUMOUR_FROM_DIAGNOSIS: {
      const { tumourId } = action.payload;
      return produce<DiagnosesStateType>(state, (draftState) => {
        const { allIds, byId } = draftState;
        const diagnoses = allIds.map((id) => byId[id]);
        for (const diagnosis of diagnoses) {
          diagnosis.tumours = diagnosis.tumours.filter((id) => id !== tumourId);
        }
      });
    }
    case ADD_TUMOUR_TO_DIAGNOSIS: {
      const { tumourId, diagnosisId } = action.payload;
      return produce<DiagnosesStateType>(state, (draftState) => {
        const tumours = draftState.byId[diagnosisId].tumours;
        if (!tumours.includes(tumourId)) {
          tumours.push(tumourId);
        }
      });
    }
    default:
      return state;
  }
};
