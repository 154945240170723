import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkIcon from "@material-ui/icons/Link";
import { ImageViewerSynchronizer } from "../../tools/ImageViewerSyncrhonizer";

function SyncedScrollCheckbox() {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    let isSyncedScrollingEnabled = imageViewerSynchronizer.getEnabled();
    setChecked(isSyncedScrollingEnabled);
  }, []);

  let onSyncedScrollingChanged = (event) => {
    let enabled = event.target.checked;
    setChecked(enabled);

    let imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    imageViewerSynchronizer.setEnabled(enabled);
  };

  return (
    <Checkbox
      icon={<LinkOffIcon />}
      checked={checked}
      checkedIcon={<LinkIcon />}
      onChange={onSyncedScrollingChanged}
    />
  );
}

export default SyncedScrollCheckbox;
