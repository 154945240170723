import styled from "styled-components";
import { StyledTableCellContainer } from "./StyledTableCellContainer";
import grey from "@material-ui/core/colors/grey";

type MeasurementCellWrapperProps = {
  $buttonPadding?: boolean;
  float?: string;
  align?: string;
};

type StyledTableCellContainerWithWidthProps = {
  width: number;
  $highlight?: boolean;
};

export const MeasurementCellWrapper = styled(StyledTableCellContainer)<
  MeasurementCellWrapperProps
>`
  padding: ${(props) => (props.$buttonPadding ? "2px" : "2px 8px")};
  float: ${(props) => props.float ?? ""};
`;

export const StyledTableCellContainerWithWidth = styled(
  StyledTableCellContainer
)<StyledTableCellContainerWithWidthProps>`
  width: ${(props) => props.width};
  padding: 3px;
  background-color: ${(props) => (props.$highlight ? `${grey[700]}` : null)};
`;
