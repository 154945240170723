import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { unassignedBiopsyDetailsSelector } from "../store/annotationPage/biopsyDetails/selectors";
import { canRoleUseComponent } from "../actions/actionCreators/app";
import { BIOPSY_LINKING } from "../auth0/AuthRules";
import UnassignedBiopsyDialog from "./Dialogs/UnassignedBiopsyDialog";
import LesionsCheckDialogs from "./LesionList/LesionsCheckDialogs";
import { lesionsWithRoisSelector } from "../store/annotationPage/lesions/selectors";

function DoneAnnotationChecks({
  unassignedBiopsyDetails,
  canRoleUseComponent,
  setDisplayErrorIcon,
  setOpenConfirmDialog,
  doneClicked,
  setDoneClicked,
  yesClicked,
  onClickYes,
}) {
  const [
    showUnassignedBiopsyDialog,
    setShowUnassignedBiopsyDialog,
  ] = React.useState(false);
  const [unassignedBiopsyDetail, setUnassignedBiopsyDetail] = React.useState(
    null
  );
  const [lesionsToCheck, setLesionsToCheck] = useState(null);

  const lesions = useSelector(lesionsWithRoisSelector);

  useEffect(() => {
    if (doneClicked) {
      handleOnClickDone();
      setDoneClicked(false);
    }
  }, [doneClicked]);

  useEffect(() => {
    if (yesClicked) {
      onClickYes();
    }
  }, [yesClicked]);

  useEffect(() => {
    let firstUnassignedBiopsy =
      unassignedBiopsyDetails.length > 0 ? unassignedBiopsyDetails[0] : null;
    let unassignedBiopsyExists = firstUnassignedBiopsy !== null;

    setDisplayErrorIcon(unassignedBiopsyExists);
    setUnassignedBiopsyDetail(firstUnassignedBiopsy);
  }, [unassignedBiopsyDetails]);

  const handleOnClickDone = () => {
    setLesionsToCheck(lesions);
  };

  const onChecksPassed = () => {
    setLesionsToCheck(null);

    if (
      unassignedBiopsyDetail !== null &&
      canRoleUseComponent(BIOPSY_LINKING)
    ) {
      setShowUnassignedBiopsyDialog(true);
      return;
    }

    setOpenConfirmDialog(true);
  };

  const onChecksFailed = () => {
    setLesionsToCheck(null);
  };

  const onAcceptUnassignedBiopsyDialog = () => {
    setShowUnassignedBiopsyDialog(false);
    setOpenConfirmDialog(true);
  };

  const onCancelUnassignedBiopsyDialog = () => {
    setShowUnassignedBiopsyDialog(false);
  };

  return (
    <div>
      <LesionsCheckDialogs
        lesions={lesionsToCheck}
        onChecksPassed={onChecksPassed}
        onChecksFailed={onChecksFailed}
      />
      <UnassignedBiopsyDialog
        onAccepted={onAcceptUnassignedBiopsyDialog}
        onCancelled={onCancelUnassignedBiopsyDialog}
        open={showUnassignedBiopsyDialog}
        biopsyDetail={unassignedBiopsyDetail}
      />
    </div>
  );
}

DoneAnnotationChecks.propTypes = {
  unassignedBiopsyDetails: PropTypes.array.isRequired,
  canRoleUseComponent: PropTypes.func.isRequired,
  setDisplayErrorIcon: PropTypes.func.isRequired,
  setOpenConfirmDialog: PropTypes.func.isRequired,
  doneClicked: PropTypes.bool.isRequired,
  setDoneClicked: PropTypes.func.isRequired,
  yesClicked: PropTypes.bool.isRequired,
  onClickYes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  unassignedBiopsyDetails: unassignedBiopsyDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoneAnnotationChecks);
