import React from "react";
import { useDispatch } from "react-redux";
import {
  removeRecistViewerThunk,
  addRecistViewerThunk,
} from "../../../actions/actionCreators/annotationPage/thunks";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin-top: 6px;
  width: 100%;
  text-transform: none;
`;

const ToggleViewerButton = ({ seriesId, inViewer, disabled }) => {
  const dispatch = useDispatch();
  const toggleViewer = (seriesId) => {
    inViewer
      ? dispatch(removeRecistViewerThunk(seriesId))
      : dispatch(addRecistViewerThunk(seriesId));
  };
  return (
    <StyledButton
      variant="outlined"
      disabled={disabled}
      onClick={() => toggleViewer(seriesId)}
    >
      {inViewer ? "Remove from View" : "Add to View"}
    </StyledButton>
  );
};

ToggleViewerButton.propTypes = {
  seriesId: PropTypes.number.isRequired,
  inViewer: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ToggleViewerButton;
