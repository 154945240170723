import getClient from "../../apollo/getClient";
import { getTumours, insertTumour, updateTumour, TumourType } from "shared-api";

export async function addTumour(
  diagnosisId: number,
  measurable: boolean,
  roiId: number,
  burden: number,
  seriesId: number
): Promise<TumourType> {
  const client = getClient();

  const tumours = await getTumours({ client, diagnosisId, roiId });
  if (tumours.length > 0) {
    const { id: tumourId } = tumours[0];
    return await updateTumour({ client, tumourId, measurable, burden });
  } else {
    return await insertTumour({
      client,
      diagnosisId,
      measurable,
      roiId,
      burden,
      seriesId,
    });
  }
}
