import { gql } from "@apollo/client/core";

export const TUMOUR_BURDEN_FRAGMENT = gql`
  fragment TumourBurden on tumour_burden {
    id
    burden
    series: series_tumour_burden_maps {
      id: series_id
    }
  }
`;
