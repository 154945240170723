import cornerstone from "cornerstone-core";

export function getImageSize(imageId) {
  let cachedImages = cornerstone.imageCache.cachedImages;
  let desiredImage = cachedImages.filter(
    (cachedImage) => cachedImage.imageId === imageId
  );
  let size = {
    height: desiredImage[0].image.height,
    width: desiredImage[0].image.width,
  };
  return size;
}
