import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { UserInfoType } from "../types/UserInfoType";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { USER_INFO_FRAGMENT } from "../fragments/UserInfoFragment";
import { UserInfoFragmentType } from "../fragments/UserInfoFragmentType";
import { parseUserInfo } from "../parsers/parseUserInfo";

const QUERY = gql`
  query GetUserInfo($auth0_user_id: String!) {
    users(where: { auth0_user_id: { _eq: $auth0_user_id } }) {
      ...UserInfo
    }
  }
  ${USER_INFO_FRAGMENT}
`;

type Input = {
  auth0UserId: string;
};

type Data = {
  users: UserInfoFragmentType[];
};

export async function getUserInfo({
  client,
  ...input
}: ClientProps & Input): Promise<UserInfoType | null> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ auth0UserId: auth0_user_id }) => ({
      auth0_user_id,
    }),
    parseData: ({ users }: Data) => {
      if (users.length === 0) {
        return null;
      }

      if (users.length > 1) {
        throw new Error("More than 1 user found for auth0UserId");
      }

      return parseUserInfo(users[0]);
    },
  });
}
