import { getToolSliceData } from "../ToolUtils";
import { ToolTypes } from "shared-api";

export function isContourDataInSlice(toolData, contoursToolRoi, imageId) {
  let sliceToolData = getToolSliceData(
    contoursToolRoi,
    imageId,
    ToolTypes.FREEHANDROITOOL
  );

  for (const existingToolData of sliceToolData) {
    if (
      existingToolData.area === toolData.area &&
      existingToolData.points === toolData.points
    ) {
      return true;
    }
  }

  return false;
}
