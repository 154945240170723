import { SET_ALL_ORGANS } from "../../../types/annotationPage/organs";
import getClient from "../../../../apollo/getClient";
import { getAnatomicalStructuresTree } from "./utilities/getAnatomicalStructuresTree";
import {
  AnatomicalStructureType,
  getEnabledAnatomicalStructures,
} from "shared-api";

const setAllOrgans = (anatomicalStructures: AnatomicalStructureType[]) => ({
  type: SET_ALL_ORGANS,
  anatomicalStructures,
});

export const getAnatomicalStructures = () => async (dispatch: any) => {
  const client = getClient();
  const anatomicalStructures = await getEnabledAnatomicalStructures({ client });

  const anatomicalStructuresTree = getAnatomicalStructuresTree(
    anatomicalStructures
  );

  dispatch(setAllOrgans(anatomicalStructuresTree));
};
