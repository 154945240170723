const ToolControllerEvents = {
  ELLIPSE_UPDATED: "ELLIPSE_UPDATED",
  CONTOUR_UPDATED: "CONTOUR_UPDATED",
  CONTOUR_RIGHT_CLICKED: "CONTOUR_RIGHT_CLICKED",
  CONTOUR_DOUBLE_CLICKED: "CONTOUR_DOUBLE_CLICKED",
  MEASURE_UPDATED: "MEASURE_UPDATE",
  ELLIPSE_PROBE_CREATED: "ELLIPSE_PROBE_CREATED",
  LABELMAP_UPDATED: "LABELMAP_UPDATED",
  CONTOUR_COPY_HOT_KEY_PRESSED: "CONTOUR_COPY_HOT_KEY_PRESSED",
  CONTOUR_PASTE_HOT_KEY_PRESSED: "CONTOUR_PASTE_HOT_KEY_PRESSED",
  USER_MASK_COPY_HOT_KEY_PRESSED: "USER_MASK_COPY_HOT_KEY_PRESSED",
  USER_MASK_PASTE_HOT_KEY_PRESSED: "USER_MASK_PASTE_HOT_KEY_PRESSED",
  BRUSH_SIZE_CHANGED: "BRUSH_SIZE_CHANGED",
};
export default ToolControllerEvents;
