import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation UpdateCaseAssignmentUserID(
    $user_id: Int!
    $case_assignment_id: Int!
  ) {
    update_case_assignment(
      _set: { user_id: $user_id }
      where: { case_assignment_id: { _eq: $case_assignment_id } }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  userId: number;
  caseAssignmentId: number;
};

export async function updateCaseAssignmentUserId({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      userId: user_id,
      caseAssignmentId: case_assignment_id,
    }) => ({
      user_id,
      case_assignment_id,
    }),
    parseData: () => undefined,
  });
}
