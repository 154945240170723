import {
  updateCaseAssignmentProgress,
  getCaseWorkListByUserId,
} from "shared-api";
import { normalizeCases } from "../../../tools/normalizeCases";
import {
  IS_CASES_LOADED,
  SET_ASSIGNED_CASES,
  SET_UNASSIGNED_CASES,
} from "../../types/worklistPage/cases";
import { SET_ASSIGNED_CASE_PROGRESS } from "../../types/annotationPage/case";
import getClient from "../../../apollo/getClient";

export function isCasesLoaded(isLoaded) {
  return {
    type: IS_CASES_LOADED,
    isLoaded: isLoaded,
  };
}

export function loadCases() {
  return async (dispatch, getState) => {
    dispatch(isCasesLoaded(false));

    let api = getState().app.api;
    let userId = getState().app.userId;

    const client = getClient();
    const { assignedCases, unassignedCases } = await getCaseWorkListByUserId({
      client,
      userId,
    });

    let normalizedUnassignedCases = normalizeCases(
      "unassignedCases",
      unassignedCases
    );

    let normalizedAssignedCases = normalizeCases(
      "assignedCases",
      assignedCases
    );

    dispatch(setAssignedCases(normalizedAssignedCases));
    dispatch(setUnassignedCases(normalizedUnassignedCases));

    dispatch(isCasesLoaded(true));
  };
}

export function setAssignedCases(cases) {
  return {
    type: SET_ASSIGNED_CASES,
    cases,
  };
}

export function setUnassignedCases(cases) {
  return {
    type: SET_UNASSIGNED_CASES,
    cases,
  };
}

export function setAssignedCaseItemProgress(caseAssignmentId, progress) {
  return {
    type: SET_ASSIGNED_CASE_PROGRESS,
    caseAssignmentId,
    progress,
  };
}

export function setAssignedCaseProgress(caseAssignmentId, progress) {
  return async (dispatch) => {
    dispatch(setAssignedCaseItemProgress(caseAssignmentId, progress));

    const client = getClient();
    await updateCaseAssignmentProgress({ client, caseAssignmentId, progress });
  };
}
