import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedRecistSeriesByIndex } from "../../../actions/actionCreators/recistSeries";
import { viewerSeriesSelector } from "../../../../store/annotationPage/viewers/selectors";
import PropTypes from "prop-types";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import grey from "@material-ui/core/colors/grey";

import ToggleViewerButton from "../ToggleViewerButton";
import { formatSeriesDate } from "../../../../utils/formatSeriesDate";
import { formatFollowUpLabel } from "../../../../utils/formatFollowUpLabel";

const StyledSelect = styled(Select)`
  width: 100%;
  ${(props) => (props.$highlight ? `background-color: ${grey[700]}` : null)}
`;

const StyledTableCell = styled(TableCell)`
  padding-left: 10px;
  padding-right: 10px;
  width: ${(props) => `calc(100% / ${props.length})`};
`;

const TableHeader = ({ series, selectedSeries }) => {
  const dispatch = useDispatch();
  const viewerSeriesIds = useSelector(viewerSeriesSelector);
  const currentSeriesId = useSelector(
    (state) => state.annotationPage.recists.recistSeries.currentSeriesId
  );

  const handleSelectChange = (event, selectIndex) => {
    dispatch(setSelectedRecistSeriesByIndex(selectIndex, event.target.value));
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {selectedSeries.map((seriesId, selectIndex) => {
          let inViewer = viewerSeriesIds.includes(seriesId);
          let buttonDisabled = inViewer && viewerSeriesIds.length === 1;
          let highlightSeries = currentSeriesId === seriesId;
          const selectedMatchedSeries = series.find(
            (sery) => sery.id === selectedSeries[selectIndex]
          );
          const selectedHasFollowUp =
            selectedMatchedSeries !== undefined &&
            selectedMatchedSeries.followUpOrder !== undefined;
          return (
            <StyledTableCell
              key={selectIndex}
              length={selectedSeries.length}
              colSpan={selectedSeries.length === 1 ? 2 : 1}
            >
              <StyledSelect
                value={selectedHasFollowUp ? selectedSeries[selectIndex] : ""}
                onChange={(event) => handleSelectChange(event, selectIndex)}
                disabled={selectedSeries.length > 2}
                $highlight={highlightSeries}
              >
                {series
                  .filter(
                    (seriesItem) => seriesItem.followUpOrder !== undefined
                  )
                  .map((seriesItem) => {
                    const { id, seriesDate, followUpOrder } = seriesItem;
                    const followUpLabel = formatFollowUpLabel(followUpOrder);
                    const seriesDateLabel = formatSeriesDate(seriesDate);
                    return (
                      <MenuItem
                        dense
                        value={id}
                        key={`${selectIndex}-${followUpOrder}`}
                      >
                        <ListItemText
                          primary={followUpLabel}
                          secondary={seriesDateLabel}
                        />
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
              <ToggleViewerButton
                seriesId={seriesId}
                inViewer={inViewer}
                disabled={buttonDisabled}
              />
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  series: PropTypes.array.isRequired,
  selectedSeries: PropTypes.array.isRequired,
};

export default TableHeader;
