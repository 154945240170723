import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { TumourFragmentType } from "../fragments/TumourFragmentType";
import { parseTumour } from "../parsers/parseTumour";
import { TumourType } from "../types/TumourType";
import { TUMOUR_FRAGMENT } from "../fragments/TumourFragment";

const MUTATION = gql`
  mutation InsertTumours($tumours: [tumour_insert_input!]!) {
    insert_tumour(objects: $tumours) {
      returning {
        ...Tumour
      }
    }
  }
  ${TUMOUR_FRAGMENT}
`;

type Input = {
  diagnosisId: number;
  measurable: boolean;
  roiId: number;
  burden: number;
  seriesId: number;
};

type Data = {
  insert_tumour: { returning: TumourFragmentType[] };
};

export async function insertTumour({
  client,
  ...input
}: ClientProps & Input): Promise<TumourType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      diagnosisId: diagnosis_id,
      measurable,
      roiId: roi_id,
      burden,
      seriesId: series_id,
    }) => {
      return {
        tumours: {
          measurable,
          diagnosis_id,
          tumour_roi_maps: {
            data: {
              roi_id,
            },
          },
          tumour_burdens: {
            data: {
              burden: burden,
              series_tumour_burden_maps: {
                data: {
                  series_id,
                },
              },
            },
          },
        },
      };
    },
    parseData: ({ insert_tumour: { returning } }: Data) => {
      if (returning.length !== 1) {
        throw new Error("tumours not inserted properly");
      }
      return parseTumour(returning[0]);
    },
  });
}
