import React from "react";
import CaseActionTypeAttribute from "../../../models/CaseActionTypeAttribute";
import { MultiSelect } from "nota-components";
import { components, MultiValueProps, OptionProps, Props } from "react-select";
import ActionTypeChip from "../../ActionTypeChip";
import styled from "styled-components";

interface ActionTypeSelectProps {
  value: string[];
  options: string[];
  onChange: (data: string[]) => void;
  placeholder?: string;
  caseActionTypeAttribute: CaseActionTypeAttribute;
}

const Wrapper = styled.div`
  margin: 1.5px;
`;

export default function ActionTypeSelect({
  options,
  value,
  caseActionTypeAttribute,
  ...props
}: ActionTypeSelectProps): React.ReactElement {
  function MultiValue({ data, selectProps, setValue }: MultiValueProps<any>) {
    const onDeleteClicked = () => {
      const initial = selectProps.value;
      const updated = initial.filter(
        (initialData: Props) => initialData.value !== data.value
      );
      setValue(updated, "remove-value");
    };
    return (
      <Wrapper>
        <ActionTypeChip
          actionType={data.value}
          caseActionTypeAttribute={caseActionTypeAttribute}
          onDelete={onDeleteClicked}
        />
      </Wrapper>
    );
  }
  function Option(props: OptionProps<any, any>) {
    return (
      <components.Option {...props}>
        <ActionTypeChip
          actionType={props.data.value}
          caseActionTypeAttribute={caseActionTypeAttribute}
        />
      </components.Option>
    );
  }

  return (
    <MultiSelect
      {...props}
      options={options}
      value={value}
      components={{
        MultiValue,
        Option,
      }}
    />
  );
}
