import { insertRois } from "shared-api";
import labelmapProvider from "../../../../labelmapUtils/initLabelmapProvider";

export async function createRois(client, seriesIds, lesion, createdBy) {
  const {
    id: lesionId,
    locationId: anatomicalStructureId,
    type: lesionType,
  } = lesion;
  const rois = await insertRois({
    client,
    seriesIds,
    lesionId,
    createdBy,
    anatomicalStructureId,
    lesionType,
  });

  for (const roi of rois) {
    const { seriesId } = roi;
    let labelmapId = labelmapProvider.getLabelmapId(seriesId);
    labelmapProvider.setLabelmapColors(seriesId, labelmapId);
    roi.labelmapId = labelmapId;
  }

  return rois;
}
