import cornerstone from "cornerstone-core";

export default class CurrentImageIdWatcher {
  constructor() {
    this.cornerstoneElement = null;
    this.currentImageId = null;
  }

  setCornerstoneElement(cornerstoneElement) {
    if (this.cornerstoneElement) {
      this.cornerstoneElement.removeEventListener(
        cornerstone.EVENTS.NEW_IMAGE,
        this.onImageRendered
      );
    }

    this.cornerstoneElement = cornerstoneElement;

    if (!cornerstoneElement) {
      return;
    }

    const targetEnabledElement = cornerstone.getEnabledElement(
      cornerstoneElement
    );
    this.currentImageId = targetEnabledElement.image.imageId;

    this.cornerstoneElement.addEventListener(
      cornerstone.EVENTS.NEW_IMAGE,
      this.onImageRendered,
      false
    );
  }

  onImageRendered = (event) => {
    this.currentImageId = event.detail.image.imageId;
  };

  getCurrentImageId() {
    return this.currentImageId;
  }
}
