import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertEllipse($roi_id: Int!, $data: jsonb!, $created_by: Int!) {
    insert_ellipse_one(
      object: { roi_id: $roi_id, data: $data, created_by: $created_by }
      on_conflict: { constraint: ellipse_roi_id_key, update_columns: data }
    ) {
      ellipse_id
    }
  }
`;

type Input = {
  roiId: number;
  data: Record<string, unknown>;
  createdBy: number;
};

export async function insertEllipse({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ roiId: roi_id, data, createdBy: created_by }) => ({
      roi_id,
      data,
      created_by,
    }),
    parseData: () => undefined,
  });
}
