let iRecistRules = {
  maxTargetLesions: function (state) {
    // Need to also verify whether or not they are lymph nodes or not
    if (state.annotationPage.lesions.allIds.length >= 5) {
      return {
        failure: "You cannot have more than 5 target lesions!",
        success: null,
      };
    } else {
      return {
        failure: null,
        success: ":)",
      };
    }
  },
  maxTargetLesionsOrgan: function (state) {
    // Todo: Need each lesion to be associated with an organ
  },
  requiredTargetLesionSize: function (state) {
    // Todo: associate the measurement with a lesion, and check its size
  },
  maxNewLesion: function (state) {
    // For iRecist only: new lesions are not just ignored, should also be recorded
    // in follow up studies
  },
  requiredNewLesionSize: function (state) {
    // They still have a min size as well
  },
};

export default iRecistRules;
