import { createSelector } from "reselect";
import { lesionsSelector } from "../../../store/annotationPage/lesions/selectors";
import { lesionEvaluationTypes } from "shared-api";

const getLesionEvaluationIds = (state) =>
  state.annotationPage.recists.evaluations.lesions.allIds;
const getLesionEvaluations = (state) =>
  state.annotationPage.recists.evaluations.lesions.byId;
const getRoiRecistEvaluations = (state) =>
  state.annotationPage.recists.evaluations.rois.byId;
const getRoiRecistEvaluationIds = (state) =>
  state.annotationPage.recists.evaluations.rois.allIds;

const getClassificationLesions = (targetClassification) => {
  return createSelector(
    getLesionEvaluationIds,
    getLesionEvaluations,
    (lesionIds, lesionEvaluations) => {
      return lesionIds
        .map((id) => lesionEvaluations[id])
        .filter(
          (lesion) => lesion["targetClassification"] === targetClassification
        );
    }
  );
};

const getLesionIdsByClassification = (targetClassification) => {
  return createSelector(
    getLesionEvaluationIds,
    getLesionEvaluations,
    (lesionIds, lesionEvaluations) => {
      return lesionIds
        .map((id) => lesionEvaluations[id])
        .filter(
          (lesion) => lesion["targetClassification"] === targetClassification
        )
        .map((item) => {
          return item.lesionId;
        });
    }
  );
};

export const roiRecistEvaluationsSelector = createSelector(
  getRoiRecistEvaluationIds,
  getRoiRecistEvaluations,
  (roiIds, roiRecistEvaluations) => {
    return roiIds.map((id) => roiRecistEvaluations[id]);
  }
);

export const targetLesionEvaluationsSelector = createSelector(
  getClassificationLesions(lesionEvaluationTypes.TARGET),
  (targetLesions) => targetLesions
);

export const nonTargetLesionEvaluationsSelector = createSelector(
  getClassificationLesions(lesionEvaluationTypes.NONTARGET),
  (targetLesions) => targetLesions
);

export const targetLesionsSelector = createSelector(
  lesionsSelector,
  getLesionIdsByClassification(lesionEvaluationTypes.TARGET),
  (lesions, targetLesionIds) =>
    lesions.filter((lesion) => targetLesionIds.includes(lesion.id))
);

export const nonTargetLesionsSelector = createSelector(
  lesionsSelector,
  getLesionIdsByClassification(lesionEvaluationTypes.NONTARGET),
  (lesions, nontargetLesionIds) =>
    lesions.filter((lesion) => nontargetLesionIds.includes(lesion.id))
);

export const newLesionsSelector = createSelector(
  lesionsSelector,
  getLesionIdsByClassification(lesionEvaluationTypes.TARGET),
  getLesionIdsByClassification(lesionEvaluationTypes.NONTARGET),
  (lesions, targetIds, nonTargetIds) => {
    return lesions.filter(
      (lesion) =>
        !targetIds.includes(lesion.id) && !nonTargetIds.includes(lesion.id)
    );
  }
);

export const existsUnsavedNewLesionsSelector = createSelector(
  newLesionsSelector,
  (newLesions) => {
    return newLesions.length > 0;
  }
);
