import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";

let client: ApolloClient<NormalizedCacheObject>;

const getClient = (
  getToken?: (args: void) => Promise<string>
): ApolloClient<NormalizedCacheObject> => {
  if (!client) {
    if (!getToken) {
      throw new Error("getToken not specified when creating new apollo client");
    }
    const apiUri = window._env_.REACT_APP_GRAPHQL_URL;

    const httpLink = new HttpLink({ uri: apiUri });

    const authMiddleware = setContext((_operation) =>
      getToken().then((token) => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      })
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach((graphQLErrors) =>
          console.error(
            `[GraphQL error]\n\n${JSON.stringify(graphQLErrors, null, 2)}`
          )
        );

      if (networkError)
        console.error(
          `[GraphQL Network error]\n\n${JSON.stringify(networkError, null, 2)}`
        );
    });

    const retryLink = new RetryLink();

    client = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: { query_root: { queryType: true } },
      }),
      link: from([retryLink, authMiddleware, errorLink, httpLink]),
    });
  }

  return client;
};

export default getClient;
