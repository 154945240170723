import { StyledToggleButton } from "../StyledComponents/StyledToggleButton";
import { StyledToggleGroup } from "../StyledComponents/StyledToggleGroup";
import React from "react";

const MeasurableToggle = ({ isMeasurable, setMeasurable }) => {
  const handleMeasurable = (event, measurable) => {
    if (measurable === null) {
      return;
    }
    setMeasurable(measurable);
  };

  return (
    <StyledToggleGroup
      value={isMeasurable}
      exclusive
      onChange={handleMeasurable}
      size={"small"}
    >
      <StyledToggleButton groupSize={2} value={true}>
        Measurable
      </StyledToggleButton>
      <StyledToggleButton groupSize={2} value={false}>
        Non-measurable
      </StyledToggleButton>
    </StyledToggleGroup>
  );
};

export default MeasurableToggle;
