import { useQuery } from "@apollo/client";
import { useParsedQueryReturnType } from "./useParsedQueryReturnType";
import { ParsedQueryArgs } from "./ParsedQueryArgs";
import { useMemo } from "react";
import { ApolloQueryResult } from "@apollo/client/core/types";

export function useParsedQuery<TInput, TOutput, TData, TVariables>({
  query,
  input,
  options,
  parseVariables,
  parseData,
}: ParsedQueryArgs<
  TInput,
  TOutput,
  TData,
  TVariables
>): useParsedQueryReturnType<TInput, TOutput, TVariables> {
  const variables = parseVariables ? parseVariables(input) : undefined;

  const { data, ...otherResults } = useQuery<TData, TVariables>(query, {
    ...options,
    variables,
  });

  let output: TOutput | undefined;

  const { loading, error, refetch: rawRefetch } = otherResults;
  if (!loading && !error) {
    if (!data) {
      throw new Error("data null but not loading/error in useParsedQuery");
    }

    output = parseData(data, input);
  }

  const refetch: (
    newInput?: TInput
  ) => Promise<ApolloQueryResult<TOutput>> = async (newInput) => {
    const variables =
      newInput && parseVariables ? parseVariables(newInput) : undefined;
    const { data, ...otherResults } = await rawRefetch(variables);
    const output = parseData(data, input);
    return { ...otherResults, data: output };
  };

  const memoizedOutput = useMemo(() => output, [data, loading, error]);

  return { ...otherResults, data: memoizedOutput, refetch };
}
