import { gql } from "@apollo/client/core";

export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on users {
    userId: user_id
    caseActionTypeRoles: user_case_action_type_roles {
      actionType: action_type
    }
  }
`;
