import React from "react";
import { useDrop } from "react-dnd";
import { DragAndDropTypes } from "./DragAndDropTypes";
import ImageViewer from "./ImageViewer";
import { setViewerSeries } from "../actions/actionCreators/annotationPage/viewers";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function ImageViewerContainer(props) {
  const { viewer, setViewerSeries, viewerWidth, ...other } = props;

  const [, drop] = useDrop({
    accept: DragAndDropTypes.SERIES,
    drop: (item) => setViewerSeries(viewer.id, item.seriesId),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  let style = {
    width: viewerWidth,
    height: "100%",
    minHeight: "200px",
    padding: "1px",
  };
  if (viewer.isActive) {
    style.borderBottom = "3px solid white";
  } else {
    style.borderBottom = "3px solid black";
  }

  return (
    <div style={style} ref={drop}>
      <div
        style={{
          width: "100%",
          height: "100%",
          borderTop: `3px solid ${viewer.color}`,
        }}
      >
        <ImageViewer viewer={viewer} {...other} />
      </div>
    </div>
  );
}

ImageViewerContainer.propTypes = {
  viewer: PropTypes.object.isRequired,
  setViewerSeries: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setViewerSeries: (viewerId, seriesId) =>
    dispatch(setViewerSeries(viewerId, seriesId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageViewerContainer);
