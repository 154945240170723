import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import workListPage from "./worklistPage/workListPage";
import app from "./app";
import config from "./config";
import { caseReducer } from "./annotationPage/case";
import { lesions } from "./annotationPage/lesions";
import { organs } from "./annotationPage/organs";
import { series } from "./annotationPage/series";
import { tools } from "./annotationPage/tools";
import { viewers } from "./annotationPage/viewers";
import { rois } from "./annotationPage/rois";
import { studyNotes } from "./annotationPage/studyNotes";
import { masks } from "./annotationPage/masks";
import { biopsyDetails } from "./annotationPage/biopsyDetails";
import recistReducer from "../RECIST/reducers/index";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    config,
    workListPage,
    annotationPage: combineReducers({
      case: caseReducer,
      lesions,
      organs,
      rois,
      series,
      studyNotes,
      biopsyDetails,
      tools,
      viewers,
      masks,
      recists: recistReducer,
    }),
  });
export default createRootReducer;
