import { gql } from "@apollo/client/core";
import { ActionTypeType } from "../types/ActionTypeType";
import { CaseAssignmentProgressType } from "../types/CaseAssignmentProgressType";
import { useParsedMutationReturnType } from "../../../../utilities/graphql/useParsedMutationReturnType";
import { useParsedMutation } from "../../../../utilities/graphql/useParsedMutation";

const MUTATION = gql`
  mutation InsertCaseAssignment(
    $case_id: Int!
    $user_id: Int!
    $action_type: enum_case_action_type_enum!
    $progress: enum_case_progress_enum!
  ) {
    insert_case_assignment_one(
      object: {
        case_id: $case_id
        user_id: $user_id
        action_type: $action_type
        progress: $progress
      }
      on_conflict: {
        constraint: action_type_case_id_user_id_unique
        update_columns: [progress]
      }
    ) {
      case_assignment_id
    }
  }
`;

type Input = {
  caseId: number;
  userId: number;
  actionType: ActionTypeType;
  progress: CaseAssignmentProgressType;
};

type Variables = {
  case_id: number;
  user_id: number;
  action_type: ActionTypeType;
  progress: CaseAssignmentProgressType;
};

type Data = {
  insert_case_assignment_one: { case_assignment_id: number };
};

export function useInsertCaseAssignment(): useParsedMutationReturnType<
  Input,
  number
> {
  return useParsedMutation<Input, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables: ({
      caseId: case_id,
      userId: user_id,
      actionType: action_type,
      progress,
    }) => ({ case_id, user_id, action_type, progress }),
    parseData: ({ insert_case_assignment_one: { case_assignment_id } }) =>
      case_assignment_id,
  });
}
