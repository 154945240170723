import { areCountsBalanced } from "./adjacentListIsBalanced";

export function findFirstImbalancedImageId(
  detectionImageId,
  annotationImageIds,
  seriesImageIds
) {
  let detectionIndex = seriesImageIds.indexOf(detectionImageId);
  let annotationIndices = annotationImageIds.map((imageId) =>
    seriesImageIds.indexOf(imageId)
  );

  if (
    detectionIndex === -1 ||
    annotationIndices.some((index) => index === -1)
  ) {
    console.log("Detection/contour index not found in series image ids");
    return undefined;
  }

  if (!annotationImageIds.includes(detectionImageId)) {
    return detectionImageId;
  }

  let numberOfImageIdsAfterDetection = 0;
  let firstMissingAnnotationImageIdAfterDetection = null;
  for (let i = detectionIndex + 1; i < seriesImageIds.length; i++) {
    let imageId = seriesImageIds[i];
    if (annotationImageIds.includes(imageId)) {
      numberOfImageIdsAfterDetection++;
    } else {
      firstMissingAnnotationImageIdAfterDetection = imageId;
      break;
    }
  }

  let numberOfImageIdsBeforeDetection = 0;
  let firstMissingAnnotationImageIdBeforeDetection = undefined;
  for (let i = detectionIndex - 1; i >= 0; i--) {
    let imageId = seriesImageIds[i];
    if (annotationImageIds.includes(imageId)) {
      numberOfImageIdsBeforeDetection++;
    } else {
      firstMissingAnnotationImageIdBeforeDetection = imageId;
      break;
    }
  }

  let firstImbalancedImageId = undefined;
  if (
    !areCountsBalanced(
      numberOfImageIdsAfterDetection,
      numberOfImageIdsBeforeDetection,
      2
    )
  ) {
    firstImbalancedImageId =
      numberOfImageIdsAfterDetection < numberOfImageIdsBeforeDetection
        ? firstMissingAnnotationImageIdAfterDetection
        : firstMissingAnnotationImageIdBeforeDetection;
  }
  return firstImbalancedImageId;
}
