let roiToolStyle = {
  handleSize: {
    active: 1.5,
    selected: 2,
    passive: 1.5,
  },
  lineWidth: {
    active: 3,
    selected: 4,
    passive: 1.5,
  },
};

export default roiToolStyle;
