import {
  IS_CASE_DATA_LOADED,
  SET_POSSIBLE_CASE_ASSIGNMENTS,
  SET_SELECTED_ASSIGNED_CASE,
} from "../../types/annotationPage/case";
import { setAssignedCaseItemProgress } from "../worklistPage/cases";
import { insertCaseFlagEntry, updateCaseAssignmentProgress } from "shared-api";
import getClient from "../../../apollo/getClient";

export function setSelectedAssignedCase(caseAssignment, newUserId) {
  return {
    type: SET_SELECTED_ASSIGNED_CASE,
    caseAssignment,
    newUserId,
  };
}

export function isCaseLoaded(isLoaded) {
  return {
    type: IS_CASE_DATA_LOADED,
    isLoaded: isLoaded,
  };
}

export function setPossibleCaseAssignments(caseAssignments) {
  return {
    type: SET_POSSIBLE_CASE_ASSIGNMENTS,
    caseAssignments,
  };
}

export function setAssignedCaseProgressThunk(caseAssignmentId, progress) {
  return async (dispatch) => {
    dispatch(setAssignedCaseItemProgress(caseAssignmentId, progress));

    const client = getClient();
    await updateCaseAssignmentProgress({ client, caseAssignmentId, progress });
  };
}

export function addCaseFlagEntry(caseId, reason, details) {
  return async (dispatch, getState) => {
    let userId = getState().app.userId;

    const client = getClient();
    await insertCaseFlagEntry({
      client,
      caseId,
      createdBy: userId,
      reason,
      details,
    });
  };
}
