import cornerstoneTools from "@altis-labs/cornerstone-tools";
import _ from "lodash/core";

function clearRoiData(roiId, toolType) {
  const globalToolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(globalToolStates).forEach((key) => {
    const sliceToolState = globalToolStates[key];

    if (_.has(sliceToolState, toolType)) {
      const roiToolData = sliceToolState[toolType].data;

      sliceToolState[toolType].data = roiToolData.filter(
        (data) => data.roi.id !== roiId
      );
    }
  });
}

function getRoiToolStateDataForRoi(roi) {
  const roiToolStateData = [];

  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    if (sliceToolState[roi.type]) {
      const roiToolData = sliceToolState[roi.type].data;

      roiToolData.forEach((data) => {
        data.imageId = key;
        if (data.roi && roi.id === data.roi.id) {
          roiToolStateData.push(data);
        }
      });
    }
  });

  return roiToolStateData;
}

export function deleteSingleToolDataFromImage(toolData, imageId, toolType) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  if (!toolStates.hasOwnProperty(imageId)) {
    throw new Error(`No tool state for imageId: ${imageId}`);
  }

  let imageToolsStates = toolStates[imageId];

  if (!imageToolsStates.hasOwnProperty(toolType)) {
    throw new Error(`No image tool state for toolType: ${toolType}`);
  }

  let imageToolStatesData = imageToolsStates[toolType];

  imageToolStatesData.data = imageToolStatesData.data.filter(
    (stateToolData) => stateToolData !== toolData
  );
}

function deleteToolDataFromImage(toolType, roiId, imageId) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    let keepData = [];
    const sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      const roiToolData = sliceToolState[toolType].data;

      roiToolData.forEach((data) => {
        if (!data.roi || roiId !== data.roi.id || imageId !== key) {
          keepData.push(data);
        }
      });
      sliceToolState[toolType].data = keepData;
    }
  });
}

function clearToolSliceData(toolType) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      sliceToolState[toolType].data = [];
    }
  });
}

function getToolStateIndices(toolType, imageId, roiId) {
  const states =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let imageToolStates = states[imageId];
  if (!imageToolStates) {
    return [];
  }

  let { data: imageToolState } = imageToolStates[toolType];
  if (!imageToolState) {
    return [];
  }

  let roiToolStates = [];

  imageToolState.forEach((state, index) => {
    let { roi } = state;
    if (roi.id === roiId) {
      roiToolStates.push(index);
    }
  });

  return roiToolStates;
}

function getToolSliceData(roi, imageId, toolType) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let toolSliceData = [];

  Object.keys(toolStates).forEach(function (key) {
    let sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      const roiToolData = sliceToolState[toolType].data;

      roiToolData.forEach((data) => {
        if (data.roi && roi.id === data.roi.id && imageId === key) {
          toolSliceData.push(data);
        }
      });
    }
  });

  return toolSliceData;
}

function setToolSliceData(toolType, imageId, toolData) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let toolSliceData = [];

  let sliceToolStates = toolStates[imageId];
  if (!sliceToolStates) {
    toolStates[imageId] = {};
    sliceToolStates = toolStates[imageId];
  }

  let toolTypeSliceToolStates = sliceToolStates[toolType];
  if (!toolTypeSliceToolStates) {
    sliceToolStates[toolType] = { data: [] };
    toolTypeSliceToolStates = sliceToolStates[toolType];
  }

  toolData.forEach((data) => toolTypeSliceToolStates.data.push(data));

  return toolSliceData;
}

function doesImageContainRoiToolData(imageId, toolType, roi) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let currentRoiToolState = [];

  if (_.has(toolStates, imageId)) {
    const imageToolState = toolStates[imageId];

    if (_.has(imageToolState, toolType)) {
      let roiToolState = imageToolState[toolType].data;

      currentRoiToolState = roiToolState.filter(
        (stateData) => stateData.roi && stateData.roi.id === roi.id
      );
    }
  }

  return currentRoiToolState.length >= 1;
}

export {
  clearRoiData,
  getRoiToolStateDataForRoi,
  deleteToolDataFromImage,
  doesImageContainRoiToolData,
  getToolSliceData,
  setToolSliceData,
  clearToolSliceData,
  getToolStateIndices,
};
