import {
  QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL,
  QUEUE_VIEWER_DRAW,
  QUEUE_VIEWER_MEASURE,
  SET_ACTIVE_VIEWER_SERIES,
  SET_VIEWER_ACTIVE,
  SET_VIEWER_IMAGE_ID_INDEX,
  SET_VIEWER_SERIES,
  SET_VIEWERS,
  QUEUE_VIEWER_CANCEL_ACTIVE_TOOL,
  QUEUE_VIEWER_WINDOW_LEVEL_PRESET,
  SET_VIEWER_ELEMENT,
  ADD_VIEWER,
  REMOVE_VIEWER,
} from "../../actions/types/annotationPage/viewers";
import merge from "lodash/merge";
import produce from "immer";

let defaultState = {
  byId: {},
  allIds: [],
};

export const viewers = (state = defaultState, action) => {
  switch (action.type) {
    case SET_VIEWERS:
      return setViewers(state, action);
    case SET_VIEWER_SERIES:
      return setViewerSeries(state, action);
    case SET_ACTIVE_VIEWER_SERIES:
      return setActiveViewerSeries(state, action);
    case SET_VIEWER_IMAGE_ID_INDEX:
      return setViewerImageIdIndex(state, action);
    case SET_VIEWER_ACTIVE:
      return setViewerActive(state, action);
    case QUEUE_VIEWER_DRAW:
      return queueViewerDraw(state, action);
    case QUEUE_VIEWER_MEASURE:
      return queueViewerMeasure(state, action);
    case QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL:
      return queueViewersCancelActiveTool(state, action);
    case QUEUE_VIEWER_CANCEL_ACTIVE_TOOL:
      return queueViewerCancelActiveTool(state, action);
    case QUEUE_VIEWER_WINDOW_LEVEL_PRESET:
      return queueViewerWindowLevel(state, action);
    case SET_VIEWER_ELEMENT:
      return setViewerElement(state, action);
    case ADD_VIEWER:
      return addViewer(state, action);
    case REMOVE_VIEWER:
      return removeViewer(state, action);
    default:
      return state;
  }
};

const setViewers = (state, action) => {
  return {
    ...state,
    byId: action.viewers.byId,
    allIds: action.viewers.allIds,
  };
};

const setViewerSeries = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.viewerId]: {
        ...state.byId[action.viewerId],
        seriesId: action.seriesId,
      },
    },
  };
};

const setActiveViewerSeries = (state, action) => {
  let viewerSeries = {};

  let activeViewerIds = state.allIds.filter((id) => state.byId[id].isActive);
  activeViewerIds.forEach((id) => {
    viewerSeries[id] = {
      ...state.byId[id],
      seriesId: action.seriesId,
    };
  });

  let byIds = merge({}, state.byId, viewerSeries);

  return {
    ...state,
    byId: byIds,
  };
};

const setViewerImageIdIndex = (state, action) => {
  const { viewerId, imageIdIndex, imageId } = action;

  return {
    ...state,
    byId: {
      ...state.byId,
      [viewerId]: {
        ...state.byId[viewerId],
        imageIdIndex: imageIdIndex,
        imageId: imageId,
      },
    },
  };
};

const setViewerActive = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.viewerId]: {
        ...state.byId[action.viewerId],
        isActive: action.isActive,
      },
    },
  };
};

const queueViewerDraw = (state, action) => {
  let viewers = state.allIds.map((id) => state.byId[id]);
  let views = {};
  viewers.forEach((viewer) => {
    viewer.isDrawQueued = action.isQueued;
    views[viewer.id] = viewer;
  });

  return {
    ...state,
    byId: views,
  };
};

const queueViewerMeasure = (state, action) => {
  let viewers = state.allIds.map((id) => state.byId[id]);
  let views = {};
  viewers.forEach((viewer) => {
    viewer.isMeasureQueued = action.isQueued;
    views[viewer.id] = viewer;
  });

  return {
    ...state,
    byId: views,
  };
};

const queueViewersCancelActiveTool = (state, action) => {
  let viewers = state.allIds.map((id) => state.byId[id]);
  let views = {};
  viewers.forEach((viewer) => {
    viewer.isCancelActiveToolQueued = action.isQueued;
    views[viewer.id] = viewer;
  });

  return {
    ...state,
    byId: views,
  };
};

const queueViewerCancelActiveTool = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.viewerId]: {
        ...state.byId[action.viewerId],
        isCancelActiveToolQueued: action.isQueued,
      },
    },
  };
};

const queueViewerWindowLevel = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.viewerId]: {
        ...state.byId[action.viewerId],
        queuedWindowLevelPreset: action.windowLevelPreset,
      },
    },
  };
};

const setViewerElement = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.viewerId]: {
        ...state.byId[action.viewerId],
        element: action.element,
      },
    },
  };
};

const addViewer = (state, action) => {
  const nextState = produce(state, (draftState) => {
    let viewer = action.viewer;
    let viewerId = viewer.id;

    draftState.allIds.push(viewerId);
    draftState.byId[viewerId] = viewer;
  });

  return nextState;
};

const removeViewer = (state, action) => {
  let viewerId = action.viewerId;

  const nextState = produce(state, (draftState) => {
    let { allIds, byId } = draftState;

    const index = allIds.indexOf(viewerId);
    if (index < 0) {
      throw new Error(`ViewerId: ${viewerId} does not exist!`);
    }

    allIds.splice(index, 1);

    delete byId[viewerId];
  });

  return nextState;
};
