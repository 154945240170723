import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import WebAnnotator from "./components/WebAnnotator";
import initCornerstone from "./tools/initCornerstone";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./configureStore";
import { ApolloProvider } from "@apollo/client";
import getClient from "./apollo/getClient";
import { useAuth0 } from "./auth0/auth0-wrapper";

//this is for safari bug where vh units don't work
import { init } from "viewport-units-buggyfill";
init();

export default function App() {
  const { getTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    initCornerstone();
  });

  let preloadedState = {};
  const store = configureStore(preloadedState);
  const client = isAuthenticated ? getClient(getTokenSilently) : {};

  if (process.env.NODE_ENV === "development") {
    //allows store to be displayed in console for debugging
    window.store = store;
  }

  const theme = createMuiTheme({
    palette: {
      type: "dark",
    },
    typography: {
      fontSize: 15,
    },
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <WebAnnotator />
          </MuiThemeProvider>
        </ApolloProvider>
      </ConnectedRouter>
    </Provider>
  );
}
