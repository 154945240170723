// iterates all of an objects properties by key and replaces the value at 'propertyName' with 'newValue'
export function replaceValue(object, propertyName, newValue) {
  if (typeof object !== "object") {
    return;
  }

  let keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let value = object[key];

    if (key === propertyName) {
      object[key] = newValue;
    } else {
      replaceValue(value, propertyName, newValue);
    }
  }
}
