import { BRUSH, FREEHAND_SCISSORS } from "../Tools";

import { ClipboardDataTypes } from "shared-api";
import { isPasteAvailableForCurrentLesionSlice } from "./isPasteAvailableForCurrentLesionSlice";

export function isUserMaskPasteAvailable(
  clipboardData,
  roi,
  currentImageId,
  selectedLesion,
  activeTool
) {
  if (!clipboardData) {
    return false;
  }

  let { copiedToolData, copiedDataType } = clipboardData;

  if (activeTool && activeTool !== BRUSH && activeTool !== FREEHAND_SCISSORS) {
    return false;
  }

  if (copiedDataType !== ClipboardDataTypes.USER_MASK) {
    return false;
  }

  if (!copiedToolData) {
    return false;
  }

  return isPasteAvailableForCurrentLesionSlice(
    clipboardData,
    roi,
    currentImageId,
    selectedLesion
  );
}
