import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { LesionColorMapType } from "../types/LesionColorMapType";
import { LesionColorFragmentType } from "../fragments/LesionColorFragmentType";
import { LESION_COLOR_FRAGMENT } from "../fragments/LesionColorFragment";
import { parseLesionColor } from "../parsers/parseLesionColor";

const QUERY = gql`
  query GetLesionColorMap {
    colors: lesion_color {
      ...Color
    }
  }
  ${LESION_COLOR_FRAGMENT}
`;

type Data = {
  colors: LesionColorFragmentType[];
};

export async function getLesionColorMap({
  client,
}: ClientProps): Promise<LesionColorMapType> {
  return await parsedQuery({
    client,
    query: QUERY,
    input: undefined,
    parseData: ({ colors }: Data) => colors.map(parseLesionColor),
  });
}
