import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { CASE_ACTION_TYPE_ATTRIBUTE_FRAGMENT } from "../fragments/CaseActionTypeAttributeFragment";
import { CaseActionTypeAttributeFragmentType } from "../fragments/CaseActionTypeAttributeFragmentType";
import { CASE_ACTION_TYPE_RULE_FRAGMENT } from "../fragments/CaseActionTypeRuleFragment";
import { CaseActionTypeRuleFragmentType } from "../fragments/CaseActionTypeRuleFragmentType";
import { ActionTypeRulesType } from "../types/ActionTypeRulesType";
import { ActionTypeAttributesType } from "../types/ActionTypeAttributesType";
import { parseActionTypeAttributes } from "../parsers/parseActionTypeAttributes";
import { parseActionTypeRules } from "../parsers/parseActionTypeRules";

const QUERY = gql`
  query GetActionTypeRulesAndAttributes {
    attributes: case_action_type_attribute {
      ...CaseActionTypeAttribute
    }
    rules: case_action_type_rule {
      ...CaseActionTypeRule
    }
  }
  ${CASE_ACTION_TYPE_ATTRIBUTE_FRAGMENT}
  ${CASE_ACTION_TYPE_RULE_FRAGMENT}
`;

type Input = Record<string, never>;

type Output = {
  attributes: ActionTypeAttributesType;
  rules: ActionTypeRulesType;
};

type Data = {
  attributes: CaseActionTypeAttributeFragmentType[];
  rules: CaseActionTypeRuleFragmentType[];
};

export async function getActionTypeRulesAndAttributes({
  client,
  ...input
}: ClientProps & Input): Promise<Output> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseData: ({
      attributes: attributeFragments,
      rules: ruleFragments,
    }: Data) => {
      const rules = parseActionTypeRules(ruleFragments);
      const attributes = parseActionTypeAttributes(attributeFragments);

      return { rules, attributes };
    },
  });
}
