import {
  lesionEvaluationTypes,
  lesionStagingTypes,
  imageModality,
} from "shared-api";
import {
  MAX_TARGET_LESIONS_CHECK,
  MEASUREABLE_LESION_CHECK,
  MAX_TARGET_LESIONS_ORGAN_CHECK,
  NODAL_PATHOLOGICAL_CHECK,
  RECONSTRUCTION_PLANE_CHECK,
} from "./ruleNames";

let recistRules = {
  [MAX_TARGET_LESIONS_CHECK]: (componentState, reduxState) => {
    const {
      annotationPage: {
        recists: {
          evaluations: {
            lesions: { allIds: lesionIds, byId: lesionEvaluationsById },
          },
        },
      },
    } = reduxState;

    const targetLesionCount = lesionIds.filter(
      (id) =>
        lesionEvaluationsById[id].targetClassification ===
        lesionEvaluationTypes.TARGET
    ).length;
    // Need to also verify if they are lymph nodes or not
    if (targetLesionCount >= 5) {
      return "You cannot have more than 5 target lesions.";
    } else {
      return null;
    }
  },

  [MEASUREABLE_LESION_CHECK]: (
    { measurable, classification, measurements, seriesId },
    reduxState
  ) => {
    if (!classification || !measurements || !measurable) {
      return null;
    }
    const [LAD, SAD] = measurements;
    const {
      annotationPage: {
        series: { byId: seriesById },
      },
    } = reduxState;
    const { modality } = seriesById[seriesId];
    if (measurable) {
      if (classification === lesionStagingTypes.NODE) {
        if (SAD < 15) {
          return "Nodal lesions must have a short-axis diameter greater than 15mm to be considered measurable.";
        } else {
          return null;
        }
      } else {
        // Need to check whether case is xray / CT; below is for CT
        if (modality === imageModality.CT && LAD < 10) {
          return "Lesions must have long-axis diameter greater than 10 mm.";
        } else if (modality === imageModality.XRAY && LAD < 20) {
          return "Lesions must have long-axis diameter greater than 20 mm.";
        } else {
          return null;
        }
      }
    }
  },

  [MAX_TARGET_LESIONS_ORGAN_CHECK]: (componentState, reduxState) => {
    const { currentLocation } = componentState;
    const {
      annotationPage: {
        recists: {
          evaluations: {
            lesions: { allIds: lesionIds, byId: lesionEvaluationsById },
          },
        },
      },
    } = reduxState;

    if (!currentLocation) return null;

    const currentLocationCount = lesionIds.filter(
      (id) =>
        lesionEvaluationsById[id].targetClassification ===
          lesionEvaluationTypes.TARGET &&
        lesionEvaluationsById[id].location === currentLocation.anatomy
    ).length;
    if (currentLocationCount >= 2) {
      return `You already have 2 target lesions in the ${currentLocation.anatomy}.`;
    } else {
      return null;
    }
  },

  [NODAL_PATHOLOGICAL_CHECK]: ({
    measurable,
    classification,
    measurements,
  }) => {
    if (!classification || !measurements || !measurable) {
      return null;
    }
    const SAD = measurements[1];
    if (classification === lesionStagingTypes.NODE) {
      if (SAD < 10)
        return "Lymph Nodes smaller than 10mm in the short-axis diameter are considered normal, and not pathological.";
    }
    return null;
  },

  [RECONSTRUCTION_PLANE_CHECK]: (componentState, reduxState) => {
    const {
      annotationPage: {
        series: { allIds: seriesIds, byId: seriesById },
      },
    } = reduxState;

    let baselineReconstructionPlane =
      seriesById[seriesIds[0]].reconstructionPlane;

    for (const seriesId in seriesById) {
      if (
        seriesById[seriesId].reconstructionPlane !== baselineReconstructionPlane
      ) {
        return `Follow-up measurements must be in the same plane as baseline. Your baseline measurements were
           made in the ${baselineReconstructionPlane} plane, while at ${seriesById[seriesId].seriesDate}, it is now
           ${seriesById[seriesId].reconstructionPlane} plane. Please contact Altis Labs for further support.`;
      }
    }
    return null;
  },
};

export default recistRules;
