import { truncate } from "./StringUtils";
import { formatSeriesDate } from "./formatSeriesDate";
import { formatFollowUpLabel } from "./formatFollowUpLabel";

let formatSeriesLabel = (series, maxLength = 10) => {
  let label = "N/A";

  const { seriesDate, followUpOrder, displayName } = series;

  if (followUpOrder != null) {
    label = formatFollowUpLabel(followUpOrder);
  } else if (displayName) {
    label = displayName;
  } else if (seriesDate) {
    label = formatSeriesDate(seriesDate);
  } else if (series.seriesDescription) {
    label = series.seriesDescription;
  }

  label = truncate(label, maxLength);
  return label;
};

export { formatSeriesLabel };
