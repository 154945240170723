import React from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import PropTypes from "prop-types";
import OkCancelDialog from "./OkCancelDialog";

function OkCancelTextDialog({
  title,
  message,
  open,
  okText,
  cancelText,
  onOk,
  onCancel,
  otherActions,
}) {
  return (
    <OkCancelDialog
      title={title}
      open={open}
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={onCancel}
      otherActions={otherActions}
    >
      <DialogContentText>{message}</DialogContentText>
    </OkCancelDialog>
  );
}

OkCancelTextDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  otherActions: PropTypes.object,
};

export default OkCancelTextDialog;
