import { gql } from "@apollo/client/core";

export const CASE_ASSIGNMENT_FRAGMENT = gql`
  fragment CaseAssignment on case_assignment {
    id: case_assignment_id
    caseId: case_id
    userId: user_id
    actionType: action_type
    progress
    case {
      name
    }
    user {
      username
    }
  }
`;
