import { gql } from "@apollo/client/core";
import { TUMOUR_BURDEN_FRAGMENT } from "./TumourBurdenFragment";

export const TUMOUR_FRAGMENT = gql`
  fragment Tumour on tumour {
    id
    measurable
    burdens: tumour_burdens {
      ...TumourBurden
    }
    rois: tumour_roi_maps {
      id: roi_id
    }
  }
  ${TUMOUR_BURDEN_FRAGMENT}
`;
