import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { ANATOMICAL_STRUCTURES_FRAGMENT } from "../fragments/AnatomicalStructureFragment";
import { AnatomicalStructureFragmentType } from "../fragments/AnatomicalStructureFragmentType";
import { parseAnatomicalStructure } from "../parsers/parseAnatomicalStructure";
import { AnatomicalStructureType } from "../types/AnatomicalStructureType";

const QUERY = gql`
  query GetEnabledAnatomicalStructures {
    anatomicalStructures: anatomical_structures {
      ...AnatomicalStructure
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

type Data = {
  anatomicalStructures: AnatomicalStructureFragmentType[];
};

export async function getEnabledAnatomicalStructures({
  client,
}: ClientProps): Promise<AnatomicalStructureType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input: undefined,
    parseData: ({ anatomicalStructures }: Data) =>
      anatomicalStructures.map(parseAnatomicalStructure),
  });
}
