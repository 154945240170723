import React, { useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import HeaderBar from "./HeaderBar";
import PrivateRoute from "./PrivateRoute";
import { useAuth0 } from "../auth0/auth0-wrapper";
import WorkList from "./WorkList";
import { connect } from "react-redux";
import AnnotationView from "./AnnotationView/AnnotationView";
import { initApp } from "../actions/actionCreators/app";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Grid from "@material-ui/core/Grid";
import LoadingSpinner from "./LoadingSpinner";
import GlobalApplicationErrorDialog from "./Dialogs/GlobalApplicationErrorDialog";
import { initAuthCallbacksForAudience } from "shared-api";
import { initCornerstoneWadoImageLoader } from "../cornerstone/initCornerstoneWadoImageLoader";

function WebAnnotator({ initApp, isAuthInitialized, globalApplicationError }) {
  const {
    loading,
    getTokenSilently,
    getTokenWithPopup,
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();

  useEffect(() => {
    const initAppWithImageLoader = async () => {
      const token = await getTokenSilently();
      await initAuthCallbacksForAudience({
        audience: window._env_.REACT_APP_NOTA_PACS_AUTH0_AUDIENCE,
        scope: "download:files",
        getTokenSilently,
        getTokenWithPopup,
        loginWithRedirect,
        onGetTokenCallbacks: [
          (token) => initCornerstoneWadoImageLoader(token),
          () => initApp(apiUrls, token, user),
        ],
      });
    };

    const apiUrls = {
      notaApiUrl: window._env_.REACT_APP_NOTA_API_URL,
      autoSegmentApiUrl: window._env_.REACT_APP_AUTO_SEGMENT_API_URL,
    };

    if (loading) {
      return;
    }

    if (isAuthenticated) {
      // eslint-disable-next-line
      initAppWithImageLoader();
    } else {
      loginWithRedirect({
        appState: { targetUrl: "/studies" },
        redirect_uri: `${window.location.origin}${process.env.PUBLIC_URL}`,
      });
    }
  }, [
    loading,
    getTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    user,
    initApp,
  ]);

  let loadingIndicator = (
    <Grid container style={{ height: "100%" }}>
      <LoadingSpinner />
    </Grid>
  );

  let routes = (
    <Switch>
      <PrivateRoute path="/studies" component={() => <WorkList />} />
      <PrivateRoute path="/annotate/:id" component={() => <AnnotationView />} />
      <PrivateRoute path="/" render={() => <Redirect to="/studies" />} />
    </Switch>
  );

  let content = isAuthInitialized ? routes : loadingIndicator;

  return (
    <DndProvider backend={HTML5Backend}>
      <GlobalApplicationErrorDialog
        globalApplicationError={globalApplicationError}
      />
      <div style={{ height: "100vh" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <HeaderBar />
          <div
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "auto",
              padding: "2px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

WebAnnotator.propTypes = {
  initApp: PropTypes.func.isRequired,
  isAuthInitialized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthInitialized: state.app.isAuthInitialized,
  globalApplicationError: state.app.globalApplicationError,
});

const mapDispatchToProps = (dispatch) => ({
  initApp: (apiUrls, token, user) => dispatch(initApp(apiUrls, token, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebAnnotator);
