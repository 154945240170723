import PropTypes from "prop-types";
import React from "react";
import OkDialog from "./OkDialog";
import DialogContentText from "@material-ui/core/DialogContentText";

function OkTextDialog({ title, message, open, onOk }) {
  return (
    <OkDialog title={title} open={open} onOk={onOk}>
      <DialogContentText>{message}</DialogContentText>
    </OkDialog>
  );
}

OkTextDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default OkTextDialog;
