import { lesionEvaluationTypes, lesionStagingTypes } from "shared-api";

export const calculateTumourResponse = (
  seriesId,
  roiRecistEvaluation,
  targetLesionEvaluation
) => {
  // TODO : Include response evaluation
  const {
    targetClassification,
    stagingClassification,
  } = targetLesionEvaluation;
  const isPresent = !["NOTPRESENT", "NONMEASURABLE"].includes(
    roiRecistEvaluation.visibility
  );
  const isTarget = targetClassification === lesionEvaluationTypes.TARGET;
  const isNode = stagingClassification === lesionStagingTypes.NODE;

  if (!isPresent || !isTarget) {
    return 0;
  }

  return isNode ? roiRecistEvaluation.SAD : roiRecistEvaluation.LAD;
};
