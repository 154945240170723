import React from "react";
// @ts-ignore
import Chip from "@material-ui/core/Chip";
// @ts-ignore
import blue from "@material-ui/core/colors/blue";
// @ts-ignore
import orange from "@material-ui/core/colors/orange";
// @ts-ignore
import green from "@material-ui/core/colors/green";

interface ProgressChipProps {
  progress: string;
  onDelete?: () => void;
}

const ProgressChip = ({ progress, onDelete }: ProgressChipProps) => {
  const getProgressMetadata = (progress: string) => {
    let progressMetadata: { label: string; color: string };

    switch (progress) {
      case "PENDING":
        progressMetadata = { label: "New", color: blue[500] };
        break;
      case "INPROGRESS":
        progressMetadata = { label: "In Progress", color: orange[500] };
        break;
      case "COMPLETED":
        progressMetadata = { label: "Completed", color: green[500] };
        break;
      default:
        progressMetadata = { label: "N/A", color: "black" };
        break;
    }

    return progressMetadata;
  };

  const { label, color } = getProgressMetadata(progress);

  return (
    <Chip label={label} style={{ background: color }} onDelete={onDelete} />
  );
};

export default ProgressChip;
