import React, { SyntheticEvent } from "react";
// @ts-ignore
import Chip from "@material-ui/core/Chip";
import { getIcon } from "./Icons/getIcon";
import CaseActionTypeAttribute from "../models/CaseActionTypeAttribute";

interface ActionTypeChipProps {
  actionType: string;
  caseActionTypeAttribute: CaseActionTypeAttribute;
  onActionTypeClicked?: (actionType: string) => {};
  onDelete?: () => void;
}

const ActionTypeChip = ({
  actionType,
  caseActionTypeAttribute,
  onActionTypeClicked,
  onDelete,
}: ActionTypeChipProps) => {
  const onChipClicked = () => {
    if (onActionTypeClicked) {
      onActionTypeClicked(actionType);
    }
  };

  const { iconName, iconSource, color, displayName } = caseActionTypeAttribute[
    actionType
  ];

  const icon = getIcon(iconName, iconSource, "small");

  return (
    <Chip
      icon={icon}
      label={displayName}
      style={{ background: color }}
      onMouseDown={(event: SyntheticEvent) => {
        if (onDelete) {
          event.stopPropagation();
        }
      }}
      onClick={onChipClicked}
      clickable={onActionTypeClicked != null}
      onDelete={onDelete}
    />
  );
};

export default ActionTypeChip;
