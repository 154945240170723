import { grey } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { setSelectedRoiId } from "../../actions/actionCreators/annotationPage/rois";
import {
  setActiveToolIfAllowed,
  setDetectMode,
} from "../../actions/actionCreators/annotationPage/tools";
import { jumpToSlice } from "../../actions/actionCreators/annotationPage/viewers";
import { COMPLETED, lesionTypes } from "shared-api";
import { ANNOTATE } from "../../tools/Tools";
import DetectionAttributes from "../RoiAttributesEditor";
import DetectionListItem from "./DetectionListItem";

function LesionSeriesListItem({
  label,
  lesion,
  roi,
  jumpToSlice,
  onLesionSelected,
  setDetectMode,
  setActiveTool,
  isSelected,
  setSelectedRoi,
  hasPendingRoiEdits,
  instancesById,
}) {
  const handleDetectionOnClick = (roi) => {
    const { ellipse, contours } = roi;
    if (lesion.type === lesionTypes.LESION) {
      const { imageId } = ellipse.slice;
      handleJump(imageId);
    } else if (lesion.type === lesionTypes.ORGAN) {
      const { instanceId } = contours[0];
      const { wadoUrl } = instancesById[instanceId];
      handleJump(wadoUrl);
    }
    setDetectMode(true);
    setSelectedRoi(roi);
  };

  let handleJump = (imageId) => {
    setActiveTool(ANNOTATE);
    onLesionSelected();

    if (imageId) {
      jumpToSlice(imageId);
    }
  };

  let paperColor = isSelected ? grey[700] : "";

  return (
    <Paper
      elevation={3}
      style={{ padding: "6px", margin: "5px", background: paperColor }}
    >
      <DetectionListItem
        lesion={lesion}
        roi={roi}
        label={label}
        isSelected={isSelected}
        hasPendingEdits={hasPendingRoiEdits}
        onClick={handleDetectionOnClick}
      />

      {isSelected && lesion.type === lesionTypes.LESION && (
        <div style={{ paddingTop: "6px", paddingBottom: "6px" }}>
          <DetectionAttributes
            roi={roi}
            disabled={lesion.status === COMPLETED}
          />
        </div>
      )}
    </Paper>
  );
}

LesionSeriesListItem.propTypes = {
  label: PropTypes.string.isRequired,
  lesion: PropTypes.object.isRequired,
  roi: PropTypes.object.isRequired,
  jumpToSlice: PropTypes.func.isRequired,
  onLesionSelected: PropTypes.func.isRequired,
  setDetectMode: PropTypes.func.isRequired,
  setActiveTool: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelectedRoi: PropTypes.func.isRequired,
  hasPendingRoiEdits: PropTypes.bool.isRequired,
  instancesById: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  instancesById: state.annotationPage.series.instancesById,
});

const mapDispatchToProps = (dispatch) => ({
  jumpToSlice: (slice) => dispatch(jumpToSlice(slice.imageId)),
  setDetectMode: (isDetectMode) => dispatch(setDetectMode(isDetectMode)),
  setActiveTool: (tool) => dispatch(setActiveToolIfAllowed(tool)),
  setSelectedRoi: (roi) => dispatch(setSelectedRoiId(roi.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LesionSeriesListItem);
