import { gql } from "@apollo/client/core";
import { RoiType } from "../types/RoiType";
import ToolRoiCreator from "../../utilities/ToolRoiCreator";
import ToolTypes from "../../utilities/ToolTypes";
import { ClientProps } from "../types/ClientProps";
import { RoiFragmentType } from "../fragments/RoiFragmentType";
import { ROI_FRAGMENT } from "../fragments/RoiFragment";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { parseRoi } from "../parsers/parseRoi";
import { LesionTypeType } from "../types/LesionTypeType";
import { getLesionColorMap } from "../queries/getLesionColorMap";
import { getLesionColor } from "../utilities/getLesionColor";
import { getBaseWadoUrl } from "../queries/getBaseWadoUrl";

const MUTATION = gql`
  mutation InsertRois($rois: [roi_insert_input!]!) {
    insert_roi(objects: $rois) {
      returning {
        ...Roi
      }
    }
  }
  ${ROI_FRAGMENT}
`;

type Input = {
  seriesIds: number[];
  lesionId: number;
  createdBy: number;
  anatomicalStructureId: number | null;
  lesionType: LesionTypeType;
};

type Data = {
  insert_roi: {
    returning: RoiFragmentType[];
  };
};

export async function insertRois({
  client,
  ...input
}: ClientProps & Input): Promise<RoiType[]> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables,
    parseData: async (
      { insert_roi: { returning } }: Data,
      { anatomicalStructureId, lesionType, lesionId }: Input
    ) => {
      const lesionColorMap = await getLesionColorMap({ client });
      const baseWadoUrl = await getBaseWadoUrl({ client });
      const color = getLesionColor(
        anatomicalStructureId,
        lesionType,
        lesionColorMap
      );
      return returning.map((roi) =>
        parseRoi(roi, color, baseWadoUrl, lesionId)
      );
    },
  });
}

const parseVariables = ({ seriesIds, lesionId, createdBy }: Input) => {
  const ellipseToolRoi = ToolRoiCreator.createToolRoi(
    ToolTypes.ELLIPTICALROITOOL,
    createdBy,
    "pink"
  );

  const rois = seriesIds.map((seriesId) => {
    return {
      lesion_id: lesionId,
      series_id: seriesId,
      created_by: createdBy,
      ellipses: {
        data: {
          data: { roi: ellipseToolRoi, slice: null },
          created_by: createdBy,
        },
        on_conflict: {
          constraint: "ellipse_roi_id_key",
          update_columns: "data",
        },
      },
    };
  });

  return { rois };
};
