import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  root: {
    paddingBottom: "20px",
  },
});

function BlockingDialog({ title, message, open, children }) {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          classes={{
            root: classes.root,
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

BlockingDialog.propTypes = {
  title: PropTypes.string,
  messasge: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

export default BlockingDialog;
