import { createToolRoi } from "./createToolRoi";

export default class ToolRoiCreator {
  static useToolRoiId(id) {
    if (global.usedIds.some((_id) => _id === id)) {
      throw new Error("ID already used!");
    }

    global.usedIds.push(id);
  }

  static releaseRoiId(id) {
    const index = global.usedIds.indexOf(id);
    if (index <= -1) {
      throw new Error("ID not found!");
    }

    global.usedIds.splice(index, 1);
  }

  static clearToolRoiIds() {
    global.usedIds = [];
  }

  static getNextRoiId() {
    let id = -1;

    for (let i = 0; i < 10000; i++) {
      if (!global.usedIds.some((_id) => _id === i)) {
        id = i;
        break;
      }
    }

    if (id < 0) {
      throw new Error("Unable to allocate unique roi id");
    }

    return id;
  }

  static createToolRoi(type, userId, color) {
    let roiId = ToolRoiCreator.getNextRoiId();
    ToolRoiCreator.useToolRoiId(roiId);

    return createToolRoi(roiId, type, userId, color);
  }
}

global.usedIds = [];
