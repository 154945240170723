import {
  SET_ROI_RECIST_EVALUATIONS,
  ADD_ROI_RECIST_EVALUATION,
  REMOVE_ROI_RECIST_EVALUATION,
  UPDATE_ROI_RECIST_EVALUATION,
} from "../../actions/types/evaluations/rois";

let defaultState = {
  allIds: [],
  byId: {},
};

export const rois = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ROI_RECIST_EVALUATIONS:
      return setRoiRecistEvaluations(state, action);
    case ADD_ROI_RECIST_EVALUATION:
      return addRoiRecistEvaluation(state, action);
    case UPDATE_ROI_RECIST_EVALUATION:
      return updateRoiRecistEvaluation(state, action);
    case REMOVE_ROI_RECIST_EVALUATION:
      return removeRoiRecistEvaluation(state, action);
    default:
      return state;
  }
};

const addRoiRecistEvaluation = (state, action) => {
  const {
    roiRecistEvaluation,
    roiRecistEvaluation: { roiId },
  } = action;
  const newAllIds = [...state.allIds];

  if (!newAllIds.includes(roiId)) {
    newAllIds.push(roiId);
  }

  return {
    ...state,
    allIds: newAllIds,
    byId: {
      ...state.byId,
      [roiId]: roiRecistEvaluation,
    },
  };
};

const removeRoiRecistEvaluation = (state, action) => {
  const { roiId } = action;

  const newAllIds = state.allIds.filter((id) => id !== roiId);
  const newById = {};
  for (const id of newAllIds) {
    newById[id] = state.byId[id];
  }

  return {
    ...state,
    byId: newById,
    allIds: newAllIds,
  };
};

const updateRoiRecistEvaluation = (state, action) => {
  let { roiRecistEvaluation } = action;
  const { byId } = state;
  const newById = { ...byId, [roiRecistEvaluation.roiId]: roiRecistEvaluation };

  return {
    ...state,
    byId: newById,
  };
};

const setRoiRecistEvaluations = (state, action) => {
  return {
    ...state,
    ...action.roiRecistEvaluations,
  };
};
