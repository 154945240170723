export default function getMeasurements(roiMeasurements) {
  if (
    !roiMeasurements ||
    roiMeasurements.LADLength.length === undefined ||
    roiMeasurements.SADLength.length === undefined
  ) {
    return null;
  }

  return [roiMeasurements.LADLength.length, roiMeasurements.SADLength.length];
}
