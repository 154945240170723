import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation UpdateBiopsyDetailsLesionId(
    $lesion_id: Int
    $unsure: Boolean
    $id: Int!
  ) {
    update_patient_biopsy_details_by_pk(
      pk_columns: { patient_biopsy_details_id: $id }
      _set: { lesion_id: $lesion_id, unsure: $unsure }
    ) {
      id: patient_biopsy_details_id
    }
  }
`;

type Input = {
  id: number;
  lesionId: number;
  unsure: boolean;
};

export async function updatePatientBiopsyDetailsLesionId({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ id, lesionId: lesion_id, unsure }) => {
      return {
        id,
        lesion_id,
        unsure,
      };
    },
    parseData: () => undefined,
  });
}
