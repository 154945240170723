import {
  SET_ROI_MEASUREMENTS,
  UPDATE_ROI_MEASUREMENT,
  DELETE_ROI_MEASUREMENT,
  REMOVE_MEASUREMENTS_FOR_ROI,
} from "../actions/types/measurements";

let defaultState = {
  measurementData: {},
};

export const measurements = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ROI_MEASUREMENTS:
      return setRoiMeasurements(state, action);
    case UPDATE_ROI_MEASUREMENT:
      return updateRoiMeasurement(state, action);
    case DELETE_ROI_MEASUREMENT:
      return deleteRoiMeasurement(state, action);
    case REMOVE_MEASUREMENTS_FOR_ROI:
      return removeMeasurementsForRoi(state, action);
    default:
      return state;
  }
};

const deleteRoiMeasurement = (state, action) => {
  const measurementData = state.measurementData;
  const {
    [action.roiId]: measurement,
    ...newMeasurementData
  } = measurementData;
  return { ...state, measurementData: newMeasurementData };
};

const updateRoiMeasurement = (state, action) => {
  const { measurementData: lengthData, roiId } = action;
  return {
    ...state,
    measurementData: { ...state.measurementData, [roiId]: lengthData },
  };
};

const setRoiMeasurements = (state, action) => {
  return { ...state, measurementData: action.measurements };
};

const removeMeasurementsForRoi = (state, action) => {
  const newMeasurementData = {};
  const measurementDataIds = Object.keys(state.measurementData).filter(
    (id) => id !== action.roiId
  );
  for (const id of measurementDataIds) {
    newMeasurementData[id] = state.measurementData[id];
  }
  return { ...state, measurementData: newMeasurementData };
};
