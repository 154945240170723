export const SET_TUMOURS = "SET_TUMOURS";
export const CLEAR_TUMOURS = "CLEAR_TUMOURS";
export const INSERT_TUMOUR = "INSERT_TUMOUR";
export const REMOVE_TUMOUR = "REMOVE_TUMOUR";

export type TumourActionType =
  | typeof SET_TUMOURS
  | typeof CLEAR_TUMOURS
  | typeof INSERT_TUMOUR
  | typeof REMOVE_TUMOUR;
