import { StyledToggleButton } from "../StyledComponents/StyledToggleButton";
import { StyledToggleGroup } from "../StyledComponents/StyledToggleGroup";
import { lesionStagingTypes } from "shared-api";
import React from "react";

const ClassificationToggle = ({
  classification,
  isMeasurable,
  setClassification,
}) => {
  const handleClassification = (event, type) => {
    if (type === null) {
      return;
    }
    setClassification(type);
  };
  return (
    <div>
      <StyledToggleGroup
        value={classification}
        exclusive
        onChange={handleClassification}
        size={"small"}
      >
        <StyledToggleButton
          groupSize={3}
          value={lesionStagingTypes.PRIMARY}
          disabled={isMeasurable == null}
        >
          Primary
        </StyledToggleButton>
        <StyledToggleButton
          groupSize={3}
          value={lesionStagingTypes.METASTASIS}
          disabled={isMeasurable == null}
        >
          Met
        </StyledToggleButton>
        <StyledToggleButton
          groupSize={3}
          value={lesionStagingTypes.NODE}
          disabled={isMeasurable == null}
        >
          Node
        </StyledToggleButton>
      </StyledToggleGroup>
    </div>
  );
};

export default ClassificationToggle;
