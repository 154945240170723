import {
  ADD_LESION,
  REMOVE_LESION,
  SET_LESIONS,
  SET_SELECTED_LESION_ID,
  SET_LESION_VISIBLE,
  SET_LESION_STATUS,
} from "../../types/annotationPage/lesions";

import { COMPLETED, insertLesionStatus, deleteLesionStatus } from "shared-api";
import getClient from "../../../apollo/getClient";

export function setLesions(lesions) {
  return {
    type: SET_LESIONS,
    lesions,
  };
}

export function addLesion2(lesion) {
  return {
    type: ADD_LESION,
    lesion,
  };
}

export function removeLesion2(lesionId) {
  return {
    type: REMOVE_LESION,
    lesionId,
  };
}

export function setSelectedLesionId(lesionId) {
  return {
    type: SET_SELECTED_LESION_ID,
    lesionId,
  };
}

export function setLesionVisible(lesion, visible) {
  return {
    type: SET_LESION_VISIBLE,
    lesion,
    visible,
  };
}

export function setLesionStatus(lesionId, status) {
  return {
    type: SET_LESION_STATUS,
    lesionId,
    status,
  };
}

export function updateLesionStatus(lesionId, status) {
  return async (dispatch) => {
    const client = getClient();
    if (status === COMPLETED) {
      await insertLesionStatus({ client, lesionId, status });
    } else {
      await deleteLesionStatus({ client, lesionId });
    }

    dispatch(setLesionStatus(lesionId, status));
  };
}
