import {
  SET_RECIST_SIDEBAR_SERIES,
  SET_RECIST_SIDEBAR_SERIES_BY_INDEX,
  SET_CURRENT_RECIST_SERIES_ID,
} from "../types/recistSeries";

export function setSelectedRecistSeriesByIndex(index, seriesId) {
  return { type: SET_RECIST_SIDEBAR_SERIES_BY_INDEX, index, seriesId };
}

export function setSelectedRecistSeries(seriesIds) {
  return { type: SET_RECIST_SIDEBAR_SERIES, seriesIndices: seriesIds };
}

export function setCurrentRecistSeriesId(seriesId) {
  return { type: SET_CURRENT_RECIST_SERIES_ID, seriesId };
}
