import { gql } from "@apollo/client/core";

export const USER_MASK_FRAGMENT = gql`
  fragment UserMask on user_mask {
    id: mask_id
    roiId: roi_id
    instanceId: instance_id
    toolData: tool_data
    version
    shape
    createdBy: created_by
  }
`;
