import React, { useState } from "react";
import NotaExporterDialog from "./NotaExporterDialog";
// @ts-ignore
import IconButton from "@material-ui/core/IconButton";
// @ts-ignore
import { CloudDownload } from "@material-ui/icons";
import { IHandleExport } from "../..";

interface NotaExporterButtonProps {
  caseId: number;
  handleExport: (options: IHandleExport) => void;
  handleCancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NotaExporterButton = ({
  caseId,
  handleExport,
  handleCancel,
}: NotaExporterButtonProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <>
      <NotaExporterDialog
        caseId={caseId}
        show={showDialog}
        handleExport={handleExport}
        handleCancel={handleCancel}
        handleClose={() => setShowDialog(false)}
      />
      <IconButton
        color="primary"
        size="small"
        onClick={() => setShowDialog(true)}
      >
        <CloudDownload fontSize="small" />
      </IconButton>
    </>
  );
};

export default NotaExporterButton;
