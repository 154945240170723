import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { TUMOUR_FRAGMENT } from "../fragments/TumourFragment";
import { TumourFragmentType } from "../fragments/TumourFragmentType";
import { parseTumour } from "../parsers/parseTumour";
import { TumourType } from "../types/TumourType";

const QUERY = gql`
  query GetTumours($diagnosis_id: Int!, $roi_id: Int!) {
    tumours: tumour(
      where: {
        _and: [
          { diagnosis_id: { _eq: $diagnosis_id } }
          { tumour_roi_maps: { roi_id: { _eq: $roi_id } } }
        ]
      }
    ) {
      ...Tumour
    }
  }
  ${TUMOUR_FRAGMENT}
`;

type Input = {
  diagnosisId: number;
  roiId: number;
};

type Data = {
  tumours: TumourFragmentType[];
};

export async function getTumours({
  client,
  ...input
}: ClientProps & Input): Promise<TumourType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ diagnosisId: diagnosis_id, roiId: roi_id }) => ({
      diagnosis_id,
      roi_id,
    }),
    parseData: ({ tumours }: Data) => tumours.map(parseTumour),
  });
}
