import { TumourType } from "../../../hooks/TumourType";
import {
  CLEAR_TUMOURS,
  INSERT_TUMOUR,
  REMOVE_TUMOUR,
  SET_TUMOURS,
} from "../../types/diagnosis/tumours";

export function setTumours(tumours: TumourType[]) {
  return { type: SET_TUMOURS, payload: tumours };
}

export function clearTumours() {
  return { type: CLEAR_TUMOURS };
}

export function insertTumour(tumour: TumourType) {
  return { type: INSERT_TUMOUR, payload: tumour };
}
export function removeTumour(tumourId: number) {
  return { type: REMOVE_TUMOUR, payload: tumourId };
}
