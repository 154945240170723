import {
  SET_ROI_MEASUREMENTS,
  UPDATE_ROI_MEASUREMENT,
  DELETE_ROI_MEASUREMENT,
  REMOVE_MEASUREMENTS_FOR_ROI,
} from "../types/measurements";
import {
  updateRoiMeasurement as updateRoiMeasurementApi,
  insertRoiMeasurement as insertRoiMeasurementApi,
  deleteRoiMeasurement as deleteRoiMeasurementApi,
} from "shared-api";
import { findDiameters } from "../../../tools/lengthUtils";
import { removeMeasurementsFromElement } from "../../../tools/lengthUtils/removeMeasurementsFromElement";
import getClient from "../../../apollo/getClient";

function updateRoiMeasurement(roiId, measurementData) {
  return { type: UPDATE_ROI_MEASUREMENT, measurementData, roiId };
}

function deleteRoiMeasurement(roiId) {
  return { type: DELETE_ROI_MEASUREMENT, roiId };
}

export function setRoiMeasurements(measurements) {
  return { type: SET_ROI_MEASUREMENTS, measurements };
}

export function removeMeasurementsForRoi(roiId) {
  return { type: REMOVE_MEASUREMENTS_FOR_ROI, roiId };
}

export function updateRoiMeasurementThunk(roiId, instanceId, toolData) {
  return async (dispatch, getState) => {
    const { annotationPage, app } = getState();
    const {
      viewers: { allIds: viewersAllIds, byId: viewersById },
      recists,
    } = annotationPage;
    const { userId } = app;

    const activeViewer = viewersAllIds
      .map((id) => viewersById[id])
      .find((viewer) => viewer.isActive);
    if (!activeViewer) {
      throw new Error("No viewers are active");
    }
    const { element: activeCornerstoneElement } = activeViewer;

    //clear out existing measurements if they exist
    const {
      measurements: { measurementData },
    } = recists;
    if (measurementData[roiId]) {
      const { LADLength, SADLength } = measurementData[roiId];
      removeMeasurementsFromElement(LADLength, SADLength);
    }

    const lengthData = findDiameters(activeCornerstoneElement, toolData);

    const measurementExists = parseInt(
      Object.keys(measurementData).find((id) => {
        return roiId === parseInt(id);
      })
    );

    dispatch(updateRoiMeasurement(roiId, lengthData));

    const client = getClient();
    if (measurementExists) {
      await updateRoiMeasurementApi({
        client,
        roiId,
        instanceId,
        toolData: lengthData,
      });
    } else {
      await insertRoiMeasurementApi({
        client,
        roiId,
        instanceId,
        createdBy: userId,
        toolData: lengthData,
      });
    }
  };
}

export function deleteRoiMeasurementThunk(roiId, instanceId) {
  return async (dispatch, getState) => {
    const { annotationPage } = getState();

    const { recists } = annotationPage;

    //clear out existing measurements if they exist
    const {
      measurements: { measurementData },
    } = recists;
    if (measurementData[roiId]) {
      const { LADLength, SADLength } = measurementData[roiId];
      removeMeasurementsFromElement(LADLength, SADLength);
    }
    const client = getClient();
    await deleteRoiMeasurementApi({ client, roiId, instanceId });

    dispatch(deleteRoiMeasurement(roiId));
  };
}
