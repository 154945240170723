import { createSelector } from "reselect";
import { selectedLesionSelector } from "../lesions/selectors";
import { getRois } from "../rois/selectors";

const getViewerIds = (state) => state.annotationPage.viewers.allIds;
const getViewers = (state) => state.annotationPage.viewers.byId;

export const viewersSelector = createSelector(
  getViewers,
  getViewerIds,
  (viewers, viewerIds) => {
    return viewerIds.map((id) => viewers[id]);
  }
);

export const viewerSeriesSelector = createSelector(
  getViewers,
  getViewerIds,
  (viewers, viewerIds) => {
    return viewerIds.map((id) => viewers[id].seriesId);
  }
);

export const viewerRoisSelector = createSelector(
  viewersSelector,
  selectedLesionSelector,
  getRois,
  (viewers, selectedLesion, roisById) => {
    if (!selectedLesion) {
      const viewerRois = {};
      viewers.forEach((viewer) => {
        const { id } = viewer;
        viewerRois[id] = null;
      });
      return viewerRois;
    }

    const { rois: lesionRoiIds } = selectedLesion;
    const selectedRois = lesionRoiIds.map((id) => roisById[id]);

    const viewerRois = {};

    viewers.forEach((viewer) => {
      const { id, seriesId } = viewer;
      viewerRois[id] = selectedRois.find((roi) => roi.seriesId === seriesId);
    });

    return viewerRois;
  }
);
