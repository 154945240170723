import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

interface EditButtonProp {
  onClick: () => void;
}

const EditButton = ({ onClick }: EditButtonProp): JSX.Element => {
  return (
    <IconButton size="small" onClick={onClick}>
      <EditIcon color="disabled" />
    </IconButton>
  );
};

export default EditButton;
