import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Divider } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { connect } from "react-redux";
import { biopsyDetailsSelector } from "../../store/annotationPage/biopsyDetails/selectors";
import {
  jumpToSliceForLesion,
  setSelectedLesion,
} from "../../actions/actionCreators/annotationPage/thunks";
import BiopsyIcon from "./BiopsyIcon";

function BiopsyDetails({ biopsyDetailsList, jumpToLesion }) {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandCollapseToggle = () => {
    setExpanded(!expanded);
  };

  const getTumorLocationListItem = ({ title, detail }, lesionId, index) => {
    let disabled = lesionId === null || lesionId === undefined;

    return (
      <ListItem dense key={index}>
        {title && <ListItemText secondary={title + ":"} />}
        <ListItemText primary={detail} />

        <IconButton
          edge="end"
          size="small"
          disabled={disabled}
          onClick={() => jumpToLesion(lesionId)}
        >
          <DoubleArrowIcon fontSize="small" />
        </IconButton>
      </ListItem>
    );
  };

  const getDetailComponent = ({ title, detail }, index) => {
    return (
      <ListItem dense key={index}>
        {title && <ListItemText secondary={title + ":"} />}
        <ListItemText primary={detail} />
      </ListItem>
    );
  };

  if (biopsyDetailsList.length <= 0) {
    return null;
  }

  //todo we only show the first biopsy result for now...
  let { lesionId, biopsyDetails, unsure } = biopsyDetailsList[0];

  return (
    <>
      <List dense component="nav">
        <ListItem dense button onClick={handleExpandCollapseToggle}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            wrap="nowrap"
          >
            <BiopsyIcon isAssigned={lesionId} isUnsure={unsure} />
            <Typography align="center" variant="subtitle2">
              Biopsy Details
            </Typography>
            <IconButton size="small">
              {expanded ? (
                <ExpandMore fontSize="small" />
              ) : (
                <ExpandLess fontSize="small" />
              )}
            </IconButton>
          </Grid>
        </ListItem>
      </List>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <List dense component="nav">
          {biopsyDetails.map((biopsyDetail, index) => {
            let { key } = biopsyDetail;
            if (key === "tumorLocation") {
              return getTumorLocationListItem(biopsyDetail, lesionId, index);
            } else {
              return getDetailComponent(biopsyDetail, index);
            }
          })}
        </List>
      </Collapse>
    </>
  );
}

const mapStateToProps = (state) => ({
  biopsyDetailsList: biopsyDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  jumpToLesion: (lesionId) => {
    dispatch(setSelectedLesion(lesionId));
    dispatch(jumpToSliceForLesion(lesionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BiopsyDetails);
