import { gql } from "@apollo/client/core";

export const ELLIPSE_FRAGMENT = gql`
  fragment Ellipse on ellipse {
    id: ellipse_id
    roiId: roi_id
    createdBy: created_by
    data
  }
`;
