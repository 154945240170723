import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { DIAGNOSIS_FRAGMENT } from "../fragments/DiagnosisFragment";
import { DiagnosisFragmentType } from "../fragments/DiagnosisFragmentType";
import { parseDiagnosis } from "../parsers/parseDiagnosis";
import { DiagnosisType } from "../types/DiagnosisType";

const MUTATION = gql`
  mutation UpdateDiagnosis(
    $diagnosis_id: Int!
    $diagnosis: diagnosis_set_input!
  ) {
    update_diagnosis(where: { id: { _eq: $diagnosis_id } }, _set: $diagnosis) {
      returning {
        ...Diagnosis
      }
    }
  }
  ${DIAGNOSIS_FRAGMENT}
`;

type Input = {
  diagnosis: DiagnosisType;
};

type Data = {
  update_diagnosis: { returning: DiagnosisFragmentType[] };
};

export async function updateDiagnosis({
  client,
  ...input
}: ClientProps & Input): Promise<DiagnosisType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ diagnosis }) => {
      const {
        id: diagnosis_id,
        type,
        code,
        primaryLocation: primary_location,
        diagnosisDate: diagnosis_date,
      } = diagnosis;
      return {
        diagnosis_id,
        diagnosis: { type, code, primary_location, diagnosis_date },
      };
    },
    parseData: ({ update_diagnosis: { returning } }: Data) => {
      if (returning.length !== 1) {
        throw new Error("diagnosis not updated properly");
      }

      return parseDiagnosis(returning[0]);
    },
  });
}
