import React, { useEffect, useState } from "react";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  // @ts-ignore
} from "@material-ui/core";
import styled from "styled-components";
import downloadjs from "downloadjs";
import { IHandleExport } from "../..";
import NotaExporterItem from "./NotaExporterItem";

const DialogContentsWrapper = styled.div`
  padding: 10px;
  min-width: 500px;
`;

interface NotaExporterDialogProps {
  caseId: number | number[];
  show: boolean;
  handleExport: (options: IHandleExport) => void;
  handleCancel: () => void;
  handleClose: () => void;
}

const NotaExporterDialog = ({
  caseId,
  show,
  handleExport,
  handleCancel,
  handleClose,
}: NotaExporterDialogProps) => {
  const [caseIdsToExport, setCaseIdsToExport] = useState<number[]>([]);

  useEffect(() => {
    if (typeof caseId === "number") {
      setCaseIdsToExport([caseId]);
    } else {
      setCaseIdsToExport(caseId);
    }
  }, [caseId]);

  const onClickCancelDownload = () => {
    handleCancel();
    handleClose();
  };

  return (
    <Dialog open={show}>
      <DialogTitle>Exporting Case Data</DialogTitle>
      <DialogContentsWrapper>
        {caseIdsToExport.map((caseId) => (
          <NotaExporterItem
            key={caseId}
            caseId={caseId}
            handleExport={handleExport}
          />
        ))}
      </DialogContentsWrapper>
      <DialogActions>
        <Button color="primary" onClick={onClickCancelDownload}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotaExporterDialog;
