import React from "react";
import Select from "../Select";

interface SingleSelectProps {
  options: string[];
  value: string | null;
  onChange: (data: string) => void;
  placeholder?: string;
  components?: any;
}

const SingleSelect = ({
  options,
  onChange,
  value,
  placeholder,
  components,
}: SingleSelectProps) => {
  const mapForSelect = (data: string | null) => ({
    value: data,
    label: data,
  });

  const demapForSelect = (data: { value: string; label: string }): string =>
    data.value;

  return (
    <Select
      isClearable={value ? true : false}
      value={mapForSelect(value)}
      options={options.map((option) => ({ value: option, label: option }))}
      placeholder={placeholder}
      onChange={(data: any) => {
        onChange(data ? demapForSelect(data) : "");
      }}
      components={components}
    />
  );
};

export default SingleSelect;
