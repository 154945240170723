import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ConnectedActionTypeChip from "../WorkListTable/ConnectedActionTypeChip";
import EnhancedTableHead from "./EnhancedTableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import { ProgressChip } from "nota-components";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
  },
  title: {
    height: "42px",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 42px - 52px)",
    overflowX: "auto",
    overflowY: "auto",
  },
  tablePagination: {
    height: "52px",
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.itemsPerPage);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.studies.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function onCaseSelected(caseItem) {
    await props.onCaseSelected(caseItem);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let tableRows = stableSort(props.cases, getSorting(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((caseItem, index) => {
      const isItemSelected = isSelected(caseItem.name);

      let progress = caseItem.progress ? caseItem.progress : "PENDING";

      let { caseId, name, actionType } = caseItem;

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={caseItem.id}
          selected={isItemSelected}
          onDoubleClick={async () => await onCaseSelected(caseItem)}
        >
          <TableCell align="center">{caseId}</TableCell>
          <TableCell align="center">{name}</TableCell>
          <TableCell align="center">
            <ProgressChip progress={progress} />
          </TableCell>
          <TableCell align="center">
            <ConnectedActionTypeChip
              actionType={actionType}
              onActionTypeClicked={() => onCaseSelected(caseItem)}
            />
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5" id="tableTitle">
        {props.tableTitle}
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.cases.length}
          />
          <TableBody className={classes.tableBody}>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[5]}
        component="div"
        count={props.cases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
