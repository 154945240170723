export default class GenericApiConsumer {
  constructor(onError) {
    this.onError = onError;
  }

  async execute(args, executeQuery, getResult, functionName) {
    this.log(`${functionName} requested...`);

    let response = null;
    let retryAttempts = 5;

    for (let i = 0; i < retryAttempts; i++) {
      try {
        response = await executeQuery(args);
        break;
      } catch (error) {
        console.error(
          `${functionName} api call failed on call ${i + 1}... retrying`,
          error
        );
        const isLastAttempt = i + 1 === retryAttempts;
        if (isLastAttempt) {
          let errorMessage;
          if (error.response)
            errorMessage = `${functionName} api call failed. Received response status [${error.response.status}] with message: "${error.response.data.message}"`;
          else
            errorMessage = `Could not connect to api while calling ${functionName}.`;
          if (this.onError) {
            //note: for some reason, the response doesn't carry forward when used, so explicitly reference it here
            let errorObject = { error, response: error.response };
            this.onError(errorObject);
          }
          throw new Error(errorMessage);
        }
      }
    }

    let result = null;
    try {
      result = getResult(response);
    } catch (error) {
      let errorMessage = `${functionName} failed to parse: ${error}`;
      throw new Error(errorMessage);
    }

    this.log(`Successfully ${functionName}`);

    //for debugging
    let debugLogResult = false;
    if (debugLogResult) {
      this.log(`Result: ${result}`);
    }

    return result;
  }

  log(message) {
    console.log(`[API] ${message}`);
  }
}
