import React, { useEffect } from "react";
import WorkListTable from "./WorkListTable/WorkListTable";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadCases } from "../actions/actionCreators/worklistPage/cases";
import PropTypes from "prop-types";
import {
  setAssignedCaseProgressThunk,
  setSelectedAssignedCase,
} from "../actions/actionCreators/annotationPage/case";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import LoadingSpinner from "./LoadingSpinner";
import {
  completedAssignedCasesSelector,
  incompleteAssignedCasesSelector,
  unassignedCasesSelector,
} from "../store/workListPage/cases/selector";
import OkCancelTextDialog from "./Dialogs/Base/OkCancelTextDialog";
import getClient from "../apollo/getClient";
import OkTextDialog from "./Dialogs/Base/OkTextDialog";
import { userIdSelector } from "../store/app/selector";
import { NotificationSnackbar } from "nota-components";
import { getDate } from "../utils/getDate";
import {
  useUserNotifications,
  useInsertCaseAssignment,
  getSimilarCaseAssignmentIds,
} from "shared-api";

function WorkList({
  loadCases,
  unassignedCases,
  pendingCases,
  completedCases,
  isCasesLoaded,
  pushHistory,
  setAssignedCaseProgress,
  setSelectedAssignedCase,
}) {
  const [selectedCompletedCase, setSelectedCompletedCase] = React.useState(
    null
  );

  const [createCaseAssignment] = useInsertCaseAssignment();

  const currentDate = getDate();
  const { data: notifications, error, loading } = useUserNotifications(
    currentDate
  );

  let userId = useSelector(userIdSelector);
  const dispatch = useDispatch();

  const [
    showCaseAlreadyAssignedDialog,
    setShowCaseAlreadyAssignedDialog,
  ] = React.useState(false);

  useEffect(() => {
    setSelectedAssignedCase(undefined, undefined);
    loadCases();
  }, [loadCases]);

  if (error) throw error;

  if (!isCasesLoaded || loading) {
    return (
      <Grid container style={{ height: "100%" }}>
        <LoadingSpinner message={"Loading cases..."} />
      </Grid>
    );
  }

  async function doesCaseAssignmentExist(caseId, actionType) {
    const client = getClient();

    const caseAssignmentIds = await getSimilarCaseAssignmentIds({
      client,
      caseId,
      actionType,
    });
    return caseAssignmentIds.length > 0;
  }

  const onConfirmReopenCase = () => {
    setSelectedCompletedCase(null);

    let { id: caseAssignmentId } = selectedCompletedCase;
    setAssignedCaseProgress(caseAssignmentId, "INPROGRESS");
    goToCase(selectedCompletedCase);
  };

  const handleCloseCaseAssignmentExistsDialog = () => {
    dispatch(loadCases);
    setShowCaseAlreadyAssignedDialog(false);
  };

  async function onUnassignedCaseSelected(caseItem) {
    let status = await doesCaseAssignmentExist(
      caseItem.caseId,
      caseItem.actionType
    );
    if (status) {
      setShowCaseAlreadyAssignedDialog(true);
      return;
    }
    await createCaseAssignment({
      caseId: caseItem.caseId,
      userId: userId,
      actionType: caseItem.actionType,
      progress: "PENDING",
    });
    goToCase(caseItem);
  }

  function onIncompleteAssignedCaseSelected(caseItem) {
    goToCase(caseItem);
  }

  function onCompleteAssignedCaseSelected(caseItem) {
    setSelectedCompletedCase(caseItem);
  }

  const goToCase = (caseAssignment) => {
    setSelectedAssignedCase(caseAssignment);

    let { caseId } = caseAssignment;
    pushHistory(`/annotate/${caseId}`);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
      <div style={{ height: "100%", width: "50%", padding: "3px" }}>
        {notifications.map((notification, index) => (
          <NotificationSnackbar key={index} notification={notification} />
        ))}
        <WorkListTable
          cases={unassignedCases}
          onCaseSelected={onUnassignedCaseSelected}
          itemsPerPage={16}
          tableTitle={"Pending"}
        />
      </div>
      <div style={{ height: "100%", width: "50%" }}>
        <div style={{ height: "66%", width: "100%", padding: "3px" }}>
          <WorkListTable
            cases={pendingCases}
            onCaseSelected={onIncompleteAssignedCaseSelected}
            itemsPerPage={10}
            tableTitle={"In Progress"}
          />
        </div>
        <div style={{ height: "34%", width: "100%", padding: "3px" }}>
          <WorkListTable
            cases={completedCases}
            onCaseSelected={onCompleteAssignedCaseSelected}
            itemsPerPage={8}
            tableTitle={"Completed"}
          />
        </div>
      </div>
      <OkCancelTextDialog
        onCancel={() => setSelectedCompletedCase(null)}
        open={selectedCompletedCase !== null}
        message={
          "This case was previously marked as Completed. Proceeding will re-open this case. Are you sure?"
        }
        onOk={onConfirmReopenCase}
        title={"Re-Open Case"}
        okText={"Yes"}
        cancelText={"No"}
      />
      <OkTextDialog
        open={showCaseAlreadyAssignedDialog}
        message={
          "A user has already been assigned to this case, the page will now refresh and reflect the current case assignments"
        }
        onOk={handleCloseCaseAssignmentExistsDialog}
        title={"Case Already Assigned"}
      />
    </div>
  );
}

WorkList.propTypes = {
  loadCases: PropTypes.func.isRequired,
  isCasesLoaded: PropTypes.bool.isRequired,
  pushHistory: PropTypes.func.isRequired,
  setAssignedCaseProgress: PropTypes.func.isRequired,
  unassignedCases: PropTypes.array.isRequired,
  pendingCases: PropTypes.array.isRequired,
  completedCases: PropTypes.array.isRequired,
  setSelectedAssignedCase: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isCasesLoaded: state.workListPage.isCasesLoaded,
  unassignedCases: unassignedCasesSelector(state),
  pendingCases: incompleteAssignedCasesSelector(state),
  completedCases: completedAssignedCasesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadCases: () => dispatch(loadCases()),
  pushHistory: (route) => dispatch(push(route)),
  setAssignedCaseProgress: (caseAssignmentId, progress) =>
    dispatch(setAssignedCaseProgressThunk(caseAssignmentId, progress)),
  setSelectedAssignedCase: (caseAssignment) =>
    dispatch(setSelectedAssignedCase(caseAssignment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkList);
