import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

import { StyledTableCellContainer } from "../StyledComponents/StyledTableCellContainer";
import { StyledEmptyCell } from "../StyledComponents/StyledEmptyCell";

const TableGroupingCollapse = ({ colSpan, expanded, children }) => {
  return (
    <TableRow>
      <StyledEmptyCell />
      <StyledTableCellContainer colSpan={colSpan}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>{children}</TableBody>
          </Table>
        </Collapse>
      </StyledTableCellContainer>
    </TableRow>
  );
};

TableGroupingCollapse.propTypes = {
  colSpan: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.array,
};

export default TableGroupingCollapse;
