import { createSelector } from "reselect";

export const getStudyNotesIds = (state) =>
  state.annotationPage.studyNotes.allIds;
export const getStudyNotes = (state) => state.annotationPage.studyNotes.byId;

export const studyNotesSelector = createSelector(
  getStudyNotes,
  getStudyNotesIds,
  (studyNotes, studyNotesIds) => studyNotesIds.map((id) => studyNotes[id])
);
