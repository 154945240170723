import {
  QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL,
  QUEUE_VIEWER_DRAW,
  QUEUE_VIEWER_MEASURE,
  SET_ACTIVE_VIEWER_SERIES,
  SET_VIEWER_ACTIVE,
  SET_VIEWER_IMAGE_ID_INDEX,
  SET_VIEWER_SERIES,
  SET_VIEWERS,
  QUEUE_VIEWER_CANCEL_ACTIVE_TOOL,
  QUEUE_VIEWER_WINDOW_LEVEL_PRESET,
  SET_VIEWER_ELEMENT,
  ADD_VIEWER,
  REMOVE_VIEWER,
} from "../../types/annotationPage/viewers";

export function setViewers(viewers) {
  return {
    type: SET_VIEWERS,
    viewers,
  };
}

export function setViewerSeries(viewerId, seriesId) {
  return {
    type: SET_VIEWER_SERIES,
    viewerId,
    seriesId,
  };
}

export function setViewerImageIdIndex(viewerId, imageIdIndex, imageId) {
  return {
    type: SET_VIEWER_IMAGE_ID_INDEX,
    viewerId,
    imageIdIndex,
    imageId,
  };
}

export function setViewerActive(viewerId, isActive) {
  return {
    type: SET_VIEWER_ACTIVE,
    viewerId,
    isActive,
  };
}

export function setActiveViewerSeries(seriesId) {
  return {
    type: SET_ACTIVE_VIEWER_SERIES,
    seriesId,
  };
}

export function queueViewerDraw(isQueued) {
  return {
    type: QUEUE_VIEWER_DRAW,
    isQueued,
  };
}

export function queueViewerMeasure(isQueued) {
  return {
    type: QUEUE_VIEWER_MEASURE,
    isQueued,
  };
}

export function queueViewersCancelActiveTool(isQueued) {
  return {
    type: QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL,
    isQueued,
  };
}

export function queueViewerCancelActiveTool(viewerId, isQueued) {
  return {
    type: QUEUE_VIEWER_CANCEL_ACTIVE_TOOL,
    viewerId,
    isQueued,
  };
}

export function queueViewerWindowLevelPreset(viewerId, windowLevelPreset) {
  return {
    type: QUEUE_VIEWER_WINDOW_LEVEL_PRESET,
    viewerId,
    windowLevelPreset,
  };
}

export function jumpToSlice(imageId) {
  return async (dispatch, getState) => {
    //todo there must be a better way...
    let viewersState = getState().annotationPage.viewers;
    let seriesState = getState().annotationPage.series;

    let viewers = viewersState.allIds.map((id) => viewersState.byId[id]);
    let seriesIds = [...new Set(viewers.map((viewer) => viewer.seriesId))];
    let series = seriesIds.map((id) => seriesState.byId[id]);
    let instancesById = seriesState.instancesById;

    let indexes = {};
    series.forEach((s) => {
      let imageIds = s.instances.map(
        (instanceId) => instancesById[instanceId].wadoUrl
      );
      let sliceIndex = imageIds.indexOf(imageId);
      if (sliceIndex !== -1) {
        indexes[s.id] = sliceIndex;
      }
    });

    viewers.forEach((viewer) => {
      let seriesId = viewer.seriesId;
      if (indexes.hasOwnProperty(seriesId)) {
        dispatch(setViewerImageIdIndex(viewer.id, indexes[seriesId]));
      }
    });
  };
}

export function setViewerElement(viewerId, element) {
  return {
    type: SET_VIEWER_ELEMENT,
    viewerId,
    element,
  };
}

export function addViewer(viewer) {
  return {
    type: ADD_VIEWER,
    viewer,
  };
}

export function removeViewer(viewerId) {
  return {
    type: REMOVE_VIEWER,
    viewerId,
  };
}
