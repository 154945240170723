import { gql } from "@apollo/client/core";
import { LesionType } from "../types/LesionType";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { ClientProps } from "../types/ClientProps";
import { LesionFragmentType } from "../fragments/LesionFragmentType";
import { LESION_FRAGMENT } from "../fragments/LesionFragment";
import { parseLesion } from "../parsers/parseLesion";
import { getLesionColorMap } from "../queries/getLesionColorMap";
import { getBaseWadoUrl } from "../queries/getBaseWadoUrl";

const MUTATION = gql`
  mutation InsertLesion(
    $case_id: Int!
    $name: String!
    $created_by: Int!
    $type: enum_lesion_type_enum!
    $location: Int = null
  ) {
    insert_lesion_one(
      object: {
        case_id: $case_id
        name: $name
        created_by: $created_by
        type: $type
        location: $location
      }
    ) {
      ...Lesion
    }
  }
  ${LESION_FRAGMENT}
`;

type Input = {
  caseId: number;
  name: string;
  createdBy: number;
  type: string;
  location: number;
};

type Data = { insert_lesion_one: LesionFragmentType };

export async function insertLesion({
  client,
  ...input
}: ClientProps & Input): Promise<LesionType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      caseId: case_id,
      name,
      type,
      location,
      createdBy: created_by,
    }) => ({
      case_id,
      name,
      type,
      location,
      created_by,
    }),
    parseData: async ({ insert_lesion_one }: Data) => {
      const lesionColorMap = await getLesionColorMap({ client });
      const baseWadoUrl = await getBaseWadoUrl({ client });
      return parseLesion(insert_lesion_one, lesionColorMap, baseWadoUrl);
    },
  });
}
