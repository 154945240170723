import { hexToRgbArray } from "shared-api";
import { getNumberOfSetBits } from "../tools/getNumberOfSetBits";

export default class Labelmap {
  constructor() {
    this.segments = new Array(16).fill(false);
    this.segmentColorMap = new Map();
  }

  getNextSegment() {
    let segment = this.segments.indexOf(false);

    if (segment < 0) {
      throw new Error("Unable to allocate unique segment");
    }

    return segment;
  }

  areSegmentsEmpty() {
    return !this.segments.includes(true);
  }

  isSegmentAvailable() {
    return this.segments.includes(false);
  }

  useNextSegment(segment) {
    if (this.segments[segment]) {
      throw new Error("Segment already used!");
    }

    this.segments[segment] = true;
  }

  releaseSegment(segment) {
    const index = Math.log2(segment);
    if (index <= -1) {
      throw new Error("Segment not found!");
    }

    this.segments[index] = false;
  }

  clearSegment() {
    this.segments = new Array(16).fill(false);
  }

  isSegmentColorSet(segment) {
    return this.segmentColorMap.has(segment);
  }

  getSegmentColor(segment) {
    return this.segmentColorMap.get(segment);
  }

  setSegmentColor(segmentId, segmentColor) {
    let rgbSegmentColor = hexToRgbArray(segmentColor);
    for (let i = 0; i < 65536; i++) {
      let isolatedSegment = segmentId & i;
      if (isolatedSegment !== segmentId) {
        continue;
      }

      if (i === segmentId) {
        this.segmentColorMap.set(i, rgbSegmentColor);
      } else {
        let numberOfSetBits = getNumberOfSetBits(16, i);
        let darkenedRgbSegmentColor = rgbSegmentColor.map(
          (colorElement, index) => {
            if (index === 3) {
              return colorElement;
            }
            let darkenedColorElement = colorElement - 25 * numberOfSetBits;
            return darkenedColorElement < 0 ? 0 : darkenedColorElement;
          }
        );
        this.segmentColorMap.set(i, darkenedRgbSegmentColor);
      }
    }
  }

  selectNextSegment() {
    let segment = this.getNextSegment();
    this.useNextSegment(segment);
    return Math.pow(2, segment);
  }
}
