export const SET_VIEWER_ACTIVE = "SET_VIEWER_ACTIVE";
export const SET_VIEWERS = "SET_VIEWERS";
export const ADD_VIEWER = "ADD_VIEWER";
export const REMOVE_VIEWER = "REMOVE_VIEWER";
export const SET_VIEWER_SERIES = "SET_VIEWER_SERIES";
export const SET_ACTIVE_VIEWER_SERIES = "SET_ACTIVE_VIEWER_SERIES";
export const SET_VIEWER_IMAGE_ID_INDEX = "SET_VIEWER_IMAGE_ID_INDEX";
export const QUEUE_VIEWER_DRAW = "QUEUE_VIEWER_DRAW";
export const QUEUE_VIEWER_MEASURE = "QUEUE_VIEWER_MEASURE";
export const QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL =
  "QUEUE_VIEWERS_CANCEL_ACTIVE_TOOL";
export const QUEUE_VIEWER_CANCEL_ACTIVE_TOOL =
  "QUEUE_VIEWER_CANCEL_ACTIVE_TOOL";
export const QUEUE_VIEWER_WINDOW_LEVEL_PRESET =
  "QUEUE_VIEWER_WINDOW_LEVEL_PRESET";
export const SET_VIEWER_ELEMENT = "SET_VIEWER_ELEMENT";
