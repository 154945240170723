import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { LesionType } from "../types/LesionType";
import { LESION_FRAGMENT } from "../fragments/LesionFragment";
import { LesionFragmentType } from "../fragments/LesionFragmentType";
import { parseLesion } from "../parsers/parseLesion";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { getLesionColorMap } from "./getLesionColorMap";
import { getBaseWadoUrl } from "./getBaseWadoUrl";

const QUERY = gql`
  query GetLesions($case_id: Int!) {
    lesions: lesion(where: { case_id: { _eq: $case_id } }) {
      ...Lesion
    }
  }
  ${LESION_FRAGMENT}
`;

type Input = {
  caseId: number;
};

type Data = {
  lesions: LesionFragmentType[];
};

export async function getLesions({
  client,
  ...input
}: ClientProps & Input): Promise<LesionType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: async ({ lesions }: Data) => {
      const lesionColorMap = await getLesionColorMap({ client });
      const baseWadoUrl = await getBaseWadoUrl({ client });
      return lesions.map((lesion) =>
        parseLesion(lesion, lesionColorMap, baseWadoUrl)
      );
    },
  });
}
