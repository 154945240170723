import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { CaseAssignmentType } from "../types/CaseAssignmentType";
import { CASE_ASSIGNMENT_FRAGMENT } from "../fragments/CaseAssignmentFragment";
import { CaseAssignmentFragmentType } from "../fragments/CaseAssignmentFragmentType";
import { parseCaseAssignment } from "../parsers/parseCaseAssignment";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";

const QUERY = gql`
  query GetCaseAssignmentsByUserId($case_id: Int!, $user_id: Int!) {
    caseAssignments: case_assignment(
      where: { case_id: { _eq: $case_id }, user_id: { _eq: $user_id } }
    ) {
      ...CaseAssignment
    }
    userCaseActionTypeRoles: user_case_action_type_role(
      where: { user_id: { _eq: $user_id } }
    ) {
      actionType: action_type
    }
  }
  ${CASE_ASSIGNMENT_FRAGMENT}
`;

type Input = {
  caseId: number;
  userId: number;
};

type Data = {
  caseAssignments: CaseAssignmentFragmentType[];
  userCaseActionTypeRoles: {
    actionType: string;
  }[];
};

export async function getCaseAssignmentsByUserId({
  client,
  ...input
}: ClientProps & Input): Promise<CaseAssignmentType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id, userId: user_id }) => ({
      case_id,
      user_id,
    }),
    parseData: ({ userCaseActionTypeRoles, caseAssignments }: Data) => {
      const userRoles = userCaseActionTypeRoles.map(
        ({ actionType }) => actionType
      );

      return caseAssignments
        .map(parseCaseAssignment)
        .filter((caseAssignment) =>
          userRoles.includes(caseAssignment.actionType)
        );
    },
  });
}
