import { gql } from "@apollo/client/core";
import { ANATOMICAL_STRUCTURES_FRAGMENT } from "./AnatomicalStructureFragment";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "./RoiRecistEvaluationFragment";

export const TUMOUR_EVALUATION_FRAGMENT = gql`
  fragment TumourEvaluation on tumour_evaluation {
    lesionId: lesion_id
    lesion {
      name
      rois {
        evaluations: roi_evaluations {
          ...RoiRecistEvaluation
        }
        lesionId: lesion_id
        seriesId: series_id
      }
    }
    targetClassification: target_classification
    stagingClassification: staging_classification
    anatomicalStructure: anatomical_structures {
      ...AnatomicalStructure
    }
  }
  ${ROI_RECIST_EVALUATION_FRAGMENT}
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;
