import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateCaseAssignmentUserId } from "shared-api";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ADMIN } from "../../auth0/AuthRules";
import {
  DialogContentText,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  List,
} from "@material-ui/core";
import OkCancelDialog from "./Base/OkCancelDialog";
import styled from "styled-components";
import getClient from "../../apollo/getClient";

const DialogContentTextStyled = styled(DialogContentText)`
  margin-top: 15px;
`;

const ConfirmSubmissionDialog = ({
  open,
  onCancel,
  onClickYes,
  selectedCaseAssignment,
  userId,
  userCaseActionTypeRoles,
}) => {
  const [showClaimOwnershipAlert, setShowClaimOwnershipAlert] = useState(false);
  const [claimOwnershipChecked, setClaimOwnershipChecked] = useState(false);

  useEffect(() => {
    setShowClaimOwnershipAlert(
      userCaseActionTypeRoles.includes(ADMIN) &&
        userId !== selectedCaseAssignment?.userId
    );
  }, [userCaseActionTypeRoles, userId, selectedCaseAssignment]);

  const handleTakeOwnershipCaseAssignment = async () => {
    const { id: caseAssignmentId } = selectedCaseAssignment;

    const client = getClient();
    await updateCaseAssignmentUserId({ client, caseAssignmentId, userId });
  };

  const handleCancel = () => {
    onCancel();

    setClaimOwnershipChecked(false);
  };

  const handleSubmit = async () => {
    if (showClaimOwnershipAlert && claimOwnershipChecked) {
      await handleTakeOwnershipCaseAssignment();
    }

    onClickYes();
  };

  return (
    <OkCancelDialog
      title={"Confirm Save"}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText={"Yes"}
      cancelText={"No"}
    >
      {showClaimOwnershipAlert && (
        <Alert severity="info">
          <AlertTitle>Admin Alert: Claim Ownership?</AlertTitle>
          <List>
            <ListItem
              button
              onClick={() => setClaimOwnershipChecked(!claimOwnershipChecked)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={claimOwnershipChecked}
                  disableRipple
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText
                primary={`As Admin, do you want to submit this case under your own user, instead of the
        current user, ${selectedCaseAssignment?.username}?`}
              />
            </ListItem>
          </List>
        </Alert>
      )}
      <DialogContentTextStyled>
        Are you sure you want to mark this case as completed?
      </DialogContentTextStyled>
    </OkCancelDialog>
  );
};

const mapState = (state) => ({
  selectedCaseAssignment: state.annotationPage.case.selectedCaseAssignment,
  userId: state.app.userId,
  userCaseActionTypeRoles: state.app.userCaseActionTypeRoles,
});

const connector = connect(mapState, null);

export default connector(ConfirmSubmissionDialog);
