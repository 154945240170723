import { InstanceType } from "../../../annotation/lesions/types/InstanceType";

export function sortSopInstances(instances: InstanceType[]): InstanceType[] {
  const sortedInstances = [...instances];
  sortedInstances.sort(({ instanceNumber: a }, { instanceNumber: b }) => {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      return 1;
    }

    if (b === null) {
      return -1;
    }

    return a - b;
  });
  return sortedInstances;
}
