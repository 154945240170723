import {
  insertLesion,
  lesionTypes,
  MALIGNANT,
  RECIST_1_1,
  RoiAttributes,
  ToolTypes,
  getUserMasks,
  getRoiMeasurements,
  getLesionEvaluations,
  getCaseAssignmentsByCaseId,
  getCaseAssignmentsByUserId,
  getStudiesByCaseId,
  getLesions,
  deleteLesion as deleteLesionApi,
  getLabelmapGroups,
  getFollowUpOrderForSeriesIds,
  getDiagnoses,
} from "shared-api";
import labelmapProvider from "../../../labelmapUtils/initLabelmapProvider";
import { normalizeSeries } from "../../../tools/normalizeSeries";
import { setSeries } from "./series";
import {
  addViewer,
  jumpToSlice,
  removeViewer,
  setActiveViewerSeries,
  setViewerSeries,
} from "./viewers";
import LesionToolLoader from "../../../tools/LesionToolLoader";
import {
  normalizeLesion,
  normalizeLesions,
} from "../../../tools/normalizeLesions";
import { addLesion2, removeLesion2, setLesions } from "./lesions";
import {
  addRoi,
  clearClipboard,
  removeProxyRoiAttributes,
  removeRoi,
  setRois,
  unsetSelectedRoiId,
  updateProxyRoiAttributes,
} from "./rois";
import {
  isCaseLoaded,
  setPossibleCaseAssignments,
  setSelectedAssignedCase,
} from "./case";
import { setAssignedCaseProgress } from "../worklistPage/cases";
import { normalizeStudyNotes } from "../../../tools/normalizeStudyNotes";
import { setStudyNotes } from "./studyNotes/studyNotes";
import { normalizeMasks } from "../../../tools/normalizeMasks";
import { setMaskGroups } from "./masks";
import { getViewerConfig } from "./viewerHelpers";
import {
  setBiopsyDetailsForStudyIds,
  updateBiopsyDetailsLesionId,
} from "./biopsyDetails/biopsyDetails";
import { biopsyDetailsSelector } from "../../../store/annotationPage/biopsyDetails/selectors";
import { viewersSelector } from "../../../store/annotationPage/viewers/selectors";
import { setSelectedRecistSeries } from "../../../RECIST/actions/actionCreators/recistSeries";
import {
  removeRoiRecistEvaluationThunk,
  setRoiRecistEvaluations,
} from "../../../RECIST/actions/actionCreators/evaluations/rois";
import {
  removeMeasurementsForRoi,
  setRoiMeasurements,
} from "../../../RECIST/actions/actionCreators/measurements";
import {
  removeLesionEvaluation,
  setLesionEvaluations,
} from "../../../RECIST/actions/actionCreators/evaluations/lesions";
import { normalizeLesionEvaluations } from "../../../tools/normalizeLesionEvaluations";
import rulesEnforcer from "../../../RECIST/tumourResponseRules/rulesEnforcer";
import iRecistRules from "../../../RECIST/tumourResponseRules/iRecistRules";
import recistRules from "../../../RECIST/tumourResponseRules/recistRules";
import { removeMeasurementsFromElement } from "../../../tools/lengthUtils/removeMeasurementsFromElement";
import { ADMIN } from "../../../auth0/AuthRules";
import { getAnatomicalStructures } from "./organs/organs";
import matchSeriesWithFollowUpOrder from "./series/matchSeriesWithFollowUpOrder";
import { getSeriesFollowUpId, getSeriesIdsWithFollowUp } from "./series/series";
import { sortSeries } from "../../../utils/sortSeries";
import { handleSetSelectedLesion } from "./utils/handleSetSelectedLesion";
import { handleJumpToLesionSlice } from "./utils/handleJumpToLesionSlice";
import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { setDiagnoses } from "../../../RECIST/actions/actionCreators/diagnosis/diagnoses";
import { setTumours } from "../../../RECIST/actions/actionCreators/diagnosis/tumours";
import getClient from "../../../apollo/getClient";
import { createRois } from "./utils/createRois";

export function setSelectedLesion(lesionId) {
  return (dispatch, getState) =>
    handleSetSelectedLesion(lesionId, dispatch, getState);
}

export function jumpToSliceForLesion(lesionId) {
  return (dispatch, getState) =>
    handleJumpToLesionSlice(lesionId, dispatch, getState);
}

export function setSelectedLesionAndJumpToSlice(lesionId, imageId) {
  return (dispatch, getState) => {
    handleSetSelectedLesion(lesionId, dispatch, getState);
    dispatch(jumpToSlice(imageId));
  };
}

export function removeRecistViewerThunk(seriesId) {
  return (dispatch, getState) => {
    let annotationPageState = getState().annotationPage;
    let { viewers } = annotationPageState;
    let viewerIds = viewers.allIds;
    if (viewerIds.length <= 1) {
      return;
    }
    let seriesIds = viewers.allIds.map((viewerId) => {
      return viewers.byId[viewerId].seriesId;
    });
    seriesIds.splice(
      seriesIds.findIndex((id) => {
        return seriesId === id;
      }),
      1
    );

    let lastViewerId = viewerIds[viewerIds.length - 1];
    dispatch(removeViewer(lastViewerId));

    seriesIds.forEach((id, index) => {
      dispatch(setViewerSeries(index, id));
    });
  };
}

export function addRecistViewerThunk(seriesId) {
  return (dispatch, getState) => {
    let { viewers } = getState().annotationPage;

    let newViewerId =
      viewers.allIds.length > 0 ? Math.max(...viewers.allIds) + 1 : 0;
    let seriesIds = viewers.allIds.map((viewerId) => {
      return viewers.byId[viewerId].seriesId;
    });

    let viewerConfig = getViewerConfig(newViewerId, seriesId);
    dispatch(addViewer(viewerConfig));

    let replaceIndex = seriesIds.findIndex((id) => seriesId < id);
    seriesIds.push(seriesId);
    for (let i = replaceIndex; i < viewers.allIds.length + 1; ++i) {
      dispatch(setViewerSeries(i, seriesIds[i]));
    }
  };
}

export function addViewerThunk() {
  return (dispatch, getState) => {
    let getSeriesId = (series, index) => {
      let seriesId;

      let numberOfSeries = series.length;
      if (index < numberOfSeries) {
        seriesId = series[index].id;
      } else {
        seriesId = series[0].id;
      }

      return seriesId;
    };

    let annotationPageState = getState().annotationPage;
    let { series, viewers } = annotationPageState;

    let allSeries = series.allIds.map((id) => series.byId[id]);

    let newViewerId =
      viewers.allIds.length > 0 ? Math.max(...viewers.allIds) + 1 : 0;

    let seriesId = getSeriesId(allSeries, newViewerId);

    let viewerConfig = getViewerConfig(newViewerId, seriesId);

    dispatch(addViewer(viewerConfig));
  };
}

export function removeViewerThunk() {
  return (dispatch, getState) => {
    let annotationPageState = getState().annotationPage;
    let { viewers } = annotationPageState;
    let viewerIds = viewers.allIds;
    if (viewerIds.length <= 1) {
      return;
    }

    let lastViewerId = viewerIds[viewerIds.length - 1];
    dispatch(removeViewer(lastViewerId));
  };
}

export function clearViewersThunk() {
  return (dispatch, getState) => {
    let annotationPageState = getState().annotationPage;
    let { viewers } = annotationPageState;
    let viewerIds = viewers.allIds;
    viewerIds.forEach((viewerId) => {
      dispatch(removeViewer(viewerId));
    });
  };
}

async function fetchDiagnosis(getState, dispatch) {
  const state = getState();
  const seriesIdsWithFollowUp = getSeriesIdsWithFollowUp(state);

  const client = getClient();

  const diagnoses = [];
  for (const seriesIdWithFollowUp of seriesIdsWithFollowUp) {
    const followUpId = getSeriesFollowUpId(seriesIdWithFollowUp, state);
    const diagnosis = await getDiagnoses({ client, followUpId });
    if (diagnosis) {
      diagnoses.push(diagnosis);
    }
  }

  const allDiagnoses = diagnoses.flatMap((diagnosis) => diagnosis);

  dispatch(setDiagnoses(allDiagnoses));
  dispatch(setTumours(allDiagnoses.flatMap((diagnosis) => diagnosis.tumours)));
}

export function loadCase(caseId) {
  return async (dispatch, getState) => {
    dispatch(isCaseLoaded(false));

    let { userId, userCaseActionTypeRoles } = getState().app;

    dispatch(clearClipboard());

    const client = getClient();
    const studies = await getStudiesByCaseId({ client, caseId });
    let allSeries = studies
      .map((study) => study.series)
      .reduce(function (a, b) {
        return a.concat(b);
      });

    const initialSeriesIds = allSeries.map((series) => series.id);
    const followUps = await getFollowUpOrderForSeriesIds({
      client,
      seriesIds: initialSeriesIds,
    });

    allSeries = matchSeriesWithFollowUpOrder(allSeries, followUps);

    allSeries = sortSeries(allSeries);

    let studyNotes = studies
      .map((study) => study.notes)
      .filter((notes) => notes);
    let normalizedStudyNotes = normalizeStudyNotes(studyNotes);
    dispatch(setStudyNotes(normalizedStudyNotes));

    let studyIds = allSeries.map((series) => series.studyId);
    await dispatch(setBiopsyDetailsForStudyIds(studyIds));

    let seriesIds = allSeries.map((series) => series.id);
    const masks = await getLabelmapGroups({ client, caseId });

    dispatch(getAnatomicalStructures());

    labelmapProvider.clearSeriesData();

    masks.forEach((mask) => {
      const { color, defaultVisible, series } = mask;
      mask.isVisible = defaultVisible;
      series.forEach((maskSeries) => {
        let { seriesId } = maskSeries;
        let labelmapId = labelmapProvider.getLabelmapId(seriesId);
        labelmapProvider.setLabelmapColors(seriesId, labelmapId, color);
        maskSeries.labelmapId = labelmapId;
      });
    });

    let normalizedMasks = normalizeMasks(masks);

    dispatch(setMaskGroups(normalizedMasks));

    let normalizedSeries = normalizeSeries(allSeries);

    dispatch(setSeries(normalizedSeries));

    dispatch(clearViewersThunk());
    dispatch(addViewerThunk());

    const lesions = await getLesions({ client, caseId });

    const userMasks = await getUserMasks({ client, caseId });

    const evaluations = await getLesionEvaluations({ client, caseId });

    let normalizedLesionEvaluations = normalizeLesionEvaluations(evaluations);

    let {
      entities: { roiRecistEvaluations, lesionEvaluations },
      result: allLesionEvaluations,
    } = normalizedLesionEvaluations;

    dispatch(
      setLesionEvaluations({
        byId: lesionEvaluations ?? {},
        allIds: allLesionEvaluations,
      })
    );
    dispatch(
      setRoiRecistEvaluations({
        byId: roiRecistEvaluations ?? {},
        allIds: roiRecistEvaluations
          ? Object.keys(roiRecistEvaluations).map((id) => parseInt(id))
          : [],
      })
    );

    if (seriesIds.length >= 2) {
      dispatch(setSelectedRecistSeries([seriesIds[0], seriesIds[1]]));
    } else {
      dispatch(setSelectedRecistSeries([seriesIds[0]]));
    }

    lesions.forEach((lesion) => {
      lesion.rois.forEach((roi) => {
        let { seriesId } = roi;
        let labelmapId = labelmapProvider.getLabelmapId(seriesId);
        labelmapProvider.setLabelmapColors(seriesId, labelmapId);
        roi.labelmapId = labelmapId;
        userMasks.forEach((userMask) => {
          if (userMask.roiId === roi.id) {
            roi.userMasks.push(userMask);
          }
        });
      });
    });

    let {
      entities: { instances },
    } = normalizedSeries;

    let lesionToolLoader = new LesionToolLoader();
    lesionToolLoader.loadTools(lesions, instances);

    const measurements = await getRoiMeasurements({ client, caseId });

    let measurementsObject = {};
    measurements.forEach((measurement) => {
      measurementsObject[measurement.roiId] = measurement.toolData;
    });
    dispatch(setRoiMeasurements(measurementsObject));
    lesionToolLoader.loadMeasurements(measurements, instances);

    //there may be cases where new series have been added, and there are no rois for those series, so create them here
    for (let i = 0; i < lesions.length; i++) {
      let lesion = lesions[i];

      const roiSeriesIds = lesion.rois.map((roi) => roi.seriesId);
      const seriesIdsWithoutRois = allSeries
        .filter((series) => !roiSeriesIds.includes(series.id))
        .map(({ id }) => id);

      if (seriesIdsWithoutRois.length <= 0) {
        continue;
      }

      const rois = await createRois(
        client,
        seriesIdsWithoutRois,
        lesion,
        userId
      );
      lesion.rois.push(...rois);
    }

    let norm = normalizeLesions(lesions);
    let entities = norm.entities;

    dispatch(
      setLesions({
        byId: entities.lesions ? entities.lesions : {},
        allIds: norm.result ? norm.result : [],
      })
    );

    let roisById = entities.rois ? entities.rois : {};

    dispatch(
      setRois({
        byId: roisById,
        allIds: Object.keys(roisById).map((id) => parseInt(id)),
      })
    );

    Object.keys(roisById).forEach((id) => {
      let roi = roisById[id];
      let roiAttributes = roi.roiAttributes;
      if (!roiAttributes) {
        let hasDetection = roi.ellipse.slice;
        if (hasDetection) {
          dispatch(
            updateProxyRoiAttributes(id, new RoiAttributes(true, MALIGNANT))
          );
        }
        return;
      }

      dispatch(updateProxyRoiAttributes(id, roi.roiAttributes));
    });

    //its possible that the user entered from the route directly, so we need to figure out what role they want to 'act' in
    let { selectedCaseAssignment } = getState().annotationPage.case;
    if (!selectedCaseAssignment) {
      let assignedCases = [];

      if (userCaseActionTypeRoles.includes(ADMIN)) {
        assignedCases = await getCaseAssignmentsByCaseId({
          client,
          caseId,
        });

        if (assignedCases.length === 1) {
          if (assignedCases[0].userId !== userId) {
            dispatch(setPossibleCaseAssignments(assignedCases));
          } else {
            dispatch(setSelectedAssignedCase(assignedCases[0]));
          }
        }
      } else {
        assignedCases = await getCaseAssignmentsByUserId({
          client,
          caseId,
          userId,
        });

        if (assignedCases.length === 1) {
          dispatch(setSelectedAssignedCase(assignedCases[0]));
        }
      }

      if (assignedCases.length === 0) {
        //todo need a better way of handling this case
      } else if (assignedCases.length > 1) {
        dispatch(setPossibleCaseAssignments(assignedCases));
      }
    }

    switch (RECIST_1_1) {
      case "iRecist":
        rulesEnforcer._rules = iRecistRules;
        break;
      case RECIST_1_1:
      default:
        rulesEnforcer._rules = recistRules;
    }

    if (lesions && lesions.length > 0) {
      dispatch(setSelectedLesion(lesions[0].id));
    }
    await fetchDiagnosis(getState, dispatch);

    dispatch(isCaseLoaded(true));
  };
}

export function addLesion(lesion) {
  return async (dispatch) => {
    let normalizedLesions = normalizeLesion(lesion);

    let normalizedLesion = normalizedLesions.entities.lesions[lesion.id];

    let normalizedRois = normalizedLesions.entities.rois;
    Object.keys(normalizedRois).forEach((id) => {
      let roi = normalizedRois[id];
      dispatch(addRoi(roi));
    });

    dispatch(addLesion2(normalizedLesion));
  };
}

export function removeLesion(lesionId) {
  return async (dispatch, getState) => {
    let lesion = getState().annotationPage.lesions.byId[lesionId];
    let lesionRoiIds = lesion.rois;

    dispatch(removeLesion2(lesionId));
    lesionRoiIds.forEach((roiId) => {
      dispatch(removeRoi(roiId));
      dispatch(removeProxyRoiAttributes(roiId));
    });
  };
}

export function deleteLesion(lesion) {
  return async (dispatch, getState) => {
    let state = getState();

    let roisById = state.annotationPage.rois.byId;
    let lesionRois = lesion.rois.map((id) => roisById[id]);

    let lesionToolLoader = new LesionToolLoader();

    const {
      annotationPage: { recists },
    } = state;
    const {
      measurements: { measurementData },
    } = recists;

    let viewers = viewersSelector(state);

    const activeViewers = viewers.filter((viewer) => viewer.isActive === true);
    activeViewers.forEach((viewer) => {
      const element = viewer.element;
      const tool = cornerstoneTools.getToolForElement(
        element,
        ToolTypes.FREEHANDROITOOL
      );

      if (tool._drawing) {
        tool.cancelDrawing(element);
      }
    });

    lesionRois.forEach((roi) => {
      const { id, seriesId, labelmapId } = roi;

      let elements = viewers
        .filter((viewer) => viewer.seriesId === seriesId)
        .map((viewer) => viewer.element);

      labelmapProvider.deleteCornerstoneData(seriesId, elements, labelmapId);

      lesionToolLoader.removeRoiToolData(roi);

      if (measurementData && measurementData[id]) {
        const { LADLength, SADLength } = measurementData[id];
        removeMeasurementsFromElement(LADLength, SADLength);
      }

      dispatch(removeMeasurementsForRoi(id));
      dispatch(removeRoiRecistEvaluationThunk(id));
    });
    dispatch(removeLesionEvaluation(lesion.id));

    let biopsyDetailsList = biopsyDetailsSelector(state);
    let associatedBiopsyDetails = biopsyDetailsList.filter(
      (biopsyDetails) => biopsyDetails.lesionId === lesion.id
    );
    associatedBiopsyDetails.forEach((biopsyDetails) => {
      let { id } = biopsyDetails;
      dispatch(updateBiopsyDetailsLesionId(id, null, false));
    });

    dispatch(removeLesion(lesion.id));

    let api = getState().app.api;

    const client = getClient();
    await deleteLesionApi({ client, lesionId: lesion.id });

    dispatch(unsetSelectedRoiId());
  };
}

export function createLesion(lesionName, locationId, type) {
  return async (dispatch, getState) => {
    if (type === lesionTypes.ORGAN && !locationId) {
      throw new Error("Cannot add organ without a valid locationId");
    }

    let api = getState().app.api;

    let { selectedCaseAssignment } = getState().annotationPage.case;
    if (!selectedCaseAssignment) {
      return;
    }

    let { id: caseAssignmentId, caseId } = selectedCaseAssignment;

    let userId = getState().app.userId;

    const seriesIds = getState().annotationPage.series.allIds;

    const client = getClient();
    let lesion = await insertLesion({
      client,
      caseId,
      name: lesionName,
      createdBy: userId,
      type,
      location: locationId,
    });

    const rois = await createRois(client, seriesIds, lesion, userId);
    lesion.rois.push(...rois);

    dispatch(addLesion(lesion));

    dispatch(setSelectedLesion(lesion.id));

    dispatch(setAssignedCaseProgress(caseAssignmentId, "INPROGRESS"));
  };
}

export function jumpToSeriesInstance(seriesId, instanceNumber) {
  return async (dispatch, getState) => {
    let seriesById = getState().annotationPage.series.byId;

    if (!seriesById.hasOwnProperty(seriesId)) {
      return;
    }

    let series = seriesById[seriesId];
    let instances = series.instances;

    let matchingInstances = instances.filter(
      (instance) => instance.instanceNumber === instanceNumber
    );
    if (matchingInstances.length > 0) {
      let imageId = matchingInstances[0].wadoUrl;

      dispatch(setActiveViewerSeries(seriesId));
      dispatch(jumpToSlice(imageId));
    }
  };
}
