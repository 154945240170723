import React from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function DetectContourModeToggle({
  disabled,
  checked,
  onCheckChanged,
}) {
  let onClickSwitchLabel = (isChecked) => {
    if (disabled) {
      return;
    }

    onCheckChanged(isChecked);
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid
        item
        style={{ padding: "4px" }}
        onClick={() => onClickSwitchLabel(false)}
      >
        <Typography disabled={disabled}>Detect</Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={checked}
          value="checked"
          size="small"
          onChange={(event) => onCheckChanged(event.target.checked)}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        style={{ padding: "4px" }}
        onClick={() => onClickSwitchLabel(true)}
      >
        <Typography disabled={disabled}>Contour</Typography>
      </Grid>
    </Grid>
  );
}
