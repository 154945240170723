import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { DiagnosisType } from "../types/DiagnosisType";
import { DiagnosisFragmentType } from "../fragments/DiagnosisFragmentType";
import { parseDiagnosis } from "../parsers/parseDiagnosis";
import { DIAGNOSIS_FRAGMENT } from "../fragments/DiagnosisFragment";

const QUERY = gql`
  query GetDiagnoses($follow_up_id: Int!) {
    diagnosis(where: { follow_up_id: { _eq: $follow_up_id } }) {
      ...Diagnosis
    }
  }
  ${DIAGNOSIS_FRAGMENT}
`;

type Input = {
  followUpId: number;
};

type Data = {
  diagnosis: DiagnosisFragmentType[];
};

export async function getDiagnoses({
  client,
  ...input
}: ClientProps & Input): Promise<DiagnosisType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    options: {
      fetchPolicy: "network-only",
    },
    input,
    parseVariables: ({ followUpId: follow_up_id }) => ({ follow_up_id }),
    parseData: ({ diagnosis }: Data) => diagnosis.map(parseDiagnosis),
  });
}
