import { getUrlParameterByName } from "./getUrlParameterByName";

export function getDatasetFromUrl(url) {
  const studyInstanceUid = getUrlParameterByName("studyUID", url);
  const seriesInstanceUid = getUrlParameterByName("seriesUID", url);
  const sopInstanceUid = getUrlParameterByName("objectUID", url);

  return {
    studyInstanceUid,
    seriesInstanceUid,
    sopInstanceUid,
  };
}
