import { gql } from "@apollo/client/core";
import { TUMOUR_FRAGMENT } from "./TumourFragment";

export const DIAGNOSIS_FRAGMENT = gql`
  fragment Diagnosis on diagnosis {
    id
    type
    code
    primaryLocation: primary_location
    diagnosisDate: diagnosis_date
    followUpId: follow_up_id
    tumours {
      ...Tumour
    }
  }
  ${TUMOUR_FRAGMENT}
`;
