import {
  SET_ROI_RECIST_EVALUATIONS,
  ADD_ROI_RECIST_EVALUATION,
  REMOVE_ROI_RECIST_EVALUATION,
  UPDATE_ROI_RECIST_EVALUATION,
} from "../../types/evaluations/rois";
import {
  COMPLETED,
  updateRoiRecistEvaluation as updateRoiRecistEvaluationApi,
  deleteRoiRecistEvaluation as deleteRoiRecistEvaluationApi,
  insertRoiRecistEvaluation as insertRoiRecistEvaluationApi,
  deleteTumour,
} from "shared-api";
import { addRoiRecistEvaluationToLesion } from "./lesions";
import { updateLesionStatus } from "../../../../actions/actionCreators/annotationPage/lesions";
import {
  addTumourToDiagnosis,
  getLungCancerDiagnosis,
  getTumour,
  insertDiagnosis,
  removeTumourFromDiagnosis,
} from "../diagnosis/diagnoses";
import { getSeriesFollowUpId } from "../../../../actions/actionCreators/annotationPage/series/series";
import { addTumour } from "../../../hooks/addTumour";
import { calculateTumourResponse } from "../../../tumourResponseRules/utils/calculateTumourResponse";
import { insertTumour, removeTumour } from "../diagnosis/tumours";
import getClient from "../../../../apollo/getClient";
import { addDiagnosis } from "../../../hooks/addDiagnosis";

export function addRoiRecistEvaluation(roiRecistEvaluation) {
  return {
    type: ADD_ROI_RECIST_EVALUATION,
    roiRecistEvaluation,
  };
}

export function removeRoiRecistEvaluation(roiId) {
  return { type: REMOVE_ROI_RECIST_EVALUATION, roiId };
}

export function updateRoiRecistEvaluation(roiRecistEvaluation) {
  return {
    type: UPDATE_ROI_RECIST_EVALUATION,
    roiRecistEvaluation,
  };
}

export function setRoiRecistEvaluations(roiRecistEvaluations) {
  return {
    type: SET_ROI_RECIST_EVALUATIONS,
    roiRecistEvaluations,
  };
}

export function addRoiRecistEvaluationThunk(roiRecistEvaluation) {
  return async (dispatch) => {
    let {
      roiId,
      LAD: longAxisDiameter,
      SAD: shortAxisDiameter,
      volume,
      visibility,
      lesionId,
    } = roiRecistEvaluation;

    const client = getClient();
    await insertRoiRecistEvaluationApi({
      client,
      roiId,
      shortAxisDiameter,
      longAxisDiameter,
      volume,
      visibility,
    });

    dispatch(addRoiRecistEvaluation(roiRecistEvaluation));
    dispatch(addRoiRecistEvaluationToLesion(roiRecistEvaluation));
    dispatch(updateLesionStatus(lesionId, COMPLETED));

    dispatch(updateTumoursThunk(roiRecistEvaluation));
  };
}

function updateTumoursThunk(roiRecistEvaluation) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      annotationPage: {
        recists: {
          evaluations: {
            lesions: {
              allIds: lesionEvaluationIds,
              byId: lesionEvaluationsById,
            },
          },
        },
      },
    } = state;

    const { roiId, seriesId, lesionId } = roiRecistEvaluation;

    const followUpId = getSeriesFollowUpId(seriesId, state);

    //if there are no diagnosis, this will make sure there are some
    //this is pretty broken code that needs to be refactored in the future
    const diagnosisSkeleton = getLungCancerDiagnosis(followUpId);
    const diagnosis = await addDiagnosis(diagnosisSkeleton);
    dispatch(insertDiagnosis(diagnosis));
    for (const tumour of diagnosis.tumours) {
      dispatch(insertTumour(tumour));
    }

    const { id: diagnosisId } = diagnosis;

    const targetLesionEvaluation = lesionEvaluationsById[lesionId];

    const tumourBurden = calculateTumourResponse(
      seriesId,
      roiRecistEvaluation,
      targetLesionEvaluation
    );

    const tumour = await addTumour(
      diagnosisId,
      false,
      roiId,
      tumourBurden,
      seriesId
    );

    dispatch(insertTumour(tumour));
    dispatch(addTumourToDiagnosis(diagnosisId, tumour.id));
  };
}

export function removeRoiRecistEvaluationThunk(roiId) {
  return async (dispatch, getState) => {
    const state = getState();

    const client = getClient();
    await deleteRoiRecistEvaluationApi({ client, roiId });

    const tumour = getTumour(roiId, state);
    if (tumour) {
      const { id: tumourId } = tumour;
      //tumour may not present for roi if the lesion was not "saved"
      await deleteTumour({ client, tumourId });
      dispatch(removeTumour(tumourId));
      dispatch(removeTumourFromDiagnosis(tumourId));
    }

    dispatch(removeRoiRecistEvaluation(roiId));
  };
}

export function updateRoiRecistEvaluationThunk(roiId) {
  return async (dispatch, getState) => {
    const {
      annotationPage: {
        recists: {
          evaluations: {
            rois: {
              byId: { [roiId]: roiRecistEvaluation },
            },
          },
          measurements: {
            measurementData: {
              [roiId]: {
                LADLength: { length: newLAD },
                SADLength: { length: newSAD },
              },
            },
          },
        },
      },
    } = getState();

    if (roiRecistEvaluation && newLAD && newSAD) {
      roiRecistEvaluation.LAD = newLAD;
      roiRecistEvaluation.SAD = newSAD;

      const {
        LAD: longAxisDiameter,
        SAD: shortAxisDiameter,
      } = roiRecistEvaluation;

      const client = getClient();
      await updateRoiRecistEvaluationApi({
        client,
        roiId,
        shortAxisDiameter,
        longAxisDiameter,
        volume: null,
      });

      dispatch(updateRoiRecistEvaluation(roiRecistEvaluation));
    }
  };
}
