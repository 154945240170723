import { CaseAssignmentFragmentType } from "../fragments/CaseAssignmentFragmentType";
import { CaseAssignmentType } from "../types/CaseAssignmentType";

export function parseCaseAssignment(
  caseAssignmentFragment: CaseAssignmentFragmentType
): CaseAssignmentType {
  const {
    user: { username },
    case: { name },
    ...props
  } = caseAssignmentFragment;

  return {
    ...props,
    name,
    username,
  };
}
