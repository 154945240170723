import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { BRUSH } from "./Tools";
import { getLabelmapPixelData } from "./maskUtils/getLabelmapPixelData";
import cornerstone from "cornerstone-core";

export default class BrushTool {
  constructor(cornerstoneElement) {
    const tool = cornerstoneTools.getToolForElement(cornerstoneElement, BRUSH);
    this.onDataUpdate = null;

    this.element = cornerstoneElement;

    if (!tool) {
      throw new Error("Cornerstone brush tool is null");
    }

    this.tool = tool;

    this.setDisabled();

    cornerstoneElement.addEventListener(
      cornerstoneTools.EVENTS.LABELMAP_MODIFIED,
      this.onLabelmapUpdated,
      false
    );

    const keyBindings = [
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "c" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onUserMaskCopyHotKeyPressed,
      },
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "v" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onUserMaskPasteHotKeyPressed,
      },
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "ArrowUp" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.increaseBrushSize,
      },
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "ArrowDown" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.decreaseBrushSize,
      },
    ];

    tool.setKeyBindings(keyBindings);
  }

  setOnUserMaskCopyHotKeyPressedCallback(onUserMaskCopyHotKeyPressedCallback) {
    this.onUserMaskCopyHotKeyPressedCallback = onUserMaskCopyHotKeyPressedCallback;
  }

  setOnUserMaskPasteHotKeyPressedCallback(
    onUserMaskPasteHotKeyPressedCallback
  ) {
    this.onUserMaskPasteHotKeyPressedCallback = onUserMaskPasteHotKeyPressedCallback;
  }

  onUserMaskCopyHotKeyPressed = () => {
    if (!this.onUserMaskCopyHotKeyPressedCallback) {
      return;
    }

    let brushPixelData = getLabelmapPixelData(this.element);

    this.onUserMaskCopyHotKeyPressedCallback(brushPixelData);
  };

  onUserMaskPasteHotKeyPressed = () => {
    if (!this.onUserMaskPasteHotKeyPressedCallback) {
      return;
    }

    this.onUserMaskPasteHotKeyPressedCallback();
  };

  onLabelmapUpdated = () => {
    if (!this.onDataUpdate) {
      return;
    }

    let brushPixelData = getLabelmapPixelData(this.element);

    this.onDataUpdate(brushPixelData);
  };

  setDataUpdateCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }

  setOnBrushSizeChangedCallback(onBrushSizeChangedCallback) {
    this.onBrushSizeChanged = onBrushSizeChangedCallback;
  }

  setDisabled() {
    cornerstoneTools.setToolDisabledForElement(this.element, BRUSH, {
      mouseButtonMask: 1,
    });
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(this.element, BRUSH, {
      mouseButtonMask: 1,
    });
  }

  increaseBrushSize = () => {
    if (!this.onBrushSizeChanged) {
      return;
    }
    let newBrushSize = this.tool.increaseBrushSize();
    cornerstone.updateImage(this.element);
    this.onBrushSizeChanged(newBrushSize);
  };

  decreaseBrushSize = () => {
    if (!this.onBrushSizeChanged) {
      return;
    }
    let newBrushSize = this.tool.decreaseBrushSize();
    cornerstone.updateImage(this.element);
    this.onBrushSizeChanged(newBrushSize);
  };

  setBrushRadiusValues(brushRadiusValues) {
    const { radius, maxRadius, minRadius } = brushRadiusValues;
    this.tool.setBrushSize(radius);
    this.tool.setBrushMinSize(minRadius);
    this.tool.setBrushMaxSize(maxRadius);
  }
}
