let joinRule = (base, action) => {
  return base + "_" + action;
};

const CREATE = "CREATE";
const READ = "READ";
const UPDATE = "UPDATE";
const DELETE = "DELETE";

const ANNOTATIONS = "ANNOTATIONS";
const RECIST = "RECIST";
export const BIOPSY_LINKING = "BIOPSY_LINKING";
export const CONTOUR_ORGAN = "CONTOUR_ORGAN";
export const ADMIN = "ADMIN";

export const ANNOTATIONS_CREATE = joinRule(ANNOTATIONS, CREATE);
export const ANNOTATIONS_READ = joinRule(ANNOTATIONS, READ);
export const ANNOTATIONS_UPDATE = joinRule(ANNOTATIONS, UPDATE);
export const ANNOTATIONS_DELETE = joinRule(ANNOTATIONS, DELETE);

export const RECIST_CREATE = joinRule(RECIST, CREATE);
