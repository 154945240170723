import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { CaseAssignmentType } from "../types/CaseAssignmentType";
import { CASE_ASSIGNMENT_FRAGMENT } from "../fragments/CaseAssignmentFragment";
import { CaseAssignmentFragmentType } from "../fragments/CaseAssignmentFragmentType";
import { parseCaseAssignment } from "../parsers/parseCaseAssignment";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";

const QUERY = gql`
  query GetCaseAssignmentsByCaseId($case_id: Int!) {
    caseAssignments: case_assignment(where: { case_id: { _eq: $case_id } }) {
      ...CaseAssignment
    }
  }
  ${CASE_ASSIGNMENT_FRAGMENT}
`;

type Input = {
  caseId: number;
};

type Variables = {
  case_id: number;
};

type Output = CaseAssignmentType[];

type Data = {
  caseAssignments: CaseAssignmentFragmentType[];
};

export function useCaseAssignmentsByCaseId(
  caseId: number
): useParsedQueryReturnType<number, Output, Variables> {
  return useParsedQuery<number, Output, Data, Variables>({
    query: QUERY,
    input: caseId,
    parseVariables: (case_id) => ({ case_id }),
    parseData: ({ caseAssignments }: Data) =>
      caseAssignments.map(parseCaseAssignment),
  });
}

export async function getCaseAssignmentsByCaseId({
  client,
  ...input
}: ClientProps & Input): Promise<Output> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: ({ caseAssignments }: Data) =>
      caseAssignments.map(parseCaseAssignment),
  });
}
