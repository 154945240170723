import {
  SET_RECIST_SIDEBAR_SERIES,
  SET_RECIST_SIDEBAR_SERIES_BY_INDEX,
  SET_CURRENT_RECIST_SERIES_ID,
} from "../actions/types/recistSeries";

let defaultState = {
  currentSeriesId: null,
  selectedSeries: [],
};

export const recistSeries = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_RECIST_SERIES_ID:
      return setCurrentSeriesId(state, action);
    case SET_RECIST_SIDEBAR_SERIES_BY_INDEX:
      return setSelectedSeriesByIndex(state, action);
    case SET_RECIST_SIDEBAR_SERIES:
      return setSelectedSeries(state, action);
    default:
      return state;
  }
};

const setSelectedSeriesByIndex = (state, action) => {
  // Receives an index of which select triggered the series change
  // Keeps the other series the same, and sets the new seriesId to index
  const seriesIndices =
    action.index === 0
      ? [
          action.seriesId,
          state.selectedSeries[1]
            ? state.selectedSeries[1]
            : state.selectedSeries[0],
        ]
      : [state.selectedSeries[0], action.seriesId];
  return setSelectedSeries(state, { seriesIndices });
};

const setSelectedSeries = (state, action) => {
  return { ...state, selectedSeries: action.seriesIndices };
};

const setCurrentSeriesId = (state, action) => {
  return { ...state, currentSeriesId: action.seriesId };
};
