import {
  IS_CASES_LOADED,
  SET_ASSIGNED_CASES,
  SET_UNASSIGNED_CASES,
} from "../../actions/types/worklistPage/cases";
import { SET_ASSIGNED_CASE_PROGRESS } from "../../actions/types/annotationPage/case";

let defaultState = {
  isCasesLoaded: false,
  unassignedCases: {
    byId: {},
    allIds: [],
  },
  assignedCases: {
    byId: {},
    allIds: [],
  },
};

const workListPage = (state = defaultState, action) => {
  switch (action.type) {
    case IS_CASES_LOADED:
      return isCasesLoaded(state, action);
    case SET_ASSIGNED_CASES:
      return setAssignedCases(state, action);
    case SET_UNASSIGNED_CASES:
      return setUnassignedCases(state, action);
    case SET_ASSIGNED_CASE_PROGRESS:
      return setAssignedCaseProgress(state, action);
    default:
      return state;
  }
};

const isCasesLoaded = (state, action) => {
  return {
    ...state,
    isCasesLoaded: action.isLoaded,
  };
};

const setAssignedCases = (state, action) => {
  return {
    ...state,
    assignedCases: {
      byId: action.cases.entities.assignedCases,
      allIds: action.cases.result,
    },
  };
};

const setUnassignedCases = (state, action) => {
  return {
    ...state,
    unassignedCases: {
      byId: action.cases.entities.unassignedCases,
      allIds: action.cases.result,
    },
  };
};

const setAssignedCaseProgress = (state, action) => {
  return {
    ...state,
    assignedCases: {
      ...state.assignedCases,
      byId: {
        ...state.assignedCases.byId,
        [action.caseAssignmentId]: {
          ...state.assignedCases.byId[action.caseAssignmentId],
          progress: action.progress,
        },
      },
    },
  };
};

export default workListPage;
