import { connect } from "react-redux";
import {
  ANNOTATIONS_CREATE,
  ANNOTATIONS_DELETE,
  ANNOTATIONS_READ,
  ANNOTATIONS_UPDATE,
  BIOPSY_LINKING,
  RECIST_CREATE,
} from "../auth0/AuthRules";

export const canUseComponent = (roles, action) => {
  if (roles.length === 0) {
    return false;
  }

  for (const role of roles) {
    if (checkUserRole(role, action)) {
      return true;
    }
  }

  return false;
};

const checkUserRole = (role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  return permissions.includes(action);
};

function Can({ action, data, roles, yes = () => null, no = () => null }) {
  return canUseComponent(roles, action, data) ? yes() : no();
}

const mapStateToProps = (state) => ({
  roles: state.app.roles,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Can);

export const ANNOTATE_ROLE = "ANNOTATE";
export const VIEW_ANNOTATIONS_ROLE = "VIEW_ANNOTATIONS";

export const rules = {
  [ANNOTATE_ROLE]: [
    ANNOTATIONS_CREATE,
    ANNOTATIONS_DELETE,
    ANNOTATIONS_READ,
    ANNOTATIONS_UPDATE,
    BIOPSY_LINKING,
    RECIST_CREATE,
  ],
  [VIEW_ANNOTATIONS_ROLE]: [ANNOTATIONS_READ],
};
