import { gql } from "@apollo/client/core";
import { ROI_FRAGMENT } from "./RoiFragment";
import { ANATOMICAL_STRUCTURES_FRAGMENT } from "./AnatomicalStructureFragment";
import { LESION_ASSOCIATION_FRAGMENT } from "./LesionAssociationFragment";
import { LESION_STATUS_FRAGMENT } from "./LesionStatusFragment";

export const LESION_FRAGMENT = gql`
  fragment Lesion on lesion {
    id: lesion_id
    name
    caseId: case_id
    type
    origin
    statuses: lesion_statuses {
      ...LesionStatus
    }
    location
    anatomicalStructure: anatomical_structure {
      ...AnatomicalStructure
    }
    associations: lesion_associations {
      ...LesionAssociation
    }
    rois(where: { series: { series_cases: { case_id: { _eq: $case_id } } } }) {
      ...Roi
    }
  }
  ${ROI_FRAGMENT}
  ${LESION_ASSOCIATION_FRAGMENT}
  ${LESION_STATUS_FRAGMENT}
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;
