import { DiagnosisType } from "../types/DiagnosisType";
import { DiagnosisFragmentType } from "../fragments/DiagnosisFragmentType";
import { TumourType } from "../types/TumourType";
import { parseTumour } from "./parseTumour";

export function parseDiagnosis({
  id,
  type,
  code,
  primaryLocation,
  diagnosisDate: rawDiagnosisDate,
  followUpId,
  tumours: tumourFragments,
}: DiagnosisFragmentType): DiagnosisType {
  const tumours: TumourType[] = tumourFragments.map(parseTumour);

  const diagnosisDate = new Date(rawDiagnosisDate);

  return {
    id,
    type,
    code,
    primaryLocation,
    diagnosisDate,
    followUpId,
    tumours,
  };
}
