import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import { StyledTableCellContainer } from "../StyledComponents/StyledTableCellContainer";
import { StyledTableCellIcon } from "../StyledComponents/StyledTableCellIcon";

const TableGroupingHeaderWrapper = styled(TableRow)`
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
const TableGroupingHeader = ({
  name,
  count,
  colSpan,
  expanded,
  setExpanded,
  children,
}) => {
  const expandIconColor = count > 0 ? "action" : "disabled";
  const typographyColor = count > 0 ? "text.primary" : "text.disabled";

  return (
    <TableGroupingHeaderWrapper
      onClick={() => {
        if (!count) return;
        setExpanded(!expanded);
      }}
    >
      <StyledTableCellIcon>
        {expanded ? (
          <ExpandLess color={expandIconColor} />
        ) : (
          <ExpandMore color={expandIconColor} />
        )}
      </StyledTableCellIcon>

      <StyledTableCellContainer colSpan={colSpan}>
        <Box color={typographyColor}>
          <Typography variant="h5">
            {name} ({count})
          </Typography>
        </Box>
      </StyledTableCellContainer>
      {children}
    </TableGroupingHeaderWrapper>
  );
};

TableGroupingHeader.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  colSpan: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  children: PropTypes.element,
};

export default TableGroupingHeader;
