import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { STUDY_NOTE_FRAGMENT } from "../fragments/StudyNoteFragment";
import { StudyNoteFragmentType } from "../fragments/StudyNoteFragmentType";
import { StudyNotesType } from "../types/StudyNotesType";
import { parseStudyNotes } from "../parsers/parseStudyNotes";

const MUTATION = gql`
  mutation InsertStudyNote($study_id: Int!, $user_id: Int!, $notes: String) {
    insert_study_notes_one(
      object: {
        study_id: $study_id
        last_modified_by_user_id: $user_id
        notes: $notes
      }
      on_conflict: {
        constraint: study_notes_study_id_key
        update_columns: [last_modified_by_user_id, notes]
      }
    ) {
      ...StudyNote
    }
  }
  ${STUDY_NOTE_FRAGMENT}
`;

type Input = {
  studyId: number;
  notes: string;
  userId: string;
};

type Data = {
  insert_study_notes_one: StudyNoteFragmentType;
};

export async function insertStudyNote({
  client,
  ...input
}: ClientProps & Input): Promise<StudyNotesType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ studyId: study_id, userId: user_id, notes }) => ({
      study_id,
      user_id,
      notes,
    }),
    parseData: ({ insert_study_notes_one }: Data) =>
      parseStudyNotes(insert_study_notes_one),
  });
}
