import {
  SET_BIOPSY_DETAILS,
  UPDATE_BIOPSY_DETAILS_LESION_ID,
  UPDATE_BIOPSY_NO_LINKING,
} from "../../../types/annotationPage/biopsyDetails";
import getClient from "../../../../apollo/getClient";
import { normalizeBiopsyDetails } from "../../../../tools/normalizeBiopsyDetails";
import {
  getPatientBiopsyDetails,
  updatePatientBiopsyDetailsLesionId,
  updatePatientBiopsyDetailsNoLinking,
} from "shared-api";

function setBiopsyDetails(biopsyDetails) {
  return {
    type: SET_BIOPSY_DETAILS,
    biopsyDetails,
  };
}

export function setBiopsyDetailsForStudyIds(studyIds) {
  return async (dispatch) => {
    const client = getClient();

    const biopsyDetails = await getPatientBiopsyDetails({ client, studyIds });

    let normalizedBiopsyDetails = normalizeBiopsyDetails(biopsyDetails);
    dispatch(setBiopsyDetails(normalizedBiopsyDetails));
  };
}

export function updateBiopsyDetailsLesionId(biopsyDetailsId, lesionId, unsure) {
  return {
    type: UPDATE_BIOPSY_DETAILS_LESION_ID,
    biopsyDetailsId,
    lesionId,
    unsure,
  };
}

export function updateBiopsyDetailsLesionIdThunk(id, lesionId, unsure) {
  return async (dispatch) => {
    const client = getClient();

    await updatePatientBiopsyDetailsLesionId({ client, id, lesionId, unsure });

    dispatch(updateBiopsyDetailsLesionId(id, lesionId, unsure));
  };
}

export function updateBiopsyNoLinkingThunk(id, state) {
  return async (dispatch) => {
    let { noLinking, noLinkingReason: reason } = state;

    const client = getClient();
    await updatePatientBiopsyDetailsNoLinking({
      client,
      id,
      noLinking,
      reason,
    });

    dispatch(updateBiopsyNoLinking(id, state));
  };
}

export function updateBiopsyNoLinking(biopsyDetailsId, noLinking) {
  return {
    type: UPDATE_BIOPSY_NO_LINKING,
    biopsyDetailsId,
    noLinking,
  };
}
