import { useSelector } from "react-redux";
import {
  instancesByIdSelector,
  seriesSelector,
} from "../../store/annotationPage/series/selectors";
import { roiIdsWithPendingAttributesEditsSelector } from "../../store/annotationPage/rois/selectors";
import { lesionTypes } from "shared-api";
import { getRoiImageIdsWithMissingAnnotations } from "../../tools/roiChecks/getRoiImageIdsWithMissingAnnotations";
import { findImbalancedAnnotationImageId } from "../../tools/roiChecks/findImbalancedAnnotationImageId";

type useRoiChecksReturnType = (
  roi: Record<string, any>,
  lesionType: string
) => RoiCheckResultType;

export function useRoiChecks(): useRoiChecksReturnType {
  const seriesList = useSelector(seriesSelector);
  const instancesById = useSelector(instancesByIdSelector);
  const roiIdsWithPendingAttributesEdits = useSelector(
    roiIdsWithPendingAttributesEditsSelector
  );

  return (roi, lesionType) => {
    const enableFeaturePreview =
      process.env.NODE_ENV === "development" ||
      window._env_.REACT_APP_ENABLE_FEATURE_PREVIEW === "true";

    let roiAttributesHasPendingEdits;
    if (lesionType === lesionTypes.ORGAN) {
      roiAttributesHasPendingEdits = false;
    } else {
      roiAttributesHasPendingEdits = roiIdsWithPendingAttributesEdits.includes(
        roi.id
      );
    }

    const { contours, userMasks } = roi;

    const imageIdsWithMissingContours = getRoiImageIdsWithMissingAnnotations(
      roi,
      seriesList,
      instancesById,
      contours
    );

    const imageIdWithMissingContour =
      imageIdsWithMissingContours.length > 0
        ? imageIdsWithMissingContours[0]
        : null;

    const imageIdsWithMissingUserMasks = getRoiImageIdsWithMissingAnnotations(
      roi,
      seriesList,
      instancesById,
      userMasks
    );

    const imageIdWithMissingUserMask =
      imageIdsWithMissingUserMasks.length > 0 && enableFeaturePreview
        ? imageIdsWithMissingUserMasks[0]
        : null;

    let imbalancedContourImageId = null;
    let imbalancedUserMaskImageId = null;

    if (lesionType === lesionTypes.LESION) {
      imbalancedContourImageId = findImbalancedAnnotationImageId(
        roi,
        seriesList,
        instancesById,
        contours
      );

      if (enableFeaturePreview) {
        imbalancedUserMaskImageId = findImbalancedAnnotationImageId(
          roi,
          seriesList,
          instancesById,
          userMasks
        );
      }
    }

    return {
      roiAttributesHasPendingEdits,
      imageIdWithMissingContour,
      imageIdWithMissingUserMask,
      imbalancedContourImageId,
      imbalancedUserMaskImageId,
    };
  };
}

type RoiCheckResultType = {
  roiAttributesHasPendingEdits: boolean;
  imageIdWithMissingContour: string | null;
  imageIdWithMissingUserMask: string | null;
  imbalancedContourImageId: string | null;
  imbalancedUserMaskImageId: string | null;
};

export function getMayForcePassRoiChecks({
  roiAttributesHasPendingEdits,
  imageIdWithMissingContour,
  imageIdWithMissingUserMask,
}: RoiCheckResultType): boolean {
  return (
    !roiAttributesHasPendingEdits &&
    imageIdWithMissingContour === null &&
    imageIdWithMissingUserMask === null
  );
}
