import { createSelector } from "reselect";
import { AnatomicalStructureType } from "../../../actions/actionCreators/annotationPage/organs/utilities/getAnatomicalStructuresTree";

const getAnatomicalStructures = (state: any) =>
  state.annotationPage.organs.anatomicalStructures;

export const selectAnatomicalStructures = createSelector(
  getAnatomicalStructures,
  (anatomicalStructures: AnatomicalStructureType[]) => anatomicalStructures
);

export const selectEnabledAnatomicalStructures = createSelector(
  getAnatomicalStructures,
  (anatomicalStructures: AnatomicalStructureType[]) => {
    return anatomicalStructures
      .map(getEnabledBranch)
      .filter((child) => child !== null);
  }
);

function getEnabledBranch(
  anatomicalStructure: AnatomicalStructureType
): AnatomicalStructureType | null {
  const { enabled, children } = anatomicalStructure;
  if (!enabled) {
    return null;
  }

  const enabledChildren = children.flatMap((child) => {
    const enabledBranch = getEnabledBranch(child);
    return enabledBranch ? [enabledBranch] : [];
  });

  return {
    ...anatomicalStructure,
    children: enabledChildren,
  };
}
