import { LabelmapGroupFragmentType } from "../fragments/LabelmapGroupFragmentType";
import { LabelmapGroupType } from "../types/LabelmapGroupType";
import { LabelmapSeriesType } from "../types/LabelmapSeriesType";
import { parseLabelmap } from "./parseLabelmap";

export function parseLabelmapGroup(
  {
    id,
    labelmaps,
    name,
    displayName,
    defaultVisible,
    color,
    enabled,
  }: LabelmapGroupFragmentType,
  maskIndex: number
): LabelmapGroupType {
  const series: LabelmapSeriesType[] = [];

  for (const labelmapFragment of labelmaps) {
    const {
      instance: { seriesId },
    } = labelmapFragment;

    let existingSeries = series.find(
      ({ seriesId: existingSeriesId }) => existingSeriesId === seriesId
    );
    if (!existingSeries) {
      existingSeries = {
        seriesId,
        instances: [],
      };
      series.push(existingSeries);
    }

    const { instances } = existingSeries;

    const labelmap = parseLabelmap(labelmapFragment, enabled);

    instances.push(labelmap);
  }

  return {
    id,
    enabled,
    maskIndex,
    name,
    displayName,
    defaultVisible,
    color,
    series,
  };
}
