import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "../auth0/auth0-wrapper";
import { NotaExporterButton } from "nota-components";
import NotaExporterInstance from "./NotaExporterInstance";

const NotaExporter = ({ caseId }) => {
  const { getTokenSilently } = useAuth0();

  const handleExport = ({
    onDownloadProgress,
    onExportComplete,
    onExportError,
  }) => {
    getTokenSilently().then(async (token) => {
      await NotaExporterInstance.exportCaseData(
        caseId,
        { exportMasks: true, exportImages: false },
        token,
        onDownloadProgress,
        onExportComplete,
        onExportError
      );
    });
  };

  const handleCancel = () => {
    NotaExporterInstance.cancel();
  };

  return (
    <NotaExporterButton
      caseId={caseId}
      handleExport={handleExport}
      handleCancel={handleCancel}
    />
  );
};

const mapState = (state) => ({
  caseId: state.annotationPage.case.selectedCaseAssignment?.caseId,
});

const connector = connect(mapState, null);

export default connector(NotaExporter);
