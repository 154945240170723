import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { TUMOUR_FRAGMENT } from "../fragments/TumourFragment";
import { TumourFragmentType } from "../fragments/TumourFragmentType";
import { parseTumour } from "../parsers/parseTumour";
import { TumourType } from "../types/TumourType";

const MUTATION = gql`
  mutation UpdateTumour(
    $tumour_id: Int!
    $measurable: Boolean!
    $burden: numeric!
  ) {
    update_tumour_burden(
      where: { tumour_id: { _eq: $tumour_id } }
      _set: { burden: $burden }
    ) {
      affected_rows
    }
    update_tumour(
      where: { id: { _eq: $tumour_id } }
      _set: { measurable: $measurable }
    ) {
      returning {
        ...Tumour
      }
    }
  }
  ${TUMOUR_FRAGMENT}
`;

type Input = {
  tumourId: number;
  measurable: boolean;
  burden: number;
};

type Data = {
  update_tumour_burden: { affected_rows: number };
  update_tumour: { returning: TumourFragmentType[] };
};

export async function updateTumour({
  client,
  ...input
}: ClientProps & Input): Promise<TumourType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ tumourId: tumour_id, burden, measurable }) => {
      return {
        tumour_id,
        burden,
        measurable,
      };
    },
    parseData: ({ update_tumour: { returning } }: Data) => {
      if (returning.length !== 1) {
        throw new Error("tumour not updated properly");
      }

      return parseTumour(returning[0]);
    },
  });
}
