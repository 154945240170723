import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

export const MUTATION = gql`
  mutation DeleteLesionStatus($lesion_id: Int!) {
    delete_lesion_status(where: { lesion_id: { _eq: $lesion_id } }) {
      affected_rows
    }
  }
`;

type Input = {
  lesionId: number;
};

export async function deleteLesionStatus({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ lesionId: lesion_id }) => ({
      lesion_id,
    }),
    parseData: () => undefined,
  });
}
