import { RoiType } from "../types/RoiType";
import { RoiFragmentType } from "../fragments/RoiFragmentType";
import ToolTypes from "../../utilities/ToolTypes";
import { parseContour } from "./parseContour";
import { parseEllipse } from "./parseEllipse";
import { parseUserMask } from "./parseUserMask";

export function parseRoi(
  roiFragment: RoiFragmentType,
  color: string,
  baseWadoUrl: string,
  lesionId: number
): RoiType {
  const {
    id,
    seriesId,
    createdBy,
    roiAttributes,
    contours: contourFragments,
    ellipse: ellipseFragment,
    userMasks: userMaskFragments,
    series: { seriesDate },
  } = roiFragment;

  const contoursToolRoi = {
    id,
    type: ToolTypes.FREEHANDROITOOL,
    fillColor: color,
    userId: createdBy,
    color,
    lesionId,
  };

  const contours = contourFragments.map((contourFragment) => {
    const { instanceId } = contourFragment;
    const parsedContourToolData = parseContour(contourFragment);
    const toolDataWithUniqueToolRoiId = parsedContourToolData.map(
      (data: any) => ({
        ...data,
        roi: { ...data.roi, id, color, fillColor: color, lesionId },
      })
    );
    return {
      instanceId,
      toolData: toolDataWithUniqueToolRoiId,
    };
  });

  const ellipse = parseEllipse(ellipseFragment, id, baseWadoUrl, lesionId);

  const userMasks = userMaskFragments.map(parseUserMask);

  return {
    id,
    seriesId,
    createdBy,
    contoursToolRoi,
    contours,
    roiAttributes,
    ellipse,
    userMasks,
    seriesDate,
    series: { id: seriesId, seriesDate },
  };
}
