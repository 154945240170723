import { getSeriesContainingRoi } from "./getSeriesContainingRoi";
import { getSeriesImageIds } from "./getSeriesImageIds";
import { findIndexGaps } from "./findIndexGaps";
import getAnnotationsWithWadoUrls from "../../store/annotationPage/rois/getAnnotationsWithWadoUrls";

export let getRoiImageIdsWithMissingAnnotations = (
  roi,
  seriesList,
  instancesById,
  annotations
) => {
  const { roiAttributes } = roi;
  if (roiAttributes && !roiAttributes.measurable) {
    console.debug(
      `Roi [${roi.id}] is marked as non-measurable. Skipping missing contour check`
    );
    return [];
  }

  let series = getSeriesContainingRoi(roi, seriesList);
  let seriesImageIds = getSeriesImageIds(series, instancesById);

  const annotationWithWadoUrls = getAnnotationsWithWadoUrls(
    annotations,
    instancesById
  );
  let annotationImageIds = annotationWithWadoUrls.map(
    (annotation) => annotation.wadoUrl
  );

  let annotationInstanceIndices = annotationImageIds.map((imageId) =>
    seriesImageIds.indexOf(imageId)
  );

  if (annotationInstanceIndices.some((index) => index === -1)) {
    throw new Error("Could not find imageId from roi in series");
  }

  let missingIndices = findIndexGaps(annotationInstanceIndices);

  return missingIndices.map((index) => seriesImageIds[index]);
};
