import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { ToolTypes } from "shared-api";

export function removeMeasurementsFromElement(LADLength, SADLength) {
  if (LADLength) {
    clearToolSliceData(LADLength, ToolTypes.MEASUREMENTTOOL);
  }
  if (SADLength) {
    clearToolSliceData(SADLength, ToolTypes.MEASUREMENTTOOL);
  }
}

function clearToolSliceData(obj, toolType) {
  const toolStates =
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  for (const imageId of Object.keys(toolStates)) {
    const imageTools = toolStates[imageId];
    const imageTool = imageTools[toolType];
    if (!imageTool) {
      continue;
    }

    const { data } = imageTool;
    imageTool.data = data.filter((data) => data !== obj);
  }
}
