import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setActiveTool,
  setDetectMode,
} from "../../../../actions/actionCreators/annotationPage/tools";
import { viewerRoisSelector } from "../../../../store/annotationPage/viewers/selectors";
import { setSelectedLesion } from "../../../../actions/actionCreators/annotationPage/thunks";
import { addNewLesionEvaluationThunk } from "../../../actions/actionCreators/evaluations/lesions";
import { ANNOTATE } from "../../../../tools/Tools";
import {
  lesionEvaluationTypes,
  lesionStagingTypes,
  lesionTypes,
} from "shared-api";

import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import TableRow from "@material-ui/core/TableRow";

import { StyledContentActionButton } from "../StyledComponents/StyledButton";
import {
  StyledCell,
  StyledCellContentsWrapper,
} from "../StyledComponents/StyledTableCellContainer";
import { StyledCellWrapper } from "../StyledComponents/StyledCellWrapper";
import { ActionsWrapper } from "../StyledComponents/ActionsWrapper";
import LocationSelect from "./LocationSelect";
import LesionHeader from "../LesionRowHeader";

import MeasurableToggle from "./MeasurableToggle";
import ClassificationToggle from "./ClassificationToggle";
import TargetToggle from "./TargetToggle";
import RuleViolationDialogs from "./RuleViolationDialogs";
import getMeasurementsNewRecistItem from "./getMeasurementsNewRecistItem";

const NewRecistItem = ({
  lesion,
  setDetectMode,
  setActiveTool,
  selected,
  setSelectedLesion,
  measureToolData,
  viewerRois,
  addLesionEvaluation,
}) => {
  const [detectClicked, setDetectClicked] = useState(false);
  const [location, setLocation] = useState(null);
  const [isMeasurable, setMeasurable] = useState(null);
  const [target, setTarget] = useState(lesionEvaluationTypes.NONTARGET);
  const [classification, setClassification] = useState(null);
  let [measurements, setMeasurements] = useState(null);
  const [pendingDeleteLesion, setPendingDeleteLesion] = React.useState(null);
  const [displayMeasurementText, setDisplayMeasurementText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (lesion.type === lesionTypes.LESION) {
        const measurements = getMeasurementsNewRecistItem(
          measureToolData,
          viewerRois
        );
        setMeasurements(measurements);
      }
    }, 50);
  }, [measureToolData, viewerRois]);

  useEffect(() => {
    if (!measurements) {
      setDisplayMeasurementText("");
      return;
    }

    let measurementsString =
      classification === lesionStagingTypes.NODE
        ? `SAD: ${measurements[1].toFixed(
            2
          )} mm, LAD: ${measurements[0].toFixed(2)} mm`
        : `LAD: ${measurements[0].toFixed(
            2
          )} mm, SAD: ${measurements[1].toFixed(2)} mm`;
    setDisplayMeasurementText(measurementsString);
  }, [measurements, classification]);

  const handleExpand = () => {
    if (pendingDeleteLesion) {
      return;
    }
    setSelectedLesion(lesion);
  };

  const handleLocationChange = (event, location) => {
    setLocation(location);
  };

  const handleDetectClicked = () => {
    setDetectClicked(true);
    setDetectMode(false);
    setActiveTool(ANNOTATE);
  };

  const handleSubmit = () => {
    if (isSaveDisabled()) {
      return;
    }

    const evaluationMeasurements = { LAD: null, SAD: null };
    if (isMeasurable) {
      evaluationMeasurements.LAD = measurements[0];
      evaluationMeasurements.SAD = measurements[1];
    }

    let roiRecistEvaluation = {
      roiId: viewerRois[0].id,
      ...evaluationMeasurements,
      volume: null,
      visibility: isMeasurable ? null : "NONMEASURABLE",
    };
    let lesionEvaluation = {
      lesionId: lesion.id,
      name: lesion.name,
      targetClassification: target,
      stagingClassification: classification,
      location,
    };

    addLesionEvaluation(lesionEvaluation, roiRecistEvaluation);
  };

  const isSaveDisabled = () => {
    return (
      isMeasurable == null ||
      location == null ||
      (isMeasurable === true && (target == null || measurements === null))
    );
  };

  const disableSave = isSaveDisabled();

  return (
    <>
      <RuleViolationDialogs
        lesion={lesion}
        isMeasurable={isMeasurable}
        classification={classification}
        measurements={measurements}
        setMeasurable={setMeasurable}
        setTarget={setTarget}
        setClassification={setClassification}
        setPendingDeleteLesion={setPendingDeleteLesion}
        target={target}
      />
      <TableRow>
        <StyledCell $container>
          <StyledCellWrapper elevation={3}>
            <StyledCellContentsWrapper>
              <LesionHeader
                lesion={lesion}
                lesionEvaluation={null}
                pendingDeleteLesion={pendingDeleteLesion}
                setPendingDeleteLesion={setPendingDeleteLesion}
                expanded={selected}
                selected={selected}
                onSelected={handleExpand}
              />
              <Collapse in={selected}>
                <ActionsWrapper>
                  <StyledContentActionButton onClick={handleDetectClicked}>
                    Segment
                  </StyledContentActionButton>
                  {detectClicked && (
                    <MeasurableToggle
                      isMeasurable={isMeasurable}
                      setMeasurable={setMeasurable}
                    />
                  )}
                  <ClassificationToggle
                    classification={classification}
                    setClassification={setClassification}
                    isMeasurable={isMeasurable}
                  />
                  <LocationSelect
                    handleLocationChange={handleLocationChange}
                    disabled={isMeasurable == null}
                  />
                  {isMeasurable ? (
                    <>
                      <TargetToggle target={target} setTarget={setTarget} />
                      <ListItemText primary={displayMeasurementText} />
                    </>
                  ) : null}
                  <StyledContentActionButton
                    $color="blue"
                    onClick={handleSubmit}
                    disabled={disableSave}
                  >
                    Save
                  </StyledContentActionButton>
                </ActionsWrapper>
              </Collapse>
            </StyledCellContentsWrapper>
          </StyledCellWrapper>
        </StyledCell>
      </TableRow>
    </>
  );
};

NewRecistItem.propTypes = {
  lesion: PropTypes.object.isRequired,
  setDetectMode: PropTypes.func.isRequired,
  setActiveTool: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLesion: PropTypes.func.isRequired,
  measureToolData: PropTypes.object,
  viewerRois: PropTypes.object,
  addLesionEvaluation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  measureToolData: state.annotationPage.recists.measurements.measurementData,
  viewerRois: viewerRoisSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedLesion: (lesion) => dispatch(setSelectedLesion(lesion.id)),
  setDetectMode: (isDetectMode) => dispatch(setDetectMode(isDetectMode)),
  setActiveTool: (tool) => dispatch(setActiveTool(tool)),
  addLesionEvaluation: (lesionEvaluation, roiRecistEvaluation) =>
    dispatch(
      addNewLesionEvaluationThunk(lesionEvaluation, roiRecistEvaluation)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRecistItem);
