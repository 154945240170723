import React from "react";
import ActionTypeChip from "../ActionTypeChip";
import { connect } from "react-redux";

const ConnectedActionTypeChip = ({
  actionType,
  caseActionTypeAttribute,
  onActionTypeClicked,
  onDelete,
}) => {
  return (
    <ActionTypeChip
      actionType={actionType}
      caseActionTypeAttribute={caseActionTypeAttribute}
      onActionTypeClicked={onActionTypeClicked}
      onDeleted={onDelete}
    />
  );
};

const mapState = (state) => ({
  caseActionTypeAttribute: state.app.metadata.caseActionTypeAttribute,
});

const connector = connect(mapState, null);

export default connector(ConnectedActionTypeChip);
