import { normalize, schema } from "normalizr";

function normalizeSeries(series) {
  const instanceSchema = new schema.Entity(
    "instances",
    {},
    { idAttribute: "id" }
  );
  const seriesSchema = new schema.Entity(
    "series",
    {
      instances: [instanceSchema],
    },
    { idAttribute: "id" }
  );
  return normalize(series, [seriesSchema]);
}

export { normalizeSeries };
