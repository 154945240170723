import { gql } from "@apollo/client/core";
import { INSTANCE_FRAGMENT } from "./InstanceFragment";

export const SERIES_FRAGMENT = gql`
  fragment Series on series {
    id: series_id
    studyId: study_id
    seriesInstanceUid: series_instance_uid
    seriesDescription: series_description
    seriesNumber: series_number
    seriesDate: series_date
    modality
    reconstructionPlane: reconstruction_plane
    displayName: display_name
    instances {
      ...Instance
    }
  }
  ${INSTANCE_FRAGMENT}
`;
