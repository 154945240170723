import { gql } from "@apollo/client/core";
import { LABELMAP_FRAGMENT } from "./LabelmapFragment";

export const LABELMAP_GROUP_FRAGMENT = gql`
  fragment LabelmapGroup on labelmap_group {
    id: labelmap_group_id
    color: color_hex
    defaultVisible: default_visible
    displayName: display_name
    enabled
    name
    labelmaps {
      ...Labelmap
    }
  }
  ${LABELMAP_FRAGMENT}
`;
