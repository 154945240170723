import { gql } from "@apollo/client/core";
import { ELLIPSE_FRAGMENT } from "./EllipseFragment";
import { CONTOUR_FRAGMENT } from "./ContourFragment";
import { USER_MASK_FRAGMENT } from "./UserMaskFragment";
import { ROI_ATTRIBUTES_FRAGMENT } from "./RoiAttributesFragment";
import { SERIES_FRAGMENT } from "./SeriesFragment";

export const ROI_FRAGMENT = gql`
  fragment Roi on roi {
    id: roi_id
    seriesId: series_id
    lesionId: lesion_id
    series {
      ...Series
    }
    ellipse {
      ...Ellipse
    }
    contours {
      ...Contour
    }
    userMasks: user_masks {
      ...UserMask
    }
    roiAttributes: roi_attribute {
      ...RoiAttributes
    }
    createdBy: created_by
  }
  ${ELLIPSE_FRAGMENT}
  ${CONTOUR_FRAGMENT}
  ${USER_MASK_FRAGMENT}
  ${ROI_ATTRIBUTES_FRAGMENT}
  ${SERIES_FRAGMENT}
`;
