import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { LABELMAP_GROUP_FRAGMENT } from "../fragments/LabelmapGroupFragment";
import { LabelmapGroupType } from "../types/LabelmapGroupType";
import { LabelmapGroupFragmentType } from "../fragments/LabelmapGroupFragmentType";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { parseLabelmapGroup } from "../parsers/parseLabelmapGroup";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";

const QUERY = gql`
  query GetLabelmapGroups($case_id: Int!) {
    groups: labelmap_group(
      where: {
        _and: [
          { enabled: { _eq: true } }
          {
            labelmaps: {
              instance: {
                series: { series_cases: { case_id: { _eq: $case_id } } }
              }
            }
          }
        ]
      }
    ) {
      ...LabelmapGroup
    }
  }
  ${LABELMAP_GROUP_FRAGMENT}
`;

type Input = { caseId: number };

type Variables = {
  case_id: number;
};

type Data = {
  groups: LabelmapGroupFragmentType[];
};

type Output = LabelmapGroupType[];

export function useLabelmapGroups(
  caseId: number
): useParsedQueryReturnType<number, Output, Variables> {
  return useParsedQuery<number, Output, Data, Variables>({
    query: QUERY,
    input: caseId,
    parseVariables: (case_id) => ({ case_id }),
    parseData: ({ groups }: Data) => groups.map(parseLabelmapGroup),
  });
}

export async function getLabelmapGroups({
  client,
  ...input
}: ClientProps & Input): Promise<Output> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: ({ groups }: Data) => groups.map(parseLabelmapGroup),
  });
}
