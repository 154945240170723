import { ParsedQueryWithClientArgs } from "./ParsedQueryWithClientArgs";

export async function parsedQuery<TInput, TOutput, TData, TVariables>({
  client,
  query,
  input,
  options,
  parseVariables,
  parseData,
}: ParsedQueryWithClientArgs<
  TInput,
  TOutput,
  TData,
  TVariables
>): Promise<TOutput> {
  const variables = parseVariables ? parseVariables(input) : undefined;

  const { data } = await client.query<TData, TVariables>({
    ...options,
    query,
    variables,
  });
  if (!data) {
    throw new Error("data null with no error in parsedQuery");
  }

  return parseData(data, input);
}
