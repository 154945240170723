import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MissingAdjacentAnnotationsDialog } from "./MissingAdjacentAnnotationsDialog";
import { MissingAnnotationsDialog } from "./MissingAnnotationsDialog";
import { getMayForcePassRoiChecks, useRoiChecks } from "./useRoiChecks";
import { UnsavedRoiAttributesDialog } from "./UnsavedRoiAttributesDialog";
import { setSelectedLesionAndJumpToSlice } from "../../actions/actionCreators/annotationPage/thunks";

export default function LesionsCheckDialogs({
  lesions,
  onChecksPassed,
  onChecksFailed,
}) {
  const [
    showMissingAdjacentContoursDialog,
    setShowMissingAdjacentContoursDialog,
  ] = React.useState(false);
  const [
    showMissingAdjacentUserMasksDialog,
    setShowMissingAdjacentUserMasksDialog,
  ] = React.useState(false);
  const [
    showUnsavedRoiAttributesDialog,
    setShowUnsavedRoiAttributesDialog,
  ] = React.useState(false);
  const [
    showMissingContoursDialog,
    setShowMissingContoursDialog,
  ] = React.useState(false);
  const [
    showMissingUserMasksDialog,
    setShowMissingUserMasksDialog,
  ] = React.useState(false);
  const [
    imageIdWithMissingContour,
    setImageIdWithMissingContour,
  ] = React.useState(null);
  const [
    imageIdWithMissingUserMask,
    setImageIdWithMissingUserMask,
  ] = React.useState(null);
  const [
    imageIdWithNonAdjacentContour,
    setImageIdWithNonAdjacentContour,
  ] = React.useState(null);
  const [
    imageIdWithNonAdjacentUserMask,
    setImageIdWithNonAdjacentUserMask,
  ] = React.useState(null);
  const [currentLesionId, setCurrentLesionId] = useState(null);
  const [mayForcePassRoiChecks, setMayForcePassRoiChecks] = React.useState(
    false
  );

  const checkRoi = useRoiChecks();

  const dispatch = useDispatch();

  useEffect(() => {
    if (lesions) {
      let allPassed = true;
      for (const lesion of lesions) {
        const { rois } = lesion;
        for (const roi of rois) {
          if (!performRoiChecks(roi, lesion)) {
            allPassed = false;
            break;
          }
        }

        if (!allPassed) {
          break;
        }
      }

      if (allPassed) {
        checksPassed(true);
      }
    }
  }, [lesions]);

  const performRoiChecks = (roi, lesion) => {
    const { type: lesionType, id: lesionId, name: lesionName } = lesion;

    setCurrentLesionId(lesionId);

    const roiCheckResult = checkRoi(roi, lesionType);

    const mayForcePassRoiChecks = getMayForcePassRoiChecks(roiCheckResult);

    const {
      roiAttributesHasPendingEdits,
      imageIdWithMissingContour,
      imageIdWithMissingUserMask,
      imbalancedContourImageId,
      imbalancedUserMaskImageId,
    } = roiCheckResult;

    setImageIdWithMissingContour(imageIdWithMissingContour);
    setImageIdWithMissingUserMask(imageIdWithMissingUserMask);
    setImageIdWithNonAdjacentContour(imbalancedContourImageId);
    setImageIdWithNonAdjacentUserMask(imbalancedUserMaskImageId);

    // this state ensures that all critical checks pass prior to accepting the roi
    // this prevents accidental re-ordering of these checks
    setMayForcePassRoiChecks(mayForcePassRoiChecks);

    if (roiAttributesHasPendingEdits) {
      setShowUnsavedRoiAttributesDialog(true);
    } else if (imageIdWithMissingContour !== null) {
      console.debug(
        `Roi (Id: ${roi.id}, Lesion Id: ${lesionId}, Lesion Name: ${lesionName}) was reported to have missing contours`
      );
      setShowMissingContoursDialog(true);
    } else if (imageIdWithMissingUserMask !== null) {
      setShowMissingUserMasksDialog(true);
    } else if (imbalancedContourImageId) {
      console.debug(
        `Roi (Id: ${roi.id}, Lesion Id: ${lesionId}, Lesion Name: ${lesionName}) was reported to have imbalanced contours`
      );
      setShowMissingAdjacentContoursDialog(true);
    } else if (imbalancedUserMaskImageId) {
      setShowMissingAdjacentUserMasksDialog(true);
    } else {
      return true;
    }

    return false;
  };

  const checksPassed = (maySave) => {
    if (!maySave) {
      throw new Error(
        "Not all save checks passed. Ensure that save checks are ordered correctly."
      );
    }
    onChecksPassed();
    setMayForcePassRoiChecks(false);
    setCurrentLesionId(null);
  };

  const onConfirmMissingAdjacentContoursDialog = () => {
    setShowMissingAdjacentContoursDialog(false);
    checksPassed(mayForcePassRoiChecks);
  };

  const onCancelMissingAdjacentContoursDialog = () => {
    setShowMissingAdjacentContoursDialog(false);
    onChecksFailed();
  };

  const onConfirmMissingAdjacentUserMasksDialog = () => {
    setShowMissingAdjacentUserMasksDialog(false);
    checksPassed(mayForcePassRoiChecks);
  };

  const onCancelMissingAdjacentUserMasksDialog = () => {
    setShowMissingAdjacentUserMasksDialog(false);
    onChecksFailed();
  };

  const onConfirmUnsavedRoiAttributesDialog = () => {
    setShowUnsavedRoiAttributesDialog(false);
    onChecksFailed();
  };

  const onConfirmMissingContoursDialog = () => {
    setShowMissingContoursDialog(false);
    onChecksFailed();
  };

  const onConfirmMissingUserMasksDialog = () => {
    setShowMissingUserMasksDialog(false);
    onChecksFailed();
  };

  const goToMissingUserMasksSlice = () => {
    dispatch(
      setSelectedLesionAndJumpToSlice(
        currentLesionId,
        imageIdWithMissingUserMask
      )
    );
    setImageIdWithMissingUserMask(null);
    onConfirmMissingUserMasksDialog();
  };

  const goToMissingContoursSlice = () => {
    dispatch(
      setSelectedLesionAndJumpToSlice(
        currentLesionId,
        imageIdWithMissingContour
      )
    );
    setImageIdWithMissingContour(null);
    onConfirmMissingContoursDialog();
  };

  const goToAdjacentContoursSlice = () => {
    dispatch(
      setSelectedLesionAndJumpToSlice(
        currentLesionId,
        imageIdWithNonAdjacentContour
      )
    );
    setImageIdWithNonAdjacentContour(null);
    onCancelMissingAdjacentContoursDialog();
  };

  const goToAdjacentUserMasksSlice = () => {
    dispatch(
      setSelectedLesionAndJumpToSlice(
        currentLesionId,
        imageIdWithNonAdjacentUserMask
      )
    );
    setImageIdWithNonAdjacentUserMask(null);
    onCancelMissingAdjacentUserMasksDialog();
  };

  return (
    <>
      <MissingAdjacentAnnotationsDialog
        showDialog={showMissingAdjacentContoursDialog}
        onConfirm={onConfirmMissingAdjacentContoursDialog}
        onCancel={onCancelMissingAdjacentContoursDialog}
        goToSlice={goToAdjacentContoursSlice}
        title={"Missing Adjacent Contours"}
        annotationType={"contours"}
      />
      <MissingAdjacentAnnotationsDialog
        showDialog={showMissingAdjacentUserMasksDialog}
        onConfirm={onConfirmMissingAdjacentUserMasksDialog}
        onCancel={onCancelMissingAdjacentUserMasksDialog}
        goToSlice={goToAdjacentUserMasksSlice}
        title={"Missing Adjacent Masks"}
        annotationType={"masks"}
      />
      <UnsavedRoiAttributesDialog
        showDialog={showUnsavedRoiAttributesDialog}
        onConfirm={onConfirmUnsavedRoiAttributesDialog}
      />
      <MissingAnnotationsDialog
        showDialog={showMissingContoursDialog}
        onConfirm={onConfirmMissingContoursDialog}
        goToSlice={goToMissingContoursSlice}
        title={"Missing Contours"}
        annotationType={"contours"}
      />
      <MissingAnnotationsDialog
        showDialog={showMissingUserMasksDialog}
        onConfirm={onConfirmMissingUserMasksDialog}
        goToSlice={goToMissingUserMasksSlice}
        title={"Missing Masks"}
        annotationType={"masks"}
      />
    </>
  );
}
