import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation DeleteTumour($tumour_id: Int!) {
    delete_tumour(where: { id: { _eq: $tumour_id } }) {
      affected_rows
    }
  }
`;

type Input = {
  tumourId: number;
};

export async function deleteTumour({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ tumourId: tumour_id }) => ({ tumour_id }),
    parseData: () => undefined,
  });
}
