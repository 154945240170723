import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const DialogContentsWrapper = styled.div`
  padding: 16px;
  min-width: 500px;
`;

const RuleViolationDialog = ({
  show,
  onAccept,
  acceptText,
  onDeleteLesion,
  message,
  showDeleteLesionButton,
}) => {
  return (
    <Dialog open={show}>
      <DialogTitle />
      <DialogContentsWrapper>
        <Typography variant="body1" gutterBottom>
          {message}
        </Typography>
      </DialogContentsWrapper>
      <DialogActions>
        <Button color="primary" onClick={onAccept}>
          {acceptText}
        </Button>
        {showDeleteLesionButton ? (
          <Button color="primary" onClick={onDeleteLesion}>
            Delete Lesion
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

RuleViolationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  acceptText: PropTypes.string.isRequired,
  onDeleteLesion: PropTypes.func,
  message: PropTypes.string,
  showDeleteLesionButton: PropTypes.bool,
};

export default RuleViolationDialog;
