import {
  SET_BIOPSY_DETAILS,
  UPDATE_BIOPSY_DETAILS_LESION_ID,
  UPDATE_BIOPSY_NO_LINKING,
} from "../../actions/types/annotationPage/biopsyDetails";
import produce from "immer";

let defaultState = {
  byId: {},
  allIds: [],
};

export const biopsyDetails = (state = defaultState, action) => {
  switch (action.type) {
    case SET_BIOPSY_DETAILS:
      return setBiopsyDetails(state, action);
    case UPDATE_BIOPSY_DETAILS_LESION_ID:
      return updateBiopsyDetailsLesionId(state, action);
    case UPDATE_BIOPSY_NO_LINKING:
      return updateBiopsyNoLinking(state, action);
    default:
      return state;
  }
};

const setBiopsyDetails = (state, action) => {
  let normalizedBiopsyDetails = action.biopsyDetails;
  let byId = normalizedBiopsyDetails.entities.biopsyDetails;

  byId = byId ? byId : {};

  return {
    byId,
    allIds: normalizedBiopsyDetails.result,
  };
};

const updateBiopsyDetailsLesionId = (state, action) => {
  let { biopsyDetailsId, lesionId, unsure } = action;

  const nextState = produce(state, (draftState) => {
    let { byId } = draftState;
    let biopsyDetails = byId[biopsyDetailsId];
    biopsyDetails.lesionId = lesionId;
    biopsyDetails.unsure = unsure;
  });

  return nextState;
};

const updateBiopsyNoLinking = (state, action) => {
  let { biopsyDetailsId, noLinking } = action;

  return produce(state, (draftState) => {
    let { byId } = draftState;
    let biopsyDetails = byId[biopsyDetailsId];
    biopsyDetails.noLinking = noLinking;
  });
};
