import {
  SET_ACTIVE_TOOL,
  SET_BRUSH_RADIUS,
  SET_DETECT_MODE,
} from "../../actions/types/annotationPage/tools";
import { ANNOTATE } from "../../tools/Tools";

let defaultState = {
  activeTool: ANNOTATE,
  isDetectMode: true,
  brushRadiusValues: {
    radius: 10,
    minRadius: 1,
    maxRadius: 50,
  },
};

export const tools = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TOOL:
      return setActiveTool(state, action);
    case SET_DETECT_MODE:
      return setDetectMode(state, action);
    case SET_BRUSH_RADIUS:
      return setBrushRadius(state, action);
    default:
      return state;
  }
};

const setActiveTool = (state, action) => {
  return {
    ...state,
    activeTool: action.tool,
  };
};

const setDetectMode = (state, action) => {
  return {
    ...state,
    isDetectMode: action.isDetectMode,
  };
};

const setBrushRadius = (state, action) => {
  return {
    ...state,
    brushRadiusValues: {
      ...state.brushRadiusValues,
      radius: action.newRadius,
    },
  };
};
