import { createSelector } from "reselect";
import { visibleLesionsSelector } from "../lesions/selectors";

export const getRoiIds = (state) => state.annotationPage.rois.allIds;
export const getRois = (state) => state.annotationPage.rois.byId;
export const getProxyRoiAttributesState = (state) =>
  state.annotationPage.rois.proxyRoiAttributes;

export const roisSelector = createSelector(getRois, getRoiIds, (rois, roiIds) =>
  roiIds.map((id) => rois[id])
);

export const roiIdsWithPendingAttributesEditsSelector = createSelector(
  getRois,
  getProxyRoiAttributesState,
  (rois, proxyRoiAttributesState) => {
    let proxyRoiAttributeIds = Object.keys(proxyRoiAttributesState);
    return proxyRoiAttributeIds
      .filter((id) => proxyRoiAttributesState[id].hasPendingEdits)
      .map((id) => parseInt(id));
  }
);

export const contoursSelector = createSelector(roisSelector, (rois) =>
  rois.map((roi) => roi.contours)
);

export const detectionsSelector = createSelector(roisSelector, (rois) =>
  rois.map((roi) => roi.ellipse)
);

export const detectionSlicesSelector = createSelector(
  detectionsSelector,
  (detections) => detections.map((detection) => detection.slice)
);

export const visibleRoiIdsSelector = createSelector(
  visibleLesionsSelector,
  (lesions) => {
    let visibleRoiIds = [];
    lesions.forEach((lesion) => {
      lesion.rois.forEach((roiId) => {
        visibleRoiIds.push(roiId);
      });
    });

    return visibleRoiIds;
  }
);

export const visibleToolRoiIdsSelector = createSelector(
  visibleRoiIdsSelector,
  getRois,
  (visibleRoiIds, roisById) => {
    let visibleToolRoiIds = [];
    visibleRoiIds.forEach((visibleRoiId) => {
      let {
        ellipse: {
          roi: { id: ellipseToolRoiId },
        },
        contoursToolRoi: { id: contoursToolRoiId },
      } = roisById[visibleRoiId];
      visibleToolRoiIds.push(ellipseToolRoiId);
      visibleToolRoiIds.push(contoursToolRoiId);
    });
    return visibleToolRoiIds;
  }
);
