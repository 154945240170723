import cornerstoneTools from "@altis-labs/cornerstone-tools";
import _ from "lodash/core";
import { ToolTypes } from "shared-api";

export default class EllipseToolWrapper {
  constructor(ellipseTool) {
    this.currentRoi = null;
    this.enabled = false;
    this.toolType = ToolTypes.ELLIPTICALROITOOL;
    this.onEllipseChangedCallback = null;

    this.tool = ellipseTool;
    this.tool.setOnEllipseChangedCallback(this.onEllipseChanged);
  }

  turnOn() {
    this.enabled = true;
  }

  turnOff() {
    this.enabled = false;
  }

  setCurrentRoi(roi) {
    this.currentRoi = roi;

    this.tool.setCurrentRoi(roi);

    if (!roi) {
      this.tool.setPassive();
    } else {
      this.updateState();
    }
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setOnEllipseChangedCallback(onEllipseChangedCallback) {
    this.onEllipseChangedCallback = onEllipseChangedCallback;
  }

  onEllipseChanged = async (data) => {
    if (!this.enabled) {
      return;
    }

    this.updateState();

    if (this.onEllipseChangedCallback) {
      let imageId = data.imageId;
      //on initial startup, there are no images loaded but the ellipse is invalidated, so don't send an update
      if (!imageId) {
        return;
      }

      let toolSliceData = { imageId: imageId, toolData: [data.data] };
      await this.onEllipseChangedCallback(toolSliceData);
    }
  };

  updateState = () => {
    if (!this.ellipseExistsForCurrentRoi()) {
      this.tool.setActive();
    } else {
      this.tool.setPassive();
    }
  };

  ellipseExistsForCurrentRoi = () => {
    const currentRoi = this.currentRoi;
    const toolType = this.toolType;

    if (!currentRoi) {
      return false;
    }

    const globalToolStates =
      cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

    const toolsStateData = [];

    Object.keys(globalToolStates).forEach((imageId) => {
      const sliceToolState = globalToolStates[imageId];

      if (_.has(sliceToolState, toolType)) {
        const toolStateData = sliceToolState[toolType].data;

        toolStateData.forEach((data) => {
          if (data.roi && data.roi.id === currentRoi.id) {
            toolsStateData.push(data);
          }
        });
      }
    });

    return toolsStateData.length > 0;
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };
}
