export function getSeriesFollowUpId(seriesId, state) {
  const {
    annotationPage: {
      series: { byId },
    },
  } = state;

  if (!Object.prototype.hasOwnProperty.call(byId, seriesId)) {
    throw new Error(`No series found with id: ${seriesId}`);
  }

  const { followUpId } = byId[seriesId];
  return followUpId;
}

export function getSeriesIdsWithFollowUp(state) {
  const {
    annotationPage: {
      series: { allIds, byId },
    },
  } = state;

  return allIds
    .map((id) => byId[id])
    .filter((series) => series.followUpId !== undefined)
    .map((series) => series.id);
}
