import pako from "pako";
import { UserMaskVersionType } from "../types/UserMaskVersionType";
import {
  USER_MASK_VERSION_1,
  USER_MASK_VERSION_2,
} from "../../../../types/userMaskVersion";

export function parseMaskHexString(
  maskString: string,
  version: UserMaskVersionType
): Uint8Array {
  const bytes = hexToBytes(maskString);

  let toolData: Uint8Array;
  switch (version) {
    case USER_MASK_VERSION_1: {
      toolData = bytes;
      break;
    }
    case USER_MASK_VERSION_2: {
      toolData = pako.inflate(bytes);
      break;
    }
    default: {
      throw new Error(`Unsupported mask version: ${version}`);
    }
  }

  return toolData;
}

function hexToBytes(hex: string): Uint8Array {
  const bytes = [];
  for (let c = 2; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return Uint8Array.from(bytes);
}
