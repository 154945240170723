import React from "react";
import { connect } from "react-redux";
import { setAssignedCaseProgressThunk } from "../actions/actionCreators/annotationPage/case";
import { RECIST_1_1 } from "shared-api";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import yellow from "@material-ui/core/colors/yellow";
import ErrorIcon from "@material-ui/icons/Error";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ConfirmSubmissionDialog from "./Dialogs/ConfirmSubmissionDialog";

import DoneRecistChecks from "../RECIST/components/DoneRecistChecks";
import DoneAnnotationChecks from "./DoneAnnotationChecks";
import { lesionsWithRoisSelector } from "../store/annotationPage/lesions/selectors";

import { COMPLETED } from "shared-api";
import { updateLesionStatus } from "../actions/actionCreators/annotationPage/lesions";

function DoneAnnotationsButtonWithDialog({
  lesions,
  updateLesionStatus,
  selectedCaseAssignment,
  setAssignedCaseProgress,
  pushHistory,
}) {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [doneClicked, setDoneClicked] = React.useState(false);
  const [displayErrorIcon, setDisplayErrorIcon] = React.useState(true);
  const [yesClicked, setYesClicked] = React.useState(false);

  const onClickYes = () => {
    setOpenConfirmDialog(false);

    for (let i = 0; i < lesions.length; ++i) {
      if (lesions[i].status !== COMPLETED) {
        updateLesionStatus(lesions[i], COMPLETED);
      }
    }

    let { id: caseAssignmentId } = selectedCaseAssignment;
    setAssignedCaseProgress(caseAssignmentId, "COMPLETED");
    pushHistory("/studies");
  };
  let actionType = selectedCaseAssignment?.actionType;

  return (
    <div>
      {actionType === RECIST_1_1 ? (
        <DoneRecistChecks
          setDisplayErrorIcon={setDisplayErrorIcon}
          setOpenConfirmDialog={setOpenConfirmDialog}
          doneClicked={doneClicked}
          setDoneClicked={setDoneClicked}
          onClickYes={onClickYes}
          yesClicked={yesClicked}
        />
      ) : (
        <DoneAnnotationChecks
          setDisplayErrorIcon={setDisplayErrorIcon}
          setOpenConfirmDialog={setOpenConfirmDialog}
          doneClicked={doneClicked}
          setDoneClicked={setDoneClicked}
          onClickYes={onClickYes}
          yesClicked={yesClicked}
        />
      )}
      <ConfirmSubmissionDialog
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onClickYes={() => {
          setYesClicked(true);
        }}
      />
      <Button
        variant={"outlined"}
        startIcon={<SaveAltIcon />}
        endIcon={
          displayErrorIcon && <ErrorIcon style={{ color: yellow[600] }} />
        }
        onClick={() => {
          setDoneClicked(true);
        }}
      >
        Done
      </Button>
    </div>
  );
}

DoneAnnotationsButtonWithDialog.propTypes = {
  setAssignedCaseProgress: PropTypes.func.isRequired,
  selectedCaseAssignment: PropTypes.object,
  pushHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedCaseAssignment: state.annotationPage.case.selectedCaseAssignment,
  lesions: lesionsWithRoisSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAssignedCaseProgress: (caseAssignmentId, progress) =>
    dispatch(setAssignedCaseProgressThunk(caseAssignmentId, progress)),
  pushHistory: (route) => dispatch(push(route)),
  updateLesionStatus: (lesion, status) =>
    dispatch(updateLesionStatus(lesion.id, status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoneAnnotationsButtonWithDialog);
