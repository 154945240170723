import { TumourFragmentType } from "../fragments/TumourFragmentType";
import { TumourType } from "../types/TumourType";
import { parseTumourBurden } from "./parseTumourBurden";

export function parseTumour({
  id,
  measurable,
  burdens: burdenFragments,
  rois,
}: TumourFragmentType): TumourType {
  const burdens = burdenFragments.map(parseTumourBurden);
  const roiIds = rois.map(({ id }) => id);
  return {
    id,
    measurable,
    burdens,
    roiIds,
  };
}
