import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";
import { selectedLesionSelector } from "../store/annotationPage/lesions/selectors";
import { viewersSelector } from "../store/annotationPage/viewers/selectors";
import styled from "styled-components";
import { getViewerViewport } from "../tools/getViewerViewport";
import { Viewer } from "../models/Viewer";
import { UrlState, UrlStateViewer } from "../models/UrlState";

const StyledIconButton = styled(IconButton)`
  margin: 0 3px;
`;

const CopyUrlButton = (): JSX.Element => {
  const selectedLesion = useSelector(selectedLesionSelector);
  const viewers: Viewer[] = useSelector(viewersSelector);
  const selectedCaseAssignment = useSelector(
    (state: RootStateOrAny) => state.annotationPage.case.selectedCaseAssignment
  );

  const createReduxStateObject = (): UrlState => {
    const viewerProperties: UrlStateViewer = viewers.map((viewer) => {
      const { seriesId, imageIdIndex, element } = viewer;

      if (!element) {
        throw new Error("Viewer element could not be found.");
      }
      const viewerViewport = getViewerViewport(element);
      const { voi, scale, translation } = viewerViewport;

      return {
        seriesId,
        imageIdIndex,
        voi,
        scale,
        translation,
      };
    });

    return {
      selectedCaseAssignment,
      selectedLesionId: selectedLesion?.id,
      viewers: viewerProperties,
    };
  };

  const getUrlQueryStringFromState = (stateObject: UrlState) => {
    const key = "state";
    const value = encodeURIComponent(JSON.stringify(stateObject));

    return `?${key}=${value}`;
  };

  const getFullUrl = (queryString: string) => {
    return window.location.origin + window.location.pathname + queryString;
  };

  const handleClick = async () => {
    try {
      const stateObject = createReduxStateObject();
      const queryString = getUrlQueryStringFromState(stateObject);
      const fullUrl = getFullUrl(queryString);
      await navigator.clipboard.writeText(fullUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledIconButton edge="start" size="small" onClick={handleClick}>
      <ShareIcon fontSize="small" />
    </StyledIconButton>
  );
};

export default CopyUrlButton;
