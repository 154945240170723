import { ANNOTATE, ROI_SCULPTOR } from "./Tools";

export default function hotkeyToolSelector(
  event: KeyboardEvent
): string | undefined {
  const controlKey = event.ctrlKey || event.metaKey;
  const contourKey = event.key === "m";
  const hockeyPuckToolKey = event.key === "b";

  if (contourKey && controlKey) {
    return ANNOTATE;
  }
  if (hockeyPuckToolKey && controlKey) {
    return ROI_SCULPTOR;
  }
}
