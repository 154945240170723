import { CaseActionTypeAttributeFragmentType } from "../fragments/CaseActionTypeAttributeFragmentType";
import { ActionTypeAttributesType } from "../types/ActionTypeAttributesType";

export function parseActionTypeAttributes(
  actionTypeAttributesFragments: CaseActionTypeAttributeFragmentType[]
): ActionTypeAttributesType {
  return actionTypeAttributesFragments.reduce((result, item) => {
    const { actionType, displayName, color, iconName, iconSource } = item;
    result[actionType] = result[actionType] || {};
    result[actionType] = {
      displayName,
      color,
      iconName,
      iconSource,
    };
    return result;
  }, Object.create(null));
}
