import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { DIAGNOSIS_FRAGMENT } from "../fragments/DiagnosisFragment";
import { DiagnosisFragmentType } from "../fragments/DiagnosisFragmentType";
import { parseDiagnosis } from "../parsers/parseDiagnosis";
import { DiagnosisType } from "../types/DiagnosisType";

const MUTATION = gql`
  mutation InsertDiagnoses($diagnoses: [diagnosis_insert_input!]!) {
    insert_diagnosis(objects: $diagnoses) {
      returning {
        ...Diagnosis
      }
    }
  }
  ${DIAGNOSIS_FRAGMENT}
`;

type Input = {
  diagnosis: DiagnosisType;
};

type Data = {
  insert_diagnosis: { returning: DiagnosisFragmentType[] };
};

export async function insertDiagnosis({
  client,
  ...input
}: ClientProps & Input): Promise<DiagnosisType> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ diagnosis }) => {
      const {
        type,
        code,
        primaryLocation: primary_location,
        diagnosisDate: diagnosis_date,
        followUpId: follow_up_id,
      } = diagnosis;
      return {
        diagnoses: {
          type,
          code,
          primary_location,
          diagnosis_date,
          follow_up_id,
          tumours: { data: [] },
        },
      };
    },
    parseData: ({ insert_diagnosis: { returning } }: Data) => {
      if (returning.length !== 1) {
        throw new Error("diagnosis not inserted properly");
      }

      return parseDiagnosis(returning[0]);
    },
  });
}
