import { gql } from "@apollo/client";
import { USER_NOTIFICATION_FRAGMENT } from "../fragments/UserNotificationFragment";
import { UserNotificationType } from "../types/UserNotificationType";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";
import { UserNotificationFragmentType } from "../fragments/UserNotificationFragmentType";

const QUERY = gql`
  query getNotifications($time: timestamptz) {
    userNotifications: user_notifications(
      order_by: { start_time: asc }
      where: { end_time: { _gte: $time } }
    ) {
      ...UserNotification
    }
  }
  ${USER_NOTIFICATION_FRAGMENT}
`;

type Input = { time: string };

type Variables = {
  time: string;
};

type Data = {
  userNotifications: UserNotificationFragmentType[];
};

export function useUserNotifications(
  input: Input
): useParsedQueryReturnType<Input, UserNotificationType[], Variables> {
  return useParsedQuery<Input, UserNotificationType[], Data, Variables>({
    query: QUERY,
    input,
    parseVariables: ({ time }) => ({ time }),
    parseData: ({ userNotifications }: Data) =>
      userNotifications.map(parseUserNotification),
  });
}

export function parseUserNotification({
  id,
  message,
  severity,
  startTime,
  endTime,
}: UserNotificationFragmentType): UserNotificationType {
  return {
    id,
    message,
    severity,
    startTime: new Date(startTime),
    endTime: new Date(endTime),
  };
}
