import { gql } from "@apollo/client/core";

export const STUDY_NOTE_FRAGMENT = gql`
  fragment StudyNote on study_notes {
    id: study_notes_id
    studyId: study_id
    lastModifiedByUserId: last_modified_by_user_id
    lastModifiedAt: last_modified
    notes
  }
`;
