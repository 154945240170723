import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { viewerRoisSelector } from "../../../store/annotationPage/viewers/selectors";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {
  MeasurementCellWrapper,
  StyledTableCellContainerWithWidth,
} from "./StyledComponents/StyledOutlinedCell";
import RecistStatusOptions from "./RecistStatusOptions";
import RecistSegmentOptions from "./RecistSegmentOptions";
import getMeasurements from "./getMeasurements";

const RecistFollowUpCell = ({
  roiId,
  seriesId,
  measureToolData,
  inViewer,
  open,
  width,
  lesionId,
  viewerRois,
  highlight,
}) => {
  const [optionSelected, setOptionSelected] = useState(false);
  const [segmentPressed, setSegmentPressed] = useState(null);
  let [measurements, setMeasurements] = useState(null);
  let [measurementStrings, setMeasurementStrings] = useState(["", ""]);

  useEffect(() => {
    setTimeout(() => {
      const measurements = getMeasurements(measureToolData[roiId]);
      setMeasurements(measurements);
    }, 50);
  }, [measureToolData, viewerRois, roiId]);

  useEffect(() => {
    if (!measurements) {
      return;
    }

    let LAD = `${measurements[0].toFixed(2)} mm`;
    let SAD = `${measurements[1].toFixed(2)} mm`;
    setMeasurementStrings([LAD, SAD]);
  }, [measurements]);

  return (
    <StyledTableCellContainerWithWidth width={width} $highlight={highlight}>
      <Table>
        <TableBody>
          {!optionSelected && (
            <>
              <RecistStatusOptions
                inViewer={inViewer}
                seriesId={seriesId}
                lesionId={lesionId}
                roiId={roiId}
                setOptionSelected={setOptionSelected}
                setSegmentPressed={setSegmentPressed}
              />
            </>
          )}
          {segmentPressed && !open && (
            <>
              <TableRow>
                <MeasurementCellWrapper align="center">
                  {measurementStrings[0]}
                </MeasurementCellWrapper>
              </TableRow>
              <RecistSegmentOptions
                roiId={roiId}
                lesionId={lesionId}
                seriesId={seriesId}
                measurements={measurements}
              />
            </>
          )}
          {segmentPressed && open && (
            <>
              <TableRow>
                <MeasurementCellWrapper float="left">
                  LAD
                </MeasurementCellWrapper>
                <MeasurementCellWrapper float="right">
                  {measurementStrings[0]}
                </MeasurementCellWrapper>
              </TableRow>
              <TableRow>
                <MeasurementCellWrapper float="left">
                  SAD
                </MeasurementCellWrapper>
                <MeasurementCellWrapper float="right">
                  {measurementStrings[1]}
                </MeasurementCellWrapper>
              </TableRow>
              <RecistSegmentOptions
                roiId={roiId}
                lesionId={lesionId}
                seriesId={seriesId}
                measurements={measurements}
              />
            </>
          )}
        </TableBody>
      </Table>
    </StyledTableCellContainerWithWidth>
  );
};

RecistFollowUpCell.propTypes = {
  roiId: PropTypes.number.isRequired,
  seriesId: PropTypes.number.isRequired,
  measureToolData: PropTypes.object.isRequired,
  inViewer: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  lesionId: PropTypes.number.isRequired,
  viewerRois: PropTypes.object.isRequired,
  highlight: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  measureToolData: state.annotationPage.recists.measurements.measurementData,
  viewerRois: viewerRoisSelector(state),
});

export default connect(mapStateToProps)(RecistFollowUpCell);
