import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { CaseAssignmentType } from "../types/CaseAssignmentType";
import { CASE_ASSIGNMENT_FRAGMENT } from "../fragments/CaseAssignmentFragment";
import { CaseAssignmentFragmentType } from "../fragments/CaseAssignmentFragmentType";
import { parseCaseAssignment } from "../parsers/parseCaseAssignment";
import { CaseInstanceType } from "../types/CaseInstanceType";
import { CaseInstancePriorityType } from "../types/CaseInstancePriorityType";
import { CaseInstanceFragmentType } from "../fragments/CaseInstanceFragmentType";
import { parseCaseInstance } from "../parsers/parseCaseInstance";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { CASE_INSTANCE_FRAGMENT } from "../fragments/CaseInstanceFragment";

//todo this will be replaced by a much cleaner query used in nota-predict which is why this is not refactored

const QUERY = gql`
  query GetCaseWorkListByUserId($user_id: Int!) {
    caseAssignments: case_assignment(
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          {
            case: {
              _or: [
                { _not: { case_flag_logs: {} } }
                { case_flag_logs: { resolved: { _eq: true } } }
              ]
            }
          }
        ]
      }
      order_by: { case_id: asc }
    ) {
      ...CaseAssignment
    }
    caseInstances: case_instance(
      where: {
        _and: [
          { _not: { case_assignments: {} } }
          { enabled: { _eq: true } }
          {
            case: {
              _or: [
                { _not: { case_flag_logs: {} } }
                { case_flag_logs: { resolved: { _eq: true } } }
              ]
            }
          }
        ]
      }
    ) {
      ...CaseInstance
    }
    userActionTypeRoles: user_case_action_type_role(
      where: { user_id: { _eq: $user_id } }
    ) {
      actionType: action_type
    }
    casePriorities: case_displayed_priority {
      priority
    }
  }
  ${CASE_ASSIGNMENT_FRAGMENT}
  ${CASE_INSTANCE_FRAGMENT}
`;

type Input = {
  userId: number;
};

type Output = {
  assignedCases: CaseAssignmentType[];
  unassignedCases: CaseInstanceType[];
};

type Data = {
  caseAssignments: CaseAssignmentFragmentType[];
  caseInstances: CaseInstanceFragmentType[];
  userActionTypeRoles: {
    actionType: string;
  }[];
  casePriorities: {
    priority: CaseInstancePriorityType;
  }[];
};

export async function getCaseWorkListByUserId({
  client,
  ...input
}: ClientProps & Input): Promise<Output> {
  return await parsedQuery({
    client,
    query: QUERY,
    options: { fetchPolicy: "network-only" },
    input,
    parseVariables: ({ userId: user_id }) => ({ user_id }),
    parseData,
  });
}

const parseData = ({
  userActionTypeRoles,
  caseAssignments,
  caseInstances,
  casePriorities,
}: Data): Output => {
  const userRoles = userActionTypeRoles.map(({ actionType }) => actionType);

  const assignedCases = caseAssignments
    .map(parseCaseAssignment)
    .filter((caseAssignment) => userRoles.includes(caseAssignment.actionType));

  const displayedPriorities = casePriorities.map(({ priority }) => priority);

  const unassignedCases = caseInstances
    .map(parseCaseInstance)
    .filter(
      ({ actionType, priority }) =>
        userRoles.includes(actionType) && displayedPriorities.includes(priority)
    );

  return { assignedCases, unassignedCases };
};
