import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { BASE_WADO_URL_FRAGMENT } from "../fragments/BaseWadoUrlFragment";
import { parseBaseWadoUrl } from "../parsers/parseBaseWadoUrl";
import { BaseWadoUrlFragmentType } from "../fragments/BaseWadoUrlFragmentType";

const QUERY = gql`
  query GetBaseWadoUrl {
    ...BaseWadoUrl
  }
  ${BASE_WADO_URL_FRAGMENT}
`;

type Data = {
  wadoUrls: BaseWadoUrlFragmentType;
};

export async function getBaseWadoUrl({ client }: ClientProps): Promise<string> {
  return await parsedQuery({
    client,
    query: QUERY,
    input: undefined,
    parseData: ({ wadoUrls }: Data) => parseBaseWadoUrl(wadoUrls),
  });
}
