import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { StagingClassificationType } from "../types/StagingClassificationType";
import { TargetClassificationType } from "../types/TargetClassificationType";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertLesionEvaluation(
    $staging_classification: enum_tumour_staging_classification_enum!
    $target_classification: enum_lesion_target_classification_enum!
    $location_id: Int!
    $lesion_id: Int!
  ) {
    insert_tumour_evaluation_one(
      object: {
        lesion_id: $lesion_id
        location_id: $location_id
        staging_classification: $staging_classification
        target_classification: $target_classification
      }
      on_conflict: {
        constraint: lesion_evaluations_pkey
        update_columns: [
          target_classification
          staging_classification
          location_id
        ]
      }
    ) {
      lesion_id
    }
  }
`;

type Input = {
  lesionId: number;
  targetClassification: TargetClassificationType;
  stagingClassification: StagingClassificationType;
  locationId: number;
};

export async function insertLesionEvaluation({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      lesionId: lesion_id,
      targetClassification: target_classification,
      stagingClassification: staging_classification,
      locationId: location_id,
    }) => ({
      lesion_id,
      target_classification,
      staging_classification,
      location_id,
    }),
    parseData: () => undefined,
  });
}
