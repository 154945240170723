import { normalize, schema } from "normalizr";

function normalizeStudyNotes(studyNotes) {
  const studyNotesSchema = new schema.Entity(
    "studyNotes",
    {},
    { idAttribute: "id" }
  );
  return normalize(studyNotes, [studyNotesSchema]);
}

export { normalizeStudyNotes };
