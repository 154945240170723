import { SET_SELECTED_WINDOW_LEVEL_PRESET_ID } from "../actions/types/annotationPage/tools";

let windowLevelPresets = [
  { name: "chest.lung", windowLevel: { w: 1500, l: -600 } },
  { name: "ct.bone", windowLevel: { w: 1500, l: 300 } },
  { name: "chest.mediastinum", windowLevel: { w: 350, l: 50 } },
  { name: "abdomen.softtissues", windowLevel: { w: 400, l: 50 } },
  { name: "abdomen.liver", windowLevel: { w: 150, l: 30 } },
];

let defaultState = {
  selectedWindowLevelPresetId: windowLevelPresets[0],
  windowLevelPresets,
};

const config = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SELECTED_WINDOW_LEVEL_PRESET_ID:
      return {
        ...state,
        selectedWindowLevelPresetId: action.id,
      };
    default:
      return state;
  }
};

export default config;
