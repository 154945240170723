import { MeasurementCellWrapper } from "./StyledComponents/StyledOutlinedCell";
import { StyledButton } from "./StyledComponents/StyledButton";
import React from "react";
import { ANNOTATE } from "../../../tools/Tools";
import { useDispatch } from "react-redux";
import { addRoiRecistEvaluationThunk } from "../../actions/actionCreators/evaluations/rois";
import { addRecistViewerThunk } from "../../../actions/actionCreators/annotationPage/thunks";
import { setDetectMode } from "../../../actions/actionCreators/annotationPage/tools";
import { setActiveTool } from "../../../actions/actionCreators/annotationPage/tools";
import { setSelectedLesion } from "../../../actions/actionCreators/annotationPage/thunks";
import TableRow from "@material-ui/core/TableRow";
import { updateLesionStatus } from "../../../actions/actionCreators/annotationPage/lesions";

const RecistStatusOptions = ({
  inViewer,
  seriesId,
  lesionId,
  roiId,
  setOptionSelected,
  setSegmentPressed,
}) => {
  const dispatch = useDispatch();

  const onSegmentClick = () => {
    if (!inViewer) {
      dispatch(addRecistViewerThunk(seriesId));
    }
    setOptionSelected(true);
    setSegmentPressed(true);
    dispatch(setDetectMode(false));
    dispatch(setActiveTool(ANNOTATE));
    dispatch(setSelectedLesion(lesionId));
    dispatch(updateLesionStatus(lesionId, null));
  };

  const onNotPresentClick = () => {
    setOptionSelected(true);
    let roiRecistEvaluation = {
      roiId,
      LAD: null,
      SAD: null,
      volume: null,
      lesionId,
      seriesId,
      visibility: "NOTPRESENT",
    };
    dispatch(addRoiRecistEvaluationThunk(roiRecistEvaluation));
    dispatch(setSelectedLesion(lesionId));
  };

  return (
    <>
      <TableRow>
        <MeasurementCellWrapper $buttonPadding>
          <StyledButton $withColor onClick={onSegmentClick}>
            Segment
          </StyledButton>
        </MeasurementCellWrapper>
      </TableRow>
      <TableRow>
        <MeasurementCellWrapper $buttonPadding>
          <StyledButton onClick={onNotPresentClick}>Not Present</StyledButton>
        </MeasurementCellWrapper>
      </TableRow>
    </>
  );
};

export default RecistStatusOptions;
