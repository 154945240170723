import { setSelectedLesionId } from "../lesions";
import { lesionTypes } from "shared-api";
import { setDetectMode } from "../tools";
import { setSelectedRoiId, unsetSelectedRoiId } from "../rois";

export function handleSetSelectedLesion(lesionId, dispatch, getState) {
  dispatch(setSelectedLesionId(lesionId));

  let appState = getState();
  let {
    annotationPage: {
      rois: roisById,
      lesions: { byId: lesionsById },
    },
  } = appState;

  if (!lesionsById.hasOwnProperty(lesionId)) {
    throw new Error("There is no lesion for id: " + lesionId);
  }

  let lesion = lesionsById[lesionId];
  let rois = lesion.rois.map((id) => roisById.byId[id]);

  let isLesionOrgan = lesion.type === lesionTypes.ORGAN;
  if (isLesionOrgan) {
    dispatch(setDetectMode(false));
  }

  if (rois.length > 0) {
    rois.forEach((roi) => {
      let roiId = roi.id;
      dispatch(setSelectedRoiId(roiId));
    });
  } else {
    dispatch(unsetSelectedRoiId());
  }
}
