import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setViewerActive } from "../actions/actionCreators/annotationPage/viewers";
import ImageViewerContainer from "./ImageViewerContainer";
import {
  viewerRoisSelector,
  viewersSelector,
} from "../store/annotationPage/viewers/selectors";

function ImageViewerList({
  viewers,
  series,
  viewerRois,
  setActiveViewer,
  urlViewersData,
}) {
  const refreshActiveViewer = (activeViewerIndex) => {
    viewers.forEach((viewer) => {
      setActiveViewer(viewer.id, viewer.id === activeViewerIndex);
    });
  };

  let numberOfViewers = viewers.length;
  let viewerWidth = `${100 / numberOfViewers}%`;

  return (
    <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
      {viewers.map((viewer, index) => {
        const { seriesId, id } = viewer;
        const selectedSeries = series[seriesId];
        const viewerRoi = viewerRois[id];

        return (
          <ImageViewerContainer
            key={index}
            viewer={viewer}
            roi={viewerRoi}
            series={selectedSeries}
            viewerWidth={viewerWidth}
            setViewportActive={(id) => {
              refreshActiveViewer(id);
            }}
            urlViewerData={urlViewersData ? urlViewersData[index] : null}
          />
        );
      })}
    </div>
  );
}

ImageViewerList.propTypes = {
  viewers: PropTypes.array.isRequired,
  series: PropTypes.object.isRequired,
  setActiveViewer: PropTypes.func.isRequired,
  viewerRois: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  viewers: viewersSelector(state),
  series: state.annotationPage.series.byId,
  viewerRois: viewerRoisSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveViewer: (viewerId, isActive) =>
    dispatch(setViewerActive(viewerId, isActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewerList);
