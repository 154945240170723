import {
  SET_LESION_EVALUATIONS,
  ADD_LESION_EVALUATION,
  REMOVE_LESION_EVALUATION,
  ADD_ROI_EVALUATION_TO_LESION,
} from "../../types/evaluations/lesions";
import {
  deleteLesionEvaluation as deleteLesionEvaluationApi,
  insertRoiRecistEvaluation as insertRoiRecistEvaluationApi,
  insertLesionEvaluation as insertLesionEvaluationApi,
} from "shared-api";
import { addRoiRecistEvaluationThunk } from "./rois.js";
import { updateLesionStatus } from "../../../../actions/actionCreators/annotationPage/lesions";
import {
  addDiagnosisThunk,
  getLungCancerDiagnosis,
  insertDiagnosis,
} from "../diagnosis/diagnoses";
import {
  getSeriesFollowUpId,
  getSeriesIdsWithFollowUp,
} from "../../../../actions/actionCreators/annotationPage/series/series";
import { addDiagnosis } from "../../../hooks/addDiagnosis";
import { insertTumour } from "../diagnosis/tumours";
import getClient from "../../../../apollo/getClient";

export function addLesionEvaluation(lesionEvaluation) {
  return { type: ADD_LESION_EVALUATION, lesionEvaluation };
}

export function removeLesionEvaluation(lesionId) {
  return { type: REMOVE_LESION_EVALUATION, lesionId };
}

export function setLesionEvaluations(lesionEvaluations) {
  return { type: SET_LESION_EVALUATIONS, lesionEvaluations };
}

export function addRoiRecistEvaluationToLesion(roiRecistEvaluation) {
  return { type: ADD_ROI_EVALUATION_TO_LESION, roiRecistEvaluation };
}

export function addNewLesionEvaluationThunk(
  lesionEvaluation,
  roiRecistEvaluation
) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      annotationPage: {
        case: {
          selectedCaseAssignment: { caseId },
        },
        recists: {
          evaluations: { lesions: lesionEvaluations },
        },
        rois: { byId: roisById },
      },
    } = state;

    let seriesId =
      roisById[
        Object.keys(roisById).find((roiId) => {
          return parseInt(roiId) === roiRecistEvaluation.roiId;
        })
      ].seriesId;

    const client = getClient();

    const {
      lesionId,
      targetClassification,
      stagingClassification,
      location: { id: locationId },
    } = lesionEvaluation;

    await insertLesionEvaluationApi({
      client,
      lesionId,
      targetClassification,
      stagingClassification,
      locationId,
    });

    //todo this is probably redundant since addRoiRecistEvaluationThunk is called below
    let {
      roiId,
      LAD: longAxisDiameter,
      SAD: shortAxisDiameter,
      volume,
      visibility,
    } = roiRecistEvaluation;

    await insertRoiRecistEvaluationApi({
      client,
      roiId,
      shortAxisDiameter,
      longAxisDiameter,
      volume,
      visibility,
    });

    let lesionReduxEvaluation = { ...lesionEvaluation };
    lesionReduxEvaluation.roiRecistEvaluations = [roiRecistEvaluation.roiId];
    lesionReduxEvaluation.location = lesionReduxEvaluation.location.anatomy;
    roiRecistEvaluation.seriesId = seriesId;
    roiRecistEvaluation.lesionId = lesionEvaluation.lesionId;

    const seriesIdsWithFollowUp = getSeriesIdsWithFollowUp(state);
    for (const seriesIdWithFollowUp of seriesIdsWithFollowUp) {
      const followUpId = getSeriesFollowUpId(seriesIdWithFollowUp, state);
      const diagnosisSkeleton = getLungCancerDiagnosis(followUpId);
      const diagnosis = await addDiagnosis(diagnosisSkeleton);
      dispatch(insertDiagnosis(diagnosis));
      for (const tumour of diagnosis.tumours) {
        dispatch(insertTumour(tumour));
      }
    }

    dispatch(addLesionEvaluation(lesionReduxEvaluation));
    dispatch(addRoiRecistEvaluationThunk(roiRecistEvaluation));
  };
}

export function removeLesionEvaluationThunk(lesionEvaluation) {
  return async (dispatch) => {
    const { lesionId } = lesionEvaluation;

    const client = getClient();
    await deleteLesionEvaluationApi({ client, lesionId });

    dispatch(removeLesionEvaluation(lesionId));
    dispatch(updateLesionStatus(lesionId, null));
  };
}
