import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getSeriesIds } from "../../store/annotationPage/series/selectors";
import {
  targetLesionEvaluationsSelector,
  nonTargetLesionEvaluationsSelector,
  existsUnsavedNewLesionsSelector,
} from "../store/evaluationDetails/selectors";

import PropTypes from "prop-types";

import OkTextDialog from "../../components/Dialogs/Base/OkTextDialog";

function DoneAnnotationsButtonWithDialog({
  existsUnsavedNewLesions,
  currentSeriesId,
  seriesIds,
  targetLesionEvaluations,
  nonTargetLesionEvaluations,
  setDisplayErrorIcon,
  setOpenConfirmDialog,
  doneClicked,
  setDoneClicked,
  yesClicked,
  onClickYes,
}) {
  const [openUnsavedNewLesions, setOpenUnSavedNewLesions] = React.useState(
    false
  );
  const [
    openMissingRoiRecistEvaluations,
    setOpenMissingRoiRecistEvaluations,
  ] = React.useState(false);
  const [
    hasUnsavedTargetFollowups,
    setHasUnsavedTargetFollowups,
  ] = React.useState(false);
  const [
    hasUnsavedNontargetFolllowups,
    setHasUnsavedNontargetFollowups,
  ] = React.useState(false);

  useEffect(() => {
    if (doneClicked) {
      handleOnClickDone();
      setDoneClicked(false);
    }
  }, [doneClicked]);

  useEffect(async () => {
    if (yesClicked) {
      onClickYes();
    }
  }, [yesClicked]);

  useEffect(() => {
    setDisplayErrorIcon(
      existsUnsavedNewLesions ||
        hasUnsavedTargetFollowups ||
        hasUnsavedNontargetFolllowups
    );
  }, [
    existsUnsavedNewLesions,
    hasUnsavedTargetFollowups,
    hasUnsavedNontargetFolllowups,
  ]);

  useEffect(() => {
    let hasMissingRoiRecistEvaluations = false;
    for (let targetLesionEvaluation of targetLesionEvaluations) {
      if (
        targetLesionEvaluation.roiRecistEvaluations.length !== seriesIds.length
      ) {
        hasMissingRoiRecistEvaluations = true;
        break;
      }
    }
    setHasUnsavedTargetFollowups(hasMissingRoiRecistEvaluations);
  }, [targetLesionEvaluations, currentSeriesId]);

  useEffect(() => {
    let hasMissingRoiRecistEvaluations = false;
    for (let nonTargetLesionEvaluation of nonTargetLesionEvaluations) {
      if (
        nonTargetLesionEvaluation.roiRecistEvaluations.length !==
        seriesIds.length
      ) {
        hasMissingRoiRecistEvaluations = true;
        break;
      }
    }
    setHasUnsavedNontargetFollowups(hasMissingRoiRecistEvaluations);
  }, [nonTargetLesionEvaluations, currentSeriesId]);

  const handleOnClickDone = () => {
    if (existsUnsavedNewLesions) {
      setOpenUnSavedNewLesions(true);
      return;
    }
    if (hasUnsavedTargetFollowups || hasUnsavedNontargetFolllowups) {
      setOpenMissingRoiRecistEvaluations(true);
      return;
    }

    setOpenConfirmDialog(true);
  };

  return (
    <div>
      <OkTextDialog
        title={"Unsaved New Lesions"}
        onOk={() => setOpenUnSavedNewLesions(false)}
        message={
          "There are still new unsaved lesions for this case. Please save/remove all new lesions before continuing."
        }
        open={openUnsavedNewLesions}
      />
      <OkTextDialog
        title={"Missing Roi Evaluations"}
        onOk={() => setOpenMissingRoiRecistEvaluations(false)}
        message={
          "There are missing follow-up measurements. Please measure all follow-up lesions before continuing."
        }
        open={openMissingRoiRecistEvaluations}
      />
    </div>
  );
}

DoneAnnotationsButtonWithDialog.propTypes = {
  currentSeriesId: PropTypes.number,
  seriesIds: PropTypes.array.isRequired,
  existsUnsavedNewLesions: PropTypes.bool.isRequired,
  targetLesionEvaluations: PropTypes.array.isRequired,
  nonTargetLesionEvaluations: PropTypes.array.isRequired,
  setDisplayErrorIcon: PropTypes.func.isRequired,
  setOpenConfirmDialog: PropTypes.func.isRequired,
  doneClicked: PropTypes.bool.isRequired,
  setDoneClicked: PropTypes.func.isRequired,
  yesClicked: PropTypes.bool.isRequired,
  onClickYes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentSeriesId: state.annotationPage.recists.recistSeries.currentSeriesId,
  seriesIds: getSeriesIds(state),
  existsUnsavedNewLesions: existsUnsavedNewLesionsSelector(state),
  targetLesionEvaluations: targetLesionEvaluationsSelector(state),
  nonTargetLesionEvaluations: nonTargetLesionEvaluationsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoneAnnotationsButtonWithDialog);
