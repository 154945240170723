import { gql } from "@apollo/client/core";
import { LESION_FRAGMENT } from "./LesionFragment";

export const CASE_LESIONS_FRAGMENT = gql`
  fragment CaseLesions on cases {
    id: case_id
    lesions {
      ...Lesion
    }
  }
  ${LESION_FRAGMENT}
`;
