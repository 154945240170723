import { PatientBiopsyDetailsFragmentType } from "../fragments/PatientBiopsyDetailsFragmentType";
import { PatientBiopsyDetailsType } from "../types/PatientBiopsyDetailsType";

export function parseBiopsyDetails({
  id,
  lesionId,
  patientId,
  unsure,
  details: biopsyDetails,
  noLinking,
  noLinkingReason: reason,
}: PatientBiopsyDetailsFragmentType): PatientBiopsyDetailsType {
  return {
    id,
    lesionId,
    patientId,
    biopsyDetails,
    unsure,
    noLinking: {
      noLinking,
      reason,
    },
  };
}
