import { gql } from "@apollo/client/core";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";
import { PATIENT_BIOPSY_DETAILS_FRAGMENT } from "../fragments/PatientBiopsyDetailsFragment";
import { PatientBiopsyDetailsType } from "../types/PatientBiopsyDetailsType";
import { PatientBiopsyDetailsFragmentType } from "../fragments/PatientBiopsyDetailsFragmentType";
import { parseBiopsyDetails } from "../parsers/parseBiopsyDetails";

export const QUERY = gql`
  query GetPatientBiopsyDetailsForCase($caseId: Int) {
    biopsyDetails: patient_biopsy_details(
      where: {
        patient: {
          studies: { series: { series_cases: { case_id: { _eq: $caseId } } } }
        }
      }
    ) {
      ...PatientBiopsyDetails
    }
  }
  ${PATIENT_BIOPSY_DETAILS_FRAGMENT}
`;

type Variables = {
  caseId: number;
};

type Data = {
  biopsyDetails: PatientBiopsyDetailsFragmentType[];
};

type Output = PatientBiopsyDetailsType[];

export function usePatientBiopsyDetails(
  caseId: number
): useParsedQueryReturnType<number, Output, Variables> {
  return useParsedQuery<number, Output, Data, Variables>({
    query: QUERY,
    input: caseId,
    parseVariables: (caseId) => ({ caseId }),
    parseData: ({ biopsyDetails }: Data) =>
      biopsyDetails.map(parseBiopsyDetails),
  });
}
