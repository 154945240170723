import { combineReducers } from "redux";
import { lesions } from "./evaluations/lesions";
import { rois } from "./evaluations/rois";
import { measurements } from "./measurements";
import { recistSeries } from "./recistSeries";
import { diagnoses } from "./diagnosis/diagnoses";
import { tumours } from "./diagnosis/tumours";

const recistReducer = combineReducers({
  evaluations: combineReducers({
    lesions,
    rois,
  }),
  measurements,
  recistSeries,
  diagnoses,
  tumours,
});

export default recistReducer;
