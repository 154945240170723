import { NotaExporterSocketClient } from "./NotaExporterSocketClient";

export const runNotaExporterAsyncWithCancellation = (
  endpoint,
  path,
  onProgress,
  payload,
  cancellationToken
) => {
  return new Promise((resolve, reject) => {
    const cancelWatcher = setInterval(async () => {
      const cancelled = cancellationToken["CANCEL"];
      if (cancelled) {
        clearInterval(cancelWatcher);
        caseExporterSocket.cancel();
        _onError("Cancelled");
      }
    }, 500);

    const _onProgress = (progress) => {
      onProgress(progress);
    };

    const _onError = (error) => {
      reject(new Error(`Export failed ${error}`));
    };

    const _onComplete = (data) => {
      clearInterval(cancelWatcher);
      resolve(data);
    };

    const caseExporterSocket = new NotaExporterSocketClient(
      endpoint,
      path,
      _onProgress,
      _onError,
      _onComplete
    );

    caseExporterSocket.export(payload);
  });
};
