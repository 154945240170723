import { gql } from "@apollo/client/core";

export const CASE_ACTION_TYPE_ATTRIBUTE_FRAGMENT = gql`
  fragment CaseActionTypeAttribute on case_action_type_attribute {
    actionType: action_type
    displayName: display_name
    color
    iconName: icon_name
    iconSource: icon_source
  }
`;
