import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

function LoadingSpinner({ message = "Loading..." }) {
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item style={{ padding: "16px" }}>
        <Typography>{message}</Typography>
      </Grid>
    </Grid>
  );
}

export default LoadingSpinner;
