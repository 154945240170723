import React from "react";
import OkDialog from "../Dialogs/Base/OkDialog";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

export function MissingAnnotationsDialog({
  showDialog,
  onConfirm,
  goToSlice,
  title,
  annotationType,
}) {
  return (
    <OkDialog title={title} onOk={onConfirm} open={showDialog}>
      <>
        <DialogContentText>
          {"It appears that there are one or more missing " +
            annotationType +
            ". All " +
            annotationType +
            " must be adjacent to each other with no gaps. Please ensure there are no missing " +
            annotationType +
            " before saving."}
        </DialogContentText>
        <Button onClick={goToSlice}>Go to slice</Button>
      </>
    </OkDialog>
  );
}
