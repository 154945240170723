import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { FREEHAND_SCISSORS } from "./Tools";
import { getLabelmapPixelData } from "./maskUtils/getLabelmapPixelData";

export default class FreehandScissorsTool {
  constructor(cornerstoneElement) {
    const tool = cornerstoneTools.getToolForElement(
      cornerstoneElement,
      FREEHAND_SCISSORS
    );
    this.onDataUpdate = null;

    this.element = cornerstoneElement;

    if (!tool) {
      throw new Error("Cornerstone freehand scissors tool is null");
    }

    tool.setLabelmapChangeCallback(this.onLabelmapUpdated);

    this.tool = tool;

    this.setDisabled();

    const keyBindings = [
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "c" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onUserMaskCopyHotKeyPressed,
      },
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "v" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onUserMaskPasteHotKeyPressed,
      },
    ];

    tool.setKeyBindings(keyBindings);
  }

  setOnUserMaskCopyHotKeyPressedCallback(onUserMaskCopyHotKeyPressedCallback) {
    this.onUserMaskCopyHotKeyPressedCallback = onUserMaskCopyHotKeyPressedCallback;
  }

  setOnUserMaskPasteHotKeyPressedCallback(
    onUserMaskPasteHotKeyPressedCallback
  ) {
    this.onUserMaskPasteHotKeyPressedCallback = onUserMaskPasteHotKeyPressedCallback;
  }

  onUserMaskCopyHotKeyPressed = () => {
    if (!this.onUserMaskCopyHotKeyPressedCallback) {
      return;
    }

    let pixelData = getLabelmapPixelData(this.element);

    this.onUserMaskCopyHotKeyPressedCallback(pixelData);
  };

  onUserMaskPasteHotKeyPressed = () => {
    if (!this.onUserMaskPasteHotKeyPressedCallback) {
      return;
    }

    this.onUserMaskPasteHotKeyPressedCallback();
  };

  onLabelmapUpdated = () => {
    if (!this.onDataUpdate) {
      return;
    }

    let pixelData = getLabelmapPixelData(this.element);

    this.onDataUpdate(pixelData);
  };

  setDataUpdateCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }

  setDisabled() {
    cornerstoneTools.setToolDisabledForElement(
      this.element,
      FREEHAND_SCISSORS,
      {
        mouseButtonMask: 1,
      }
    );
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(this.element, FREEHAND_SCISSORS, {
      mouseButtonMask: 1,
    });
  }
}
