import React from "react";
import { Launch } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import { jumpToSliceForLesion } from "../../actions/actionCreators/annotationPage/thunks";

interface JumpToLesionButtonProps {
  lesion: any;
}

export function JumpToLesionButton({
  lesion,
}: JumpToLesionButtonProps): JSX.Element {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (lesion === null) {
      throw new Error("Lesion is null in JumpToLesionButton");
    }
    const { id } = lesion;
    dispatch(jumpToSliceForLesion(id));
  };

  return (
    <IconButton
      edge="start"
      size="small"
      style={{ marginRight: "6px" }}
      disabled={false}
      onClick={handleOnClick}
    >
      <Launch
        style={{
          color: "white",
          transform: "scaleX(-1)", // Looks better with the arrow pointing towards the viewer
        }}
      />
    </IconButton>
  );
}
