import { LabelmapFragmentType } from "../fragments/LabelmapFragmentType";
import { LabelmapType } from "../types/LabelmapType";
import { parseMaskFragment } from "./parseMaskFragment";

export function parseLabelmap(
  {
    groupId,
    instance: { seriesId },
    origin,
    ...maskProps
  }: LabelmapFragmentType,
  enabled: boolean
): LabelmapType {
  const mask = parseMaskFragment(maskProps);

  return {
    ...mask,
    groupId,
    seriesId,
    origin,
    enabled,
  };
}
