import {
  GenericRestApiConsumer,
  getDatasetFromUrl,
  getEllipsePointsFromStartEnd,
} from "shared-api";

let restApiConsumer = null;

export function initAutoSegmentApi(url, token, onError) {
  if (restApiConsumer) {
    throw new Error("AutoSegment API already initialized");
  }

  restApiConsumer = new GenericRestApiConsumer(url, token, onError);
}

function getAutoSegmentApi() {
  if (!restApiConsumer) {
    throw new Error("AutoSegment API not initialized");
  }

  return restApiConsumer;
}

async function autoSegment(dataset, shape, settings) {
  const api = getAutoSegmentApi();

  return api.post(
    "/",
    { dataset, shape, settings },
    (result) => result.data,
    "autoSegment"
  );
}

export async function autoSegmentEllipseData(
  imageId,
  toolData,
  settings,
  resolution
) {
  const {
    handles: { start, end },
  } = toolData;

  const shape = getEllipsePointsFromStartEnd(start, end, resolution);
  const dataset = getDatasetFromUrl(imageId);

  const { segmentation, code, message } = await autoSegment(
    dataset,
    shape,
    settings
  );
  if (code) {
    throw new Error(
      `Error occurred during auto-segment: Code: ${code}: ${message}`
    );
  }

  return segmentation;
}
