import {
  ADD_ROI,
  DELETE_CONTOUR,
  DELETE_DETECTION,
  INSERT_CONTOUR,
  REMOVE_PROXY_ROI_ATTRIBUTES,
  REMOVE_ROI,
  SET_ROIS,
  SET_SELECTED_ROI_ID,
  UNSET_SELECTED_ROI_ID,
  UPDATE_CONTOUR,
  UPDATE_DETECTION,
  UPDATE_PROXY_ROI_ATTRIBUTES,
  UPDATE_ROI_ATTRIBUTES,
  UPDATE_USER_MASK,
  INSERT_USER_MASK,
  DELETE_USER_MASK,
  COPY_DATA_TO_CLIPBOARD,
  CLEAR_CLIPBOARD,
} from "../../actions/types/annotationPage/rois";
import { CONTOUR_VERSION, CURRENT_USER_MASK_VERSION } from "shared-api";
import produce from "immer";

let defaultState = {
  byId: {},
  allIds: [],
  proxyRoiAttributes: {},
  selectedContourToolRoiId: undefined,
  selectedEllipseToolRoiId: undefined,
  selectedRoiId: undefined,
  toolRoiSavedStatuses: { savedToolRoiIds: [] },
  clipboardData: undefined,
};

export const rois = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ROI:
      return addRoi(state, action);
    case REMOVE_ROI:
      return removeRoi(state, action);
    case SET_ROIS:
      return setRois(state, action);
    case DELETE_CONTOUR:
      return deleteContour(state, action);
    case DELETE_DETECTION:
      return deleteDetection(state, action);
    case UPDATE_DETECTION:
      return updateDetection(state, action);
    case UPDATE_CONTOUR:
      return updateContour(state, action);
    case INSERT_CONTOUR:
      return insertContour(state, action);
    case UPDATE_USER_MASK:
      return updateUserMask(state, action);
    case INSERT_USER_MASK:
      return insertUserMask(state, action);
    case DELETE_USER_MASK:
      return deleteUserMask(state, action);
    case UPDATE_ROI_ATTRIBUTES:
      return updateRoiAttributes(state, action);
    case UPDATE_PROXY_ROI_ATTRIBUTES:
      return updateProxyRoiAttributes(state, action);
    case REMOVE_PROXY_ROI_ATTRIBUTES:
      return removeProxyRoiAttributes(state, action);
    case SET_SELECTED_ROI_ID:
      return setSelectedRoiId(state, action);
    case UNSET_SELECTED_ROI_ID:
      return unsetSelectedRoiId(state, action);
    case COPY_DATA_TO_CLIPBOARD:
      return copyDataToClipboard(state, action);
    case CLEAR_CLIPBOARD:
      return clearClipboard(state, action);
    default:
      return state;
  }
};

const addRoi = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.roi.id]: action.roi,
    },
    allIds: state.allIds.concat(action.roi.id),
  };
};

const removeRoi = (state, action) => {
  let rois = state.byId
    ? Object.keys(state.byId).reduce((object, key) => {
        // eslint-disable-next-line eqeqeq
        if (action.roiId != key) {
          object[key] = state.byId[key];
        }
        return object;
      }, {})
    : undefined;
  let roiIds = state.allIds.filter((id) => id !== action.roiId);

  return {
    ...state,
    byId: rois,
    allIds: roiIds,
  };
};

const setRois = (state, action) => {
  return {
    ...state,
    byId: action.rois.byId,
    allIds: action.rois.allIds,
    proxyRoiAttributes: {},
  };
};

const deleteContour = (state, action) => {
  const { roiId, instanceId } = action.payload;

  const contours = state.byId[roiId].contours;

  const existingContour = contours.find(
    (contour) => contour.instanceId === instanceId
  );

  let index = contours.indexOf(existingContour);
  contours.splice(index, 1);

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        contours,
      },
    },
  };
};

const deleteDetection = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.roiId]: {
        ...state.byId[action.roiId],
        ellipse: {
          ...state.byId[action.roiId].ellipse,
          slice: null,
        },
        contours: [],
        roiAttributes: null,
      },
    },
  };
};

const updateDetection = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.roiId]: {
        ...state.byId[action.roiId],
        ellipse: {
          ...state.byId[action.roiId].ellipse,
          slice: action.data,
        },
      },
    },
  };
};

const updateRoiAttributes = (state, action) => {
  let roiId = action.roiId;
  let roiAttributes = action.roiAttributes;

  let newState = {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        roiAttributes: roiAttributes,
      },
    },
    proxyRoiAttributes: {
      ...state.proxyRoiAttributes,
      [roiId]: {
        ...roiAttributes,
        hasPendingEdits: false,
      },
    },
  };

  newState.toolRoiSavedStatuses = getSavedUnsavedToolRoiIds(newState);

  return newState;
};

let getSavedUnsavedToolRoiIds = (state) => {
  let rois = state.allIds.map((id) => state.byId[id]);
  let proxyRoiAttributes = state.proxyRoiAttributes;

  let savedToolRoiIds = [];

  rois.forEach((roi) => {
    let detectionToolRoiId = roi.ellipse.roi.id;
    if (roi.roiAttributes) {
      let proxy = proxyRoiAttributes[roi.id];
      if (!proxy || !proxy.hasPendingEdits) {
        savedToolRoiIds.push(detectionToolRoiId);
      }
    }
  });

  return { savedToolRoiIds };
};

let hasPendingEdits = (state) => {
  if (!state.proxyRoiAttributes) {
    return false;
  }

  let proxyRoiAttributeIds = Object.keys(state.proxyRoiAttributes);

  let hasPendingEdits = proxyRoiAttributeIds.some((id) => {
    let hasEdits = state.proxyRoiAttributes[id].hasPendingEdits;
    return hasEdits;
  });

  return hasPendingEdits;
};

let roiAttributesHasPendingEdits = (state, roiId, proxyRoiAttributes) => {
  let roi = state.byId[roiId];
  if (!roi) {
    throw new Error("Roi does not exist!");
  }

  let roiAttributes = roi.roiAttributes;
  if (!roiAttributes) {
    return true;
  }

  if (proxyRoiAttributes.measurable !== roiAttributes.measurable) {
    return true;
  }

  if (
    proxyRoiAttributes.tumourClassification !==
    roiAttributes.tumourClassification
  ) {
    return true;
  }

  return false;
};

const updateProxyRoiAttributes = (state, action) => {
  let roiId = action.roiId;
  let proxyRoiAttributes = action.roiAttributes;

  let hasPendingRoiEdits = roiAttributesHasPendingEdits(
    state,
    roiId,
    proxyRoiAttributes
  );

  let newState = {
    ...state,
    proxyRoiAttributes: {
      ...state.proxyRoiAttributes,
      [roiId]: {
        ...proxyRoiAttributes,
        hasPendingEdits: hasPendingRoiEdits,
      },
    },
  };

  newState.toolRoiSavedStatuses = getSavedUnsavedToolRoiIds(newState);

  return newState;
};

const removeProxyRoiAttributes = (state, action) => {
  let roiAttributes = state.proxyRoiAttributes
    ? Object.keys(state.proxyRoiAttributes).reduce((object, key) => {
        // eslint-disable-next-line eqeqeq
        if (action.roiId != key) {
          object[key] = state.proxyRoiAttributes[key];
        }
        return object;
      }, {})
    : undefined;

  return {
    ...state,
    proxyRoiAttributes: roiAttributes,
  };
};

const updateContour = (state, action) => {
  //ideally immer is used here, however it is making the properties readonly for some reason and causing issues in cornerstone
  const { roiId, instanceId, toolData } = action.payload;

  const { byId } = state;
  const { contours } = byId[roiId];

  const contoursCopy = [...contours];
  const existingContourIndex = contoursCopy.findIndex(
    (contour) => contour.instanceId === instanceId
  );

  if (existingContourIndex === -1) {
    throw new Error("Contour already exists in state");
  }

  contoursCopy[existingContourIndex] = {
    ...contoursCopy[existingContourIndex],
    toolData,
  };

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        contours: contoursCopy,
      },
    },
  };
};

const updateUserMask = (state, action) => {
  const { roiId, instanceId, toolData, shape } = action.payload;
  const { byId } = state;
  const { userMasks } = byId[roiId];

  const userMasksCopy = [...userMasks];
  const existingUserMaskIndex = userMasks.findIndex(
    (userMask) => userMask.instanceId === instanceId
  );

  if (existingUserMaskIndex === -1) {
    throw new Error("User Mask already exists in state");
  }

  userMasksCopy[existingUserMaskIndex] = {
    ...userMasksCopy[existingUserMaskIndex],
    toolData,
    shape,
  };

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        userMasks: userMasksCopy,
      },
    },
  };
};

const insertContour = (state, action) => {
  //ideally immer is used here, however it is making the properties readonly for some reason and causing issues in cornerstone
  const { roiId, instanceId, toolData, userId } = action.payload;
  const { byId } = state;
  const { contours } = byId[roiId];

  const contoursCopy = [...contours];
  const existingContourIndex = contoursCopy.findIndex(
    (contour) => contour.instanceId === instanceId
  );

  if (existingContourIndex !== -1) {
    throw new Error("Contour already exists in state");
  }

  contoursCopy.push({
    instanceId,
    toolData,
    version: CONTOUR_VERSION,
    createdBy: userId,
  });

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        contours: contoursCopy,
      },
    },
  };
};

const insertUserMask = (state, action) => {
  const { roiId, instanceId, toolData, shape, createdBy } = action.payload;
  const { byId } = state;
  const { userMasks } = byId[roiId];

  const userMasksCopy = [...userMasks];
  const existingUserMaskIndex = userMasksCopy.findIndex(
    (userMask) => userMask.instanceId === instanceId
  );

  if (existingUserMaskIndex !== -1) {
    throw new Error("User Mask already exists in state");
  }

  userMasksCopy.push({
    instanceId,
    toolData,
    version: CURRENT_USER_MASK_VERSION,
    shape,
    createdBy,
  });

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        userMasks: userMasksCopy,
      },
    },
  };
};

const deleteUserMask = (state, action) => {
  const { roiId, instanceId } = action.payload;
  const { byId } = state;
  const { userMasks } = byId[roiId];
  const existingUserMaskIndex = userMasks.findIndex(
    (userMask) => userMask.instanceId === instanceId
  );

  userMasks.splice(existingUserMaskIndex, 1);

  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        userMasks,
      },
    },
  };
};

const setDetectionToolColor = (state, action) => {
  let roiId = action.roiId;
  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        ellipse: {
          ...state.byId[roiId].ellipse,
          roi: {
            ...state.byId[roiId].ellipse.roi,
            color: action.color,
          },
        },
      },
    },
  };
};
const setContourToolColor = (state, action) => {
  let roiId = action.roiId;
  return {
    ...state,
    byId: {
      ...state.byId,
      [roiId]: {
        ...state.byId[roiId],
        ellipse: {
          ...state.byId[roiId].contours,
          roi: {
            ...state.byId[roiId].contours.roi,
            color: action.color,
          },
        },
      },
    },
  };
};

const setSelectedRoiId = (state, action) => {
  let selectedEllipseToolRoiId = undefined;
  let selectedContourToolRoiId = undefined;

  let selectedRoi = state.byId[action.id];
  if (selectedRoi) {
    selectedEllipseToolRoiId = selectedRoi.ellipse.roi.id;
    selectedContourToolRoiId = selectedRoi.contoursToolRoi.id;
  }

  return {
    ...state,
    selectedRoiId: action.id,
    selectedEllipseToolRoiId,
    selectedContourToolRoiId,
  };
};

const unsetSelectedRoiId = (state, action) => {
  return {
    ...state,
    selectedRoiId: undefined,
    selectedEllipseToolRoiId: undefined,
    selectedContourToolRoiId: undefined,
  };
};

const copyDataToClipboard = (state, action) => {
  return {
    ...state,
    clipboardData: action.clipboardData,
  };
};

const clearClipboard = (state, action) => {
  return {
    ...state,
    clipboardData: undefined,
  };
};
