import { StudyFragmentType } from "../fragments/StudyFragmentType";
import { StudyType } from "../types/StudyType";
import { sortSopInstances } from "../../../../api/pacs/utilities/dicom/sortSopInstances";
import { parseStudyNotes } from "./parseStudyNotes";

export function parseStudy(
  studyFragment: StudyFragmentType,
  baseWadoUrl: string
): StudyType {
  const {
    series: originalSeriesList,
    studyInstanceUid,
    notes: notesFragment,
  } = studyFragment;
  const seriesList = originalSeriesList.map((series) => {
    const { instances: originalInstances, seriesInstanceUid } = series;
    const instances = originalInstances.map((instance) => {
      const { sopInstanceUid } = instance;

      //we allow the wado url to be changed in different environments to preserve the image viewing functionality when restore db backups. since the front end can't easily determine this path, we inject it here instead. We also handle sorting here which may not be necessary
      const wadoUrl = getWadoUrl(
        baseWadoUrl,
        studyInstanceUid,
        seriesInstanceUid,
        sopInstanceUid
      );

      return { ...instance, wadoUrl };
    });

    const sortedInstances = sortSopInstances(instances);
    return { ...series, instances: sortedInstances };
  });
  const notes = notesFragment ? parseStudyNotes(notesFragment) : null;
  return { ...studyFragment, notes, series: seriesList };
}

export function getWadoUrl(
  baseWadoUrl: string,
  studyInstanceUid: string | null,
  seriesInstanceUid: string | null,
  sopInstanceUid: string | null
): string {
  const uriBasePath = "wadouri:" + baseWadoUrl;
  const requestType = "?requestType=WADO";
  const studyUID = "&studyUID=" + studyInstanceUid;
  const seriesUID = "&seriesUID=" + seriesInstanceUid;
  const objectUID = "&objectUID=" + sopInstanceUid;
  const contentType = "&contentType=application/dicom";

  return (
    uriBasePath + requestType + studyUID + seriesUID + objectUID + contentType
  );
}
