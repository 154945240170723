import EllipseTool from "./EllipseTool";
import EllipseToolWrapper from "./EllipseToolWrapper";
import RoiTool from "./RoiTool";
import RoiToolWrapper from "./RoiToolWrapper";
import LengthTool from "./LengthTool";
import CurrentImageIdWatcher from "./CurrentImageIdWatcher";
import labelmapProvider from "../labelmapUtils/initLabelmapProvider";
import { COMPLETED, ToolTypes } from "shared-api";
import {
  ANNOTATE,
  ROI_SCULPTOR,
  BRUSH,
  FREEHAND_SCISSORS,
} from "../tools/Tools";
import cornerstone from "cornerstone-core";
import ToolControllerEvents from "./ToolControllerEvents";
import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { getRoiToolStateDataForRoi } from "./ToolUtils";
import { getImageSize } from "./imageUtils/getImageSize";
import HockeyPuckTool from "./HockeyPuckTool";
import BrushTool from "./BrushTool";
import EllipticalProbeTool from "./EllipticalProbeTool";
import BrushToolWrapper from "./BrushToolWrapper";
import FreehandScissorsTool from "./FreehandScissorsTool";
import FreehandScissorsToolWrapper from "./FreehandScissorsToolWrapper";
import getIsolatedSegmentToolData from "../labelmapUtils/getIsolatedSegmentToolData";

import EventEmitter from "events";

class ToolController extends EventEmitter {
  constructor(cornerstoneElement) {
    super();

    if (!cornerstoneElement) {
      throw new Error("CornerstoneElement cannot be null!");
    }

    this.cornerstoneElement = cornerstoneElement;

    this.selectedRoi = null;
    this.selectedToolRoi = null;

    this.currentImageIdWatcher = new CurrentImageIdWatcher();
    this.currentImageIdWatcher.setCornerstoneElement(cornerstoneElement);

    let ellipseTool = new EllipseTool(cornerstoneElement);
    ellipseTool.setPassive();

    this.hockeyPuckTool = new HockeyPuckTool(cornerstoneElement);
    this.hockeyPuckTool.setOnRoiPushedCallback(this.onContourChanged);

    this.ellipseToolWrapper = new EllipseToolWrapper(ellipseTool);
    this.ellipseToolWrapper.setOnEllipseChangedCallback(this.onEllipseChanged);

    this.brushTool = new BrushTool(cornerstoneElement);
    this.brushToolWrapper = new BrushToolWrapper(
      this.currentImageIdWatcher,
      this.brushTool
    );
    this.brushToolWrapper.setToolDataChangedCallback(this.onLabelmapChanged);
    this.brushToolWrapper.setOnUserMaskCopyHotKeyPressedCallback(
      this.onUserMaskCopyHotKeyPressed
    );
    this.brushToolWrapper.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );
    this.brushToolWrapper.setOnBrushSizeChangedCallback(
      this.onBrushSizeChanged
    );

    this.freehandScissorsTool = new FreehandScissorsTool(cornerstoneElement);
    this.freehandScissorsToolWrapper = new FreehandScissorsToolWrapper(
      this.currentImageIdWatcher,
      this.freehandScissorsTool
    );
    this.freehandScissorsToolWrapper.setToolDataChangedCallback(
      this.onLabelmapChanged
    );
    this.freehandScissorsToolWrapper.setOnUserMaskCopyHotKeyPressedCallback(
      this.onUserMaskCopyHotKeyPressed
    );
    this.freehandScissorsToolWrapper.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );

    let roiTool = new RoiTool(cornerstoneElement);
    roiTool.setPassive();

    this.roiToolWrapper = new RoiToolWrapper(
      this.currentImageIdWatcher,
      roiTool
    );
    this.roiToolWrapper.setToolDataChangedCallback(this.onContourChanged);
    this.roiToolWrapper.setOnContourRightClickedCallback(
      this.onContourRightClicked
    );
    this.roiToolWrapper.setOnContourDoubleClickedCallback(
      this.onContourDoubleClicked
    );
    this.roiToolWrapper.setOnContourCopyHotKeyPressedCallback(
      this.onContourCopyHotKeyPressed
    );

    this.roiToolWrapper.setOnContourPasteHotKeyPressedCallback(
      this.onContourPasteHotKeyPressed
    );

    this.lengthTool = new LengthTool(cornerstoneElement);
    this.lengthTool.setOnLengthChangedCallback(this.onLengthDataChanged);

    this.ellipticalProbeTool = new EllipticalProbeTool(
      cornerstoneElement,
      this.onEllipseProbeCreated
    );

    //temp
    this.roiToolWrapper.setCornerstoneElement(cornerstoneElement);

    this.isDetectMode = true;
  }

  subscribeToEllipseChanged(callback) {
    this.on(ToolControllerEvents.ELLIPSE_UPDATED, callback);
  }

  unsubscribeToEllipseChanged(callback) {
    this.off(ToolControllerEvents.ELLIPSE_UPDATED, callback);
  }

  subscribeToContourChanged(callback) {
    this.on(ToolControllerEvents.CONTOUR_UPDATED, callback);
  }

  unsubscribeToContourChanged(callback) {
    this.off(ToolControllerEvents.CONTOUR_UPDATED, callback);
  }

  subscribeToLabelmapChanged(callback) {
    this.on(ToolControllerEvents.LABELMAP_UPDATED, callback);
  }

  unsubscribeToLabelmapChanged(callback) {
    this.off(ToolControllerEvents.LABELMAP_UPDATED, callback);
  }

  subscribeToContourRightClicked(callback) {
    this.on(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, callback);
  }

  subscribeToContourDoubleClicked(callback) {
    this.on(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, callback);
  }

  subscribeToContourCopyHotKeyPressed(callback) {
    this.on(ToolControllerEvents.CONTOUR_COPY_HOT_KEY_PRESSED, callback);
  }

  subscribeToContourPasteHotKeyPressed(callback) {
    this.on(ToolControllerEvents.CONTOUR_PASTE_HOT_KEY_PRESSED, callback);
  }

  subscribeToUserMaskCopyHotKeyPressed(callback) {
    this.on(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, callback);
  }

  subscribeToUserMaskPasteHotKeyPressed(callback) {
    this.on(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, callback);
  }

  subscribeToBrushSizeChanged(callback) {
    this.on(ToolControllerEvents.BRUSH_SIZE_CHANGED, callback);
  }

  unsubscribeFromContourRightClicked(callback) {
    this.off(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, callback);
  }

  unsubscribeFromContourDoubleClicked(callback) {
    this.off(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, callback);
  }

  subscribeToMeasureChanged(callback) {
    this.on(ToolControllerEvents.MEASURE_UPDATED, callback);
  }

  unsubscribeToMeasureChanged(callback) {
    this.off(ToolControllerEvents.MEASURE_UPDATED, callback);
  }

  subscribeToEllipseProbeCreated(callback) {
    this.on(ToolControllerEvents.ELLIPSE_PROBE_CREATED, callback);
  }

  unsubscribeToEllipseProbeCreated(callback) {
    this.off(ToolControllerEvents.ELLIPSE_PROBE_CREATED, callback);
  }

  unsubscribeToContourCopyHotKeyPressed(callback) {
    this.off(ToolControllerEvents.CONTOUR_COPY_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToContourPasteHotKeyPressed(callback) {
    this.off(ToolControllerEvents.CONTOUR_PASTE_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToUserMaskCopyHotKeyPressed(callback) {
    this.off(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToUserMaskPasteHotKeyPressed(callback) {
    this.off(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToBrushSizeChanged(callback) {
    this.off(ToolControllerEvents.BRUSH_SIZE_CHANGED, callback);
  }

  onEllipseChanged = async (toolSliceData) => {
    let imageId = toolSliceData.imageId;
    let toolData = toolSliceData.toolData;

    let data = { imageId: imageId, name: "Detection", toolData: toolData };
    this.emit(ToolControllerEvents.ELLIPSE_UPDATED, data);
  };

  onEllipseProbeCreated = async (ellipseData) => {
    const roiId = this.selectedRoi.id;
    this.emit(ToolControllerEvents.ELLIPSE_PROBE_CREATED, ellipseData, roiId);
  };

  onContourChanged = async (toolData, imageId) => {
    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData,
    };

    this.emit(ToolControllerEvents.CONTOUR_UPDATED, eventData);
  };

  onContourRightClicked = (toolData) => {
    this.emit(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, toolData);
  };

  onContourDoubleClicked = (toolData) => {
    this.emit(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, toolData);
  };

  onContourCopyHotKeyPressed = (toolData, imageId) => {
    let eventData = {
      roiId: this.selectedRoi.id,
      toolData,
      imageId,
    };
    this.emit(ToolControllerEvents.CONTOUR_COPY_HOT_KEY_PRESSED, eventData);
  };

  onContourPasteHotKeyPressed = (imageId) => {
    let eventData = {
      roi: this.selectedRoi,
      imageId,
    };
    this.emit(ToolControllerEvents.CONTOUR_PASTE_HOT_KEY_PRESSED, eventData);
  };

  onUserMaskCopyHotKeyPressed = (toolData, imageId) => {
    let shape = getImageSize(imageId);
    let { seriesId, labelmapId } = this.selectedRoi;
    let modifiedtoolData = getIsolatedSegmentToolData(
      seriesId,
      labelmapId,
      toolData
    );

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData: modifiedtoolData,
      shape,
    };
    this.emit(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, eventData);
  };

  onUserMaskPasteHotKeyPressed = (imageId) => {
    let eventData = {
      roi: this.selectedRoi,
      imageId,
      element: this.cornerstoneElement,
    };

    this.emit(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, eventData);
  };

  onBrushSizeChanged = (newBrushSize) => {
    this.emit(ToolControllerEvents.BRUSH_SIZE_CHANGED, newBrushSize);
  };

  onLabelmapChanged = async (toolData, imageId) => {
    let shape = getImageSize(imageId);
    let { seriesId, labelmapId } = this.selectedRoi;
    let modifiedtoolData = getIsolatedSegmentToolData(
      seriesId,
      labelmapId,
      toolData
    );

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData: modifiedtoolData,
      shape,
    };

    this.emit(ToolControllerEvents.LABELMAP_UPDATED, eventData);
  };

  onLengthDataChanged = async (event) => {
    this.emit(ToolControllerEvents.MEASURE_UPDATED, event);
  };

  onActiveToolChanged = (activeTool) => {
    console.log("onActiveToolChanged", activeTool);
  };

  selectToolMode(detectMode) {
    this.isDetectMode = detectMode;
  }

  selectRoi(roi, activeTool, visibleRoiIds, selectedLesion) {
    if (this.isRoiToolDrawing()) {
      this.cancelActiveTool();
    }
    const roiChanged = this.selectedRoi !== roi;

    this.selectedRoi = roi;

    let activeToolRoi = null;
    let passiveToolRoi = null;

    let isRoiVisible = roi ? visibleRoiIds.includes(roi.id) : false;

    let activeToolActions = {
      [ANNOTATE]: () => {
        let ellipseToolRoi = roi.ellipse?.roi;
        let contourToolRoi = roi.contoursToolRoi;

        activeToolRoi = this.isDetectMode ? ellipseToolRoi : contourToolRoi;
        passiveToolRoi = this.isDetectMode ? contourToolRoi : ellipseToolRoi;

        this.selectToolRoi(activeToolRoi, passiveToolRoi);
      },
      [ROI_SCULPTOR]: () => {
        this.turnOffAllTools(ROI_SCULPTOR);

        let imageId = this.currentImageIdWatcher.getCurrentImageId();
        if (!imageId) {
          return;
        }

        const { contoursToolRoi } = roi;
        if (!contoursToolRoi) {
          throw new Error("No roi found for contour");
        }
        /*
          roiChanged should actually not be inverted here because when the selected roi
          changes, we want the hockey puck tool to forceStart. By inverting it, we are
          actually force starting the hockey puck tool when the roi doesn't change which is
          wrong (see tryStart for details). The reason this works here is because contours
          are included in the roi object in nota-web, so when the hockey puck tool changes a
          contour, the roiChanged comparison will evaluate to true even when the selected roi
          is still the same. The solution to this is to compare the roiIds only, which was the
          change made in nota-predict to solve this issue. Note: In this case, when the
          contoursToolRoi is compared to the selectedContoursToolRoi in tryStart, they are
          evaluated as the same if the selected roi hasn't change because nota-web uses
          memoization (which is correct). In nota-predict-web, this is not the case because
          the roi object is not memoized, instead a copy is created. To solve this in nota-predict,
          the ids of the contoursToolRois where compared instead.
        */
        this.hockeyPuckTool.tryStart(contoursToolRoi, imageId, !roiChanged);
      },
      [BRUSH]: () => {
        this.turnOffAllTools();
        labelmapProvider.setActiveLabelmap(
          this.selectedRoi.seriesId,
          this.cornerstoneElement,
          this.selectedRoi.labelmapId
        );
        this.brushToolWrapper.setActive();
      },
      [FREEHAND_SCISSORS]: () => {
        this.turnOffAllTools();
        labelmapProvider.setActiveLabelmap(
          this.selectedRoi.seriesId,
          this.cornerstoneElement,
          this.selectedRoi.labelmapId
        );
        this.freehandScissorsToolWrapper.setActive();
      },
    };

    let activeToolAction = activeToolActions[activeTool];
    if (
      !roi ||
      !activeToolAction ||
      !isRoiVisible ||
      (selectedLesion && selectedLesion.status === COMPLETED)
    ) {
      this.selectedToolRoi = null;
      this.turnOffAllTools();
      return;
    }

    activeToolAction();
  }

  turnOnEllipseTool(activeToolRoi) {
    this.ellipseToolWrapper.turnOn();
    this.ellipseToolWrapper.setCurrentRoi(activeToolRoi);
    this.ellipseToolWrapper.setPassiveRoi(null);
  }

  turnOffEllipseTool() {
    this.ellipseToolWrapper.setCurrentRoi(null);
    this.ellipseToolWrapper.turnOff();
  }

  turnOnRoiTool(activeToolRoi) {
    this.roiToolWrapper.turnOn();
    this.roiToolWrapper.setCurrentRoi(activeToolRoi);
  }

  turnOffRoiTool() {
    this.roiToolWrapper.setCurrentRoi(null);
    this.roiToolWrapper.turnOff();
  }

  selectToolRoi(activeToolRoi, passiveToolRoi) {
    this.selectedToolRoi = activeToolRoi;

    if (activeToolRoi) {
      if (activeToolRoi.type === ToolTypes.ELLIPTICALROITOOL) {
        this.turnOffRoiTool();
        this.turnOnEllipseTool(activeToolRoi);
      } else if (activeToolRoi.type === ToolTypes.FREEHANDROITOOL) {
        this.turnOffEllipseTool();
        this.turnOnRoiTool(activeToolRoi);
      }

      this.onActiveToolChanged(activeToolRoi.type);
      this.forceRedraw();
    }

    if (passiveToolRoi) {
      if (passiveToolRoi.type === ToolTypes.ELLIPTICALROITOOL) {
        this.ellipseToolWrapper.setPassiveRoi(passiveToolRoi);
      }
    }
  }

  forceRedraw = () => {
    cornerstone.updateImage(this.cornerstoneElement);
  };

  cancelActiveTool() {
    console.log("cancelActiveTool");

    let toolRoi = this.selectedToolRoi;
    if (!toolRoi) {
      return;
    }

    let toolType = toolRoi.type;
    switch (toolType) {
      case ToolTypes.FREEHANDROITOOL:
        this.roiToolWrapper.cancelToolDrawing();
        break;
    }
  }

  getImageIdForCurrentToolRoi() {
    let toolRoi = this.selectedToolRoi;

    let data = getRoiToolStateDataForRoi(toolRoi);
    if (data.length <= 0) {
      return null;
    }

    return data[0].imageId;
  }

  setWindowLevel(windowLevel) {
    const viewport = cornerstone.getViewport(this.cornerstoneElement);
    viewport.voi.windowWidth = windowLevel.w;
    viewport.voi.windowCenter = windowLevel.l;
    this.forceRedraw();
  }

  setActiveTool = (tool) => {
    cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  setPassiveTool = (tool) => {
    cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  disableTool = (tool) => {
    cornerstoneTools.setToolDisabledForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  setToolRoiInfo = (toolRoiInfo) => {
    let {
      toolRoiSavedStatuses,
      selectedContourToolRoiId,
      visibleToolRoiIds,
    } = toolRoiInfo;

    this.ellipseToolWrapper.setToolRoiStates({
      toolRoiSavedStatuses,
      visibleToolRoiIds,
    });
    this.roiToolWrapper.setToolRoiStates({
      selectedContourToolRoiId,
      visibleToolRoiIds,
    });

    this.forceRedraw();
  };

  setBrushRadiusValues(brushRadiusValues) {
    this.brushToolWrapper.setBrushRadiusValues(brushRadiusValues);
  }

  turnOffAllTools(skip) {
    this.turnOffEllipseTool();
    this.turnOffRoiTool();
    this.brushToolWrapper.setDisabled();
    if (skip !== ROI_SCULPTOR) {
      this.hockeyPuckTool.setDisabled();
    }
    this.freehandScissorsToolWrapper.setDisabled();
    this.forceRedraw();
  }

  setViewerViewport(viewerData) {
    const originalViewport = cornerstone.getViewport(this.cornerstoneElement);
    const { scale, translation, voi } = viewerData;
    const newViewport = {
      ...originalViewport,
      scale,
      translation: {
        ...originalViewport.translation,
        ...translation,
      },
      voi: {
        ...originalViewport.voi,
        ...voi,
      },
    };
    cornerstone.setViewport(this.cornerstoneElement, newViewport);
  }

  isRoiToolDrawing() {
    return this.roiToolWrapper.isDrawing();
  }
}

export default ToolController;
