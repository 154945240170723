import React from "react";
import TableRow from "@material-ui/core/TableRow";
import { MeasurementCellWrapper } from "./StyledComponents/StyledOutlinedCell";
import { StyledButton } from "./StyledComponents/StyledButton";
import { addRoiRecistEvaluationThunk } from "../../actions/actionCreators/evaluations/rois";
import { useDispatch } from "react-redux";

const RecistSegmentOptions = ({ roiId, lesionId, seriesId, measurements }) => {
  const dispatch = useDispatch();

  const onNotMeasurableClick = () => {
    let roiRecistEvaluation = {
      roiId,
      LAD: null,
      SAD: null,
      volume: null,
      lesionId,
      seriesId,
      visibility: "NONMEASURABLE",
    };
    dispatch(addRoiRecistEvaluationThunk(roiRecistEvaluation));
  };

  const onSaveClicked = () => {
    let roiRecistEvaluation = {
      roiId,
      LAD: measurements[0],
      SAD: measurements[1],
      volume: null,
      lesionId,
      seriesId,
    };
    dispatch(addRoiRecistEvaluationThunk(roiRecistEvaluation));
  };

  const disableSave = !measurements;
  return (
    <>
      <TableRow>
        <MeasurementCellWrapper $buttonPadding align="center">
          <StyledButton variant="outlined" onClick={onNotMeasurableClick}>
            Non-Measurable
          </StyledButton>
        </MeasurementCellWrapper>
      </TableRow>
      <TableRow>
        <MeasurementCellWrapper $buttonPadding>
          <StyledButton
            $withColor
            onClick={onSaveClicked}
            disabled={disableSave}
          >
            Save
          </StyledButton>
        </MeasurementCellWrapper>
      </TableRow>
    </>
  );
};

export default RecistSegmentOptions;
