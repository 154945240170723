import React from "react";

const useLocalState = (defaultValue: boolean, key: string) => {
  const [acknowledge, setAcknowledge] = React.useState(() => {
    const value = window.localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(acknowledge));
  }, [key, acknowledge]);
  return [acknowledge, setAcknowledge];
};

export default useLocalState;
