import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { queueAllViewersWindowLevelPreset } from "../../actions/actionCreators/annotationPage/tools";
import Menu from "@material-ui/core/Menu";
import Brightness6Icon from "@material-ui/icons/Brightness6";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

function WindowLevelPresetSelector({
  disabled,
  windowLevelPresets,
  queueAllViewersWindowLevel,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectWindowLevelPreset = (preset) => {
    queueAllViewersWindowLevel(preset);
    closeMenu();
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  let getPresetOptions = () => {
    let options = windowLevelPresets.map((preset) => {
      return (
        <MenuItem
          key={preset.name}
          onClick={() => handleSelectWindowLevelPreset(preset)}
        >
          {preset.name}
        </MenuItem>
      );
    });
    return options;
  };

  return (
    <>
      <Brightness6Icon fontSize="small" />
      <KeyboardArrowDownIcon
        fontSize="small"
        disabled={disabled}
        onClick={handleClickOptions}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {getPresetOptions()}
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  windowLevelPresets: state.config.windowLevelPresets,
});

const mapDispatchToProps = (dispatch) => ({
  queueAllViewersWindowLevel: (windowLevel) =>
    dispatch(queueAllViewersWindowLevelPreset(windowLevel)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WindowLevelPresetSelector);
