import { gql } from "@apollo/client/core";
import { UserMasksType } from "../types/UserMasksType";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { USER_MASK_FRAGMENT } from "../fragments/UserMaskFragment";
import { UserMaskFragmentType } from "../fragments/UserMaskFragmentType";
import { parseUserMask } from "../parsers/parseUserMask";

const QUERY = gql`
  query GetUserMasks($case_id: Int!) {
    userMasks: user_mask(
      where: { roi: { lesion: { case_id: { _eq: $case_id } } } }
    ) {
      ...UserMask
    }
  }
  ${USER_MASK_FRAGMENT}
`;

type Input = {
  caseId: number;
};

type Data = {
  userMasks: UserMaskFragmentType[];
};

export async function getUserMasks({
  client,
  ...input
}: ClientProps & Input): Promise<UserMasksType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: ({ userMasks }: Data) => userMasks.map(parseUserMask),
  });
}
