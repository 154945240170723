import { gql } from "@apollo/client/core";
import { stringify } from "flatted";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertRoiMeasurement(
    $roi_id: Int!
    $instance_id: Int!
    $created_by: Int!
    $tool_data: jsonb!
  ) {
    insert_roi_measurement_one(
      object: {
        roi_id: $roi_id
        instance_id: $instance_id
        tool_data: $tool_data
        created_by: $created_by
      }
    ) {
      roi_id
    }
  }
`;

type Input = {
  roiId: number;
  instanceId: number;
  createdBy: number;
  toolData: Record<string, unknown>;
};

export async function insertRoiMeasurement({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      instanceId: instance_id,
      createdBy: created_by,
      toolData,
    }) => {
      const tool_data = JSON.parse(stringify(toolData));
      return {
        roi_id,
        instance_id,
        created_by,
        tool_data,
      };
    },
    parseData: () => undefined,
  });
}
