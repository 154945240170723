import React from "react";
import styled from "styled-components";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

export const StyledIconButton = styled(IconButton)`
  margin-right: 6px;
`;

export const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  color: #c7c4c1;
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)`
  color: white;
`;

export const StyledMutedVisibilityIcon = styled(VisibilityIcon)`
  color: #c7c4c1;
`;

function AlteredVisibilityIcon({ isVisible }) {
  if (isVisible === false) return <StyledVisibilityOffIcon />;
  else if (isVisible === true) return <StyledVisibilityIcon />;
  else return <StyledMutedVisibilityIcon />;
}

export default function RenderVisibilityIcon({ isVisible, onClick }) {
  return (
    <StyledIconButton
      edge="start"
      size="small"
      disabled={false}
      onClick={onClick}
    >
      <AlteredVisibilityIcon isVisible={isVisible} />
    </StyledIconButton>
  );
}
