import Collapse from "@material-ui/core/Collapse";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { COMPLETED, lesionTypes } from "shared-api";
import { updateBiopsyDetailsLesionIdThunk } from "../../actions/actionCreators/annotationPage/biopsyDetails/biopsyDetails";
import {
  setLesionVisible,
  updateLesionStatus,
} from "../../actions/actionCreators/annotationPage/lesions";
import { setSelectedLesion } from "../../actions/actionCreators/annotationPage/thunks";
import {
  ANNOTATIONS_CREATE,
  ANNOTATIONS_DELETE,
  BIOPSY_LINKING,
} from "../../auth0/AuthRules";
import { biopsyDetailsSelector } from "../../store/annotationPage/biopsyDetails/selectors";
import { seriesSelector } from "../../store/annotationPage/series/selectors";
import { formatSeriesLabel } from "../../utils/formatSeriesLabel";
import BiopsyAssignmentDialog, {
  ASSIGN,
  CHANGE_ASSIGNMENT,
  UNASSIGN,
} from "../Biopsy/BiopsyAssignmentDialog";
import Can from "../Can";
import OkTextDialog from "../Dialogs/Base/OkTextDialog";
import LesionSeriesListItem from "./LesionSeriesListItem";
import BiopsyIcon from "../Biopsy/BiopsyIcon";
import { canRoleUseComponent } from "../../actions/actionCreators/app";
import LesionsCheckDialogs from "./LesionsCheckDialogs";
import DeleteLesionDialog from "./DeleteLesionDialog";
import RenderVisibilityIcon from "./RenderVisibilityIcon";
import { JumpToLesionButton } from "./JumpToLesionButton";

function getDetectedRoiSeries(rois, series) {
  return rois
    .filter((roi) => roi.ellipse.slice)
    .map((roi) => {
      let roiSeries = series.find((s) => s.id === roi.seriesId);
      if (!roiSeries) {
        throw new Error();
      }
      return {
        ...roi,
        series: roiSeries,
      };
    });
}

function sortRoiSeriesByDateDescending(roiSeries) {
  let orderedRoiSeries = [...roiSeries];
  orderedRoiSeries.sort((a, b) => {
    const seriesDateA = a.series.seriesDate?.toUpperCase();
    const seriesDateB = b.series.seriesDate?.toUpperCase();
    if (seriesDateA < seriesDateB) {
      return 1;
    }
    if (seriesDateA > seriesDateB) {
      return -1;
    }
    return 0;
  });
  return orderedRoiSeries;
}

function LesionListItem({
  lesion,
  rois,
  series,
  isSelected,
  setSelectedLesion,
  proxyRoiAttributes,
  selectedRoiId,
  biopsyDetailsList,
  updateBiopsyDetailsLesionId,
  updateLesionStatus,
  canRoleUseComponent,
  setLesionVisible,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [pendingDeleteLesion, setPendingDeleteLesion] = React.useState(null);
  const [
    showBiopsyAssignmentDialog,
    setShowBiopsyAssignmentDialog,
  ] = React.useState(false);
  const [
    showUnconfirmedRoisDialog,
    setShowUnconfirmedRoisDialog,
  ] = React.useState(false);
  const [biopsyAssignmentAction, setBiopsyAssignmentAction] = React.useState(
    undefined
  );
  const [lesionsToCheck, setLesionsToCheck] = useState(null);

  let highlightStyle = isSelected
    ? { background: grey[700] }
    : { background: grey[800] };
  highlightStyle.minWidth = "50px";

  let detectedRoiSeries = getDetectedRoiSeries(rois, series);
  let orderedDetectedRoiSeries = sortRoiSeriesByDateDescending(
    detectedRoiSeries
  );

  let numberOfDetections = orderedDetectedRoiSeries.length;
  let hasDetection = numberOfDetections > 0;

  let isLesion = lesion.type === lesionTypes.LESION;
  let showSubItems = isLesion ? hasDetection : false;

  useEffect(() => {
    if (biopsyDetailsList.length <= 0) {
      return;
    }

    //todo always assign the first biopsy result since its the only one presently displayed in the ui.
    let biopsyDetails = biopsyDetailsList[0];
    let { lesionId } = biopsyDetails;

    let unassigned = lesionId === null || lesionId === undefined;
    let assignedToCurrentLesion = lesionId === lesion.id;

    let action;
    if (unassigned) {
      action = ASSIGN;
    } else if (assignedToCurrentLesion) {
      action = UNASSIGN;
    } else {
      action = CHANGE_ASSIGNMENT;
    }

    setBiopsyAssignmentAction(action);
  }, [lesion, biopsyDetailsList]);

  const handleExpandCollapseToggle = () => {
    setExpanded(!expanded);
  };

  const handleDelete = (lesion) => {
    setPendingDeleteLesion(lesion);
  };

  const handleLesionDeleted = () => {
    setPendingDeleteLesion(null);
  };

  const handleDeleteLesionCancelled = () => {
    setPendingDeleteLesion(null);
  };

  const onCompletedStatusToggled = (lesion) => {
    if (lesion.status) {
      updateLesionStatus(lesion, null);
      return;
    }

    const lesions = [
      {
        ...lesion,
        rois,
      },
    ];
    setLesionsToCheck(lesions);
  };

  const onChecksPassed = () => {
    if (!lesionsToCheck) {
      throw new Error("Pending Complete roi is null");
    }

    setLesionsToCheck(null);
    updateLesionStatus(lesion, COMPLETED);
  };

  const onChecksFailed = () => {
    setLesionsToCheck(null);
  };

  const onClickBiopsy = (event) => {
    event.stopPropagation();
    if (canRoleUseComponent(BIOPSY_LINKING)) {
      setShowBiopsyAssignmentDialog(true);
    }
  };

  const onConfirmBiopsyAssignment = (unsure) => {
    //todo always assign the first biopsy result since its the only one presently displayed in the ui.
    let biopsyDetails = biopsyDetailsList[0];

    let biopsyDetailsId = biopsyDetails.id;

    switch (biopsyAssignmentAction) {
      case ASSIGN:
      case CHANGE_ASSIGNMENT:
        updateBiopsyDetailsLesionId(biopsyDetailsId, lesion.id, unsure);
        break;
      case UNASSIGN:
        updateBiopsyDetailsLesionId(biopsyDetailsId, null, true);
        break;
      default:
        throw new Error("biopsyAssignmentAction not supported!");
    }

    setShowBiopsyAssignmentDialog(false);
  };

  let isBiopsyDetailsAvailable = biopsyDetailsList.length > 0;
  let assignedBiopsy = biopsyDetailsList.find(
    (biopsyDetails) => biopsyDetails.lesionId === lesion.id
  );
  let isBiopsyAssigned = assignedBiopsy !== undefined;
  let isBiopsyUnsure = assignedBiopsy ? assignedBiopsy.unsure : false;

  return (
    <div style={highlightStyle}>
      <LesionsCheckDialogs
        lesions={lesionsToCheck}
        onChecksPassed={onChecksPassed}
        onChecksFailed={onChecksFailed}
      />
      <OkTextDialog
        title={"Unconfirmed Rois"}
        onOk={() => setShowUnconfirmedRoisDialog(false)}
        message={
          "There are unconfirmed Rois for this lesion. Please confirm all Rois before completing this lesion."
        }
        open={showUnconfirmedRoisDialog}
      />
      <BiopsyAssignmentDialog
        action={biopsyAssignmentAction}
        open={showBiopsyAssignmentDialog}
        onOk={() => onConfirmBiopsyAssignment(false)}
        onMaybe={() => onConfirmBiopsyAssignment(true)}
        onCancel={() => setShowBiopsyAssignmentDialog(false)}
      />
      <ListItem
        dense
        button
        onClick={() => setSelectedLesion(lesion)}
        selected={isSelected}
      >
        <RenderVisibilityIcon
          isVisible={lesion.visible}
          onClick={() => setLesionVisible(lesion, !lesion.visible)}
        />
        <JumpToLesionButton lesion={lesion} />
        {isLesion && (
          <IconButton
            edge="start"
            size="small"
            style={{ marginRight: "10px" }}
            disabled={!isBiopsyDetailsAvailable}
            onClick={onClickBiopsy}
          >
            <BiopsyIcon
              isAssigned={isBiopsyAssigned}
              isUnsure={isBiopsyUnsure}
            />
          </IconButton>
        )}

        <ListItemText primary={`${lesion.name}`} />

        <Can
          yes={() => (
            <IconButton onClick={() => onCompletedStatusToggled(lesion)}>
              {lesion.status === COMPLETED && <EditIcon fontSize={"small"} />}
              {lesion.status !== COMPLETED && <CheckIcon fontSize={"small"} />}
            </IconButton>
          )}
          action={ANNOTATIONS_CREATE}
        />

        {isLesion && (
          <IconButton
            size="medium"
            disabled={!showSubItems}
            onClick={handleExpandCollapseToggle}
          >
            {expanded ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </IconButton>
        )}

        <ListItemSecondaryAction>
          <Can
            yes={() => (
              <DeleteLesionDialog
                lesion={pendingDeleteLesion}
                onDelete={handleLesionDeleted}
                onCancel={handleDeleteLesionCancelled}
              >
                <IconButton
                  aria-label="delete-lesion"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDelete(lesion);
                  }}
                >
                  <DeleteIcon color="disabled" fontSize="small" />
                </IconButton>
              </DeleteLesionDialog>
            )}
            action={ANNOTATIONS_DELETE}
          />
        </ListItemSecondaryAction>
      </ListItem>

      {showSubItems && (
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          style={{ width: "100%" }}
        >
          <List dense>
            {orderedDetectedRoiSeries.map((roi, index) => {
              let hasPendingRoiAttributesEdits =
                proxyRoiAttributes[roi.id].hasPendingEdits;
              let seriesLabel = formatSeriesLabel(roi.series, 8);
              let isRoiSelected = selectedRoiId === roi.id;

              return (
                <LesionSeriesListItem
                  key={index}
                  label={seriesLabel}
                  lesion={lesion}
                  roi={roi}
                  isSelected={isRoiSelected}
                  isLesionSelected={isSelected}
                  onLesionSelected={() => setSelectedLesion(lesion)}
                  hasPendingRoiEdits={hasPendingRoiAttributesEdits}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
}

LesionListItem.propTypes = {
  lesion: PropTypes.object.isRequired,
  rois: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelectedLesion: PropTypes.func.isRequired,
  proxyRoiAttributes: PropTypes.object.isRequired,
  selectedRoiId: PropTypes.number,
  biopsyDetailsList: PropTypes.array.isRequired,
  updateBiopsyDetailsLesionId: PropTypes.func.isRequired,
  updateLesionStatus: PropTypes.func.isRequired,
  setLesionVisible: PropTypes.func.isRequired,
  canRoleUseComponent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  series: seriesSelector(state),
  proxyRoiAttributes: state.annotationPage.rois.proxyRoiAttributes,
  selectedRoiId: state.annotationPage.rois.selectedRoiId,
  biopsyDetailsList: biopsyDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedLesion: (lesion) => dispatch(setSelectedLesion(lesion.id)),
  updateBiopsyDetailsLesionId: (biopsyDetailsId, lesionId, unsure) =>
    dispatch(
      updateBiopsyDetailsLesionIdThunk(biopsyDetailsId, lesionId, unsure)
    ),
  updateLesionStatus: (lesion, status) =>
    dispatch(updateLesionStatus(lesion.id, status)),
  setLesionVisible: (lesion, visible) =>
    dispatch(setLesionVisible(lesion, visible)),
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LesionListItem);
