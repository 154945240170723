import { LesionEvaluationType } from "../types/LesionEvaluationType";
import { TumourEvaluationFragmentType } from "../fragments/TumourEvaluationFragmentType";

export function parseLesionEvaluation({
  lesionId,
  targetClassification,
  stagingClassification,
  anatomicalStructure: { displayName: location },
  lesion: { name, rois },
}: TumourEvaluationFragmentType): LesionEvaluationType {
  const roiRecistEvaluations = rois.flatMap((roi) => {
    const { seriesId, evaluations } = roi;

    return evaluations.map((evaluation) => {
      const {
        roiId,
        longAxisDiameter: LAD,
        shortAxisDiameter: SAD,
        volume,
        visibility,
      } = evaluation;

      return {
        roiId,
        lesionId,
        LAD,
        SAD,
        volume,
        seriesId,
        visibility,
      };
    });
  });

  return {
    lesionId,
    name,
    targetClassification,
    stagingClassification,
    location,
    roiRecistEvaluations,
  };
}
