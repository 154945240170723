import {
  clearRoiData,
  clearToolSliceData,
  setToolSliceData,
} from "./ToolUtils";
import { ToolTypes } from "shared-api";
import {
  addEllipseToCache,
  clearEllipseCache,
} from "./EllipseToolChangeDetector";

class LesionToolLoader {
  loadMeasurements(measurements, instancesById) {
    clearToolSliceData(ToolTypes.MEASUREMENTTOOL);
    measurements.forEach((measurement) => {
      const { instanceId, toolData } = measurement;
      const { LADLength, SADLength } = toolData;

      let { wadoUrl } = instancesById[instanceId];
      setToolSliceData(ToolTypes.MEASUREMENTTOOL, wadoUrl, [LADLength]);
      setToolSliceData(ToolTypes.MEASUREMENTTOOL, wadoUrl, [SADLength]);
    });
  }

  loadTools(lesions, instancesById) {
    clearEllipseCache();

    //inject too data into cornerstone
    clearToolSliceData(ToolTypes.FREEHANDROITOOL);
    clearToolSliceData(ToolTypes.ELLIPTICALROITOOL);

    lesions.forEach((lesion) => {
      lesion.rois.forEach((roi) => {
        let ellipse = roi.ellipse;
        if (ellipse && ellipse.slice) {
          let toolData = ellipse.slice.toolData;
          let imageId = ellipse.slice.imageId;

          addEllipseToCache(toolData);

          setToolSliceData(ToolTypes.ELLIPTICALROITOOL, imageId, toolData);
        }

        let contours = roi.contours;
        if (contours) {
          contours.forEach((contour) => {
            let { toolData, instanceId } = contour;

            let { wadoUrl } = instancesById[instanceId];
            setToolSliceData(ToolTypes.FREEHANDROITOOL, wadoUrl, toolData);
          });
        }
      });
    });
  }

  removeRoiToolData(roi) {
    let ellipse = roi.ellipse;
    if (ellipse) {
      let roi = ellipse.roi;
      clearRoiData(roi.id, ToolTypes.ELLIPTICALROITOOL);
    }
    const { contoursToolRoi } = roi;
    if (contoursToolRoi) {
      const { id } = contoursToolRoi;
      clearRoiData(id, ToolTypes.FREEHANDROITOOL);
    }
  }

  removeToolData(lesion) {
    lesion.rois.forEach((roi) => {
      this.removeRoiToolData(roi);
    });
  }
}
export default LesionToolLoader;
