import React from "react";
import BlockingDialog from "./Base/BlockingDialog";
import Button from "@material-ui/core/Button";
import { replaceValue } from "../../utils/replaceValue";

const GlobalApplicationErrorDialog = ({ globalApplicationError }) => {
  let onCopyErrorClick = () => {
    let errorCopy = { ...globalApplicationError };
    replaceValue(errorCopy, "Authorization", "<omitted>");
    let errorString = JSON.stringify(errorCopy, null, 2);

    // derived from https://stackoverflow.com/a/52033479/1620721
    // note: this may not work on pre-2018 browsers
    navigator.clipboard.writeText(errorString);
  };

  return (
    <BlockingDialog
      title="Error"
      message="An error was detected in NOTA. Please refresh the page. If this error persists, please contact Altis Labs."
      open={globalApplicationError !== null}
    >
      <Button onClick={onCopyErrorClick}>Copy Error To Clipboard</Button>
    </BlockingDialog>
  );
};

export default GlobalApplicationErrorDialog;
