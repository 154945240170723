import { SubMenuNodeTreeType } from "../../../../../models/SubMenuNodeType";
import { AnatomicalStructureType } from "shared-api";

export type AnatomicalStructureTreeType = SubMenuNodeTreeType<
  AnatomicalStructureType
>;

export function getAnatomicalStructuresTree(
  rawAnatomicalStructures: AnatomicalStructureType[]
): AnatomicalStructureTreeType[] {
  const anatomicalStructures: AnatomicalStructureTreeType[] = [];

  const sortedAnatomicalStructures = [...rawAnatomicalStructures].sort((a, b) =>
    a.structure.localeCompare(b.structure)
  );

  sortedAnatomicalStructures.forEach((rawAnatomicalStructure) => {
    const parentPath = getParentPath(rawAnatomicalStructure);

    const parent = anatomicalStructures.find((anatomicalStructure) =>
      parentPath.includes(anatomicalStructure.path)
    );

    if (parent) {
      addAnatomyToParent(rawAnatomicalStructure, parent);
    } else {
      const anatomicalStructure = getAnatomicalStructure(
        rawAnatomicalStructure
      );
      anatomicalStructures.push(anatomicalStructure);
    }
  });

  return anatomicalStructures;
}

function getAnatomicalStructure(
  anatomicalStructure: AnatomicalStructureType
): AnatomicalStructureTreeType {
  const { structure: path } = anatomicalStructure;
  return {
    ...anatomicalStructure,
    path,
    children: [],
  };
}

function getParentPath(
  rawAnatomicalStructure: AnatomicalStructureType
): string {
  return rawAnatomicalStructure.structure.substr(
    0,
    rawAnatomicalStructure.structure.lastIndexOf(".")
  );
}

function addAnatomyToParent(
  rawAnatomicalStructure: AnatomicalStructureType,
  parent: AnatomicalStructureTreeType
): void {
  const anatomicalStructure = getAnatomicalStructure(rawAnatomicalStructure);

  const { children } = parent;

  const parentPath = getParentPath(rawAnatomicalStructure);

  if (parent.path === parentPath) {
    parent.children.push(anatomicalStructure);
    return;
  }

  let nested = children.find((child) => parentPath.includes(child.path));

  if (!nested) {
    throw new Error(`No parent found for path: ${parentPath}`);
  }

  while (nested.path !== parentPath) {
    nested =
      nested.children[
        nested.children.findIndex((res) =>
          rawAnatomicalStructure.structure.includes(res.path)
        )
      ];
  }

  nested.children.push(anatomicalStructure);
}
