import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LinkIcon from "@material-ui/icons/Link";
import CreateIcon from "@material-ui/icons/Create";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";

export function getIcon(iconName, iconSource, fontSize) {
  const icons = {
    mui: {
      SearchIcon: <SearchIcon fontSize={fontSize} />,
      FormatShapesIcon: <FormatShapesIcon fontSize={fontSize} />,
      AssessmentIcon: <AssessmentIcon fontSize={fontSize} />,
      CreateIcon: <CreateIcon fontSize={fontSize} />,
      Link: <LinkIcon fontSize={fontSize} />,
    },
    fa: { faGlasses: <FontAwesomeIcon icon={faGlasses} /> },
  };

  if (!icons.hasOwnProperty(iconSource)) {
    throw new Error(`Icon source ${iconSource} not supported`);
  }

  const sourceIcons = icons[iconSource];

  if (!sourceIcons.hasOwnProperty(iconName)) {
    throw new Error(`${iconSource} icon ${iconName} not supported`);
  }

  return sourceIcons[iconName];
}
