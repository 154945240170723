import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateStudyNotes } from "../../actions/actionCreators/annotationPage/studyNotes/studyNotes";
import { ANNOTATIONS_CREATE } from "../../auth0/AuthRules";
import { canRoleUseComponent } from "../../actions/actionCreators/app";
import TextFieldDialog from "../Dialogs/TextFieldDialog";

function StudyNotesDialog({
  studyNotes,
  studyId,
  updateStudyNotes,
  show,
  onOk,
  onCancel,
  canRoleUseComponent,
}) {
  const [notes, setNotes] = useState("");

  useEffect(() => {
    let existingNotes = "";
    if (studyNotes) {
      existingNotes = studyNotes.notes ? studyNotes.notes : "";
    }

    setNotes(existingNotes);
  }, [studyNotes]);

  const onTextAccepted = (text) => {
    updateStudyNotes(studyId, text);
    onOk();
  };

  let canUserEditText = canRoleUseComponent(ANNOTATIONS_CREATE);

  return (
    <TextFieldDialog
      text={notes}
      textLabel={"Notes"}
      textPlaceholder={"Add notes related to this study"}
      title={"Study Notes"}
      onTextAccepted={onTextAccepted}
      onCancel={onCancel}
      show={show}
      canEditText={canUserEditText}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateStudyNotes: (studyId, notes) =>
    dispatch(updateStudyNotes(studyId, notes)),
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(null, mapDispatchToProps)(StudyNotesDialog);
