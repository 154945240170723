import { StudyNoteFragmentType } from "../fragments/StudyNoteFragmentType";
import { StudyNotesType } from "../types/StudyNotesType";

export function parseStudyNotes({
  id,
  studyId,
  notes,
  lastModifiedAt,
  lastModifiedByUserId,
}: StudyNoteFragmentType): StudyNotesType {
  return {
    id,
    studyId,
    notes,
    lastModifiedAt,
    lastModifiedByUserId,
  };
}
