export default class BrushToolWrapper {
  constructor(currentImageIdWatcher, brushTool) {
    this.currentImageIdWatcher = currentImageIdWatcher;
    this.onToolDataChangedCallback = null;

    this.tool = brushTool;
    this.tool.setDataUpdateCallback(this.onLabelmapCompleted);
    this.tool.setOnUserMaskCopyHotKeyPressedCallback(
      this.onUserMaskCopyHotKeyPressed
    );
    this.tool.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );
    this.tool.setOnBrushSizeChangedCallback(this.onBrushSizeChanged);
  }

  setToolDataChangedCallback(onToolDataChangedCallback) {
    this.onToolDataChangedCallback = onToolDataChangedCallback;
  }

  setOnUserMaskCopyHotKeyPressedCallback(onUserMaskCopyHotKeyPressedCallback) {
    this.onUserMaskCopyHotKeyPressedCallback = onUserMaskCopyHotKeyPressedCallback;
  }

  setOnBrushSizeChangedCallback(onBrushSizeChangedCallback) {
    this.onBrushSizeChangedCallback = onBrushSizeChangedCallback;
  }

  setOnUserMaskPasteHotKeyPressedCallback(
    onUserMaskPasteHotKeyPressedCallback
  ) {
    this.onUserMaskPasteHotKeyPressedCallback = onUserMaskPasteHotKeyPressedCallback;
  }

  onLabelmapCompleted = async (data) => {
    if (this.onToolDataChangedCallback) {
      let currentImageId = this.currentImageIdWatcher.getCurrentImageId();
      await this.onToolDataChangedCallback(data, currentImageId);
    }
  };

  onUserMaskCopyHotKeyPressed = (data) => {
    if (!this.onUserMaskCopyHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onUserMaskCopyHotKeyPressedCallback(data, currentImageId);
  };

  onUserMaskPasteHotKeyPressed = () => {
    if (!this.onUserMaskPasteHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onUserMaskPasteHotKeyPressedCallback(currentImageId);
  };

  onBrushSizeChanged = (newBrushSize) => {
    if (!this.onBrushSizeChangedCallback) {
      return;
    }

    this.onBrushSizeChangedCallback(newBrushSize);
  };

  setDisabled() {
    this.tool.setDisabled();
  }

  setActive() {
    this.tool.setActive();
  }

  setBrushRadiusValues(brushRadiusValues) {
    this.tool.setBrushRadiusValues(brushRadiusValues);
  }
}
