import pako from "pako";
import { UserMaskVersionType } from "../types/UserMaskVersionType";
import {
  USER_MASK_VERSION_1,
  USER_MASK_VERSION_2,
} from "../../../../types/userMaskVersion";

function bufferToHex(buffer: Uint8Array) {
  // noinspection TypeScriptValidateJSTypes
  return Array.prototype.map
    .call(buffer, (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

export function getMaskHexString(
  toolData: ArrayBuffer,
  version: UserMaskVersionType
): string {
  let buffer: Uint8Array;

  switch (version) {
    case USER_MASK_VERSION_1: {
      buffer = new Uint8Array(Object.values(toolData));
      break;
    }
    case USER_MASK_VERSION_2: {
      buffer = pako.deflate(new Uint8Array(Object.values(toolData)));
      break;
    }
    default: {
      throw new Error(`Unsupported mask version: ${version}`);
    }
  }

  const hex = bufferToHex(buffer);

  return `\\x${hex}`;
}
