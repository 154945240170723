let contextMenuStyle = {
  icon: {
    minWidth: "30px",
  },
  text: {
    textAlign: "center",
    margin: "auto",
  },
};

export default contextMenuStyle;
