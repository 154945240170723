import {
  ADD_TUMOUR_TO_DIAGNOSIS,
  CLEAR_DIAGNOSES,
  INSERT_DIAGNOSIS,
  REMOVE_TUMOUR_FROM_DIAGNOSIS,
  SET_DIAGNOSES,
} from "../../types/diagnosis/diagnoses";
import { DiagnosisType } from "../../../hooks/DiagnosisType";
import { TumourType } from "../../../hooks/TumourType";

export function setDiagnoses(diagnoses: DiagnosisType[]) {
  return { type: SET_DIAGNOSES, payload: diagnoses };
}

export function insertDiagnosis(diagnosis: DiagnosisType) {
  return { type: INSERT_DIAGNOSIS, payload: diagnosis };
}

export function clearDiagnosis() {
  return { type: CLEAR_DIAGNOSES };
}

export function removeTumourFromDiagnosis(tumourId: number) {
  return {
    type: REMOVE_TUMOUR_FROM_DIAGNOSIS,
    payload: { tumourId },
  };
}

export function addTumourToDiagnosis(diagnosisId: number, tumourId: number) {
  return {
    type: ADD_TUMOUR_TO_DIAGNOSIS,
    payload: { diagnosisId, tumourId },
  };
}

export function getLungCancerDiagnosis(followUpId: number): DiagnosisType {
  return {
    id: undefined,
    type: "Lung Carcinoma",
    code: 448993007,
    primaryLocation: "Lung",
    diagnosisDate: new Date(),
    followUpId,
    tumours: [],
  };
}

export function getDiagnosis(followUpId: number, state: any): DiagnosisType {
  const {
    annotationPage: {
      recists: {
        diagnoses: { allIds, byId },
      },
    },
  } = state;

  const diagnosis = allIds
    .map((id: number) => byId[id])
    .find((d: DiagnosisType) => d.followUpId === followUpId);

  if (!diagnosis) {
    throw new Error(`diagnosis not found for followUpId: ${followUpId}`);
  }

  return diagnosis;
}

export function getTumour(roiId: number, state: any): TumourType | null {
  const {
    annotationPage: {
      recists: {
        tumours: { byId, allIds },
      },
    },
  } = state;

  const tumours = allIds
    .map((id: number) => byId[id])
    .filter((tumour: TumourType) => tumour.roiIds.includes(roiId));

  return tumours.length > 0 ? tumours[0] : null;
}
