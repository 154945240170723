import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { ToolTypes } from "shared-api";
import EllipseToolChangeDetector from "./EllipseToolChangeDetector";
import ellipseStyles from "./EllipseToolStyle";

export default class EllipseTool {
  constructor(cornerstoneElement) {
    this.onDataUpdate = null;
    this.onEllipseCreatedCallback = null;

    const tool = cornerstoneTools.getToolForElement(
      cornerstoneElement,
      ToolTypes.ELLIPTICALROITOOL
    );

    if (!tool) {
      throw new Error("Cornerstone ellipse tool is null");
    }

    tool.setStyle(ellipseStyles);
    tool.setDataInvalidatedCallback(this.onToolDataInvalidated);

    this.tool = tool;
    this.cornerstoneElement = cornerstoneElement;
    this.ellipseToolChangeDetector = new EllipseToolChangeDetector(
      this.onEllipseCreated,
      this.onEllipseChanged
    );
  }

  onToolDataInvalidated = (data) => {
    this.ellipseToolChangeDetector.testEllipseData(data);
  };

  setCurrentRoi(roi) {
    this.tool.setCurrentRoi(roi);
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(
      this.cornerstoneElement,
      ToolTypes.ELLIPTICALROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  }

  setPassive = () => {
    cornerstoneTools.setToolPassiveForElement(
      this.cornerstoneElement,
      ToolTypes.ELLIPTICALROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  };

  setEnabled() {
    cornerstoneTools.setToolEnabledForElement(
      this.cornerstoneElement,
      ToolTypes.ELLIPTICALROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  }

  setOnEllipseChangedCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }

  onEllipseCreated = async (toolData) => {
    await this.onEllipseChanged(toolData);
  };

  onEllipseChanged = async (toolData) => {
    if (!this.onDataUpdate) {
      return;
    }

    await this.onDataUpdate(toolData);
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };
}
