import { TumourType } from "../../hooks/TumourType";
import { TumoursStateType } from "./tumours";
import { normalize } from "normalizr";
import { tumourSchema } from "./normalizeDiagnoses";

export function normalizeTumours(tumours: TumourType[]): TumoursStateType {
  const normalizedData = normalize<TumourType>(tumours, [tumourSchema]);

  const {
    result: allIds,
    entities: { tumours: byId },
  } = normalizedData;

  return {
    allIds,
    byId: byId ?? {},
  };
}
