import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import LesionList from "../LesionList/LesionList";
import BiopsyDetails from "../Biopsy/BiopsyDetails";
import MaskToggle from "../MaskToggle";

const useStyles = makeStyles(() => ({
  rightPanel: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
  },
}));

const RightSidebar = () => {
  const classes = useStyles();

  return (
    <div style={{ width: "24%", overflowX: "hidden" }}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        className={classes.rightPanel}
      >
        <Grid
          item
          style={{
            padding: "6px",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              marginTop: "7px",
            }}
          >
            <MaskToggle />
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "50px",
            overflowY: "auto",
            padding: "2px",
            height: "100%",
          }}
        >
          <Paper
            elevation={16}
            style={{
              padding: "3px",
            }}
          >
            <LesionList />
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={16}
            style={{
              marginTop: "3px",
              padding: "0.5px",
            }}
          >
            <BiopsyDetails />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default RightSidebar;
