import { MaskFragmentType } from "../fragments/MaskFragmentType";
import { MaskType } from "../types/MaskType";
import { parseMaskHexString } from "../utilities/parseMaskHexString";
import { UserMaskVersionType } from "../types/UserMaskVersionType";

export function parseMaskFragment({
  id,
  instanceId,
  toolData: toolDataHexString,
  shape,
  version,
}: MaskFragmentType): MaskType {
  const toolData = parseMaskHexString(
    toolDataHexString,
    version as UserMaskVersionType
  );
  return {
    id,
    instanceId,
    toolData,
    shape: {
      width: shape[1],
      height: shape[0],
    },
  };
}
