import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { StudyType } from "../types/StudyType";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { STUDY_FRAGMENT } from "../fragments/StudyFragment";
import { StudyFragmentType } from "../fragments/StudyFragmentType";
import { parseStudy } from "../parsers/parseStudy";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";
import { BASE_WADO_URL_FRAGMENT } from "../fragments/BaseWadoUrlFragment";
import { BaseWadoUrlFragmentType } from "../fragments/BaseWadoUrlFragmentType";
import { parseBaseWadoUrl } from "../parsers/parseBaseWadoUrl";

const QUERY = gql`
  query GetStudiesByCaseId($case_id: Int!) {
    studies: study(
      where: { series: { series_cases: { case_id: { _eq: $case_id } } } }
    ) {
      ...Study
    }
    ...BaseWadoUrl
  }
  ${STUDY_FRAGMENT}
  ${BASE_WADO_URL_FRAGMENT}
`;

type Input = {
  caseId: number;
};

type Variables = {
  case_id: number;
};

type Data = {
  studies: StudyFragmentType[];
  wadoUrls: BaseWadoUrlFragmentType;
};

type Output = StudyType[];

export function useStudiesByCaseId(
  caseId: number
): useParsedQueryReturnType<number, Output, Variables> {
  return useParsedQuery<number, Output, Data, Variables>({
    query: QUERY,
    input: caseId,
    parseVariables: (case_id) => ({ case_id }),
    parseData: ({ studies, wadoUrls }: Data) => {
      const baseWadoUrl = parseBaseWadoUrl(wadoUrls);
      return studies.map((study) => parseStudy(study, baseWadoUrl));
    },
  });
}

export async function getStudiesByCaseId({
  client,
  ...input
}: ClientProps & Input): Promise<Output> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: async ({ studies, wadoUrls }: Data) => {
      const baseWadoUrl = parseBaseWadoUrl(wadoUrls);
      return studies.map((study) => parseStudy(study, baseWadoUrl));
    },
  });
}
