import React, { useEffect, useState } from "react";
import OkCancelDialog from "../Dialogs/Base/OkCancelDialog";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
  },
}));

function TextFieldDialog({
  text,
  textLabel,
  textPlaceholder,
  title,
  onTextAccepted,
  show,
  onCancel,
  canEditText,
  allowEmptyText = true,
}) {
  const classes = useStyles();

  const [pendingTextContent, setPendingTextContent] = useState("");
  const [textContent, setTextContent] = useState("");

  useEffect(() => {
    let existingText = "";
    if (text) {
      existingText = text ?? "";
    }

    setTextContent(existingText);
    setPendingTextContent(existingText);
  }, [text]);

  const handleTextChanged = (event) => {
    setPendingTextContent(event.target.value);
  };

  const handleSubmitClicked = () => {
    setTextContent(pendingTextContent);
    onTextAccepted(pendingTextContent);
  };

  const handleDiscardChanges = () => {
    setPendingTextContent(textContent);
    onCancel();
  };

  let canSubmit = allowEmptyText || pendingTextContent !== "";

  return (
    <OkCancelDialog
      title={title}
      open={show}
      onOk={handleSubmitClicked}
      onCancel={handleDiscardChanges}
      cancelText={"Discard Changes"}
      okText={"Submit"}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      disableOk={!canEditText || !canSubmit}
    >
      <div className={classes.root}>
        <FormControl>
          <TextField
            label={textLabel}
            placeholder={textPlaceholder}
            multiline
            rows="5"
            variant="filled"
            value={pendingTextContent}
            onChange={handleTextChanged}
          />
        </FormControl>
      </div>
    </OkCancelDialog>
  );
}

export default TextFieldDialog;
