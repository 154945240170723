import React, { useState } from "react";
import PropTypes from "prop-types";
import OkCancelDialog from "./Dialogs/Base/OkCancelDialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

FlagCaseDialog.propTypes = {
  caseId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
  },
  info: {
    marginBottom: theme.spacing(3),
  },
  select: {
    marginBottom: theme.spacing(3),
  },
}));

export const POOR_IMAGE_QUALITY = "POOR_IMAGE_QUALITY";
export const SLICES_MISSING = "SLICES_MISSING";
export const SLICES_OUT_OF_ORDER = "SLICES_OUT_OF_ORDER";
export const OTHER = "OTHER";

function FlagCaseDialog({ caseId, show, onOk, onCancel }) {
  const classes = useStyles();

  const [reason, setReason] = useState("");
  const [details, setDetails] = useState(undefined);

  const [detailsError, setDetailsError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  let onSubmitFlag = () => {
    if (!reason) {
      setReasonError(true);
      return;
    }
    if (reason === OTHER && !details) {
      setDetailsError(true);
      return;
    }

    let report = { reason, details };
    onOk(report);

    clearState();
  };

  let onClickCancel = () => {
    onCancel();

    clearState();
  };

  let clearState = () => {
    setReasonError(false);
    setDetailsError(false);
    setReason("");
    setDetails(undefined);
  };

  const handleReasonChange = (event) => {
    setReasonError(false);
    setReason(event.target.value);
  };

  const handleDetailsChanged = (event) => {
    setDetailsError(false);
    setDetails(event.target.value);
  };

  return (
    <OkCancelDialog
      title={"Flag Case"}
      open={show}
      onOk={onSubmitFlag}
      onCancel={onClickCancel}
    >
      <div className={classes.root}>
        <div className={classes.info}>
          <Typography variant="subtitle1">{"Case: " + caseId}</Typography>
          <Typography variant="subtitle2">
            Flagging a case will remove it from your worklist. Pressing OK will
            flag the case and return you to your worklist.
          </Typography>
        </div>
        <FormControl>
          <InputLabel
            id="flag-case-reason-select-label"
            style={{ paddingLeft: "10px" }}
          >
            Reason
          </InputLabel>
          <Select
            className={classes.select}
            error={reasonError}
            labelId="flag-case-reason-select-label"
            id="flag-case-reason-select"
            value={reason}
            variant="filled"
            autoWidth
            onChange={handleReasonChange}
          >
            <MenuItem value={POOR_IMAGE_QUALITY}>
              Images are poor quality
            </MenuItem>
            <MenuItem value={SLICES_MISSING}>Slices are missing</MenuItem>
            <MenuItem value={SLICES_OUT_OF_ORDER}>
              Slices are out of order
            </MenuItem>
            <MenuItem value={OTHER}>Other (please specify)</MenuItem>
          </Select>
          <TextField
            error={detailsError}
            helperText={detailsError ? "Please include details" : ""}
            label="Details"
            placeholder="Describe why this case is being flagged"
            multiline
            rows="5"
            variant="filled"
            value={details}
            onChange={handleDetailsChanged}
          />
        </FormControl>
      </div>
    </OkCancelDialog>
  );
}

export default FlagCaseDialog;
