export function getDate(): string {
  const newDate = new Date();

  const currentDate =
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate();

  return currentDate;
}
