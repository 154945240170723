import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicroscope } from "@fortawesome/free-solid-svg-icons";
import { getBiopsyDetailColor } from "./getBiopsyDetailColor";
import Badge from "@material-ui/core/Badge";

const BiopsyIcon = ({ isAssigned, isUnsure }) => {
  let biopsyColor = getBiopsyDetailColor(isAssigned);

  let icon = <FontAwesomeIcon icon={faMicroscope} color={biopsyColor} />;

  if (isAssigned && isUnsure) {
    return (
      <Badge variant="dot" color="primary">
        {icon}
      </Badge>
    );
  } else {
    return icon;
  }
};

export default BiopsyIcon;
