import { TumourType } from "../../hooks/TumourType";
import {
  CLEAR_TUMOURS,
  INSERT_TUMOUR,
  REMOVE_TUMOUR,
  SET_TUMOURS,
  TumourActionType,
} from "../../actions/types/diagnosis/tumours";
import produce from "immer";
import { normalizeTumours } from "./normalizeTumours";

export type TumoursStateType = {
  allIds: number[];
  byId: Record<number, TumourType>;
};

const defaultState: TumoursStateType = { allIds: [], byId: {} };

export const tumours = (
  state = defaultState,
  action: { type: TumourActionType; payload: any }
): TumoursStateType => {
  switch (action.type) {
    case SET_TUMOURS: {
      const tumours = action.payload;
      const { allIds, byId } = normalizeTumours(tumours);
      return produce<TumoursStateType>(state, (draftState) => {
        draftState.allIds = allIds;
        draftState.byId = byId;
      });
    }
    case CLEAR_TUMOURS: {
      return produce<TumoursStateType>(state, (draftState) => {
        draftState.allIds = [];
        draftState.byId = {};
      });
    }
    case INSERT_TUMOUR: {
      const tumour = action.payload;
      const { id } = tumour;
      return produce<TumoursStateType>(state, (draftState) => {
        const existingTumour = draftState.byId[id];
        if (!existingTumour) {
          draftState.allIds.push(id);
        }
        draftState.byId[id] = tumour;
      });
    }
    case REMOVE_TUMOUR: {
      const tumourId = action.payload;
      return produce<TumoursStateType>(state, (draftState) => {
        draftState.allIds = draftState.allIds.filter((id) => id !== tumourId);
        delete draftState.byId[tumourId];
      });
    }
    default:
      return state;
  }
};
