import getMeasurements from "../getMeasurements";

export default function getMeasurementsNewRecistItem(
  measureToolData,
  viewerRois
) {
  if (!viewerRois[0]) {
    return;
  }

  const roiId = viewerRois[0].id;
  return getMeasurements(measureToolData[roiId]);
}
