import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation UpdateRoiRecistEvaluation(
    $long_axis_diameter: numeric!
    $short_axis_diameter: numeric!
    $volume: numeric
    $roi_id: Int!
  ) {
    update_roi_recist_evaluations(
      _set: {
        long_axis_diameter: $long_axis_diameter
        short_axis_diameter: $short_axis_diameter
        volume: $volume
      }
      where: { roi_id: { _eq: $roi_id } }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  roiId: number;
  shortAxisDiameter: number;
  longAxisDiameter: number;
  volume: number | null;
};

export async function updateRoiRecistEvaluation({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      shortAxisDiameter: short_axis_diameter,
      longAxisDiameter: long_axis_diameter,
      volume,
    }) => ({
      roi_id,
      short_axis_diameter,
      long_axis_diameter,
      volume,
    }),
    parseData: () => undefined,
  });
}
