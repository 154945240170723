import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";

const QUERY = gql`
  query GetFollowUpOrder($series_ids: [Int!]) {
    follow_up(
      where: {
        study_follow_up_maps: {
          study: { series: { series_id: { _in: $series_ids } } }
        }
      }
    ) {
      id
      follow_up_order
      study_follow_up_maps {
        study {
          series(where: { series_id: { _in: $series_ids } }) {
            series_id
          }
        }
      }
    }
  }
`;

type Input = {
  seriesIds: number[];
};

type Data = {
  follow_up: {
    id: number;
    follow_up_order: number;
    study_follow_up_maps: {
      study: {
        series: {
          series_id: number;
        }[];
      };
    }[];
  }[];
};

type Output = {
  id: number;
  order: number;
  seriesId: number;
};

export async function getFollowUpOrderForSeriesIds({
  client,
  ...input
}: ClientProps & Input): Promise<Output[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ seriesIds: series_ids }) => ({ series_ids }),
    parseData: ({ follow_up }: Data): Output[] => {
      const followUps: Output[] = [];

      for (const followUp of follow_up) {
        const {
          id,
          follow_up_order: order,
          study_follow_up_maps: studyFollowUps,
        } = followUp;
        const seriesIds = [
          ...new Set(
            studyFollowUps
              .map((studyFollowUp) => studyFollowUp.study)
              .flatMap((study) => study.series.map((s) => s.series_id))
          ),
        ];

        for (const seriesId of seriesIds) {
          if (
            !followUps.some(
              (fu) => fu.seriesId === seriesId && fu.order === order
            )
          ) {
            followUps.push({ id, seriesId, order });
          }
        }
      }
      return followUps;
    },
  });
}
