export const QA = "QA";
export const QA_CONTOUR = "QA_CONTOUR";
export const QA_DETECT = "QA_DETECT";

export const CONTOUR = "CONTOUR";
export const CONTOUR_ORGAN = "CONTOUR_ORGAN";
export const CONTOUR_ABDOMEN = "CONTOUR_ABDOMEN";
export const CONTOUR_LUNG = "CONTOUR_LUNG";
export const CONTOUR_MEDIASTINUM = "CONTOUR_MEDIASTINUM";

export const VIEWER = "VIEWER";
export const TRAINEE = "TRAINEE";
export const RECIST_1_1 = "RECIST_1_1";
export const ADMIN = "ADMIN";
export const BIOPSY_LINKING = "BIOPSY_LINKING";
export const DETECT = "DETECT";
export const CONTOUR_LIVER = "CONTOUR_LIVER";
export const CONTOUR_KIDNEY = "CONTOUR_KIDNEY";
