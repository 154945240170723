import { getToolSliceData, setToolSliceData } from "../ToolUtils";
import { ToolTypes } from "shared-api";

export function addContoursToSlice(toolData, imageId) {
  let { roi } = toolData[0];
  let sliceToolData = getToolSliceData(roi, imageId, ToolTypes.FREEHANDROITOOL);
  toolData.forEach((contour) => sliceToolData.push(contour));
  setToolSliceData(ToolTypes.FREEHANDROITOOL, imageId, sliceToolData);
  return sliceToolData;
}
