import { useState, useEffect } from "react";
import { UrlStateViewer } from "../models/UrlState";
import { setSelectedAssignedCase } from "../actions/actionCreators/annotationPage/case";
import { setSelectedLesionId } from "../actions/actionCreators/annotationPage/lesions";
import {
  setViewerSeries,
  setViewerImageIdIndex,
  addViewer,
} from "../actions/actionCreators/annotationPage/viewers";
import { useHistory, useLocation } from "react-router";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

const useLoadUrlState = (): UrlStateViewer | null => {
  const [assignedCaseFromUrl, setAssignedCaseFromUrl] = useState<Record<
    string,
    unknown
  > | null>(null);
  const [lesionIdFromUrl, setLesionIdFromUrl] = useState<number | null>(null);
  const [viewersFromUrl, setViewersFromUrl] = useState<UrlStateViewer | null>(
    null
  );
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const viewers = useSelector(
    (state: RootStateOrAny) => state.annotationPage.viewers
  );
  const lesions = useSelector(
    (state: RootStateOrAny) => state.annotationPage.lesions
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedUrlState = searchParams.get("state");

    if (encodedUrlState !== null) {
      const { selectedCaseAssignment, selectedLesionId, viewers } = JSON.parse(
        decodeURIComponent(encodedUrlState)
      );
      setLesionIdFromUrl(selectedLesionId);
      setAssignedCaseFromUrl(selectedCaseAssignment);
      setViewersFromUrl(viewers);
      history.replace({
        search: undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (
      !assignedCaseFromUrl ||
      loaded ||
      viewers.allIds.length == 0 ||
      (lesionIdFromUrl && !lesions.selectedLesionId)
    ) {
      return;
    }

    dispatch(setSelectedAssignedCase(assignedCaseFromUrl));

    if (lesionIdFromUrl) {
      dispatch(setSelectedLesionId(lesionIdFromUrl));
    }

    if (viewersFromUrl) {
      dispatch(setViewerSeries(0, viewersFromUrl[0].seriesId));
      dispatch(setViewerImageIdIndex(0, viewersFromUrl[0].imageIdIndex));

      for (let id = 1; id < viewersFromUrl.length; id++) {
        const { imageIdIndex, seriesId } = viewersFromUrl[id];
        dispatch(
          addViewer({
            id,
            imageIdIndex,
            seriesId,
          })
        );
      }

      setLoaded(true);
    }
  }, [viewers, lesions]);

  return viewersFromUrl;
};

export default useLoadUrlState;
