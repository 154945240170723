import React, { useEffect, useState } from "react";
import OkCancelTextDialog from "../Dialogs/Base/OkCancelTextDialog";
import Button from "@material-ui/core/Button";

export const UNASSIGN = "UNASSIGN";
export const ASSIGN = "ASSIGN";
export const CHANGE_ASSIGNMENT = "CHANGE_ASSIGNMENT";

let dialogContents = {
  [UNASSIGN]: {
    title: "Unassign Biopsy Results from Lesion",
    message:
      "Please confirm that you would like to unassign the biopsy results from this lesion.",
    showMaybe: false,
  },
  [ASSIGN]: {
    title: "Link to Histology",
    message: "Is this the primary tumor that was biopsied?",
    showMaybe: true,
  },
  [CHANGE_ASSIGNMENT]: {
    title: "Re-assign Biopsy Results to Lesion",
    message:
      "Please confirm that you would like to re-assign the biopsy. Is this the primary tumor that was biopsied?",
    showMaybe: true,
  },
};

function BiopsyAssignmentDialog({ action, open, onOk, onCancel, onMaybe }) {
  let [title, setTitle] = useState("");
  let [message, setMessage] = useState("");
  let [showMaybe, setShowMaybe] = useState("");

  useEffect(() => {
    if (!dialogContents.hasOwnProperty(action)) {
      return;
    }

    let { message, title, showMaybe } = dialogContents[action];
    setTitle(title);
    setMessage(message);
    setShowMaybe(showMaybe);
  }, [action]);

  let getMaybeButton = (showMaybe) => {
    if (!showMaybe) {
      return null;
    }
    return (
      <Button color="primary" onClick={onMaybe}>
        Probably
      </Button>
    );
  };

  return (
    <OkCancelTextDialog
      title={title}
      message={message}
      open={open}
      okText={"Yes"}
      cancelText={"No"}
      onOk={onOk}
      onCancel={onCancel}
      otherActions={getMaybeButton(showMaybe)}
    />
  );
}

export default BiopsyAssignmentDialog;
