import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertRoiRecistEvaluation(
    $roi_id: Int!
    $short_axis_diameter: numeric
    $volume: numeric
    $long_axis_diameter: numeric
    $visibility: String
  ) {
    insert_roi_recist_evaluations_one(
      object: {
        roi_id: $roi_id
        short_axis_diameter: $short_axis_diameter
        volume: $volume
        long_axis_diameter: $long_axis_diameter
        visibility: $visibility
      }
      on_conflict: {
        constraint: roi_evaluations_pkey
        update_columns: [long_axis_diameter, short_axis_diameter, volume]
      }
    ) {
      roi_id
    }
  }
`;

type Input = {
  roiId: number;
  shortAxisDiameter: number | null;
  longAxisDiameter: number | null;
  volume: number | null;
  visibility: string | null;
};

export async function insertRoiRecistEvaluation({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      shortAxisDiameter: short_axis_diameter,
      longAxisDiameter: long_axis_diameter,
      volume,
      visibility,
    }) => ({
      roi_id,
      short_axis_diameter,
      long_axis_diameter,
      volume,
      visibility,
    }),
    parseData: () => undefined,
  });
}
