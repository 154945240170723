import React, { useEffect } from "react";
import OkCancelTextDialog from "../Dialogs/Base/OkCancelTextDialog";
import { deleteLesion } from "../../actions/actionCreators/annotationPage/thunks";
import { connect } from "react-redux";

const DeleteLesionDialog = ({
  lesion,
  onCancel,
  onDelete,
  children,
  deleteLesion,
}) => {
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setShow(lesion != null);
  }, [lesion]);

  const handleConfirmDelete = () => {
    if (!lesion) {
      throw new Error("No lesion in handleConfirmDeleteLesion");
    }
    deleteLesion(lesion);
    onDelete();
  };

  return (
    <>
      {children}
      <OkCancelTextDialog
        title={"Delete Label"}
        message={
          "Deleting a label will also remove all related annotations. Are you sure you want to delete this label?"
        }
        open={show}
        onOk={handleConfirmDelete}
        onCancel={onCancel}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteLesion: (lesion) => dispatch(deleteLesion(lesion)),
});

export default connect(null, mapDispatchToProps)(DeleteLesionDialog);
