import Labelmap from "./Labelmap";

export default class LabelmapSeries {
  constructor(seriesId, labelmapWrapper) {
    this.seriesId = seriesId;
    this.usedLabelmaps = [];
    this.maskMap = new Map();
    this.labelmapWrapper = labelmapWrapper;
  }

  addLabelmap() {
    this.usedLabelmaps.push(new Labelmap());
    let labelmapIndex = this.usedLabelmaps.length - 1;
    this.labelmapWrapper.setColorLUT(labelmapIndex);
    return labelmapIndex;
  }

  findFreeLabelmap() {
    let usedLabelmapsLength = this.usedLabelmaps.length;
    if (usedLabelmapsLength === 0) {
      let labelmapIndex = this.addLabelmap();
      return labelmapIndex;
    } else {
      let foundLabelmapIndex = -1;
      for (let i = 0; i < usedLabelmapsLength; i++) {
        let labelmap = this.usedLabelmaps[i];
        if (!labelmap) {
          continue;
        }
        if (labelmap.isSegmentAvailable()) {
          foundLabelmapIndex = i;
          break;
        }
      }

      if (foundLabelmapIndex >= 0) {
        return foundLabelmapIndex;
      } else {
        let labelmapIndex = this.addLabelmap();
        return labelmapIndex;
      }
    }
  }

  setLabelmapColors(labelmapId, color) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);
  }

  addCornerstoneDataToMap(labelmapIndex, labelmapSegment) {
    if (this.maskMap.size === 0) {
      this.maskMap.set(0, {
        labelmapIndex,
        segmentIndex: labelmapSegment,
      });
      return 0;
    } else {
      let labelmapId = Array.from(this.maskMap.keys()).pop() + 1;
      this.maskMap.set(labelmapId, {
        labelmapIndex: labelmapIndex,
        segmentIndex: labelmapSegment,
      });
      return labelmapId;
    }
  }

  selectNextLabelmapSegment(labelmapIndex) {
    return this.usedLabelmaps[labelmapIndex].selectNextSegment();
  }

  setLabelmapSegmentVisible(element, labelmapId, visible) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);
    let isVisible = this.labelmapWrapper.getSegmentVisibility(
      element,
      segmentIndex,
      labelmapIndex
    );
    if (isVisible !== visible) {
      this.labelmapWrapper.setActiveLabelmap(element, labelmapIndex);
      this.labelmapWrapper.setSegmentVisibility(
        element,
        segmentIndex,
        labelmapIndex
      );
    }
  }

  setActiveLabelmap(element, labelmapId) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);
    this.labelmapWrapper.setActiveLabelmap(element, labelmapIndex);
    this.labelmapWrapper.setActiveSegment(element, segmentIndex);
  }

  refreshLabelmap(labelmap3D, labelmapIndex) {
    for (let i = 0; i < 65536; i++) {
      if (this.usedLabelmaps[labelmapIndex].isSegmentColorSet(i)) {
        let segmentColor = this.usedLabelmaps[labelmapIndex].getSegmentColor(i);
        this.labelmapWrapper.setSegmentColor(labelmap3D, i, segmentColor);
      }
    }
  }

  setLabelmapSlice(element, labelmapId, toolData, visible) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);

    this.labelmapWrapper.setActiveLabelmap(element, labelmapIndex);

    const { labelmap3D, labelmap2D } = this.labelmapWrapper.getLabelmap2D(
      element
    );

    this.labelmapWrapper.assignColorLUTToLabelmap(labelmap3D, labelmapIndex);

    this.refreshLabelmap(labelmap3D, labelmapIndex);

    const { pixelData } = labelmap2D;
    for (let j = 0; j < pixelData.length; j++) {
      if (toolData[j] !== 0) {
        if (visible) {
          pixelData[j] = pixelData[j] | segmentIndex;
        } else {
          pixelData[j] = pixelData[j] & ~segmentIndex;
        }
      }
    }
  }

  setLabelmap(element, labelmapId, seriesInstances, maskInstances, visible) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);

    this.labelmapWrapper.setActiveLabelmap(element, labelmapIndex);

    const { labelmap3D } = this.labelmapWrapper.getLabelmap2D(element);

    this.labelmapWrapper.assignColorLUTToLabelmap(labelmap3D, labelmapIndex);

    this.refreshLabelmap(labelmap3D, labelmapIndex);

    for (let i = 0; i < seriesInstances.length; i++) {
      let instance = seriesInstances[i];
      let instanceId = instance.id;

      let maskInstance = maskInstances.find(
        (mask) => mask.instanceId === instanceId
      );

      if (!maskInstance) {
        continue;
      }

      let { toolData, shape } = maskInstance;

      let data = {
        data: toolData.data ? toolData.data : toolData,
        height: shape.height,
        width: shape.width,
      };

      let labelmap2D = this.labelmapWrapper.getLabelmap2DByImageId(
        labelmap3D,
        i,
        data.height,
        data.width
      );

      const { pixelData } = labelmap2D;
      for (let j = 0; j < pixelData.length; j++) {
        if (data.data[j] !== 0) {
          if (visible) {
            pixelData[j] = pixelData[j] | segmentIndex;
          } else {
            pixelData[j] = pixelData[j] & ~segmentIndex;
          }
        }
      }

      this.labelmapWrapper.setSegmentsOnLabelmap(labelmap2D);
    }
  }

  getSegmentIdFromLabelmapId(labelmapId) {
    let { segmentIndex } = this.maskMap.get(labelmapId);
    return segmentIndex;
  }

  getLabelmapIndexFromLabelmapId(labelmapId) {
    let { labelmapIndex } = this.maskMap.get(labelmapId);
    return labelmapIndex;
  }

  clearLabelmapData() {
    this.usedLabelmaps = [];
    this.maskMap = new Map();
  }

  deleteCornerstoneData(elements, labelmapId) {
    let { labelmapIndex, segmentIndex } = this.maskMap.get(labelmapId);
    elements.forEach((element) => {
      this.labelmapWrapper.setActiveLabelmap(element, labelmapIndex);
      this.labelmapWrapper.deleteSegment(element, segmentIndex);
    });
    this.usedLabelmaps[labelmapIndex].releaseSegment(segmentIndex);
    this.maskMap.delete(labelmapId);
    if (this.usedLabelmaps[labelmapIndex].areSegmentsEmpty(segmentIndex)) {
      this.usedLabelmaps.splice(labelmapIndex, 1);
      elements.forEach((element) => {
        this.labelmapWrapper.deleteLabelmap(element, labelmapIndex);
      });
      this.maskMap.forEach((data, dataId) => {
        if (data.labelmapIndex >= labelmapIndex) {
          let labelmapToSet = data.labelmapIndex - 1;
          let segmentToSet = data.segmentIndex;
          this.maskMap.set(dataId, {
            labelmapIndex: labelmapToSet,
            segmentIndex: segmentToSet,
          });
        }
      });
    }
  }
}
