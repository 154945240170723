import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { RoiAttributes, BENIGN, MALIGNANT } from "shared-api";
import {
  updateProxyRoiAttributes,
  updateRoiAttributesData,
} from "../actions/actionCreators/annotationPage/rois";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import { ANNOTATIONS_CREATE } from "../auth0/AuthRules";
import { canRoleUseComponent } from "../actions/actionCreators/app";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    wrap: "nowrap",
  },
  highlightedText: {
    fontWeight: "bold",
    opacity: "1.0",
  },
  unhighlightedText: {
    fontWeight: "normal",
    opacity: "0.4",
  },
}));

function RoiAttributesEditor({
  roi,
  disabled,
  updateProxyRoiAttributes,
  updateRoiAttributes,
  proxyRoiAttributes,
  canRoleUseComponent,
}) {
  const classes = useStyles();

  const [measurable, setMeasurable] = useState(true);
  const [tumourClassification, setTumourClassification] = useState(MALIGNANT);

  let roiAttributes = proxyRoiAttributes[roi.id];
  let hasEdits = roiAttributes.hasPendingEdits;

  useEffect(() => {
    let { measurable, tumourClassification } = roiAttributes;

    setMeasurable(measurable);
    setTumourClassification(tumourClassification);
  }, [roiAttributes]);

  let setProxyMeasurable = (measurable) => {
    if (disabled) return;
    setMeasurable(measurable);

    let proxyRoiAttributes = new RoiAttributes(
      measurable,
      tumourClassification
    );
    updateProxyRoiAttributes(roi.id, proxyRoiAttributes);
  };

  let setProxyTumourClassification = (classification) => {
    if (disabled) return;
    setTumourClassification(classification);

    let proxyRoiAttributes = new RoiAttributes(measurable, classification);
    updateProxyRoiAttributes(roi.id, proxyRoiAttributes);
  };

  if (!roi || !roi.ellipse.slice) {
    return <div>No roi selected</div>;
  }

  let onSaveClick = () => {
    let roiId = roi.id;
    let roiAttributes = new RoiAttributes(measurable, tumourClassification);
    updateRoiAttributes(roiId, roiAttributes);
  };

  let isMalignant = tumourClassification === MALIGNANT;

  let disable = disabled || !canRoleUseComponent(ANNOTATIONS_CREATE);

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <Typography component="div" style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              onClick={() => setProxyTumourClassification(BENIGN)}
              className={
                !isMalignant
                  ? classes.highlightedText
                  : classes.unhighlightedText
              }
              style={{ width: "38%" }}
            >
              Benign
            </Grid>
            <Grid item container justify="center" style={{ width: "12%" }}>
              <AntSwitch
                disabled={disable}
                checked={isMalignant}
                onChange={(event) =>
                  setProxyTumourClassification(
                    event.target.checked ? MALIGNANT : BENIGN
                  )
                }
                value="tumourClassification"
              />
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              onClick={() => setProxyTumourClassification(MALIGNANT)}
              className={
                isMalignant
                  ? classes.highlightedText
                  : classes.unhighlightedText
              }
              style={{ width: "50%" }}
            >
              Malignant
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <Typography component="div" style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              onClick={() => setProxyMeasurable(true)}
              className={
                measurable ? classes.highlightedText : classes.unhighlightedText
              }
              style={{ width: "38%" }}
            >
              Measurable
            </Grid>
            <Grid item container justify="center" style={{ width: "12%" }}>
              <AntSwitch
                disabled={disable}
                checked={!measurable}
                onChange={(event) => setProxyMeasurable(!event.target.checked)}
                value="measurable"
              />
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              onClick={() => setProxyMeasurable(false)}
              className={
                !measurable
                  ? classes.highlightedText
                  : classes.unhighlightedText
              }
              style={{ width: "50%" }}
              wrap="nowrap"
            >
              Non-Measurable
            </Grid>
          </Grid>
        </Typography>
      </Grid>

      {hasEdits && (
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{ height: "20%", paddingTop: "6px" }}
        >
          <Button
            variant="outlined"
            disabled={!hasEdits || disable}
            onClick={() => onSaveClick()}
          >
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

RoiAttributesEditor.propTypes = {
  roi: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateProxyRoiAttributes: PropTypes.func.isRequired,
  updateRoiAttributes: PropTypes.func.isRequired,
  proxyRoiAttributes: PropTypes.object.isRequired,
  canRoleUseComponent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  proxyRoiAttributes: state.annotationPage.rois.proxyRoiAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  updateProxyRoiAttributes: (roiId, roiAttributes) =>
    dispatch(updateProxyRoiAttributes(roiId, roiAttributes)),
  updateRoiAttributes: (roiId, roiAttributes) =>
    dispatch(updateRoiAttributesData(roiId, roiAttributes)),
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoiAttributesEditor);
