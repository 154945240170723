import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation UpdateBiopsyDetailsNoLinking(
    $id: Int!
    $no_linking: Boolean!
    $no_linking_reason: String
  ) {
    update_patient_biopsy_details_by_pk(
      pk_columns: { patient_biopsy_details_id: $id }
      _set: { no_linking: $no_linking, no_linking_reason: $no_linking_reason }
    ) {
      id: patient_biopsy_details_id
    }
  }
`;

type Input = {
  id: number;
  noLinking: boolean;
  reason?: string;
};

export async function updatePatientBiopsyDetailsNoLinking({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      id,
      noLinking: no_linking,
      reason: no_linking_reason,
    }) => {
      return {
        id,
        no_linking,
        no_linking_reason,
      };
    },
    parseData: () => undefined,
  });
}
