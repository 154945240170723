import {
  IS_CASE_DATA_LOADED,
  SET_POSSIBLE_CASE_ASSIGNMENTS,
  SET_SELECTED_ASSIGNED_CASE,
} from "../../actions/types/annotationPage/case";

let defaultState = {
  isCaseDataLoaded: false,
  selectedCaseAssignment: undefined,
  possibleCaseAssignments: undefined,
};

export const caseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case IS_CASE_DATA_LOADED:
      return isCaseDataLoaded(state, action);
    case SET_SELECTED_ASSIGNED_CASE:
      return setSelectedAssignedCase(state, action);
    case SET_POSSIBLE_CASE_ASSIGNMENTS:
      return setPossibleCaseAssignments(state, action);
    default:
      return state;
  }
};

const isCaseDataLoaded = (state, action) => {
  return {
    ...state,
    isCaseDataLoaded: action.isLoaded,
  };
};

const setSelectedAssignedCase = (state, action) => {
  let { caseAssignment, newUserId } = action;

  let selectedCaseAssignment;

  if (newUserId) {
    selectedCaseAssignment = {
      ...caseAssignment,
      userId: newUserId,
      username: undefined,
    };
  } else {
    selectedCaseAssignment = caseAssignment;
  }

  return {
    ...state,
    selectedCaseAssignment,
    possibleCaseAssignments: undefined,
  };
};

const setPossibleCaseAssignments = (state, action) => {
  let { caseAssignments } = action;
  return {
    ...state,
    possibleCaseAssignments: caseAssignments,
  };
};
