import { TumourBurdenFragmentType } from "../fragments/TumourBurdenFragmentType";
import { TumourBurdenType } from "../types/TumourBurdenType";

export function parseTumourBurden({
  burden,
  series,
}: TumourBurdenFragmentType): TumourBurdenType {
  const seriesIds = series.map(({ id }) => id);
  return { burden, seriesIds };
}
