import {
  SET_MASK_GROUPS,
  SET_MASK_GROUP_VISIBILITY,
} from "../../types/annotationPage/masks";

export function setMaskGroups(maskGroups) {
  return {
    type: SET_MASK_GROUPS,
    maskGroups,
  };
}

export function setMaskGroupVisibility(maskGroupId, isVisible) {
  return {
    type: SET_MASK_GROUP_VISIBILITY,
    maskGroupId,
    isVisible,
  };
}
