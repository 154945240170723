import { gql } from "@apollo/client/core";
import { useParsedQueryReturnType } from "../../../../utilities/graphql/useParsedQueryReturnType";
import { useParsedQuery } from "../../../../utilities/graphql/useParsedQuery";
import { LesionType } from "../types/LesionType";
import { LesionFragmentType } from "../fragments/LesionFragmentType";
import { parseLesion } from "../parsers/parseLesion";
import { LesionColorFragmentType } from "../fragments/LesionColorFragmentType";
import { LESION_COLOR_FRAGMENT } from "../fragments/LesionColorFragment";
import { parseLesionColor } from "../parsers/parseLesionColor";
import { LesionTypeType } from "../types/LesionTypeType";
import { BASE_WADO_URL_FRAGMENT } from "../fragments/BaseWadoUrlFragment";
import { BaseWadoUrlFragmentType } from "../fragments/BaseWadoUrlFragmentType";
import { parseBaseWadoUrl } from "../parsers/parseBaseWadoUrl";
import { CASE_LESIONS_FRAGMENT } from "../fragments/CaseLesionsFragment";

const QUERY = gql`
  query GetLesions($case_id: Int!) {
    case: cases_by_pk(case_id: $case_id) {
      ...CaseLesions
    }
    colors: lesion_color {
      ...Color
    }
    ...BaseWadoUrl
  }
  ${CASE_LESIONS_FRAGMENT}
  ${LESION_COLOR_FRAGMENT}
  ${BASE_WADO_URL_FRAGMENT}
`;

type Input = {
  caseId: number;
  lesionType?: LesionTypeType;
};

type Variables = {
  case_id: number;
};

type Data = {
  case: { lesions: LesionFragmentType[] };
  colors: LesionColorFragmentType[];
  wadoUrls: BaseWadoUrlFragmentType;
};

type Output = LesionType[];

export function useLesions(
  input: Input
): useParsedQueryReturnType<Input, Output, Variables> {
  return useParsedQuery<Input, Output, Data, Variables>({
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({
      case_id,
    }),
    parseData: (
      { case: { lesions: lesionFragments }, colors, wadoUrls }: Data,
      { lesionType }: Input
    ) => {
      const lesions = lesionFragments.map((lesion) => {
        const baseWadoUrl = parseBaseWadoUrl(wadoUrls);
        const lesionColorMap = colors.map(parseLesionColor);
        return parseLesion(lesion, lesionColorMap, baseWadoUrl);
      });
      return lesionType
        ? lesions.filter(({ type }) => type === lesionType)
        : lesions;
    },
  });
}
