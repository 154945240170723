import { createSelector } from "reselect";

export const getMaskGroups = (state) => state.annotationPage.masks.byId;
export const getMaskGroupIds = (state) => state.annotationPage.masks.allIds;

export const maskGroupsSelector = createSelector(
  getMaskGroups,
  getMaskGroupIds,
  (maskGroups, maskGroupIds) => maskGroupIds.map((id) => maskGroups[id])
);
