import { useSelector } from "react-redux";

import rulesEnforcer from "../../tumourResponseRules/rulesEnforcer";

function useRuleCheck(rule, componentState) {
  const reduxState = useSelector((state) => state);

  return rulesEnforcer.checkRules(rule, componentState, reduxState);
}

export default useRuleCheck;
