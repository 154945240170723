import { UserMaskFragmentType } from "../fragments/UserMaskFragmentType";
import { UserMasksType } from "../types/UserMasksType";
import { parseMaskFragment } from "./parseMaskFragment";

export function parseUserMask({
  roiId,
  ...maskProps
}: UserMaskFragmentType): UserMasksType {
  const mask = parseMaskFragment(maskProps);

  return {
    ...mask,
    roiId,
  };
}
