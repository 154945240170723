import {
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";

type InitAuthCallbacksForAudienceType = {
  audience: string;
  scope: string;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getTokenWithPopup: (options?: GetTokenWithPopupOptions) => Promise<string>;
  loginWithRedirect: (options?: RedirectLoginOptions) => void;
  onGetTokenCallbacks: ((token: string) => void)[];
};

export async function initAuthCallbacksForAudience({
  audience,
  scope,
  getTokenSilently,
  getTokenWithPopup,
  loginWithRedirect,
  onGetTokenCallbacks,
}: InitAuthCallbacksForAudienceType): Promise<void> {
  let token: string | undefined = undefined;
  try {
    token = await getTokenSilently({
      audience,
      scope,
    });
  } catch ({ error }) {
    if (error === "consent_required") {
      try {
        token = await getTokenWithPopup({
          audience,
          scope,
        });
      } catch ({ message }) {
        if (message.toLowerCase() === "could not open popup") {
          loginWithRedirect({
            audience,
            scope,
            redirect_uri: `${window.location.origin}${process.env.PUBLIC_URL}`,
          });
          return;
        }
      }
    }
  }

  if (!token) throw new Error("No token returned from auth provider.");

  for (const callback of onGetTokenCallbacks) {
    callback(token);
  }
}
