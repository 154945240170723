import {
  SET_MASK_GROUPS,
  SET_MASK_GROUP_VISIBILITY,
} from "../../actions/types/annotationPage/masks";

let defaultState = {
  byId: {},
  allIds: [],
  instancesById: {},
};

export const masks = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MASK_GROUPS:
      return setMaskGroups(state, action);
    case SET_MASK_GROUP_VISIBILITY:
      return setMaskGroupVisibility(state, action);
    default:
      return state;
  }
};

const setMaskGroups = (state, action) => {
  return {
    ...state,
    byId: action.maskGroups.entities.groups,
    allIds: action.maskGroups.result,
    seriesById: action.maskGroups.entities.series,
    instancesById: action.maskGroups.entities.instances,
  };
};

const setMaskGroupVisibility = (state, action) => {
  let { maskGroupId, isVisible } = action;
  return {
    ...state,
    byId: {
      ...state.byId,
      [maskGroupId]: {
        ...state.byId[maskGroupId],
        isVisible,
      },
    },
  };
};
