import {
  ADD_ROI,
  DELETE_CONTOUR,
  DELETE_DETECTION,
  REMOVE_ROI,
  SET_ROIS,
  UPDATE_CONTOUR,
  UPDATE_DETECTION,
  UPDATE_ROI_ATTRIBUTES,
  UPDATE_PROXY_ROI_ATTRIBUTES,
  REMOVE_PROXY_ROI_ATTRIBUTES,
  INSERT_CONTOUR,
  UPDATE_USER_MASK,
  INSERT_USER_MASK,
  DELETE_USER_MASK,
  COPY_DATA_TO_CLIPBOARD,
  CLEAR_CLIPBOARD,
  SET_SELECTED_ROI_ID,
  UNSET_SELECTED_ROI_ID,
} from "../../types/annotationPage/rois";
import LesionToolLoader from "../../../tools/LesionToolLoader";
import labelmapProvider from "../../../labelmapUtils/initLabelmapProvider";
import {
  deleteSingleToolDataFromImage,
  deleteToolDataFromImage,
  setToolSliceData,
} from "../../../tools/ToolUtils";
import { isContourDataInSlice } from "../../../tools/contourUtils/isContourDataInSlice";
import { addContoursToSlice } from "../../../tools/contourUtils/addContoursToSlice";
import { isContourPasteAvailable } from "../../../tools/pasteUtils/isContourPasteAvailable";
import { isUserMaskPasteAvailable } from "../../../tools/pasteUtils/isUserMaskPasteAvailable";
import {
  RoiAttributes,
  MALIGNANT,
  ToolTypes,
  RECIST_1_1,
  getContourDataWithToolRoi,
  lesionTypes,
  deleteContour as deleteContourApi,
  deleteContours as deleteContoursApi,
  updateContour as updateContourApi,
  insertContour as insertContourApi,
  insertEllipse as insertEllipseApi,
  insertRoiAttributes as insertRoiAttributesApi,
  deleteRoiAttributes as deleteRoiAttributesApi,
  updateUserMask as updateUserMaskApi,
  insertUserMask as insertUserMaskApi,
  deleteUserMask as deleteUserMaskApi,
} from "shared-api";
import { autoSegmentEllipseData } from "../../../api/autoSegmentApi";
import cornerstone from "cornerstone-core";
import {
  updateRoiMeasurementThunk,
  deleteRoiMeasurementThunk,
} from "../../../RECIST/actions/actionCreators/measurements";
import { updateRoiRecistEvaluationThunk } from "../../../RECIST/actions/actionCreators/evaluations/rois";

import cloneDeep from "lodash/cloneDeep";
import { setSelectedLesion } from "./thunks";
import getClient from "../../../apollo/getClient";
import { CONTOUR_VERSION } from "shared-api/src/types/contourVersion";
import { CURRENT_USER_MASK_VERSION } from "shared-api/src/types/userMaskVersion";

export function addRoi(roi) {
  return {
    type: ADD_ROI,
    roi,
  };
}

export function removeRoi(roiId) {
  return {
    type: REMOVE_ROI,
    roiId,
  };
}

export function setRois(rois) {
  return {
    type: SET_ROIS,
    rois,
  };
}

export function deleteDetection(roiId, slice) {
  return {
    type: DELETE_DETECTION,
    roiId,
    slice,
  };
}

export function deleteContour(roiId, instanceId) {
  return {
    type: DELETE_CONTOUR,
    payload: { roiId, instanceId },
  };
}

export function deleteUserMask(roiId, instanceId) {
  return {
    type: DELETE_USER_MASK,
    payload: { roiId, instanceId },
  };
}

export function updateDetection(roiId, data) {
  return {
    type: UPDATE_DETECTION,
    roiId,
    data,
  };
}

export function updateContour(roiId, instanceId, toolData) {
  return {
    type: UPDATE_CONTOUR,
    payload: { roiId, instanceId, toolData },
  };
}

export function insertContour(roiId, instanceId, toolData, userId) {
  return {
    type: INSERT_CONTOUR,
    payload: { roiId, instanceId, toolData, userId },
  };
}

export function updateUserMask(roiId, instanceId, toolData, shape) {
  return {
    type: UPDATE_USER_MASK,
    payload: { roiId, instanceId, toolData, shape },
  };
}

export function insertUserMask(roiId, instanceId, toolData, shape, createdBy) {
  return {
    type: INSERT_USER_MASK,
    payload: { roiId, instanceId, toolData, shape, createdBy },
  };
}

export function updateRoiAttributes(roiId, roiAttributes) {
  return {
    type: UPDATE_ROI_ATTRIBUTES,
    roiId,
    roiAttributes,
  };
}

export function updateProxyRoiAttributes(roiId, roiAttributes) {
  return {
    type: UPDATE_PROXY_ROI_ATTRIBUTES,
    roiId,
    roiAttributes,
  };
}

export function removeProxyRoiAttributes(roiId) {
  return {
    type: REMOVE_PROXY_ROI_ATTRIBUTES,
    roiId,
  };
}

export function copyDataToClipboard(clipboardData) {
  return {
    type: COPY_DATA_TO_CLIPBOARD,
    clipboardData,
  };
}

export function clearClipboard() {
  return {
    type: CLEAR_CLIPBOARD,
  };
}

export function deleteDetectionData(roiId, slice) {
  return async (dispatch, getState) => {
    let rois = getState().annotationPage.rois.byId;
    let roi = rois[roiId];

    let lesionToolLoader = new LesionToolLoader();
    lesionToolLoader.removeRoiToolData(roi);

    dispatch(removeProxyRoiAttributes(roiId));
    dispatch(deleteDetection(roiId, slice));

    let userId = getState().app.userId;

    //re-get the new data to send to api
    rois = getState().annotationPage.rois.byId;
    roi = rois[roiId];

    const client = getClient();
    await insertEllipseApi({
      client,
      roiId: roi.id,
      data: roi.ellipse,
      createdBy: userId,
    });

    await deleteContoursApi({ client, roiId });
    await deleteRoiAttributesApi({ client, roiId });
  };
}

export function deleteContourToolThunk(toolData) {
  return (dispatch, getState) => {
    let {
      annotationPage: {
        rois: roiState,
        series: { instancesById },
        case: { selectedCaseAssignment },
      },
    } = getState();
    let stateRois = roiState.allIds.map((id) => roiState.byId[id]);

    let {
      roi: { id: toolRoiId },
    } = toolData;

    let rois = stateRois.filter((roi) => roi.contoursToolRoi.id === toolRoiId);
    if (rois.length !== 1) {
      throw new Error(
        "None or more than one roi not found in deleteContourToolData"
      );
    }

    let { id: roiId, contours } = rois[0];

    let affectedInstanceId = undefined;
    let affectedToolData = undefined;

    for (let i = 0; i < contours.length; i++) {
      let { instanceId, toolData: existingToolData } = contours[i];
      for (let j = 0; j < existingToolData.length; j++) {
        if (toolData === existingToolData[j]) {
          affectedInstanceId = instanceId;
          affectedToolData = existingToolData;
          affectedToolData.splice(j, 1);
          break;
        }
      }

      if (affectedToolData) {
        break;
      }
    }

    if (selectedCaseAssignment) {
      const { actionType } = selectedCaseAssignment;
      if (actionType === RECIST_1_1) {
        dispatch(deleteRoiMeasurementThunk(roiId, affectedInstanceId));
      }
    }

    let { wadoUrl } = instancesById[affectedInstanceId];

    //if there are multiple contours remaining for the slice, only delete one of them, otherwise, delete the whole slice
    if (affectedToolData.length >= 1) {
      deleteSingleToolDataFromImage(
        toolData,
        wadoUrl,
        ToolTypes.FREEHANDROITOOL
      );

      dispatch(updateContourThunk(roiId, wadoUrl, affectedToolData));
    } else {
      dispatch(deleteContourThunk(roiId, affectedInstanceId, wadoUrl));
    }
  };
}

export function copyContourToClipboardThunk(
  roiId,
  imageId,
  toolData,
  dataType
) {
  return async (dispatch, getState) => {
    const appState = getState();
    let {
      annotationPage: {
        lesions: { selectedLesionId: selectedLesionId },
      },
    } = appState;

    let copiedToolData = toolData.map((contour) => cloneDeep(contour));
    dispatch(
      copyDataToClipboard({
        copiedLesionId: selectedLesionId,
        copiedToolData,
        copiedImageId: imageId,
        copiedRoiId: roiId,
        copiedDataType: dataType,
      })
    );
  };
}

export function pasteContourFromClipboardThunk(roi, imageId, activeTool) {
  return async (dispatch, getState) => {
    const appState = getState();
    let {
      annotationPage: {
        lesions: { selectedLesionId: selectedLesionId, byId: lesionsById },
        rois: { clipboardData: clipboardData },
      },
    } = appState;
    let selectedLesion = lesionsById[selectedLesionId];

    if (
      !isContourPasteAvailable(
        clipboardData,
        roi,
        imageId,
        selectedLesion,
        activeTool
      )
    ) {
      return;
    }

    let { copiedToolData } = clipboardData;

    let { id, contoursToolRoi } = roi;

    let copyOfCopiedToolData = copiedToolData
      .filter(
        (contour) => !isContourDataInSlice(contour, contoursToolRoi, imageId)
      )
      .map((contour) => {
        let copiedContour = cloneDeep(contour);
        copiedContour.roi = cloneDeep(contoursToolRoi);
        return copiedContour;
      });
    if (copyOfCopiedToolData.length > 0) {
      let sliceToolData = addContoursToSlice(copyOfCopiedToolData, imageId);
      dispatch(updateContourThunk(id, imageId, sliceToolData));
    }
  };
}

export function deleteContourThunk(roiId, instanceId, wadoUrl) {
  return async (dispatch, getState) => {
    const appState = getState();

    let {
      annotationPage: { rois: roisState },
    } = appState;
    const { byId: roisById } = roisState;

    const roi = roisById[roiId];

    deleteToolDataFromImage(
      ToolTypes.FREEHANDROITOOL,
      roi.contoursToolRoi.id,
      wadoUrl
    );

    dispatch(deleteContour(roiId, instanceId));

    let {
      app: { api },
    } = appState;

    const client = getClient();
    await deleteContourApi({ client, roiId, instanceId });
  };
}

export function copyUserMaskToClipboardThunk(
  roiId,
  imageId,
  toolData,
  shape,
  dataType
) {
  return async (dispatch, getState) => {
    const appState = getState();
    let {
      annotationPage: {
        lesions: { selectedLesionId: selectedLesionId },
      },
    } = appState;

    dispatch(
      copyDataToClipboard({
        copiedLesionId: selectedLesionId,
        copiedToolData: toolData,
        copiedMaskShape: shape,
        copiedImageId: imageId,
        copiedRoiId: roiId,
        copiedDataType: dataType,
      })
    );
  };
}

export function pasteUserMaskFromClipboardThunk(
  roi,
  imageId,
  element,
  activeTool
) {
  return async (dispatch, getState) => {
    const appState = getState();
    let {
      annotationPage: {
        lesions: { selectedLesionId: selectedLesionId, byId: lesionsById },
        rois: { clipboardData: clipboardData },
      },
    } = appState;
    let selectedLesion = lesionsById[selectedLesionId];

    if (
      !isUserMaskPasteAvailable(
        clipboardData,
        roi,
        imageId,
        selectedLesion,
        activeTool
      )
    ) {
      return;
    }

    let { copiedToolData, copiedMaskShape } = clipboardData;

    let { visible } = selectedLesion;
    let { id, seriesId, labelmapId } = roi;

    labelmapProvider.setLabelmapSlice(
      seriesId,
      copiedToolData,
      labelmapId,
      element,
      visible
    );

    dispatch(updateUserMaskThunk(id, imageId, copiedToolData, copiedMaskShape));
  };
}

function forceRefreshAllViewers(viewers) {
  const { allIds, byId } = viewers;
  allIds.forEach((id) => {
    cornerstone.updateImage(byId[id].element);
  });
}

function autoSegmentRoi(roi, ellipseData) {
  return async (dispatch, getState) => {
    const {
      annotationPage: { viewers },
    } = getState();

    const { id: roiId, contoursToolRoi } = roi;
    const { imageId, data: toolData } = ellipseData;

    const resolution = 200;
    const settings = { alpha: 0.025, beta: 10, gamma: 0.001 };

    const segmentation = await autoSegmentEllipseData(
      imageId,
      toolData,
      settings,
      resolution
    );

    //its possible the roi no longer exists by the time the auto-segment results return, so early out if that occurs
    const {
      annotationPage: {
        rois: { byId: roisById },
      },
    } = getState();
    if (!roisById[roiId]) {
      console.warn(
        `Roi #${roiId} no longer exists. Ignoring auto-segment results`
      );
      return;
    }

    const segmentationToolData = getContourDataWithToolRoi(
      segmentation,
      1,
      0,
      contoursToolRoi
    );

    setToolSliceData(ToolTypes.FREEHANDROITOOL, imageId, [
      segmentationToolData,
    ]);

    dispatch(updateContourThunk(roiId, imageId, [segmentationToolData]));

    forceRefreshAllViewers(viewers);
  };
}

export function updateDetectionData(ellipseData) {
  return async (dispatch, getState) => {
    const { annotationPage } = getState();
    let { rois: roisState } = annotationPage;
    let rois = roisState.allIds.map((id) => roisState.byId[id]);

    let toolRoi = ellipseData.toolData[0].roi;
    let roi = rois.find((roi) => roi.ellipse.roi.id === toolRoi.id);
    if (!roi) {
      throw new Error("Unable to find roi");
    }

    let roiId = roi.id;

    let isNewEllipse = !roi.ellipse.slice;
    if (isNewEllipse) {
      let roiAttributes = new RoiAttributes(true, MALIGNANT);
      dispatch(updateProxyRoiAttributes(roiId, roiAttributes));

      dispatch(setSelectedRoiId(roi.id));
    }

    dispatch(updateDetection(roiId, ellipseData));

    //re-aquire the rois after the update otherwise they wont be up to date
    roisState = getState().annotationPage.rois;
    roi = roisState.byId[roiId];

    let userId = getState().app.userId;

    const client = getClient();
    await insertEllipseApi({
      client,
      roiId: roi.id,
      data: roi.ellipse,
      createdBy: userId,
    });
  };
}

export function updateContourThunk(roiId, imageId, toolData) {
  return async (dispatch, getState) => {
    const { annotationPage, app } = getState();

    const {
      lesions: { selectedLesionId: selectedLesionId, byId: lesionsById },
      rois: { byId: roisById },
      series: seriesState,
      case: { selectedCaseAssignment },
    } = annotationPage;

    const { instancesById } = seriesState;

    const instanceId = parseInt(
      Object.keys(instancesById).find((id) => {
        const { wadoUrl } = instancesById[id];
        return wadoUrl === imageId;
      })
    );

    const { userId } = app;

    if (selectedCaseAssignment) {
      const { actionType } = selectedCaseAssignment;
      if (
        actionType === RECIST_1_1 &&
        lesionsById[selectedLesionId].type === lesionTypes.LESION
      ) {
        await dispatch(updateRoiMeasurementThunk(roiId, instanceId, toolData));
        await dispatch(updateRoiRecistEvaluationThunk(roiId));
      }
    }

    const contourHasData = toolData.length > 0;

    const client = getClient();

    const { contours } = roisById[roiId];
    const contourExists = contours.some(
      (contour) => contour.instanceId === instanceId
    );
    if (contourExists) {
      if (contourHasData) {
        dispatch(updateContour(roiId, instanceId, toolData));
        await updateContourApi({
          client,
          roiId,
          instanceId,
          version: CONTOUR_VERSION,
          toolData,
        });
      } else {
        dispatch(deleteContour(roiId, instanceId));
        await deleteContourApi(client, roiId, instanceId);
      }
    } else {
      // don't add the contour if there is no data in it
      if (contourHasData) {
        dispatch(insertContour(roiId, instanceId, toolData, userId));
        await insertContourApi({
          client,
          roiId,
          instanceId,
          version: CONTOUR_VERSION,
          toolData,
          createdBy: userId,
        });
      }
    }
  };
}

export function updateUserMaskThunk(roiId, imageId, toolData, shape) {
  return async (dispatch, getState) => {
    const { annotationPage, app } = getState();
    const {
      rois: { byId: roisById },
      series: seriesState,
    } = annotationPage;

    const { instancesById } = seriesState;

    const instanceId = parseInt(
      Object.keys(instancesById).find((id) => {
        const { wadoUrl } = instancesById[id];
        return wadoUrl === imageId;
      })
    );

    const { userId } = app;

    const { userMasks } = roisById[roiId];
    const userMaskExists = userMasks.some(
      (userMask) => userMask.instanceId === instanceId
    );
    let dataHasBitSet = toolData.includes(1);

    const client = getClient();
    if (userMaskExists) {
      if (dataHasBitSet) {
        dispatch(updateUserMask(roiId, instanceId, toolData, shape));
        await updateUserMaskApi({
          client,
          roiId,
          instanceId,
          toolData,
          shape,
          version: CURRENT_USER_MASK_VERSION,
        });
      } else {
        dispatch(deleteUserMask(roiId, instanceId));
        await deleteUserMaskApi({ client, roiId, instanceId });
      }
    } else {
      if (dataHasBitSet) {
        dispatch(insertUserMask(roiId, instanceId, toolData, shape, userId));
        await insertUserMaskApi({
          client,
          roiId,
          instanceId,
          toolData,
          version: CURRENT_USER_MASK_VERSION,
          shape,
          createdBy: userId,
        });
      }
    }
  };
}

export function updateRoiAttributesData(roiId, roiAttributes) {
  return async (dispatch) => {
    dispatch(updateRoiAttributes(roiId, roiAttributes));

    const { measurable, tumourClassification } = roiAttributes;
    const client = getClient();
    await insertRoiAttributesApi({
      client,
      roiId,
      measurable,
      tumourClassification,
    });
  };
}

export function setSelectedRoiId(id) {
  return {
    type: SET_SELECTED_ROI_ID,
    id,
  };
}

export function unsetSelectedRoiId() {
  return {
    type: UNSET_SELECTED_ROI_ID,
  };
}

export function onEllipseProbeCreated(ellipseData, roiId) {
  return async (dispatch, getState) => {
    const { annotationPage } = getState();
    let { rois: roisState } = annotationPage;

    let roi = roisState.byId[roiId];
    if (!roi) {
      throw new Error("Unable to find roi");
    }

    dispatch(autoSegmentRoi(roi, ellipseData));
  };
}

export function selectLesionFromContourData(roiId, toolData) {
  return (dispatch, getState) => {
    let {
      annotationPage: {
        rois: roiState,
        lesions: { byId: lesionsById, allIds: lesionIds },
      },
    } = getState();
    const rois = roiState.allIds
      .map((id) => roiState.byId[id])
      .filter((roi) => roi.contoursToolRoi.id === toolData.roi.id);

    const lesionIdToSelectFromContourData = lesionIds.find((lesionId) =>
      rois.every((roi) => lesionsById[lesionId].rois.includes(roi.id))
    );

    dispatch(setSelectedLesion(lesionIdToSelectFromContourData));
  };
}
