import { gql } from "@apollo/client/core";

export const ANATOMICAL_STRUCTURES_FRAGMENT = gql`
  fragment AnatomicalStructure on anatomical_structures {
    id: anatomical_structures_id
    structure
    displayName: display_name
    enabledOrgans: enabled_organs {
      enabled
    }
  }
`;
