import { gql } from "@apollo/client/core";

export const CONTOUR_FRAGMENT = gql`
  fragment Contour on contour {
    id: contour_id
    roiId: roi_id
    instanceId: instance_id
    toolData: tool_data
    version
    createdBy: created_by
  }
`;
