import dicomParser from "dicom-parser";
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "@altis-labs/cornerstone-tools";
import Hammer from "hammerjs";
import { ImageViewerSynchronizer } from "./ImageViewerSyncrhonizer";

let isInitialized = false;

export default function initCornerstone() {
  if (isInitialized) {
    return;
  }

  isInitialized = true;

  // Cornertone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

  cornerstoneTools.init();

  // Preferences
  const fontFamily =
    "Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif";
  cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);
  cornerstoneTools.toolStyle.setToolWidth(2);
  cornerstoneTools.toolColors.setToolColor("red");
  cornerstoneTools.toolColors.setActiveColor("red");
  cornerstoneTools.toolColors.setFillColor("red");

  let segmentationModule = cornerstoneTools.getModule("segmentation");
  segmentationModule.configuration.renderOutline = false;
  segmentationModule.configuration.fillAlpha = 0.666;
  segmentationModule.configuration.fillAlphaInactive = 0.666;

  cornerstoneTools.store.state.touchProximity = 40;

  // IMAGE LOADER
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });

  let imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
  imageViewerSynchronizer.createSynchronizer(
    cornerstoneTools.EVENTS.STACK_SCROLL,
    cornerstoneTools.stackImageIndexSynchronizer
  );
  imageViewerSynchronizer.setEnabled(false);

  // Debug
  window.cornerstone = cornerstone;
  window.cornerstoneTools = cornerstoneTools;
}
