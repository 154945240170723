export const SET_DIAGNOSES = "SET_DIAGNOSES";
export const CLEAR_DIAGNOSES = "CLEAR_DIAGNOSES";
export const INSERT_DIAGNOSIS = "INSERT_DIAGNOSIS";
export const REMOVE_TUMOUR_FROM_DIAGNOSIS = "REMOVE_TUMOUR_FROM_DIAGNOSIS";
export const ADD_TUMOUR_TO_DIAGNOSIS = "ADD_TUMOUR_TO_DIAGNOSIS";

export type DiagnosesActionType =
  | typeof SET_DIAGNOSES
  | typeof CLEAR_DIAGNOSES
  | typeof INSERT_DIAGNOSIS
  | typeof REMOVE_TUMOUR_FROM_DIAGNOSIS
  | typeof ADD_TUMOUR_TO_DIAGNOSIS;
