import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { ActionTypeType } from "../types/ActionTypeType";

const QUERY = gql`
  query GetSimilarCaseAssignment(
    $case_id: Int!
    $action_type: enum_case_action_type_enum!
  ) {
    assignments: case_assignment(
      where: { case_id: { _eq: $case_id }, action_type: { _eq: $action_type } }
    ) {
      id: case_assignment_id
    }
  }
`;

type Input = {
  caseId: number;
  actionType: ActionTypeType;
};

type Data = {
  assignments: {
    id: number;
  }[];
};

export async function getSimilarCaseAssignmentIds({
  client,
  ...input
}: Input & ClientProps): Promise<number[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id, actionType: action_type }) => ({
      case_id,
      action_type,
    }),
    parseData: ({ assignments }: Data) => assignments.map(({ id }) => id),
  });
}
