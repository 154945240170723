import {
  SET_STUDY_NOTES,
  UPDATE_STUDY_NOTE,
} from "../../actions/types/annotationPage/studyNotes";
import produce from "immer";

let defaultState = {
  byId: {},
  allIds: [],
};

export const studyNotes = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STUDY_NOTES:
      return setStudyNotes(state, action);
    case UPDATE_STUDY_NOTE:
      return updateStudyNote(state, action);
    default:
      return state;
  }
};

const setStudyNotes = (state, action) => {
  let normalizedStudyNotes = action.studyNotes;

  let byId = normalizedStudyNotes.entities.studyNotes;

  byId = byId ? byId : {};

  return {
    byId,
    allIds: normalizedStudyNotes.result,
  };
};

const updateStudyNote = (state, action) => {
  let studyNote = action.studyNote;
  let id = studyNote.id;

  const nextState = produce(state, (draftState) => {
    let { allIds, byId } = draftState;
    if (!allIds.includes(id)) {
      allIds.push(id);
    }
    byId[id] = studyNote;
  });

  return nextState;
};
