import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React from "react";

function OkDialog({
  title,
  open,
  onOk,
  children,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
}) {
  const handleOk = () => {
    onOk();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOk}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OkDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default OkDialog;
