import axios from "axios";
import GenericApiConsumer from "./GenericApiConsumer";

export default class GenericRestApiConsumer {
  constructor(baseUrl, token, onError) {
    this.apiConsumer = new GenericApiConsumer(onError);

    this.instance = axios.create({
      baseURL: baseUrl,
      timeout: 30000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async post(path, args, getResult, functionName) {
    return await this.apiConsumer.execute(
      args,
      async (args) => await this.instance.post(path, args),
      getResult,
      functionName
    );
  }

  async put(path, args, getResult, functionName) {
    return await this.apiConsumer.execute(
      args,
      async (args) => await this.instance.put(path, args),
      getResult,
      functionName
    );
  }

  async get(path, args, getResult, functionName) {
    return await this.apiConsumer.execute(
      args,
      async (args) => await this.instance.get(path, { params: args }),
      getResult,
      functionName
    );
  }

  async delete(path, args, getResult, functionName) {
    return await this.apiConsumer.execute(
      args,
      async (args) => await this.instance.delete(path, { data: args }),
      getResult,
      functionName
    );
  }
}
