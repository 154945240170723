import { gql } from "@apollo/client/core";

export const LABELMAP_FRAGMENT = gql`
  fragment Labelmap on labelmap {
    id: labelmap_id
    groupId: labelmap_group_id
    instanceId: instance_id
    toolData: data
    origin
    shape
    createdAt: created_at
    instance {
      seriesId: series_id
    }
    version
  }
`;
