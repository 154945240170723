import { EllipseFragmentType } from "../fragments/EllipseFragmentType";
import { EllipseType } from "../types/EllipseType";
import cloneDeep from "lodash.clonedeep";
import { createToolRoi } from "shared-api";
import ToolTypes from "../../utilities/ToolTypes";

export function parseEllipse(
  ellipseFragment: EllipseFragmentType | null,
  toolRoiId: number,
  baseWadoUrl: string,
  lesionId: number
): EllipseType {
  const {
    data: { roi, slice },
  } = ellipseFragment
    ? cloneDeep(ellipseFragment)
    : {
        data: {
          roi: createToolRoi(toolRoiId, ToolTypes.ELLIPTICALROITOOL, 0),
          slice: null,
        },
      };

  let sliceWithUniqueToolRoiId = null;

  if (slice) {
    //  swap the internal imageId url to use the one from the db so that the ellipse shows up on the images correctly
    const { imageId: originalImageId } = slice;
    const regex = /(wadouri:)(.*\/dcm4chee-arc\/aets\/.+\/wado)(.*)/gi;
    const imageId = originalImageId.replace(regex, `$1${baseWadoUrl}$3`);

    sliceWithUniqueToolRoiId = {
      ...slice,
      imageId,
      toolData: slice.toolData.map((data) => {
        const { roi } = data;
        return {
          ...data,
          roi: { ...roi, id: toolRoiId, lesionId },
        };
      }),
    };
  }

  return {
    roi: { ...roi, id: toolRoiId, lesionId },
    slice: sliceWithUniqueToolRoiId,
  };
}
