export function getNumberOfSetBits(numberOfTotalBits, segmentNumber) {
  let bitNumber = 0;
  let numberOfSetBits = 0;
  while (bitNumber < numberOfTotalBits) {
    let mask = 1 << bitNumber;
    if ((mask & segmentNumber) === mask) {
      numberOfSetBits++;
    }
    bitNumber++;
  }

  return numberOfSetBits;
}
