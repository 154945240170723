import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { RoiMeasurementType } from "../types/RoiMeasurementType";
import { parse } from "flatted";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { RoiMeasurementFragmentType } from "../fragments/RoiMeasurementFragmentType";
import { ROI_MEASUREMENT_FRAGMENT } from "../fragments/RoiMeasurementFragment";

const QUERY = gql`
  query GetRoiMeasurements($case_id: Int!) {
    measurements: roi_measurement(
      where: { roi: { lesion: { case_id: { _eq: $case_id } } } }
    ) {
      ...RoiMeasurement
    }
  }
  ${ROI_MEASUREMENT_FRAGMENT}
`;

type Input = { caseId: number };

type Data = {
  measurements: RoiMeasurementFragmentType[];
};

export async function getRoiMeasurements({
  client,
  ...input
}: ClientProps & Input): Promise<RoiMeasurementType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: ({ measurements }: Data) =>
      measurements.map(({ roiId, toolData, instanceId }) => {
        const parsedToolData = parse(JSON.stringify(toolData));
        return { roiId, instanceId, toolData: parsedToolData };
      }),
  });
}
