import React from "react";
import OkCancelDialog from "../Dialogs/Base/OkCancelDialog";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

export function MissingAdjacentAnnotationsDialog({
  showDialog,
  onConfirm,
  onCancel,
  goToSlice,
  title,
  annotationType,
}) {
  return (
    <OkCancelDialog
      title={title}
      open={showDialog}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={"Yes"}
      cancelText={"No"}
    >
      <>
        <DialogContentText>
          {"It appears that there are one or more missing adjacent " +
            annotationType +
            " to a detection. Are you sure you would like to accept this roi?"}
        </DialogContentText>
        <Button onClick={goToSlice}>Go to missing adjacent slice</Button>
      </>
    </OkCancelDialog>
  );
}
