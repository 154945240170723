import React from "react";
import { useSelector } from "react-redux";
import {
  selectedRecistSeriesWithFollowUpSelector,
  seriesSelector,
} from "../../../store/annotationPage/series/selectors";
import {
  targetLesionsSelector,
  nonTargetLesionsSelector,
  targetLesionEvaluationsSelector,
  nonTargetLesionEvaluationsSelector,
} from "../../store/evaluationDetails/selectors";
import { lesionEvaluationTypes } from "shared-api";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHeader from "./TableGroupings/TableHeader";
import TableGrouping from "./TableGroupings/TableGrouping";
import NewTableGrouping from "./TableGroupings/NewTableGrouping";
import TableFooter from "./TableGroupings/TableFooter";

const RecistTable = () => {
  const series = useSelector(seriesSelector);
  const targetLesions = useSelector(targetLesionsSelector);
  const nonTargetLesions = useSelector(nonTargetLesionsSelector);
  const targetLesionEvaluations = useSelector(targetLesionEvaluationsSelector);
  const nonTargetLesionEvaluations = useSelector(
    nonTargetLesionEvaluationsSelector
  );
  const selectedSeries = useSelector(selectedRecistSeriesWithFollowUpSelector);

  return (
    <TableContainer
      style={{ height: "inherit", display: "flex", flexDirection: "column" }}
    >
      <div style={{ overflow: "auto" }}>
        <Table stickyHeader size="small">
          <TableHeader series={series} selectedSeries={selectedSeries} />
          <TableBody>
            <TableGrouping
              groupingName={lesionEvaluationTypes.TARGET}
              lesions={targetLesions}
              lesionEvaluations={targetLesionEvaluations}
              selectedSeries={selectedSeries}
            />
            <TableGrouping
              lesions={nonTargetLesions}
              groupingName={lesionEvaluationTypes.NONTARGET}
              selectedSeries={selectedSeries}
              lesionEvaluations={nonTargetLesionEvaluations}
            />
            <NewTableGrouping
              lesions={nonTargetLesions}
              selectedSeries={selectedSeries}
            />
          </TableBody>
        </Table>
      </div>
      <Table size="small" style={{ marginTop: "auto" }}>
        <TableFooter selectedSeries={selectedSeries} />
      </Table>
    </TableContainer>
  );
};

export default RecistTable;
