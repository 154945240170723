import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";
import styled from "styled-components";
import DeleteLesionDialog from "../../../components/LesionList/DeleteLesionDialog";
import { JumpToLesionButton } from "../../../components/LesionList/JumpToLesionButton";
import { removeLesionEvaluationThunk } from "../../actions/actionCreators/evaluations/lesions";
import { useDispatch } from "react-redux";
import EditButton from "./EditButton";

const LesionHeaderWrapper = styled(Paper)`
  display: flex;
  direction: row;
  width: 100%;
  padding: 4px;
  background: ${({ selected }) => (selected === true && blue[300]) || "unset"};
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const LesionHeaderTitleWrapper = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;

const LesionHeaderActionsWrapper = styled("div")`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
`;

const LesionHeader = React.forwardRef(
  (
    {
      lesion,
      lesionEvaluation,
      pendingDeleteLesion,
      setPendingDeleteLesion,
      expanded,
      selected,
      onSelected,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const formatHeaderTitle = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const handleDelete = (lesion) => {
      setPendingDeleteLesion(lesion);
    };

    const handleLesionDeleted = () => {
      setPendingDeleteLesion(null);
    };

    const handleDeleteLesionCancelled = () => {
      setPendingDeleteLesion(null);
    };

    const onClickEdit = () => {
      if (!lesionEvaluation) throw new Error("Lesion Evaluation is null");
      dispatch(removeLesionEvaluationThunk(lesionEvaluation));
    };

    const { name } = lesion;

    let headerText = `${formatHeaderTitle(name)}`;

    let subText = "";
    if (lesionEvaluation) {
      headerText = `${headerText}:`;
      const { location, stagingClassification } = lesionEvaluation;
      subText = ` ${location} (${stagingClassification})`;
    }

    return (
      <LesionHeaderWrapper
        selected={selected}
        elevation={0}
        onClick={onSelected}
        variant="outlined"
        ref={ref}
      >
        <LesionHeaderTitleWrapper>
          <JumpToLesionButton lesion={lesion} />
          <Typography component="div" variant="subtitle1">
            <Box fontWeight="fontWeightBold" display="inline">
              {headerText}
            </Box>
            {subText}
          </Typography>
        </LesionHeaderTitleWrapper>
        <LesionHeaderActionsWrapper>
          <DeleteLesionDialog
            lesion={pendingDeleteLesion}
            onDelete={handleLesionDeleted}
            onCancel={handleDeleteLesionCancelled}
          >
            <IconButton
              size="small"
              aria-label="delete-lesion"
              onClick={(event) => {
                event.stopPropagation();
                handleDelete(lesion);
              }}
            >
              <DeleteIcon color="disabled" fontSize="small" />
            </IconButton>
          </DeleteLesionDialog>
          {lesionEvaluation && <EditButton onClick={onClickEdit} />}
          <IconButton size="small">
            {expanded ? (
              <RemoveIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )}
          </IconButton>
        </LesionHeaderActionsWrapper>
      </LesionHeaderWrapper>
    );
  }
);

LesionHeader.propTypes = {
  lesion: PropTypes.object.isRequired,
  lesionEvaluation: PropTypes.object,
  pendingDeleteLesion: PropTypes.object,
  setPendingDeleteLesion: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
};

LesionHeader.displayName = "LesionHeader";

export default LesionHeader;
