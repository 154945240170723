import {
  ADD_LESION,
  REMOVE_LESION,
  SET_LESIONS,
  SET_SELECTED_LESION_ID,
  SET_LESION_VISIBLE,
  SET_LESION_STATUS,
} from "../../actions/types/annotationPage/lesions";

let defaultState = {
  byId: {},
  allIds: [],
};

export const lesions = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LESIONS:
      return setLesions(state, action);
    case ADD_LESION:
      return addLesion(state, action);
    case REMOVE_LESION:
      return removeLesion(state, action);
    case SET_SELECTED_LESION_ID:
      return setSelectedLesionId(state, action);
    case SET_LESION_VISIBLE:
      return setLesionVisible(state, action);
    case SET_LESION_STATUS:
      return setLesionStatus(state, action);
    default:
      return state;
  }
};

const setLesions = (state, action) => {
  return {
    ...state,
    byId: action.lesions.byId,
    allIds: action.lesions.allIds,
  };
};

const addLesion = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.lesion.id]: action.lesion,
    },
    allIds: state.allIds.concat(action.lesion.id),
  };
};

const removeLesion = (state, action) => {
  return {
    ...state,
    byId: Object.keys(state.byId).reduce((object, key) => {
      if (key !== action.lesionId.toString()) {
        object[key] = state.byId[key];
      }
      return object;
    }, {}),
    allIds: state.allIds.filter((id) => id !== action.lesionId),
  };
};

const setSelectedLesionId = (state, action) => {
  return {
    ...state,
    selectedLesionId: action.lesionId,
  };
};

const setLesionVisible = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.lesion.id]: {
        ...state.byId[action.lesion.id],
        visible: action.visible,
      },
    },
  };
};

const setLesionStatus = (state, action) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.lesionId]: {
        ...state.byId[action.lesionId],
        status: action.status,
      },
    },
  };
};
