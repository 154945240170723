import React, { useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core";

PrefetchProgressIndicator.propTypes = {};

const useStyles = makeStyles({
  root: {
    height: 6,
  },
});

function PrefetchProgressIndicator() {
  const classes = useStyles();
  const [maxRequests, setMaxRequests] = useState(0);
  const [numberOfRequests, setNumberOfRequests] = useState(0);

  useEffect(() => {
    let timer = setInterval(() => {
      let requestPool = window.cornerstoneTools.requestPoolManager.getRequestPool();
      let prefetch = requestPool.prefetch;
      let numberOfRequests = prefetch.length;
      setMaxRequests((oldMaxRequests) => {
        return numberOfRequests > oldMaxRequests
          ? numberOfRequests
          : oldMaxRequests;
      });
      setNumberOfRequests(numberOfRequests);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  let progress =
    maxRequests === 0 ? 0 : 100 * (1.0 - numberOfRequests / maxRequests);

  return (
    <LinearProgress
      className={classes.root}
      variant="determinate"
      value={progress}
    />
  );
}

export default PrefetchProgressIndicator;
