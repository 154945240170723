import getClient from "../../apollo/getClient";
import {
  DiagnosisType,
  getDiagnoses,
  insertDiagnosis,
  updateDiagnosis,
} from "shared-api";

export async function addDiagnosis(
  diagnosisIn: DiagnosisType
): Promise<DiagnosisType> {
  const client = getClient();
  const { followUpId } = diagnosisIn;

  const diagnoses = await getDiagnoses({ client, followUpId });

  if (diagnoses.length > 0) {
    const diagnosis = { ...diagnosisIn, id: diagnoses[0].id };

    return await updateDiagnosis({
      client,
      diagnosis,
    });
  } else {
    return await insertDiagnosis({
      client,
      diagnosis: diagnosisIn,
    });
  }
}
