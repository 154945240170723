import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { PATIENT_BIOPSY_DETAILS_FRAGMENT } from "../fragments/PatientBiopsyDetailsFragment";
import { PatientBiopsyDetailsType } from "../types/PatientBiopsyDetailsType";
import { PatientBiopsyDetailsFragmentType } from "../fragments/PatientBiopsyDetailsFragmentType";
import { parseBiopsyDetails } from "../parsers/parseBiopsyDetails";

const QUERY = gql`
  query getBiopsyDetailsForStudies($study_ids: [Int!]!) {
    biopsyDetails: patient_biopsy_details(
      where: { patient: { studies: { study_id: { _in: $study_ids } } } }
    ) {
      ...PatientBiopsyDetails
    }
  }
  ${PATIENT_BIOPSY_DETAILS_FRAGMENT}
`;
type Input = {
  studyIds: number[];
};

type Data = {
  biopsyDetails: PatientBiopsyDetailsFragmentType[];
};

export async function getPatientBiopsyDetails({
  client,
  ...input
}: ClientProps & Input): Promise<PatientBiopsyDetailsType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ studyIds: study_ids }) => ({ study_ids }),
    parseData: ({ biopsyDetails }: Data) =>
      biopsyDetails.map(parseBiopsyDetails),
  });
}
