import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectedLesionSelector } from "../../store/annotationPage/lesions/selectors";
import { roisSelector } from "../../store/annotationPage/rois/selectors";
import LesionListItem from "./LesionListItem";
import { setLesionVisible } from "../../actions/actionCreators/annotationPage/lesions";
import RenderVisibilityIcon from "./RenderVisibilityIcon";

function LesionListHeader({
  lesions,
  title,
  rois,
  selectedLesion,
  setLesionVisible,
}) {
  const [expanded, setExpanded] = React.useState(true);
  const [hasLesions, setHasLesions] = React.useState(lesions.length > 0);
  const [displayTitle, setDisplayTitle] = React.useState(title);

  useEffect(() => {
    setHasLesions(lesions.length > 0);

    let displayTitle = expanded ? title : `${title} (${lesions.length})`;
    setDisplayTitle(displayTitle);
  }, [lesions, expanded]);

  const handleExpandCollapseToggle = () => {
    if (hasLesions) setExpanded(!expanded);
  };

  const handleSetAllLesionsVisibility = (event) => {
    event.stopPropagation();
    const allVisible = !getAllLesionsVisible();
    lesions.forEach((lesion) => setLesionVisible(lesion, allVisible));
  };

  const getAllLesionsVisible = () => {
    const invisibleCount = lesions.filter((lesion) => !lesion.visible).length;
    if (invisibleCount === 0) return true;
    else if (lesions.length === invisibleCount) return false;
    else return undefined;
  };

  return (
    <div>
      <ListItem
        dense
        button
        onClick={handleExpandCollapseToggle}
        divider={true}
        disabled={!hasLesions}
      >
        <RenderVisibilityIcon
          isVisible={getAllLesionsVisible()}
          onClick={handleSetAllLesionsVisibility}
        />
        <ListItemText primary={displayTitle} />

        <IconButton
          size="medium"
          disabled={!hasLesions}
          onClick={handleExpandCollapseToggle}
        >
          {expanded ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )}
        </IconButton>
      </ListItem>

      {hasLesions && (
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          style={{ width: "100%" }}
        >
          <List dense>
            {lesions.map((lesion, index) => {
              let lesionRois = rois.filter((roi) =>
                lesion.rois.includes(roi.id)
              );
              let isSelected = selectedLesion === lesion;
              return (
                <LesionListItem
                  key={index}
                  lesion={lesion}
                  rois={lesionRois}
                  isSelected={isSelected}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
}

LesionListHeader.propTypes = {
  lesions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  rois: PropTypes.array.isRequired,
  selectedLesion: PropTypes.object,
};

const mapStateToProps = (state) => ({
  rois: roisSelector(state),
  selectedLesion: selectedLesionSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLesionVisible: (lesion, visible) =>
    dispatch(setLesionVisible(lesion, visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LesionListHeader);
