import { gql } from "@apollo/client/core";

export const CASE_INSTANCE_FRAGMENT = gql`
  fragment CaseInstance on case_instance {
    id: case_instance_id
    caseId: case_id
    actionType: action_type
    priority
    enabled
    case {
      name
    }
  }
`;
