import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { CaseAssignmentProgressType } from "../types/CaseAssignmentProgressType";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation UpdateCaseAssignmentProgress(
    $case_assignment_id: Int!
    $progress: enum_case_progress_enum = COMPLETED
  ) {
    update_case_assignment(
      _set: { progress: $progress }
      where: { case_assignment_id: { _eq: $case_assignment_id } }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  caseAssignmentId: number;
  progress: CaseAssignmentProgressType;
};

export async function updateCaseAssignmentProgress({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ caseAssignmentId: case_assignment_id, progress }) => ({
      case_assignment_id,
      progress,
    }),
    parseData: () => undefined,
  });
}
