import { LesionColorFragmentType } from "../fragments/LesionColorFragmentType";
import { LesionColorType } from "../types/LesionColorType";

export function parseLesionColor({
  anatomicalStructureId,
  type,
  color: { color },
}: LesionColorFragmentType): LesionColorType {
  return { anatomicalStructureId, type, color };
}
