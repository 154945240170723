import cornerstone from "cornerstone-core";
import { ToolTypes } from "shared-api";
import { doesImageContainRoiToolData, getToolSliceData } from "./ToolUtils";

export default class RoiToolWrapper {
  constructor(currentImageIdWatcher, roiTool) {
    this.currentImageIdWatcher = currentImageIdWatcher;
    this.currentRoi = null;
    this.enabled = false;
    this.toolType = ToolTypes.FREEHANDROITOOL;
    this.onToolDataChangedCallback = null;
    this.onContourRightClickedCallback = null;
    this.onContourDoubleClickedCallback = null;

    this.tool = roiTool;
    this.tool.setDataUpdateCallback(this.onMeasureCompleted);
    this.tool.setOnContourRightClickedCallback(this.onContourRightClicked);
    this.tool.setOnContourDoubleClickedCallback(this.onContourDoubleClicked);
    this.tool.setOnContourCopyHotKeyPressedCallback(
      this.onContourCopyHotKeyPressed
    );
    this.tool.setOnContourPasteHotKeyPressedCallback(
      this.onContourPasteHotKeyPressed
    );
  }

  turnOn() {
    this.enabled = true;
  }

  turnOff() {
    this.enabled = false;
  }

  setCurrentRoi(roi) {
    this.currentRoi = roi;

    this.tool.setCurrentRoi(roi);

    if (!roi) {
      this.tool.setPassive();
    } else {
      this.updateState();
    }
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setToolDataChangedCallback(onToolDataChangedCallback) {
    this.onToolDataChangedCallback = onToolDataChangedCallback;
  }

  setOnContourRightClickedCallback(onContourRightClickedCallback) {
    this.onContourRightClickedCallback = onContourRightClickedCallback;
  }

  setOnContourDoubleClickedCallback(onContourDoubleClickedCallback) {
    this.onContourDoubleClickedCallback = onContourDoubleClickedCallback;
  }

  setOnContourCopyHotKeyPressedCallback(onContourCopyHotKeyPressedCallback) {
    this.onContourCopyHotKeyPressedCallback = onContourCopyHotKeyPressedCallback;
  }

  setOnContourPasteHotKeyPressedCallback(onContourPasteHotKeyPressedCallback) {
    this.onContourPasteHotKeyPressedCallback = onContourPasteHotKeyPressedCallback;
  }

  onMeasureCompleted = async () => {
    if (!this.enabled) {
      return;
    }

    this.updateState();

    if (this.onToolDataChangedCallback) {
      let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

      let toolData = getToolSliceData(
        this.currentRoi,
        currentImageId,
        this.toolType
      );

      await this.onToolDataChangedCallback(toolData, currentImageId);
    }
  };

  onContourRightClicked = (toolData) => {
    if (!this.onContourRightClickedCallback) {
      return;
    }

    this.onContourRightClickedCallback(toolData);
  };

  onContourDoubleClicked = (toolData) => {
    if (!this.onContourDoubleClickedCallback) {
      return;
    }

    this.onContourDoubleClickedCallback(toolData);
  };

  onContourCopyHotKeyPressed = () => {
    if (!this.onContourCopyHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    let toolData = getToolSliceData(
      this.currentRoi,
      currentImageId,
      this.toolType
    );

    this.onContourCopyHotKeyPressedCallback(toolData, currentImageId);
  };

  onContourPasteHotKeyPressed = () => {
    if (!this.onContourPasteHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onContourPasteHotKeyPressedCallback(currentImageId);
  };

  updateState = () => {
    this.tool.setActive();
  };

  onImageRendered = () => {
    if (!this.enabled) {
      return;
    }

    this.updateState();
  };

  setCornerstoneElement(cornerstoneElement) {
    if (this.cornerstoneElement) {
      this.cornerstoneElement.removeEventListener(
        cornerstone.EVENTS.NEW_IMAGE,
        this.onImageRendered
      );
    }

    this.cornerstoneElement = cornerstoneElement;

    this.cornerstoneElement.addEventListener(
      cornerstone.EVENTS.NEW_IMAGE,
      this.onImageRendered,
      false
    );
  }

  cancelToolDrawing() {
    this.tool.cancel();
  }

  currentSliceContainsCurrentRoiContour = () => {
    if (!this.currentRoi) {
      return false;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();
    return doesImageContainRoiToolData(
      currentImageId,
      this.toolType,
      this.currentRoi
    );
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };

  isDrawing() {
    return this.tool.isDrawing();
  }
}
