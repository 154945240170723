import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {
  MeasurementCellWrapper,
  StyledTableCellContainerWithWidth,
} from "./StyledComponents/StyledOutlinedCell";
import { useDispatch, useSelector } from "react-redux";
import { seriesSelector } from "../../../store/annotationPage/series/selectors";
import { removeRoiRecistEvaluationThunk } from "../../actions/actionCreators/evaluations/rois";
import EditButton from "./EditButton";
import { formatFollowUpLabel } from "../../../utils/formatFollowUpLabel";
import { updateLesionStatus } from "../../../actions/actionCreators/annotationPage/lesions";

const RecistCell = ({ lesionSeriesData, open, width, highlight }) => {
  const [wait, setWait] = useState(false);
  const [cellOpen, setCellOpen] = useState(false);

  const series = useSelector(seriesSelector);
  const dispatch = useDispatch();

  const getFollowup = (id) => {
    const followUpSeries = series.find((s) => s.id === id);
    if (!followUpSeries) {
      throw new Error("Follow up series not found");
    }

    const { followUpOrder } = followUpSeries;
    return formatFollowUpLabel(followUpOrder);
  };

  const onClickEdit = () => {
    const { roiId, lesionId } = lesionSeriesData;
    dispatch(removeRoiRecistEvaluationThunk(roiId));
    dispatch(updateLesionStatus(lesionId, null));
  };

  useEffect(() => {
    setCellOpen(open);
    if (cellOpen) {
      setWait(true);

      setTimeout(() => {
        setWait(false);
      }, 300);
    }
  }, [open]);

  const followupLabel = getFollowup(lesionSeriesData.seriesId);

  const volumeString = lesionSeriesData.volume
    ? `${lesionSeriesData.volume} mm³`
    : "N/A";
  let mainContent;

  switch (lesionSeriesData.visibility) {
    case "NOTPRESENT":
      mainContent = (
        <>
          <TableRow>
            <MeasurementCellWrapper align="center">
              {followupLabel}
            </MeasurementCellWrapper>
            <EditButton onClick={onClickEdit} />
          </TableRow>
          <TableRow>
            <MeasurementCellWrapper align="center">
              Not Present
            </MeasurementCellWrapper>
          </TableRow>
        </>
      );
      break;
    case "NONMEASURABLE":
      mainContent = (
        <>
          <TableRow>
            <MeasurementCellWrapper align="center">
              {followupLabel}
            </MeasurementCellWrapper>
            <EditButton onClick={onClickEdit} />
          </TableRow>
          <TableRow>
            <MeasurementCellWrapper align="center">
              Not Measurable
            </MeasurementCellWrapper>
          </TableRow>
        </>
      );
      break;
    case null:
    default:
      mainContent =
        !cellOpen && !wait ? (
          <>
            <TableRow>
              <MeasurementCellWrapper align="center">
                {followupLabel}
              </MeasurementCellWrapper>
              <EditButton onClick={onClickEdit} />
            </TableRow>
            <TableRow>
              <MeasurementCellWrapper align="center">
                {lesionSeriesData.LAD.toFixed(2)} mm
              </MeasurementCellWrapper>
            </TableRow>
          </>
        ) : (
          <>
            <TableRow>
              <MeasurementCellWrapper align="center">
                {followupLabel}
              </MeasurementCellWrapper>
              <EditButton onClick={onClickEdit} />
            </TableRow>
            <TableRow>
              <MeasurementCellWrapper float="left">LAD</MeasurementCellWrapper>
              <MeasurementCellWrapper float="right">{`${lesionSeriesData.LAD.toFixed(
                2
              )} mm`}</MeasurementCellWrapper>
            </TableRow>
            <TableRow>
              <MeasurementCellWrapper float="left">SAD</MeasurementCellWrapper>
              <MeasurementCellWrapper float="right">{`${lesionSeriesData.SAD.toFixed(
                2
              )} mm`}</MeasurementCellWrapper>
            </TableRow>
            <TableRow>
              <MeasurementCellWrapper float="left">
                Volume
              </MeasurementCellWrapper>
              <MeasurementCellWrapper float="right">
                {volumeString}
              </MeasurementCellWrapper>
            </TableRow>
          </>
        );
  }
  return (
    <StyledTableCellContainerWithWidth width={width} $highlight={highlight}>
      <Table>
        <TableBody>{mainContent}</TableBody>
      </Table>
    </StyledTableCellContainerWithWidth>
  );
};

RecistCell.propTypes = {
  lesionSeriesData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  highlight: PropTypes.bool.isRequired,
};

export default RecistCell;
