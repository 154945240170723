import { StyledToggleButton } from "../StyledComponents/StyledToggleButton";
import { StyledToggleGroup } from "../StyledComponents/StyledToggleGroup";
import { lesionEvaluationTypes } from "shared-api";
import React from "react";

const TargetToggle = ({ target, setTarget }) => {
  const handleTarget = (event, target) => {
    if (target === null) {
      return;
    }

    setTarget(target);
  };
  return (
    <div>
      <StyledToggleGroup
        value={target}
        exclusive
        onChange={handleTarget}
        size={"small"}
      >
        <StyledToggleButton groupSize={2} value={lesionEvaluationTypes.TARGET}>
          {lesionEvaluationTypes.TARGET}
        </StyledToggleButton>
        <StyledToggleButton
          groupSize={2}
          value={lesionEvaluationTypes.NONTARGET}
        >
          {lesionEvaluationTypes.NONTARGET}
        </StyledToggleButton>
      </StyledToggleGroup>
    </div>
  );
};

export default TargetToggle;
