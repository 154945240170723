let activeWidth = 3;
let passiveWidth = 1.5;
let selectedWidth = 6;
let savedColor = "rgba(0, 255, 0, 255)";
let unsavedColor = "rgba(255, 255, 0, 255)";

let ellipseToolStyle = {
  selectedLineWidth: selectedWidth,
  activeSaved: {
    lineWidth: activeWidth,
    color: savedColor,
  },
  activeUnsaved: {
    lineWidth: activeWidth,
    color: unsavedColor,
  },
  passiveSaved: {
    lineWidth: passiveWidth,
    color: savedColor,
  },
  passiveUnsaved: {
    lineWidth: passiveWidth,
    color: unsavedColor,
  },
};

export default ellipseToolStyle;
