import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

export function initCornerstoneWadoImageLoader(accessToken: string): void {
  cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr: {
      setRequestHeader: (arg0: string, arg1: string) => void;
    }) {
      xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
    },
  });
}
