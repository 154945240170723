import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { loadCase } from "../../actions/actionCreators/annotationPage/thunks";
import { queueViewersCancelActiveTool } from "../../actions/actionCreators/annotationPage/viewers";
import ImageViewerList from "../ImageViewerList";

import LoadingSpinner from "../LoadingSpinner";
import PrefetchProgressIndicator from "../PrefetchProgressIndicator";
import CaseAssignmentSelectionDialog from "../Dialogs/CaseAssignmentSelectionDialog";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import RecistSidebar from "../../RECIST/components/RecistList/RecistSidebar";
import { RECIST_1_1 } from "shared-api";
import useLoadUrlState from "../../hooks/useLoadUrlState";

function AnnotationView({
  isCaseLoaded,
  loadCase,
  queueViewersCancelActiveTool,
  possibleCaseAssignments,
  selectedCaseAssignment,
}) {
  let { id: caseIdString } = useParams();
  let caseId = parseInt(caseIdString);

  const urlViewersData = useLoadUrlState();

  useEffect(() => {
    loadCase(caseId);
  }, [loadCase, caseId]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "Delete":
        case "Escape":
          queueViewersCancelActiveTool();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown, false);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [queueViewersCancelActiveTool]);

  if (!isCaseLoaded) {
    return (
      <Grid container style={{ height: "100%" }}>
        <LoadingSpinner message={`Loading case...`} />
      </Grid>
    );
  }

  let rightSidebar;
  switch (selectedCaseAssignment?.actionType) {
    case RECIST_1_1:
      rightSidebar = <RecistSidebar />;
      break;
    default:
      rightSidebar = <RightSidebar />;
  }

  return (
    <>
      <CaseAssignmentSelectionDialog open={possibleCaseAssignments != null} />
      <div style={{ display: "flex", height: "100%" }}>
        <LeftSidebar />
        <div style={{ width: "66%", paddingLeft: "3px" }}>
          <div style={{ height: "calc(100% - 6px)" }}>
            <ImageViewerList urlViewersData={urlViewersData} />
          </div>
          <div style={{ height: "6px" }}>
            <PrefetchProgressIndicator />
          </div>
        </div>
        {rightSidebar}
      </div>
    </>
  );
}

AnnotationView.propTypes = {
  loadCase: PropTypes.func.isRequired,
  isCaseLoaded: PropTypes.bool.isRequired,
  queueViewersCancelActiveTool: PropTypes.func.isRequired,
  possibleCaseAssignments: PropTypes.array,
  selectedCaseAssignment: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedCaseAssignment: state.annotationPage.case.selectedCaseAssignment,
  isCaseLoaded: state.annotationPage.case.isCaseDataLoaded,
  possibleCaseAssignments: state.annotationPage.case.possibleCaseAssignments,
});

const mapDispatchToProps = (dispatch) => ({
  loadCase: (caseId) => dispatch(loadCase(caseId)),
  queueViewersCancelActiveTool: () =>
    dispatch(queueViewersCancelActiveTool(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationView);
