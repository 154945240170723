import Chip from "@material-ui/core/Chip";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { createLesion } from "../../actions/actionCreators/annotationPage/thunks";
import {
  setActiveTool,
  setDetectMode,
} from "../../actions/actionCreators/annotationPage/tools";
import { nextLesionNameSelector } from "../../store/annotationPage/lesions/selectors";
import { ANNOTATE } from "../../tools/Tools";
import { seriesFollowUpExistsInRecistCaseSelector } from "../../store/annotationPage/series/selectors";
import { lesionTypes } from "shared-api";

CreateNewLesionButton.propTypes = {
  createLesion: PropTypes.func.isRequired,
  nextLesionName: PropTypes.string.isRequired,
  setContourDetectMode: PropTypes.func.isRequired,
  setActiveTool: PropTypes.func.isRequired,
};

function CreateNewLesionButton({
  createLesion,
  nextLesionName,
  setContourDetectMode,
  setActiveTool,
}) {
  const seriesFollowUpExists = useSelector(
    seriesFollowUpExistsInRecistCaseSelector
  );
  const handleClick = () => {
    if (!seriesFollowUpExists) {
      throw new Error(
        "Prohibited to create lesion in series with no follow up"
      );
    }
    setContourDetectMode(true);
    setActiveTool(ANNOTATE);
    createLesion(nextLesionName);
  };

  return (
    <Chip
      variant="outlined"
      label={"Lesion"}
      icon={<AddCircle />}
      onClick={() => handleClick()}
      disabled={!seriesFollowUpExists}
    />
  );
}

const mapStateToProps = (state) => ({
  nextLesionName: nextLesionNameSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTool: (tool) => dispatch(setActiveTool(tool)),
  createLesion: (name) =>
    dispatch(createLesion(name, null, lesionTypes.LESION)),
  setContourDetectMode: (isDetectMode) => dispatch(setDetectMode(isDetectMode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLesionButton);
