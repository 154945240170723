import React from "react";
import { history } from "./configureStore";
import { Auth0Provider } from "./auth0/auth0-wrapper";
import AuthConfig from "./auth0/AuthConfig";
import App from "./App";

const AuthProvider = () => {
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  const { domain, clientId, audience } = AuthConfig;

  return (
    <Auth0Provider
      domain={domain}
      client_id={clientId}
      audience={audience}
      redirect_uri={`${window.location.origin}${process.env.PUBLIC_URL}`}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  );
};

export default AuthProvider;
