import _ from "lodash";

const ellipseCache = [];

export function addEllipseToCache(toolData) {
  let roiId = toolData[0].roi.id;

  if (ellipseCache.hasOwnProperty(roiId)) {
    throw new Error("Ellipse already exists in ellipse cache");
  }

  //todo toolData is currently an array but we only use the first index, in the future this will have to be modified to include all toolData
  ellipseCache[roiId] = getHandles(toolData[0]);
}

export function clearEllipseCache() {
  Object.keys(ellipseCache).forEach((key) => delete ellipseCache[key]);
}

function getHandles(toolData) {
  return {
    startX: toolData.handles.start.x,
    startY: toolData.handles.start.y,
    endX: toolData.handles.end.x,
    endY: toolData.handles.end.y,
  };
}

export default class EllipseToolChangeDetector {
  constructor(onEllipseCreatedCallback, onEllipseChangedCallback) {
    this.onEllipseCreatedCallback = onEllipseCreatedCallback;
    this.onEllipseChangedCallback = onEllipseChangedCallback;

    this.queueOnEllipseChanged = _.debounce(this.onEllipseChanged, 125);
  }

  testEllipseData = (data) => {
    let toolData = data.data;

    if (!toolData || !toolData.roi) {
      throw new Error("Ellipse toolData is null");
    }

    let roiId = toolData.roi.id;
    let newHandles = getHandles(toolData);

    const { startX, startY, endX, endY } = newHandles;

    if (startX === endX && startY === endY) {
      return;
    }

    if (ellipseCache.hasOwnProperty(roiId)) {
      let existingHandles = ellipseCache[roiId];
      let hasChanged = !_.isEqual(existingHandles, newHandles);
      if (hasChanged) {
        ellipseCache[roiId] = newHandles;

        this.lastEllipseData = data;
        this.queueOnEllipseChanged();
      }
    } else {
      ellipseCache[roiId] = newHandles;
      this.onEllipseCreated(data);
    }
  };

  onEllipseCreated(toolData) {
    this.onEllipseCreatedCallback(toolData);
  }

  onEllipseChanged = () => {
    this.onEllipseChangedCallback(this.lastEllipseData);
  };
}
