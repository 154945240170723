import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { getLengthData, ToolTypes } from "shared-api";

export const intersects = (line1, line2) => {
  let det, gamma, lambda;
  let [{ x: a, y: b }, { x: c, y: d }] = line1;
  let [{ x: p, y: q }, { x: r, y: s }] = line2;

  det = (c - a) * (s - q) - (d - b) * (r - p);

  if (det === 0) return false;
  else {
    lambda = ((p - a) * (s - q) - (q - b) * (r - p)) / det;
    gamma = ((p - a) * (d - b) - (q - b) * (c - a)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};

export const isInternalLine = (line, edges) => {
  const refPoint = { x: line[0].x, y: line[0].y };
  const edgeRefs = [];

  for (let i = 0; i < edges.length; ++i) {
    if (
      pointIsEqual(refPoint, edges[i][0]) ||
      pointIsEqual(refPoint, edges[i][1])
    ) {
      edgeRefs.push(edges[i]);
    }
    if (edgeRefs.length === 2) break;
  }

  const slope1 = getSlope(edgeRefs[0][0], edgeRefs[0][1]);
  const slope2 = getSlope(edgeRefs[1][0], edgeRefs[1][1]);
  const lineSlope = getSlope(line[0], line[1]);
  if (slope1 * slope2 > 0) {
    return slope1 < lineSlope && lineSlope < slope2;
  } else {
    if (slope1 > 0) {
      return lineSlope > slope1 || lineSlope < slope2;
    } else {
      return lineSlope < slope1 || lineSlope > slope2;
    }
  }
};

const pointIsEqual = (point1, point2) => {
  return point1.x === point2.x && point1.y === point2.y;
};

const getSlope = (point1, point2) => {
  return (point2.y - point1.y) / (point2.x - point1.x);
};

const getIntersection = (line1, line2) => {
  let det, gamma, lambda;
  let [{ x: a, y: b }, { x: c, y: d }] = line1;
  let [{ x: p, y: q }, { x: r, y: s }] = line2;

  det = (c - a) * (s - q) - (d - b) * (r - p);

  if (det === 0) return false;
  else {
    lambda = ((p - a) * (s - q) - (q - b) * (r - p)) / det;
    gamma = ((p - a) * (d - b) - (q - b) * (c - a)) / det;
    return { x: a + lambda * (c - a), y: b + lambda * (d - b) };
  }
};

const findPerpendicular = (edges, longest) => {
  let originalSlope =
    (longest.currLength[1].y - longest.currLength[0].y) /
    (longest.currLength[1].x - longest.currLength[0].x);
  let targetSlope = -1 / originalSlope;

  let candidateLengths = [];
  let xLength = Math.abs(longest.currLength[1].x - longest.currLength[0].x);
  let yLength = Math.abs(longest.currLength[1].y - longest.currLength[0].y);
  let point1;
  let iterator;
  let target;
  let iteratorTarget;

  if (xLength > yLength) {
    point1 =
      longest.currLength[0].x < longest.currLength[1].x
        ? longest.currLength[0]
        : longest.currLength[1];
    iterator = point1.x;
    target = point1.x + xLength;
    iteratorTarget = "x";
  } else {
    point1 =
      longest.currLength[0].y < longest.currLength[1].y
        ? longest.currLength[0]
        : longest.currLength[1];
    iterator = point1.y;
    target = point1.y + yLength;
    iteratorTarget = "y";
  }

  // Continuously slice our contours with perpendicular lines, checking that we have only two intersections
  // Take the one that is the longest
  while (iterator <= target) {
    let currPoint =
      iteratorTarget === "x"
        ? { x: iterator, y: point1.y + originalSlope * (iterator - point1.x) }
        : {
            x: point1.x + (iterator - point1.y) / originalSlope,
            y: iterator,
          };
    let currLength =
      targetSlope < 0
        ? [
            { x: 0, y: targetSlope * (0 - currPoint.x) + currPoint.y },
            { x: (0 - currPoint.y) / targetSlope + currPoint.x, y: 0 },
          ]
        : [
            { x: 0, y: targetSlope * (0 - currPoint.x) + currPoint.y },
            { x: (512 - currPoint.y) / targetSlope + currPoint.x, y: 512 },
          ];

    let intersectingLengths = [];
    for (let edge of edges) {
      if (intersects(edge, currLength)) {
        intersectingLengths.push(edge);
        if (intersectingLengths.length > 2) break;
      }
    }
    if (intersectingLengths.length === 2) {
      // We now have two edges that are at the ends of our perpendicular line
      let length = [
        getIntersection(currLength, intersectingLengths[0]),
        getIntersection(currLength, intersectingLengths[1]),
      ];
      if (length.includes(false)) continue;
      candidateLengths.push({
        length,
        distance: Math.sqrt(
          Math.pow(length[1].x - length[0].x, 2) +
            Math.pow(length[1].y - length[0].y, 2)
        ),
      });
    }
    iterator += 0.05;
  }
  return candidateLengths.reduce((currMax, length) => {
    return length.distance > currMax.distance ? length : currMax;
  });
};

export const findDiameters = (cornerstoneElement, toolData) => {
  let edges = [];
  let lengths = [];
  let contourPoints = toolData[0].handles.points;
  contourPoints.forEach((point) => {
    edges.push([
      { x: point.x, y: point.y },
      { x: point.lines[0].x, y: point.lines[0].y },
    ]);
  });

  // Double for loop for all other points
  // Check that it is not a neighbouring vertex
  for (let i = 0; i < contourPoints.length; ++i) {
    for (let j = i + 2; j < contourPoints.length; ++j) {
      if (i === 0 && j === contourPoints.length - 1) continue;
      let currLength = [
        { x: contourPoints[i].x, y: contourPoints[i].y },
        { x: contourPoints[j].x, y: contourPoints[j].y },
      ];
      // Otherwise, now we check that this line does not intersect with any previous edge
      if (
        edges.every((edge) => {
          return !intersects(edge, currLength);
        })
      ) {
        // If no intersections, good to push this back
        // Exception is if this specific length is completely outside of the polygon
        // Check the slopes to make sure is actually going into the polygon
        lengths.push({
          currLength,
          distance: Math.sqrt(
            Math.pow(currLength[1].x - currLength[0].x, 2) +
              Math.pow(currLength[1].y - currLength[0].y, 2)
          ),
        });
      }
    }
  }

  const LAD = lengths.reduce((currMax, length) => {
    return length.distance > currMax.distance ? length : currMax;
  });

  const SAD = findPerpendicular(edges, LAD);
  const LADLength = getLengthData(LAD.currLength, "x", "y");
  const SADLength = getLengthData(SAD.length, "x", "y");
  cornerstoneTools.addToolState(
    cornerstoneElement,
    ToolTypes.MEASUREMENTTOOL,
    LADLength
  );
  cornerstoneTools.addToolState(
    cornerstoneElement,
    ToolTypes.MEASUREMENTTOOL,
    SADLength
  );

  return { LADLength, SADLength };
};
