export let splitListContinuousAtMiddle = (list, middleIndex) => {
  if (!list || middleIndex >= list.length || !list[middleIndex]) {
    throw new Error("Invalid args!");
  }

  let leftCount = 0;
  for (let i = middleIndex - 1; i >= 0; i--) {
    if (!list[i]) {
      break;
    }

    leftCount++;
  }

  let rightCount = 0;
  for (let i = middleIndex + 1; i < list.length; i++) {
    if (!list[i]) {
      break;
    }

    rightCount++;
  }

  return {
    leftCount,
    rightCount,
  };
};

export let areCountsBalanced = (leftCount, rightCount, minThreshold) => {
  let isBalanced;

  if (leftCount >= 1 && rightCount >= 1) {
    isBalanced = true;
  } else {
    isBalanced = Math.abs(leftCount - rightCount) < minThreshold;
  }

  return isBalanced;
};
