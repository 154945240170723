import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React from "react";

function OkCancelDialog({
  title,
  open,
  okText = "OK",
  cancelText = "Cancel",
  onOk,
  onCancel,
  children,
  disableOk = false,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  otherActions,
}) {
  const handleOk = () => {
    onOk();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelText}
        </Button>
        <Button
          disabled={disableOk}
          onClick={handleOk}
          color="primary"
          autoFocus
        >
          {okText}
        </Button>
        {otherActions}
      </DialogActions>
    </Dialog>
  );
}

OkCancelDialog.propTypes = {
  title: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  otherActions: PropTypes.object,
};

export default OkCancelDialog;
