import React from "react";
import { connect } from "react-redux";
import { setActiveViewerSeries } from "../../actions/actionCreators/annotationPage/viewers";
import SeriesItem from "./SeriesItem";
import PropTypes from "prop-types";
import { seriesSelector } from "../../store/annotationPage/series/selectors";
import List from "@material-ui/core/List";
import { Divider } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { sortSeries } from "../../utils/sortSeries";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function SeriesSelector({ series, onSelectSeries }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List dense component="nav">
        {series.map((s, index) => {
          return (
            <React.Fragment key={index}>
              <SeriesItem series={s} onSelectSeries={onSelectSeries} />
              {index < series.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
}

SeriesSelector.propTypes = {
  series: PropTypes.array.isRequired,
  onSelectSeries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  series: seriesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSelectSeries: (series) => dispatch(setActiveViewerSeries(series.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesSelector);
