export function isPasteAvailableForCurrentLesionSlice(
  clipboardData,
  roi,
  currentImageId,
  selectedLesion
) {
  if (!roi || !selectedLesion) {
    return false;
  }

  let { id: selectedLesionId } = selectedLesion;

  let { id: currentRoiId } = roi;

  let { copiedLesionId, copiedImageId, copiedRoiId } = clipboardData;

  let isCopiedLesionSelected = copiedLesionId === selectedLesionId;
  let isCopiedRoiId = copiedRoiId === currentRoiId;
  let isCopiedImageCurrent = copiedImageId === currentImageId;

  if (isCopiedLesionSelected && (!isCopiedRoiId || !isCopiedImageCurrent)) {
    return true;
  }

  return false;
}
