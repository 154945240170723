import TOOLTYPES from "./ToolTypes";

export function createToolRoi(id, type, userId, color) {
  if (type !== TOOLTYPES.ELLIPTICALROITOOL) {
    if (!color) {
      throw new Error(
        "ERROR: Free hand lesion was not passed a color value during creation of ToolRoi"
      );
    }
  }

  return {
    id: id,
    color: color,
    fillColor: color,
    type: type,
    userId: userId,
  };
}
