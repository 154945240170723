import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { getMaskHexString } from "../utilities/getMaskHexString";
import { UserMaskVersionType } from "../types/UserMaskVersionType";

const MUTATION = gql`
  mutation UpdateUserMask(
    $roi_id: Int!
    $instance_id: Int!
    $tool_data: bytea!
    $version: enum_mask_version_enum
    $shape: _int4!
  ) {
    update_user_mask(
      _set: { tool_data: $tool_data, version: $version, shape: $shape }
      where: {
        _and: [
          { roi_id: { _eq: $roi_id } }
          { instance_id: { _eq: $instance_id } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  roiId: number;
  instanceId: number;
  toolData: Uint8Array;
  shape: { width: number; height: number };
  version: UserMaskVersionType;
};

export async function updateUserMask({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      instanceId: instance_id,
      toolData,
      shape: { width, height },
      version,
    }) => {
      const tool_data = getMaskHexString(
        toolData,
        version as UserMaskVersionType
      );
      const shape = `{${height}, ${width}}`;

      return {
        roi_id,
        instance_id,
        tool_data,
        shape,
        version,
      };
    },
    parseData: () => undefined,
  });
}
