export const DELETE_DETECTION = "DELETE_DETECTION";
export const DELETE_CONTOUR = "DELETE_CONTOUR";
export const UPDATE_DETECTION = "UPDATE_DETECTION";
export const UPDATE_CONTOUR = "UPDATE_CONTOUR";
export const INSERT_CONTOUR = "INSERT_CONTOUR";
export const UPDATE_USER_MASK = "UPDATE_USER_MASK";
export const INSERT_USER_MASK = "INSERT_USER_MASK";
export const DELETE_USER_MASK = "DELETE_USER_MASK";
export const UPDATE_ROI_ATTRIBUTES = "UPDATE_ROI_ATTRIBUTES";
export const UPDATE_PROXY_ROI_ATTRIBUTES = "UPDATE_PROXY_ROI_ATTRIBUTES";
export const REMOVE_PROXY_ROI_ATTRIBUTES = "REMOVE_PROXY_ROI_ATTRIBUTES";
export const SET_ROIS = "SET_ROIS";
export const ADD_ROI = "ADD_ROI";
export const REMOVE_ROI = "REMOVE_ROI";
export const SET_SELECTED_ROI_ID = "SET_SELECTED_ROI_ID";
export const UNSET_SELECTED_ROI_ID = "UNSET_SELECTED_ROI_ID";
export const COPY_DATA_TO_CLIPBOARD = "COPY_DATA_TO_CLIPBOARD";
export const CLEAR_CLIPBOARD = "CLEAR_CLIPBOARD";
