import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { stringify } from "flatted";

const MUTATION = gql`
  mutation UpdateRoiMeasurement(
    $tool_data: jsonb!
    $roi_id: Int!
    $instance_id: Int!
  ) {
    update_roi_measurement(
      _set: { tool_data: $tool_data }
      where: {
        _and: [
          { roi_id: { _eq: $roi_id } }
          { instance_id: { _eq: $instance_id } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  roiId: number;
  instanceId: number;
  toolData: Record<string, unknown>;
};

export async function updateRoiMeasurement({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ roiId: roi_id, instanceId: instance_id, toolData }) => {
      const tool_data = JSON.parse(stringify(toolData));
      return {
        roi_id,
        instance_id,
        tool_data,
      };
    },
    parseData: () => undefined,
  });
}
