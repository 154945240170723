import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";

const QUERY = gql`
  query GetUserRoles($user_id: Int!) {
    roles: role(where: { user_id: { _eq: $user_id } }) {
      role
    }
  }
`;

type Input = {
  userId: number;
};

type Data = {
  roles: { role: string }[];
};

export async function getUserRoles({
  client,
  ...input
}: ClientProps & Input): Promise<string[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ userId: user_id }) => ({
      user_id,
    }),
    parseData: ({ roles }: Data) => roles.map(({ role }) => role),
  });
}
