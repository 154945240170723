import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useLocalState from "./hooks/useLocalState";
import {
  UserNotificationType,
  ERROR,
  SUCCESS,
  INFO,
  WARNING,
  UserNotificationSeverityType,
} from "shared-api";
import { Color } from "@material-ui/lab/Alert/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

interface NotificationPropType {
  notification: UserNotificationType;
}

export default function NotificationSnackbar({
  notification,
}: NotificationPropType): JSX.Element {
  const { id, message, severity, startTime, endTime } = notification;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [acknowledge, setAcknowledge] = useLocalState(false, id.toString());
  const [time, setTime] = useState(Date.parse(new Date().toISOString()));

  useEffect(() => {
    if (
      time >= startTime.getTime() &&
      time <= endTime.getTime() &&
      !acknowledge
    ) {
      setOpen(true);
    }

    if (time > endTime.getTime()) {
      setOpen(false);
    }

    const interval = setInterval(
      () => setTime(Date.parse(new Date().toISOString())),
      5000
    );
    return () => {
      clearInterval(interval);
    };
  }, [acknowledge, time]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAcknowledge(true);
    setOpen(false);
  };

  const severityDictionary: Record<UserNotificationSeverityType, Color> = {
    [ERROR]: "error",
    [WARNING]: "warning",
    [INFO]: "info",
    [SUCCESS]: "success",
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severityDictionary[severity]}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
