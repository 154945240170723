import { normalize, schema } from "normalizr";
import { DiagnosisType } from "../../hooks/DiagnosisType";
import { DiagnosesStateType } from "./diagnoses";
import { NormalizedDiagnosisType } from "../../hooks/NormalizedDiagnosisType";

export const tumourSchema = new schema.Entity("tumours");
export const diagnosesSchema = new schema.Entity("diagnoses", {
  tumours: [tumourSchema],
});

export function normalizeDiagnoses(
  diagnoses: DiagnosisType[]
): DiagnosesStateType {
  const normalizedData = normalize<NormalizedDiagnosisType>(diagnoses, [
    diagnosesSchema,
  ]);

  const {
    result: allIds,
    entities: { diagnoses: byId },
  } = normalizedData;

  return {
    allIds,
    byId: byId ?? {},
  };
}
