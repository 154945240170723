import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  displayedLesionsSelector,
  displayedOrgansSelector,
} from "../../store/annotationPage/lesions/selectors";
import LesionListHeader from "./LesionListHeader";

function LesionList({ lesions, organs }) {
  return (
    <List dense aria-label="list of lesions">
      <LesionListHeader lesions={lesions} title={"Lesions"} />
      <LesionListHeader lesions={organs} title={"Labels"} />
    </List>
  );
}

LesionList.propTypes = {
  lesions: PropTypes.array.isRequired,
  organs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  lesions: displayedLesionsSelector(state),
  organs: displayedOrgansSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LesionList);
