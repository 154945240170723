import React from "react";
import ReactSelect from "react-select";

const Select = (props: any) => (
  <ReactSelect
    {...props}
    placeholder={props.placeholder || ""}
    styles={{
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      valueContainer: (provided) => ({
        ...provided,
        height: "38px",
        overflow: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }),
    }}
    menuPortalTarget={document.body}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#768297",
        neutral0: "#303030",
        neutral80: "#fefefe",
        neutral10: "#2684FF",
      },
    })}
  />
);

export default Select;
