import { getSeriesContainingRoi } from "./getSeriesContainingRoi";
import { getSeriesImageIds } from "./getSeriesImageIds";
import { findFirstImbalancedImageId } from "./findFirstImbalancedImageId";
import getAnnotationsWithWadoUrls from "../../store/annotationPage/rois/getAnnotationsWithWadoUrls";

export function findImbalancedAnnotationImageId(
  roi,
  seriesList,
  instancesById,
  annotations
) {
  let series = getSeriesContainingRoi(roi, seriesList);
  let seriesImageIds = getSeriesImageIds(series, instancesById);

  let { ellipse, roiAttributes } = roi;

  let detectionImageId = ellipse.slice?.imageId;

  const annotationWithWadoUrls = getAnnotationsWithWadoUrls(
    annotations,
    instancesById
  );

  let annotationImageIds = annotationWithWadoUrls.map(
    (annotation) => annotation.wadoUrl
  );

  if (roiAttributes && !roiAttributes.measurable) {
    console.debug(
      `Roi [${roi.id}] is marked as non-measurable. Skipping imbalanced contour check`
    );
    return undefined;
  }

  if (!detectionImageId || annotationImageIds.length === 0) {
    console.debug(
      `Roi [${roi.id}] has no detection/annotations. Skipping imbalanced contour check`
    );
    return undefined;
  }

  return findFirstImbalancedImageId(
    detectionImageId,
    annotationImageIds,
    seriesImageIds
  );
}
