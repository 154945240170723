export let findIndexGaps = (list) => {
  let gaps = [];

  let sorted = list.concat().sort((a, b) => a - b);

  if (sorted[0] < 0) {
    throw new Error("Invalid args: values in list must be positive");
  }

  for (let i = 1; i < sorted.length; i++) {
    let a = sorted[i - 1];
    let b = sorted[i];

    if (b - a > 1) {
      let gapIndex = a + 1;
      gaps.push(gapIndex);
    }
  }

  return gaps;
};
