import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";
import { getMaskHexString } from "../utilities/getMaskHexString";
import { UserMaskVersionType } from "../types/UserMaskVersionType";

const MUTATION = gql`
  mutation InsertUserMask(
    $roi_id: Int!
    $instance_id: Int!
    $created_by: Int!
    $tool_data: bytea!
    $shape: _int4!
    $version: enum_mask_version_enum!
  ) {
    insert_user_mask_one(
      object: {
        roi_id: $roi_id
        instance_id: $instance_id
        tool_data: $tool_data
        version: $version
        created_by: $created_by
        shape: $shape
      }
    ) {
      mask_id
    }
  }
`;

type Input = {
  roiId: number;
  instanceId: number;
  toolData: Uint8Array;
  shape: { width: number; height: number };
  version: UserMaskVersionType;
  createdBy: number;
};

type Data = { insert_user_mask_one: { mask_id: number } };

export async function insertUserMask({
  client,
  ...input
}: ClientProps & Input): Promise<number> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      instanceId: instance_id,
      toolData,
      shape: { width, height },
      version,
      createdBy: created_by,
    }) => {
      const tool_data = getMaskHexString(toolData, version);
      const shape = `{${height}, ${width}}`;

      return {
        roi_id,
        instance_id,
        tool_data,
        shape,
        version,
        created_by,
      };
    },
    parseData: ({ insert_user_mask_one: { mask_id } }: Data) => mask_id,
  });
}
