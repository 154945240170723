import { ActionTypeRulesType } from "../types/ActionTypeRulesType";
import { CaseActionTypeRuleFragmentType } from "../fragments/CaseActionTypeRuleFragmentType";

export function parseActionTypeRules(
  actionTypeRulesFragments: CaseActionTypeRuleFragmentType[]
): ActionTypeRulesType {
  return actionTypeRulesFragments.reduce((result, item) => {
    const { actionType, rule } = item;
    result[actionType] = result[actionType] || [];
    result[actionType].push(rule);
    return result;
  }, Object.create(null));
}
