export const getViewerConfig = (id, seriesId) => {
  let colorMap = {
    0: "red",
    1: "green",
    2: "blue",
    3: "yellow",
    4: "teal",
    5: "cyan",
  };

  return {
    id,
    seriesId,
    imageIdIndex: 0,
    imageId: undefined,
    isActive: id === 0,
    color: colorMap[id],
    queuedWindowLevelPreset: null,
    elementUid: undefined,
  };
};
