import { gql } from "@apollo/client/core";
import { STUDY_NOTE_FRAGMENT } from "./StudyNoteFragment";
import { SERIES_FRAGMENT } from "./SeriesFragment";

export const STUDY_FRAGMENT = gql`
  fragment Study on study {
    accessionNumber: accession_number
    created
    lastModified: last_modified
    patientDob: patient_dob
    patientId: patient_id
    patientName: patient_name
    siteId: site_id
    studyDate: study_date
    studyDescription: study_description
    studyDicomId: study_dicom_id
    id: study_id
    studyInstanceUid: study_instance_uid
    studyTime: study_time
    notes: study_note {
      ...StudyNote
    }
    series(where: { series_cases: { case_id: { _eq: $case_id } } }) {
      ...Series
    }
  }
  ${SERIES_FRAGMENT}
  ${STUDY_NOTE_FRAGMENT}
`;
