import { runNotaExporterAsyncWithCancellation } from "./runNotaExporterAsyncWithCancellation";

const API_NAME = "CaseExporter";

export class NotaExporter {
  constructor(socketEndpoint, path) {
    this._api = null;
    this.isRunning = false;
    this._socketEndpoint = socketEndpoint;
    this._path = path;
    this._cancellationToken = { CANCEL: false };
  }

  init(api) {
    if (this._api) {
      throw new Error(`${API_NAME} API already initialized`);
    }

    this._api = api;
  }

  async getExportDetails(caseId, settings) {
    return this._api.post(
      "/export/details",
      { caseId, settings },
      (response) => response.data,
      "exporterDetails"
    );
  }

  async exportCaseData(
    caseId,
    settings,
    token,
    onProgress,
    onComplete,
    onError
  ) {
    if (!this._api) {
      throw new Error(`${API_NAME} API is not initialized`);
    }

    if (this.isRunning) {
      console.log("Already running!");
      return;
    }

    //reset cancellation token
    this._cancellationToken["CANCEL"] = false;

    const exportDetails = await this.getExportDetails(caseId, settings);

    let payload = { ...exportDetails, settings, token };

    let data = null;
    try {
      data = await runNotaExporterAsyncWithCancellation(
        this._socketEndpoint,
        this._path,
        onProgress,
        payload,
        this._cancellationToken
      );
      onComplete(data);
    } catch (error) {
      onError(error.message);
    }
  }

  cancel() {
    this._cancellationToken["CANCEL"] = true;
  }
}
