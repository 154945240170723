import { createSelector } from "reselect";

const getAssignedCaseIds = (state) => state.workListPage.assignedCases.allIds;
const getAssignedCases = (state) => state.workListPage.assignedCases.byId;

const getUnassignedCaseIds = (state) =>
  state.workListPage.unassignedCases.allIds;
const getUnassignedCases = (state) => state.workListPage.unassignedCases.byId;

export const assignedCasesSelector = createSelector(
  getAssignedCases,
  getAssignedCaseIds,
  (assignedCases, assignedCaseIds) =>
    assignedCaseIds.map((id) => assignedCases[id])
);

export const completedAssignedCasesSelector = createSelector(
  assignedCasesSelector,
  (assignedCases) =>
    assignedCases.filter((caseItem) => caseItem.progress === "COMPLETED")
);

export const incompleteAssignedCasesSelector = createSelector(
  assignedCasesSelector,
  (assignedCases) =>
    assignedCases.filter(
      (caseItem) =>
        caseItem.progress === "PENDING" || caseItem.progress === "INPROGRESS"
    )
);

export const unassignedCasesSelector = createSelector(
  getUnassignedCases,
  getUnassignedCaseIds,
  (unassignedCases, unassignedCaseIds) =>
    unassignedCaseIds.map((id) => unassignedCases[id])
);
