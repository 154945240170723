import cornerstoneTools from "@altis-labs/cornerstone-tools";

let ImageViewerSynchronizer = (function () {
  let instance;

  function init() {
    let synchronizer = null;
    let elements = [];

    function destroySynchronizer() {
      synchronizer.destroy();
    }

    return {
      // Public methods and variables
      createSynchronizer: function (event, handler) {
        if (synchronizer) {
          destroySynchronizer();
        }

        synchronizer = new cornerstoneTools.Synchronizer(event, handler);

        //re-add all elements if any exist
        elements.forEach((element) => synchronizer.add(element));
      },
      setEnabled: function (isEnabled) {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        synchronizer.enabled = isEnabled;
      },
      getEnabled: function () {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        return synchronizer.enabled;
      },
      add: function (element) {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        elements.push(element);
        synchronizer.add(element);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export { ImageViewerSynchronizer };
