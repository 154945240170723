import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useAuth0 } from "../auth0/auth0-wrapper";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PrivateRoute from "./PrivateRoute";
import Toolbar from "./Toolbar/Toolbar";
import logo from "../resources/altis_logo_small.png";
import ProfileMenu from "./ProfileMenu";

// MUI Styling
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function HeaderBar({ push }) {
  // State info
  const classes = useStyles();
  const { isAuthenticated, loading, logout } = useAuth0();

  return (
    <div className="row" style={{ display: "flex", padding: "3px" }}>
      <Grid container item style={{ width: "7%" }}>
        <Button
          size="small"
          onClick={() => push("/studies")}
          style={{ width: "100%" }}
        >
          <ChevronLeftIcon />
          <img style={{ maxHeight: "24px" }} src={logo} alt="Altis Labs" />
        </Button>
      </Grid>
      <Grid
        item
        style={{ width: "100%", paddingLeft: "0px", paddingRight: "14%" }}
      >
        {isAuthenticated && (
          <PrivateRoute path="/annotate/:id" component={() => <Toolbar />} />
        )}
      </Grid>
      <Grid container item style={{ width: "7%" }} justify="flex-end">
        {(loading || !isAuthenticated) && (
          <div className={classes.wrapper}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={true}
            >
              Log in
            </Button>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </div>
        )}
        {isAuthenticated && <ProfileMenu />}
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(HeaderBar);
