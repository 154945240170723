export function sortSeries(seriesList: any[]): any[] {
  const seriesWithFollowUp = seriesList.filter(
    (series) => series.followUpOrder != null
  );

  seriesWithFollowUp.sort((a, b) =>
    a.followUpOrder > b.followUpOrder ? 1 : -1
  );

  const seriesWithoutFollowUp = seriesList.filter(
    (series) => series.followUpOrder == null
  );

  seriesWithoutFollowUp.sort((a, b) => {
    if (a.seriesDate && b.seriesDate) {
      return a.seriesDate > b.seriesDate ? 1 : -1;
    } else {
      return a.seriesDescription > b.seriesDescription ? 1 : -1;
    }
  });

  return [...seriesWithFollowUp, ...seriesWithoutFollowUp];
}
