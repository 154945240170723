import { gql } from "@apollo/client/core";

export const PATIENT_BIOPSY_DETAILS_FRAGMENT = gql`
  fragment PatientBiopsyDetails on patient_biopsy_details {
    details: biopsy_details
    lesionId: lesion_id
    noLinking: no_linking
    noLinkingReason: no_linking_reason
    id: patient_biopsy_details_id
    unsure
    patientId: patient_id
  }
`;
