import {
  SET_LESION_EVALUATIONS,
  ADD_LESION_EVALUATION,
  REMOVE_LESION_EVALUATION,
  ADD_ROI_EVALUATION_TO_LESION,
} from "../../actions/types/evaluations/lesions";

let defaultState = {
  allIds: [],
  byId: {},
};

export const lesions = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LESION_EVALUATIONS:
      return setLesionEvaluations(state, action);
    case ADD_LESION_EVALUATION:
      return addLesionEvaluation(state, action);
    case REMOVE_LESION_EVALUATION:
      return removeLesionEvaluation(state, action);
    case ADD_ROI_EVALUATION_TO_LESION:
      return addRoiRecistEvaluationToLesion(state, action);
    default:
      return state;
  }
};

const addLesionEvaluation = (state, action) => {
  let {
    lesionEvaluation,
    lesionEvaluation: { lesionId },
  } = action;
  const newAllIds = [...state.allIds];
  if (!state.allIds.includes(lesionId)) {
    newAllIds.push(lesionId);
  }
  const byId = !state.byId
    ? { [lesionId]: lesionEvaluation }
    : { ...state.byId, [lesionId]: lesionEvaluation };
  return {
    ...state,
    allIds: [...newAllIds],
    byId: { ...byId },
  };
};

const removeLesionEvaluation = (state, action) => {
  let { lesionId } = action;

  const newAllIds = state.allIds.filter((id) => id !== lesionId);
  const newById = {};
  for (const id of newAllIds) {
    newById[id] = state.byId[id];
  }

  return {
    ...state,
    byId: newById,
    allIds: newAllIds,
  };
};

const setLesionEvaluations = (state, action) => {
  return {
    ...state,
    ...action.lesionEvaluations,
  };
};

const addRoiRecistEvaluationToLesion = (state, action) => {
  const {
    roiRecistEvaluation: { roiId, lesionId },
  } = action;
  const newLesionRoiList = [...state.byId[lesionId].roiRecistEvaluations];

  if (!newLesionRoiList.includes(roiId)) {
    newLesionRoiList.push(roiId);
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [lesionId]: {
        ...state.byId[lesionId],
        roiRecistEvaluations: newLesionRoiList,
      },
    },
  };
};
