const matchSeriesWithFollowUpOrder = (series, followUpOrder) => {
  for (const sery of series) {
    const studySeriesId = sery.id;
    const matchedFollowUp = followUpOrder.find(
      (followUpMap) => followUpMap.seriesId === studySeriesId
    );
    if (matchedFollowUp) {
      const { id, order } = matchedFollowUp;
      sery.followUpId = id;
      sery.followUpOrder = order;
    }
  }
  return series;
};

export default matchSeriesWithFollowUpOrder;
