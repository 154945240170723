export const lesionTypes = {
  LESION: "LESION",
  ORGAN: "ORGAN",
};

export const lesionOrigins = {
  USER: "USER",
  RTSTRUCT: "RTSTRUCT",
};

export const lesionEvaluationTypes = {
  TARGET: "Target",
  NONTARGET: "NonTarget",
};

export const lesionStagingTypes = {
  NODE: "Node",
  METASTASIS: "Metastasis",
  PRIMARY: "Primary",
};
