import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {
  addViewerThunk,
  removeViewerThunk,
} from "../../actions/actionCreators/annotationPage/thunks";
import SeriesSelector from "../SeriesList/SeriesSelector";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    width: "10%",
    position: "relative",
  },
  series: {
    flexGrow: 1,
    overflow: "hidden",
  },
  seriesList: {
    overflowY: "auto",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      background: "transparent",
    },
  },
  buttons: {
    paddingTop: "6px",
  },
}));

const LeftSidebar = ({ addViewer, removeViewer }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      wrap="nowrap"
      className={classes.root}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        className={classes.series}
      >
        <Grid item className={classes.seriesList}>
          <Paper
            elevation={16}
            style={{
              padding: "3px",
            }}
          >
            <SeriesSelector />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-around"
        className={classes.buttons}
      >
        <Button onClick={() => addViewer()}>Add Viewer</Button>
        <Button onClick={() => removeViewer()}>Remove Viewer</Button>
      </Grid>
    </Grid>
  );
};

LeftSidebar.propTypes = {
  addViewer: PropTypes.func.isRequired,
  removeViewer: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addViewer: () => dispatch(addViewerThunk()),
  removeViewer: () => dispatch(removeViewerThunk()),
});

export default connect(null, mapDispatchToProps)(LeftSidebar);
