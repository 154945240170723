import { SET_ALL_ORGANS } from "../../actions/types/annotationPage/organs";

const defaultState = {
  anatomicalStructures: [],
};

export const organs = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_ORGANS:
      return setAllOrgans(state, action);
    default:
      return state;
  }
};

const setAllOrgans = (state, action) => {
  const { anatomicalStructures } = action;

  return { ...state, anatomicalStructures };
};
