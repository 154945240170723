import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { maskGroupsSelector } from "../store/annotationPage/masks/selectors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { viewersSelector } from "../store/annotationPage/viewers/selectors";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import labelmapProvider from "../labelmapUtils/initLabelmapProvider";
import { setMaskGroupVisibility } from "../actions/actionCreators/annotationPage/masks";

function MaskToggle({
  maskGroups,
  viewers,
  maskInstancesById,
  instancesById,
  seriesById,
  maskSeriesById,
  setMaskGroupVisibility,
}) {
  let [checkedState, setCheckedState] = useState({});

  useEffect(() => {
    let state = {};
    maskGroups.forEach((maskGroup) => {
      state[maskGroup.groupId] = maskGroup.isVisible;
    });
    setCheckedState(state);
  }, [maskGroups]);

  if (maskGroups.length === 0) {
    return null;
  }

  const getCheckbox = (maskGroup) => {
    let { color, groupId } = maskGroup;

    let isChecked = checkedState[groupId];

    const ColoredCheckbox = withStyles({
      root: {
        color: color,
        "&$checked": {
          color: color,
        },
      },
      checked: {},
    })((props) => (
      <Checkbox
        color="default"
        {...props}
        checked={isChecked}
        onChange={(event) => handleOnChecked(event.target.checked, maskGroup)}
      />
    ));

    return <ColoredCheckbox color="primary" />;
  };

  const handleOnChecked = (isChecked, maskGroup) => {
    let { groupId, series } = maskGroup;

    setCheckedState({ ...checkedState, [groupId]: isChecked });

    setMaskGroupVisibility(groupId, isChecked);

    series.forEach((seriesId) => {
      let seriesViewers = viewers.filter(
        (viewer) => viewer.seriesId === seriesId
      );

      if (seriesViewers.length === 0) {
        return;
      }

      seriesViewers.forEach((seriesViewer) => {
        let { element } = seriesViewer;
        let maskSeries = maskSeriesById[seriesId];

        let series = seriesById[seriesId];
        let instanceIds = series.instances;
        let seriesInstances = instanceIds.map(
          (instanceId) => instancesById[instanceId]
        );

        let { instances, labelmapId } = maskSeries;
        let maskInstances = instances.map((id) => maskInstancesById[id]);
        labelmapProvider.setLabelmap(
          seriesId,
          element,
          labelmapId,
          seriesInstances,
          maskInstances,
          isChecked
        );
      });
    });
  };

  return (
    <div>
      <Typography variant="h6">Masks</Typography>
      {maskGroups.map((maskGroup, index) => {
        let { displayName } = maskGroup;

        return (
          <div key={index}>
            <FormControlLabel
              control={getCheckbox(maskGroup)}
              label={displayName}
              labelPlacement="end"
            />
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  maskGroups: maskGroupsSelector(state),
  viewers: viewersSelector(state),
  instancesById: state.annotationPage.series.instancesById,
  maskInstancesById: state.annotationPage.masks.instancesById,
  maskSeriesById: state.annotationPage.masks.seriesById,
  seriesById: state.annotationPage.series.byId,
});

const mapDispatchToProps = (dispatch) => ({
  setMaskGroupVisibility: (maskGroupId, isVisible) =>
    dispatch(setMaskGroupVisibility(maskGroupId, isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaskToggle);
