import React, { useState } from "react";
import { useSelector } from "react-redux";
import { roiRecistEvaluationsSelector } from "../../../store/evaluationDetails/selectors";
import { selectedLesionSelector } from "../../../../store/annotationPage/lesions/selectors";
import { roisSelector } from "../../../../store/annotationPage/rois/selectors";
import { viewerSeriesSelector } from "../../../../store/annotationPage/viewers/selectors";
import PropTypes from "prop-types";

import TableGroupingHeader from "./TableGroupingHeader";
import TableGroupingCollapse from "./TableGroupingCollapse";
import RecistRow from "../RecistRow";
import { useEffect } from "react";

const TableGrouping = ({
  groupingName,
  lesions,
  lesionEvaluations,
  selectedSeries,
}) => {
  const [groupingOpen, setGroupingOpen] = useState(false);
  const rois = useSelector(roisSelector);
  const roiRecistEvaluations = useSelector(roiRecistEvaluationsSelector);
  const selectedLesion = useSelector(selectedLesionSelector);
  const currentSeriesId = useSelector(
    (state) => state.annotationPage.recists.recistSeries.currentSeriesId
  );
  const viewerSeriesIds = useSelector(viewerSeriesSelector);

  const lesionsExist = lesions.length > 0;
  useEffect(() => {
    if (!lesionsExist) {
      setGroupingOpen(lesionsExist);
    }
  }, [lesionsExist]);

  return (
    <>
      <TableGroupingHeader
        name={groupingName}
        colSpan={selectedSeries.length}
        count={lesions.length}
        expanded={groupingOpen}
        setExpanded={setGroupingOpen}
      />
      <TableGroupingCollapse
        colSpan={selectedSeries.length + 1}
        expanded={groupingOpen}
      >
        {lesions.map((lesion) => {
          let lesionEvaluation = lesionEvaluations.find(
            (lesionEval) => lesionEval.lesionId === lesion.id
          );
          let lesionRoiRecistEvaluations = roiRecistEvaluations.filter(
            (roiRecistEvaluation) =>
              lesionEvaluation.roiRecistEvaluations.includes(
                roiRecistEvaluation.roiId
              )
          );
          let lesionRois = rois.filter((roi) => lesion.rois.includes(roi.id));

          let selected = selectedLesion === lesion;

          return (
            <RecistRow
              lesion={lesion}
              lesionEvaluation={lesionEvaluation}
              rois={lesionRois}
              roiRecistEvaluations={lesionRoiRecistEvaluations}
              key={lesion.id}
              selectedSeries={selectedSeries}
              selected={selected}
              viewerSeriesIds={viewerSeriesIds}
              currentSeriesId={currentSeriesId}
            />
          );
        })}
      </TableGroupingCollapse>
    </>
  );
};

TableGrouping.propTypes = {
  groupingName: PropTypes.string.isRequired,
  lesions: PropTypes.array.isRequired,
  lesionEvaluations: PropTypes.array.isRequired,
  selectedSeries: PropTypes.array.isRequired,
};

export default TableGrouping;
