import ToggleButton from "@material-ui/lab/ToggleButton";
import blue from "@material-ui/core/colors/blue";
import styled from "styled-components";

type StyledToggleButtonProps = {
  groupSize: number;
};

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  text-transform: none;
  background-color: ${blue[900]};
  &.MuiToggleButton-root.Mui-selected {
    background-color: ${blue[400]};
  }
  :hover {
    background-color: ${blue[800]};
  }
  width: ${(props) => `calc(100% / ${props.groupSize})`};
`;
