import { gql } from "@apollo/client/core";
import { TumourClassificationType } from "../types/TumourClassificationType";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation InsertRoiAttributes(
    $measurable: Boolean!
    $roi_id: Int!
    $tumour_classification: enum_tumour_tissue_classification_enum!
  ) {
    insert_roi_attributes_one(
      object: {
        measurable: $measurable
        roi_id: $roi_id
        tumour_classification: $tumour_classification
      }
      on_conflict: {
        constraint: roi_attributes_roi_id_key
        update_columns: [measurable, tumour_classification]
      }
    ) {
      roi_attributes_id
    }
  }
`;

type Input = {
  roiId: number;
  measurable: boolean;
  tumourClassification: TumourClassificationType;
};

export async function insertRoiAttributes({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({
      roiId: roi_id,
      measurable,
      tumourClassification: tumour_classification,
    }) => ({
      roi_id,
      measurable,
      tumour_classification,
    }),
    parseData: () => undefined,
  });
}
