import React, { useEffect, useState } from "react";
import {
  LinearProgress,
  Typography,
  // @ts-ignore
} from "@material-ui/core";
import downloadjs from "downloadjs";
import { IHandleExport } from "nota-components";

interface NotaExporterItemProps {
  caseId: number;
  handleExport: (options: IHandleExport) => void;
}

const NotaExporterItem = ({ caseId, handleExport }: NotaExporterItemProps) => {
  const [errorText, setErrorText] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    handleExport({
      caseId,
      onDownloadProgress,
      onExportComplete,
      onExportError,
    });
  }, []);

  const onDownloadProgress = (progress: {
    message: string;
    progress: number | null;
  }) => {
    const { progress: exportProgress } = progress;
    if (exportProgress) {
      const exportProgressPercent = 100 * exportProgress;
      setProgress(exportProgressPercent);
    }
  };

  const onExportComplete = (data: Blob) => {
    downloadjs(data, `nota-case_${caseId}-export.zip`, "application/zip");
  };

  const onExportError = (message: string) => {
    setErrorText(message);
  };

  return (
    <div>
      {errorText ? (
        <Typography variant="body1" gutterBottom color="error">
          There was an error exporting this case. Contact Altis Labs for
          details.
        </Typography>
      ) : (
        <>
          <Typography>Case ID: {caseId}</Typography>
          <LinearProgress
            variant={progress === 0 ? "indeterminate" : "determinate"}
            value={progress}
          />
        </>
      )}
    </div>
  );
};

export default NotaExporterItem;
