import { Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import React from "react";
import { SubMenuNodeTreeType } from "../../models/SubMenuNodeType";
import { AnatomicalStructureType } from "../../actions/actionCreators/annotationPage/organs/utilities/getAnatomicalStructuresTree";

SubMenuItem.propTypes = {
  node: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  menuAnchors: PropTypes.object.isRequired,
  setMenuAnchor: PropTypes.func.isRequired,
  disabledNodes: PropTypes.array.isRequired,
};

interface SubMenuItemProps<T> {
  node: SubMenuNodeTreeType<T>;
  onClick: (node: SubMenuNodeTreeType<T>) => void;
  menuAnchors: any;
  setMenuAnchor: any;
  disabledNodes: string[];
}

function SubMenuItem({
  node,
  onClick,
  menuAnchors,
  setMenuAnchor,
  disabledNodes,
}: SubMenuItemProps<AnatomicalStructureType>): JSX.Element {
  const hasChildren = node.children.length > 0;

  function shouldDisableNode<T>(node: SubMenuNodeTreeType<T>): boolean {
    return (
      disabledNodes.includes(node.displayName) || allChildrenDisabled(node)
    );
  }

  function allChildrenDisabled<T>(node: SubMenuNodeTreeType<T>): boolean {
    if (disabledNodes.length === 0) return false;

    if (node.children.length === 0) {
      return disabledNodes.includes(node.displayName);
    } else {
      return node.children.every((node) => allChildrenDisabled(node));
    }
  }

  if (hasChildren) {
    const menuId = `${node.displayName}-menu`;
    return (
      <>
        <Menu
          id={menuId}
          anchorEl={menuAnchors[menuId]}
          keepMounted
          open={Boolean(menuAnchors[menuId])}
          onClose={() => setMenuAnchor(menuId, null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <div>
            {node.children.map((child) => {
              return (
                <SubMenuItem
                  key={`menu-item-${child.displayName}`}
                  node={child}
                  onClick={onClick}
                  menuAnchors={menuAnchors}
                  setMenuAnchor={setMenuAnchor}
                  disabledNodes={disabledNodes}
                />
              );
            })}
          </div>
        </Menu>
        <MenuItem
          key={`menu-item-${node.displayName}`}
          onClick={(e) => setMenuAnchor(menuId, e.currentTarget)}
          disabled={shouldDisableNode(node)}
        >
          {node.displayName}
          <KeyboardArrowRightIcon />
        </MenuItem>
      </>
    );
  } else {
    return (
      <MenuItem
        key={`menu-item-${node.displayName}`}
        onClick={() => onClick(node)}
        disabled={shouldDisableNode(node)}
      >
        {node.displayName}
      </MenuItem>
    );
  }
}

export default SubMenuItem;
