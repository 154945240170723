import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useAuth0 } from "../auth0/auth0-wrapper";

function ProfileMenu() {
  const { logout } = useAuth0();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Grid container justify="flex-end" alignItems="center">
      <AccountCircleIcon
        fontSize="large"
        style={{ marginRight: "6px" }}
        onClick={handleMenuClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default ProfileMenu;
