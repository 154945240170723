import { UserInfoFragmentType } from "../fragments/UserInfoFragmentType";
import { UserInfoType } from "../types/UserInfoType";
import { ActionTypeType } from "../types/ActionTypeType";

export function parseUserInfo({
  userId,
  caseActionTypeRoles,
}: UserInfoFragmentType): UserInfoType {
  const actionTypeRoles = caseActionTypeRoles.map(
    ({ actionType }) => actionType as ActionTypeType
  );
  return {
    userId,
    actionTypeRoles,
  };
}
