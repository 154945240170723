import { gql } from "@apollo/client";

export const USER_NOTIFICATION_FRAGMENT = gql`
  fragment UserNotification on user_notifications {
    id
    message
    severity
    startTime: start_time
    endTime: end_time
  }
`;
