import { AnatomicalStructureFragmentType } from "../fragments/AnatomicalStructureFragmentType";
import { AnatomicalStructureType } from "../types/AnatomicalStructureType";

export function parseAnatomicalStructure(
  anatomicalStructureFragment: AnatomicalStructureFragmentType
): AnatomicalStructureType {
  const {
    id,
    structure,
    displayName,
    enabledOrgans,
  } = anatomicalStructureFragment;
  const enabled = enabledOrgans.length > 0 ? enabledOrgans[0].enabled : false;

  return {
    id,
    structure,
    displayName,
    enabled,
  };
}
