import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { LesionEvaluationType } from "../types/LesionEvaluationType";
import { parsedQuery } from "../../../../utilities/graphql/parsedQuery";
import { TUMOUR_EVALUATION_FRAGMENT } from "../fragments/TumourEvaluationFragment";
import { TumourEvaluationFragmentType } from "../fragments/TumourEvaluationFragmentType";
import { parseLesionEvaluation } from "../parsers/parseLesionEvaluation";

const QUERY = gql`
  query GetLesionEvaluations($case_id: Int!) {
    evaluations: tumour_evaluation(
      where: { lesion: { case_id: { _eq: $case_id } } }
    ) {
      ...TumourEvaluation
    }
  }
  ${TUMOUR_EVALUATION_FRAGMENT}
`;

type Input = {
  caseId: number;
};

type Data = {
  evaluations: TumourEvaluationFragmentType[];
};

export async function getLesionEvaluations({
  client,
  ...input
}: ClientProps & Input): Promise<LesionEvaluationType[]> {
  return await parsedQuery({
    client,
    query: QUERY,
    input,
    parseVariables: ({ caseId: case_id }) => ({ case_id }),
    parseData: ({ evaluations }: Data) =>
      evaluations.map(parseLesionEvaluation),
  });
}
