import React, { useEffect } from "react";
import DetectContourModeToggle from "./DetectContourModeToggle";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import AddCircle from "@material-ui/icons/AddCircle";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import PanToolIcon from "@material-ui/icons/PanTool";
import TollIcon from "@material-ui/icons/Toll";
import BrushIcon from "@material-ui/icons/Brush";
import AllOutIcon from "@material-ui/icons/AllOut";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import { clearToolSliceData } from "../../tools/ToolUtils";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import WindowLevelPresetSelector from "./WindowLevelPresetSelector";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ANNOTATE,
  MEASURE,
  PAN,
  ROI_SCULPTOR,
  WINDOWLEVEL,
  ZOOM,
  SYNCED_SCROLL,
  BRUSH,
  ELLIPTICAL_PROBE_TOOL,
  FREEHAND_SCISSORS,
} from "../../tools/Tools";
import {
  queueViewerDraw,
  queueViewerMeasure,
} from "../../actions/actionCreators/annotationPage/viewers";
import {
  setActiveTool,
  setDetectMode,
} from "../../actions/actionCreators/annotationPage/tools";
import SyncedScrollCheckbox from "./SyncedScrollCheckbox";
import Typography from "@material-ui/core/Typography";
import FlagCaseButton from "../FlagCaseButton";
import CreateNewLesionButton from "./CreateNewLesionButton";
import CreateNewOrganButton from "./CreateNewOrganButton";
import Can from "../Can";
import Paper from "@material-ui/core/Paper";
import { ANNOTATIONS_CREATE } from "../../auth0/AuthRules";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import DoneButtonWithDialog from "../DoneButtonWithDialog";
import CopyUrlButton from "../CopyUrlButton.tsx";

import {
  selectedLesionTypeSelector,
  selectedLesionSelector,
} from "../../store/annotationPage/lesions/selectors";
import { lesionTypes } from "shared-api";
import ConnectedActionTypeChip from "../WorkListTable/ConnectedActionTypeChip";
import NotaExporter from "../../notaExporter/NotaExporter";
import hotkeyToolSelector from "../../tools/hotkeyToolSelector";
import { canRoleUseComponent } from "../../actions/actionCreators/app";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "5px",
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: "wrap",
  },
  divider: {
    alignSelf: "stretch",
    height: "auto",
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

function Toolbar({
  contourDetectMode,
  onContourDetectModeChanged,
  activeTool,
  setActiveTool,
  queueViewerDraw,
  queueViewerMeasure,
  selectedCaseAssignment,
  selectedLesionType,
  selectedLesion,
  canRoleUseComponent,
}) {
  const [selected, setSelected] = React.useState("");

  const isLesionVisible = selectedLesion ? selectedLesion.visible : false;

  useEffect(() => {
    setSelected(activeTool);
  }, [activeTool]);

  useEffect(() => {
    if (
      (selected === ELLIPTICAL_PROBE_TOOL ||
        selected === BRUSH ||
        selected === FREEHAND_SCISSORS) &&
      (selectedLesionType == null || !isLesionVisible)
    ) {
      toolSelected(ANNOTATE);
    }
  }, [selectedLesionType, isLesionVisible]);

  const classes = useStyles();

  let toolSelected = (tool) => {
    if (!tool || tool === SYNCED_SCROLL) {
      return;
    }

    setSelected(tool);
    setActiveTool(tool);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!canRoleUseComponent(ANNOTATIONS_CREATE)) {
        return;
      }

      const hotkeyTool = hotkeyToolSelector(event);
      if (hotkeyTool) toolSelected(hotkeyTool);
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  let deleteLengthMeasurements = () => {
    clearToolSliceData(MEASURE);
    queueViewerMeasure(false);
    queueViewerDraw();
  };

  const isLesionSelected = selectedLesionType != null;
  let isSelectedLesionOrgan = selectedLesionType === lesionTypes.ORGAN;
  let caseId = selectedCaseAssignment?.caseId;
  let actionType = selectedCaseAssignment?.actionType;
  let enableFeaturePreview =
    process.env.NODE_ENV === "development" ||
    window._env_.REACT_APP_ENABLE_FEATURE_PREVIEW === "true";

  return (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <Grid item>
        <Grid container alignItems="center">
          {selectedCaseAssignment && (
            <Grid item style={{ paddingRight: "8px" }}>
              <Grid
                container
                direction={"row"}
                justify="center"
                alignItems="center"
              >
                <Grid item style={{ paddingRight: "16px" }}>
                  <Can
                    yes={() => <DoneButtonWithDialog />}
                    action={ANNOTATIONS_CREATE}
                  />
                </Grid>
                <Grid item style={{ paddingRight: "8px" }}>
                  <Typography>Case {caseId}</Typography>
                </Grid>
                <Grid item>
                  <CopyUrlButton />
                </Grid>
                <Grid item style={{ paddingRight: "8px" }}>
                  <FlagCaseButton caseId={caseId} />
                </Grid>
                {enableFeaturePreview && (
                  <Grid item style={{ paddingRight: "8px" }}>
                    <NotaExporter caseId={caseId} />
                  </Grid>
                )}
                <Grid item>
                  <ConnectedActionTypeChip actionType={actionType} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Can
            yes={() => (
              <Grid item>
                <CreateNewLesionButton />
                <CreateNewOrganButton />
                <Chip
                  style={{ marginLeft: "15px" }}
                  variant="outlined"
                  label={"Measure"}
                  icon={<AddCircle />}
                  onClick={() => {
                    toolSelected(MEASURE);
                    queueViewerMeasure(true);
                  }}
                  onDelete={deleteLengthMeasurements}
                />
              </Grid>
            )}
            action={ANNOTATIONS_CREATE}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Paper className={classes.paper}>
              <Can
                yes={() => (
                  <ToggleButton
                    value={ANNOTATE}
                    style={{ border: "none" }}
                    selected={selected === ANNOTATE}
                    onChange={() => {
                      toolSelected(ANNOTATE);
                    }}
                  >
                    {/*<SearchIcon fontSize="small" />*/}
                    <DetectContourModeToggle
                      disabled={isSelectedLesionOrgan}
                      checked={!contourDetectMode}
                      onCheckChanged={(checked) => {
                        onContourDetectModeChanged(!checked);
                      }}
                    />
                  </ToggleButton>
                )}
                action={ANNOTATIONS_CREATE}
              />

              <StyledToggleButtonGroup
                value={activeTool}
                onChange={(event, tool) => toolSelected(tool)}
                exclusive
                size={"small"}
              >
                <ToggleButton
                  value={ELLIPTICAL_PROBE_TOOL}
                  disabled={!isLesionSelected || !isLesionVisible}
                >
                  <SlowMotionVideoIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton
                  value={WINDOWLEVEL}
                  style={{ paddingRight: "0px" }}
                >
                  <WindowLevelPresetSelector
                    disabled={activeTool !== WINDOWLEVEL}
                  />
                </ToggleButton>
                <ToggleButton value={ZOOM}>
                  <ZoomInIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value={PAN} style={{ paddingLeft: "5px" }}>
                  <PanToolIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value={ROI_SCULPTOR}>
                  <TollIcon fontSize="small" />
                </ToggleButton>
                {enableFeaturePreview ? (
                  <ToggleButton
                    value={BRUSH}
                    disabled={!isLesionSelected || !isLesionVisible}
                  >
                    <BrushIcon fontSize="small" />
                  </ToggleButton>
                ) : null}
                {enableFeaturePreview ? (
                  <ToggleButton
                    value={FREEHAND_SCISSORS}
                    disabled={!isLesionSelected || !isLesionVisible}
                  >
                    <AllOutIcon fontSize="small" />
                  </ToggleButton>
                ) : null}
              </StyledToggleButtonGroup>
              <Divider orientation="vertical" className={classes.divider} />
              <SyncedScrollCheckbox />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Toolbar.propTypes = {
  contourDetectMode: PropTypes.bool.isRequired,
  onContourDetectModeChanged: PropTypes.func.isRequired,
  activeTool: PropTypes.string.isRequired,
  setActiveTool: PropTypes.func.isRequired,
  queueViewerDraw: PropTypes.func.isRequired,
  queueViewerMeasure: PropTypes.func.isRequired,
  selectedCaseAssignment: PropTypes.object,
  selectedLesionType: PropTypes.string,
  selectedLesion: PropTypes.object,
};

const mapStateToProps = (state) => ({
  contourDetectMode: state.annotationPage.tools.isDetectMode,
  activeTool: state.annotationPage.tools.activeTool,
  selectedCaseAssignment: state.annotationPage.case.selectedCaseAssignment,
  selectedLesionType: selectedLesionTypeSelector(state),
  selectedLesion: selectedLesionSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onContourDetectModeChanged: (isDetectMode) =>
    dispatch(setDetectMode(isDetectMode)),
  setActiveTool: (tool) => dispatch(setActiveTool(tool)),
  queueViewerDraw: () => dispatch(queueViewerDraw(true)),
  queueViewerMeasure: (isQueued) => dispatch(queueViewerMeasure(isQueued)),
  canRoleUseComponent: (action) => dispatch(canRoleUseComponent(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
