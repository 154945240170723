import { ContourFragmentType } from "../fragments/ContourFragmentType";
import { parse } from "flatted";
import { CONTOUR_VERSION } from "../../../../types/contourVersion";
import { ToolDataType } from "../types/ToolDataType";

export function parseContour(
  contourFragment: ContourFragmentType
): ToolDataType {
  const { version, toolData } = contourFragment;

  let parsedToolData;
  if (version) {
    if (version !== CONTOUR_VERSION) {
      throw new Error(`Contours data version: ${version} not supported!`);
    }
    parsedToolData = parse(JSON.stringify(toolData));
  }
  return parsedToolData;
}
