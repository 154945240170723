import { gql } from "@apollo/client/core";

export const LESION_COLOR_FRAGMENT = gql`
  fragment Color on lesion_color {
    anatomicalStructureId: anatomical_structure_id
    type
    color: mapped_color {
      color: color_value
    }
  }
`;
