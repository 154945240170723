import React from "react";
import { connect } from "react-redux";
import { updateCaseAssignmentUserId } from "shared-api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { setSelectedAssignedCase } from "../../actions/actionCreators/annotationPage/case";
import ConnectedActionTypeChip from "../WorkListTable/ConnectedActionTypeChip";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  DialogContent,
} from "@material-ui/core";
import getClient from "../../apollo/getClient";

const CaseAssignmentSelectionDialog = ({
  open,
  possibleCaseAssignments,
  userId,
  setSelectedAssignedCase,
}) => {
  const sortCaseAssignments = (caseAssignments) => {
    const userCaseAssignments = caseAssignments.filter(
      (caseAssignment) => caseAssignment.userId === userId
    );

    const otherCaseAssignments = caseAssignments.filter(
      (caseAssignment) => caseAssignment.userId !== userId
    );

    return [...userCaseAssignments, ...otherCaseAssignments];
  };

  const handleTakeOwnershipCaseAssignment = async (caseAssignment) => {
    const { id: caseAssignmentId } = caseAssignment;

    const client = getClient();
    await updateCaseAssignmentUserId({ client, caseAssignmentId, userId });

    setSelectedAssignedCase(caseAssignment, userId);
  };

  return (
    <Dialog
      open={open}
      onClose={() => undefined}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>{"Select Case Assignment"}</DialogTitle>
      <DialogContent>
        <CaseAssignmentsTable
          caseAssignments={
            possibleCaseAssignments &&
            sortCaseAssignments(possibleCaseAssignments)
          }
          userId={userId}
          handleSelectedCaseAssignment={setSelectedAssignedCase}
          handleTakeOwnershipCaseAssignment={handleTakeOwnershipCaseAssignment}
        />
      </DialogContent>
    </Dialog>
  );
};

const CaseAssignmentsTable = ({
  caseAssignments,
  userId,
  handleSelectedCaseAssignment,
  handleTakeOwnershipCaseAssignment,
}) => {
  const checkIfDisabled = (caseAssignment) =>
    caseAssignments
      .filter((assignment) => assignment.userId === userId)
      .some(
        (assignment) => assignment.actionType === caseAssignment.actionType
      );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell>Action Type</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {caseAssignments?.map((caseAssignment) => (
          <TableRow hover={true} key={caseAssignment.id}>
            <TableCell>{caseAssignment.username}</TableCell>
            <TableCell>
              <ConnectedActionTypeChip actionType={caseAssignment.actionType} />
            </TableCell>
            {caseAssignment.userId !== userId ? (
              <TableCell>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  disabled={checkIfDisabled(caseAssignment)}
                  onClick={() =>
                    handleTakeOwnershipCaseAssignment(caseAssignment)
                  }
                >
                  Take Ownership
                </Button>
              </TableCell>
            ) : (
              <TableCell />
            )}
            <TableCell>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => handleSelectedCaseAssignment(caseAssignment)}
              >
                Select
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapState = (state) => ({
  possibleCaseAssignments: state.annotationPage.case.possibleCaseAssignments,
  userId: state.app.userId,
});

const mapDispatch = (dispatch) => ({
  setSelectedAssignedCase: (caseAssignment, newUserId) =>
    dispatch(setSelectedAssignedCase(caseAssignment, newUserId)),
});

const connector = connect(mapState, mapDispatch);

export default connector(CaseAssignmentSelectionDialog);
