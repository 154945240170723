export function getLengthData(rawPoints, xKey, yKey) {
  const [start, end] = generatePoints(rawPoints, xKey, yKey);
  return {
    active: false,
    visible: true,
    invalidated: true,
    handles: {
      start,
      end,
      textBox: {
        active: false,
        hasMoved: false,
        hasBoundingBox: true,
        drawnIndependently: true,
        movesIndependently: false,
        allowedOutsideImage: true,
      },
    },
  };
}

function generatePoints(rawPoints, xKey, yKey) {
  return rawPoints.map((rawPoint) => {
    return {
      x: rawPoint[xKey],
      y: rawPoint[yKey],
      active: true,
      highlight: true,
    };
  });
}
