import {
  SET_ACTIVE_TOOL,
  SET_BRUSH_RADIUS,
  SET_DETECT_MODE,
  SET_SELECTED_WINDOW_LEVEL_PRESET_ID,
} from "../../types/annotationPage/tools";
import { queueViewerWindowLevelPreset } from "./viewers";
import { canUseComponent } from "../../../components/Can";
import { ANNOTATIONS_CREATE } from "../../../auth0/AuthRules";

export function setDetectMode(isDetectMode) {
  return {
    type: SET_DETECT_MODE,
    isDetectMode,
  };
}

export function setActiveTool(tool) {
  return {
    type: SET_ACTIVE_TOOL,
    tool,
  };
}

export function setBrushRadius(newRadius) {
  return {
    type: SET_BRUSH_RADIUS,
    newRadius,
  };
}

export function setActiveToolIfAllowed(tool) {
  return async (dispatch, getState) => {
    let { roles } = getState().app;

    if (canUseComponent(roles, ANNOTATIONS_CREATE)) {
      dispatch(setActiveTool(tool));
    }
  };
}

export function setSelectedWindowLevelPreset(id) {
  return {
    type: SET_SELECTED_WINDOW_LEVEL_PRESET_ID,
    id,
  };
}

export function queueAllViewersWindowLevelPreset(windowLevelPreset) {
  return async (dispatch, getState) => {
    let viewerIds = getState().annotationPage.viewers.allIds;
    viewerIds.forEach((viewerId) => {
      dispatch(queueViewerWindowLevelPreset(viewerId, windowLevelPreset));
    });
  };
}
