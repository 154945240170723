import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { ToolTypes } from "shared-api";
import roiToolStyle from "./RoiToolStyle";

export default class RoiTool {
  constructor(cornerstoneElement) {
    this.onDataUpdate = null;
    this.onContourRightClickedCallback = null;
    this.onContourDoubleClickedCallback = null;

    const tool = cornerstoneTools.getToolForElement(
      cornerstoneElement,
      ToolTypes.FREEHANDROITOOL
    );

    if (!tool) {
      throw new Error("Cornerstone roi tool is null");
    }

    cornerstoneElement.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
      this.onMeasureCompleted,
      false
    );

    // Cancel drawing when a scroll occurs
    cornerstoneElement.addEventListener(
      cornerstoneTools.EVENTS.STACK_SCROLL,
      this.cancel,
      false
    );

    tool.setStyle(roiToolStyle);
    tool.setOnContourRightClicked(this.onContourRightClicked);
    tool.setOnContourDoubleClicked(this.onContourDoubleClicked);

    this.tool = tool;
    this.cornerstoneElement = cornerstoneElement;

    const keyBindings = [
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "c" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onContourCopyHotKeyPressed,
      },
      {
        isKeyPressed: (keys) => {
          const { letterKey, ctrlKey, metaKey } = keys;
          return letterKey === "v" && (ctrlKey || metaKey);
        },
        onKeyPressed: this.onContourPasteHotKeyPressed,
      },
    ];

    tool.setKeyBindings(keyBindings);
  }

  setCurrentRoi(roi) {
    this.tool.setCurrentRoi(roi);
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(
      this.cornerstoneElement,
      ToolTypes.FREEHANDROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  }

  setPassive = () => {
    cornerstoneTools.setToolPassiveForElement(
      this.cornerstoneElement,
      ToolTypes.FREEHANDROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  };

  setEnabled() {
    cornerstoneTools.setToolEnabledForElement(
      this.cornerstoneElement,
      ToolTypes.FREEHANDROITOOL,
      {
        mouseButtonMask: 1,
      }
    );
  }

  cancel = () => {
    this.tool.cancelDrawing(this.cornerstoneElement);
  };

  setDataUpdateCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }

  setOnContourRightClickedCallback(onContourRightClickedCallback) {
    this.onContourRightClickedCallback = onContourRightClickedCallback;
  }

  setOnContourDoubleClickedCallback(onContourDoubleClickedCallback) {
    this.onContourDoubleClickedCallback = onContourDoubleClickedCallback;
  }

  setOnContourCopyHotKeyPressedCallback(onContourCopyHotKeyPressedCallback) {
    this.onContourCopyHotKeyPressedCallback = onContourCopyHotKeyPressedCallback;
  }

  setOnContourPasteHotKeyPressedCallback(onContourPasteHotKeyPressedCallback) {
    this.onContourPasteHotKeyPressedCallback = onContourPasteHotKeyPressedCallback;
  }

  onMeasureCompleted = (event) => {
    if (!this.onDataUpdate) {
      return;
    }

    this.onDataUpdate(event);
  };

  onContourRightClicked = (toolData) => {
    if (!this.onContourRightClickedCallback) {
      return;
    }

    this.onContourRightClickedCallback(toolData);
  };

  onContourDoubleClicked = (toolData) => {
    if (!this.onContourDoubleClickedCallback) {
      return;
    }

    this.onContourDoubleClickedCallback(toolData);
  };

  onContourCopyHotKeyPressed = () => {
    if (!this.onContourCopyHotKeyPressedCallback) {
      return;
    }

    this.onContourCopyHotKeyPressedCallback();
  };

  onContourPasteHotKeyPressed = () => {
    if (!this.onContourPasteHotKeyPressedCallback) {
      return;
    }

    this.onContourPasteHotKeyPressedCallback();
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };

  isDrawing() {
    return this.tool._drawing;
  }
}
