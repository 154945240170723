import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

export const MUTATION = gql`
  mutation DeleteRoiAttributes($roi_id: Int!) {
    delete_roi_attributes(where: { roi_id: { _eq: $roi_id } }) {
      affected_rows
    }
  }
`;

type Input = {
  roiId: number;
};

export async function deleteRoiAttributes({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ roiId: roi_id }) => ({
      roi_id,
    }),
    parseData: () => undefined,
  });
}
