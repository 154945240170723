import React from "react";
import OkTextDialog from "../Dialogs/Base/OkTextDialog";

interface UnsavedRoiAttributesDialogProps {
  showDialog: boolean;
  onConfirm: () => void;
}

export function UnsavedRoiAttributesDialog({
  showDialog,
  onConfirm,
}: UnsavedRoiAttributesDialogProps): JSX.Element {
  return (
    <OkTextDialog
      title={"Unsaved Detection Attributes"}
      onOk={onConfirm}
      message={
        "There are unsaved detection attributes. Please save all detection attributes before saving."
      }
      open={showDialog}
    />
  );
}
