import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { ToolTypes } from "shared-api";
import { getToolSliceData, getToolStateIndices } from "./ToolUtils";
import cornerstone from "cornerstone-core";
import { ROI_SCULPTOR } from "./Tools";

export default class HockeyPuckTool {
  constructor(cornerstoneElement) {
    this.onRoiPushedCallback = null;
    this.toolRoi = null;
    this.cornerstoneElement = cornerstoneElement;
    this.lastImageId = null;

    this.cornerstoneElement.addEventListener(
      cornerstone.EVENTS.IMAGE_RENDERED,
      this.onImageRendered
    );

    const tool = cornerstoneTools.getToolForElement(
      cornerstoneElement,
      ROI_SCULPTOR
    );

    if (!tool) {
      throw new Error("Cornerstone hockey puck tool is null");
    }

    tool.setOnRoiPushed(this.onRoiPushed);

    this.tool = tool;
  }

  setOnRoiPushedCallback(onRoiPushed) {
    this.onRoiPushedCallback = onRoiPushed;
  }

  onRoiPushed = async (event) => {
    let { imageId, roi } = event;

    let toolData = getToolSliceData(roi, imageId, ToolTypes.FREEHANDROITOOL);

    if (this.onRoiPushedCallback) {
      await this.onRoiPushedCallback(toolData, imageId);
    }
  };

  onImageRendered = (event) => {
    let {
      detail: {
        image: { imageId },
      },
    } = event;

    if (imageId === this.lastImageId) {
      return;
    }

    this.tryStart(this.toolRoi, imageId, false);
    this.lastImageId = imageId;
  };

  setToolRoi = (toolRoi) => {
    this.toolRoi = toolRoi;
    this.tool.setCurrentRoi(toolRoi);
  };

  tryStart = (toolRoi, imageId, force) => {
    const isToolActive = this.tool.mode === "active";

    let toolRoiChanged = this.toolRoi !== toolRoi;
    let imageIdChanged = this.lastImageId !== imageId;

    this.setToolRoi(toolRoi);

    if (
      !force &&
      (!isToolActive ||
        !toolRoi ||
        !imageId ||
        (!toolRoiChanged && !imageIdChanged))
    ) {
      return;
    }

    let toolIndices = getToolStateIndices(
      ToolTypes.FREEHANDROITOOL,
      imageId,
      this.toolRoi.id
    );

    let toolIndex = toolIndices.length > 0 ? toolIndices[0] : null;
    this.forceStart(toolIndex);
  };

  // this function will force the hockey puck to initialize on the correct roi as per the tool index.
  // in order to accommodate how the tool works, a series of mouse clicks are used to make the tool show up immediately once its selected in the toolbar
  forceStart(toolIndex) {
    let mouseDownEvent = "mousedown";
    let mouseUpEvent = "mouseup";
    let mouseListenerEvent = cornerstoneTools.EVENTS.MOUSE_DOWN;

    let onClick = (event) => {
      if (toolIndex != null) {
        this.tool.forceInitialiseSculpting(event, toolIndex);
      }

      this.cornerstoneElement.removeEventListener(
        mouseListenerEvent,
        onClick,
        true
      );

      fireMouseEvent(this.cornerstoneElement, mouseDownEvent);
      fireMouseEvent(this.cornerstoneElement, mouseUpEvent);
    };

    this.cornerstoneElement.addEventListener(mouseListenerEvent, onClick, true);

    fireMouseEvent(this.cornerstoneElement, mouseDownEvent);
  }

  setEnabled() {
    cornerstoneTools.setToolActiveForElement(
      this.cornerstoneElement,
      ROI_SCULPTOR,
      {
        mouseButtonMask: 1,
      }
    );
  }

  setDisabled() {
    cornerstoneTools.setToolPassiveForElement(
      this.cornerstoneElement,
      ROI_SCULPTOR,
      {
        mouseButtonMask: 1,
      }
    );
  }
}

function fireMouseEvent(element, eventType) {
  let elementPosition = getElementPosition(element);
  let event = new MouseEvent(eventType, {
    bubbles: true,
    cancelable: true,
    clientX: elementPosition.left + 100,
    clientY: elementPosition.top + 100,
  });
  element.dispatchEvent(event);
}

function getElementPosition(element) {
  const rect = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
