export default class FreehandScissorsToolWrapper {
  constructor(currentImageIdWatcher, freehandScissorsTool) {
    this.currentImageIdWatcher = currentImageIdWatcher;
    this.onToolDataChangedCallback = null;

    this.tool = freehandScissorsTool;
    this.tool.setOnUserMaskCopyHotKeyPressedCallback(
      this.onUserMaskCopyHotKeyPressed
    );
    this.tool.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );
    this.tool.setDataUpdateCallback(this.onLabelmapCompleted);
  }

  setToolDataChangedCallback(onToolDataChangedCallback) {
    this.onToolDataChangedCallback = onToolDataChangedCallback;
  }

  setOnUserMaskCopyHotKeyPressedCallback(onUserMaskCopyHotKeyPressedCallback) {
    this.onUserMaskCopyHotKeyPressedCallback = onUserMaskCopyHotKeyPressedCallback;
  }

  setOnUserMaskPasteHotKeyPressedCallback(
    onUserMaskPasteHotKeyPressedCallback
  ) {
    this.onUserMaskPasteHotKeyPressedCallback = onUserMaskPasteHotKeyPressedCallback;
  }

  onUserMaskCopyHotKeyPressed = (data) => {
    if (!this.onUserMaskCopyHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onUserMaskCopyHotKeyPressedCallback(data, currentImageId);
  };

  onUserMaskPasteHotKeyPressed = () => {
    if (!this.onUserMaskPasteHotKeyPressedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onUserMaskPasteHotKeyPressedCallback(currentImageId);
  };

  onLabelmapCompleted = async (data) => {
    if (this.onToolDataChangedCallback) {
      let currentImageId = this.currentImageIdWatcher.getCurrentImageId();
      await this.onToolDataChangedCallback(data, currentImageId);
    }
  };

  setDisabled() {
    this.tool.setDisabled();
  }

  setActive() {
    this.tool.setActive();
  }
}
