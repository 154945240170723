import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContextMenuStyle from "./ContextMenuStyle";

const useStyles = makeStyles(() => ContextMenuStyle);

PasteContextMenu.propTypes = {
  mouseState: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  onPasteClicked: PropTypes.func.isRequired,
};

function PasteContextMenu({
  mouseState,
  handleClose,
  onPasteClicked,
  isPasteAvailable,
}) {
  const classes = useStyles();
  return (
    <>
      <Menu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        <MenuItem
          key={`context-menu-item-paste`}
          onClick={onPasteClicked}
          disabled={!isPasteAvailable}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText classes={{ root: classes.text }}>Paste</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default PasteContextMenu;
