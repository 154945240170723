import { CaseInstanceType } from "../types/CaseInstanceType";
import { CaseInstanceFragmentType } from "../fragments/CaseInstanceFragmentType";

export function parseCaseInstance(
  caseAssignmentFragment: CaseInstanceFragmentType
): CaseInstanceType {
  const {
    case: { name },
    ...props
  } = caseAssignmentFragment;

  return {
    ...props,
    name,
  };
}
