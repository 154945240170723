import { normalize, schema } from "normalizr";

let getSchema = () => {
  const roi = new schema.Entity(
    "roiRecistEvaluations",
    {},
    { idAttribute: "roiId" }
  );

  const lesion = new schema.Entity(
    "lesionEvaluations",
    {
      roiRecistEvaluations: [roi],
    },
    { idAttribute: "lesionId" }
  );

  return lesion;
};

function normalizeLesionEvaluations(lesionEvaluations) {
  let schema = getSchema();
  return normalize(lesionEvaluations, [schema]);
}

export { normalizeLesionEvaluations };
