import { LesionType } from "../types/LesionType";
import { parseRoi } from "./parseRoi";
import { LesionFragmentType } from "../fragments/LesionFragmentType";
import { LesionColorMapType } from "../types/LesionColorMapType";
import { getLesionColor } from "../utilities/getLesionColor";

export function parseLesion(
  lesionFragment: LesionFragmentType,
  lesionColorMap: LesionColorMapType,
  baseWadoUrl: string
): LesionType {
  const {
    id,
    name,
    caseId,
    origin,
    type,
    rois: roiFragments,
    statuses,
    anatomicalStructure,
    associations,
  } = lesionFragment;

  const { structure: location, id: locationId } = anatomicalStructure ?? {
    structure: null,
    id: null,
  };

  const color = getLesionColor(locationId, type, lesionColorMap);

  const rois = roiFragments.map((roi) => parseRoi(roi, color, baseWadoUrl, id));

  const associatedLesions = associations.map(
    (lesion_association) => lesion_association.referenceLesionId
  );

  const status = statuses.length > 0 ? statuses[0].status : null;

  return {
    id,
    name,
    caseId,
    origin,
    type,
    rois,
    status,
    location,
    locationId,
    associatedLesions,
    visible: true,
  };
}
