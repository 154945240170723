import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextFieldDialog from "./TextFieldDialog";
import OkCancelDialog from "./Base/OkCancelDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { updateBiopsyNoLinkingThunk } from "../../actions/actionCreators/annotationPage/biopsyDetails/biopsyDetails";
import { connect } from "react-redux";

const UnassignedBiopsyDialog = ({
  open,
  biopsyDetail,
  onAccepted,
  onCancelled,
  updateBiopsyNoLinking,
}) => {
  const [noLink, setNoLink] = React.useState(false);
  const [noLinkingReason, setNoLinkingReason] = React.useState("");
  const [
    showReasonTextFieldDialog,
    setShowReasonTextFieldDialog,
  ] = React.useState(false);

  useEffect(() => {
    if (!biopsyDetail) {
      setNoLink(false);
      setNoLinkingReason("");
      return;
    }

    let { noLinking } = biopsyDetail;
    let { noLinking: noLinkingState, reason } = noLinking;

    setNoLink(noLinkingState);
    setNoLinkingReason(reason);
  }, [biopsyDetail]);

  let onNoLinkingCheckboxChanged = (event) => {
    let checked = event.target.checked;

    setNoLink(checked);
    if (checked) {
      setShowReasonTextFieldDialog(true);
    } else {
      setNoLinkingReason("");
    }
  };

  const onReasonTextAccepted = (reasonText) => {
    updateBiopsyNoLinking(biopsyDetail.id, {
      noLinking: true,
      noLinkingReason: reasonText,
    });
    setShowReasonTextFieldDialog(false);
  };

  const onReasonTextCancelled = () => {
    setNoLink(false);
    setShowReasonTextFieldDialog(false);
  };

  let message =
    "There is a biopsy result that has not been assigned to a lesion. Please assign the biopsy to a lesion before saving";

  return (
    <>
      <TextFieldDialog
        text={noLinkingReason}
        textLabel={"Reason"}
        textPlaceholder={
          "Add reason why the biopsy was not linked for this case"
        }
        title={"Reason for not linking biopsy"}
        onTextAccepted={onReasonTextAccepted}
        onCancel={onReasonTextCancelled}
        show={showReasonTextFieldDialog}
        canEditText={true}
        allowEmptyText={false}
      />
      <OkCancelDialog
        title={"Unassigned Biopsy Result"}
        onOk={onAccepted}
        onCancel={onCancelled}
        open={open}
        disableOk={!noLink}
      >
        <>
          <DialogContentText>{message}</DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={noLink}
                onChange={onNoLinkingCheckboxChanged}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Acknowledged"
          />
        </>
      </OkCancelDialog>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateBiopsyNoLinking: (biopsyDetailId, noLinking) =>
    dispatch(updateBiopsyNoLinkingThunk(biopsyDetailId, noLinking)),
});

export default connect(null, mapDispatchToProps)(UnassignedBiopsyDialog);
