import { useDrag } from "react-dnd";
import { DragAndDropTypes } from "../DragAndDropTypes";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StudyNotesDialog from "./StudyNotesDialog";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { studyNotesSelector } from "../../store/annotationPage/studyNotes/selectors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import BurstModeIcon from "@material-ui/icons/BurstMode";
import { formatSeriesLabel } from "../../utils/formatSeriesLabel";

function SeriesItem({ series, onSelectSeries, allStudyNotes }) {
  const [showStudyNotesDialog, setShowStudyNotesDialog] = useState(false);
  const [studyNotes, setStudyNotes] = useState(null);

  const [, drag] = useDrag({
    item: {
      type: DragAndDropTypes.SERIES,
      seriesId: series.id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    let studyId = series.studyId;
    let note = allStudyNotes.find((notes) => notes.studyId === studyId);
    setStudyNotes(note);
  }, [series, allStudyNotes]);

  return (
    <>
      <StudyNotesDialog
        studyNotes={studyNotes}
        studyId={series.studyId}
        show={showStudyNotesDialog}
        onOk={() => setShowStudyNotesDialog(false)}
        onCancel={() => setShowStudyNotesDialog(false)}
      />
      <ListItem dense ref={drag} button onClick={() => onSelectSeries(series)}>
        <ListItemText primary={formatSeriesLabel(series)} />
        <IconButton edge="end" size="medium">
          <BurstModeIcon fontSize="small" />
        </IconButton>
      </ListItem>
      <ListItem dense button onClick={() => setShowStudyNotesDialog(true)}>
        <ListItemText secondary="Notes" />
        <IconButton edge="end" size="medium">
          <EditIcon fontSize="small" />
        </IconButton>
      </ListItem>
    </>
  );
}

SeriesItem.propTypes = {
  series: PropTypes.object.isRequired,
  onSelectSeries: PropTypes.func.isRequired,
  allStudyNotes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allStudyNotes: studyNotesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesItem);
