import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";

type StyledCellContentsWrapperProps = {
  $open?: boolean;
  $maxHeight: number;
};

type StyledCellProps = {
  $container?: boolean;
};

export const StyledTableCellContainer = styled(TableCell)`
  padding: unset;
  border-bottom: none;
`;

export const StyledCellContentsWrapper = styled("div")<
  StyledCellContentsWrapperProps
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  ${(props) =>
    props.$open ? `max-height:200px;` : `max-height: ${props.$maxHeight}px;`}
  transition: max-height 0.3s;
  transition-timing-function: cubic-bezier(1, 1.05, 1.05, 1);
`;

export const StyledCell = styled(StyledTableCellContainer)<StyledCellProps>`
  ${(props) =>
    props.$container
      ? `
padding: 4px;
border: none;`
      : `padding: calc(8px + 0.5rem);`}
`;
