import { createSelector } from "reselect";

export const getBiopsyDetailsIds = (state) =>
  state.annotationPage.biopsyDetails.allIds;
export const getBiopsyDetails = (state) =>
  state.annotationPage.biopsyDetails.byId;

export const biopsyDetailsSelector = createSelector(
  getBiopsyDetails,
  getBiopsyDetailsIds,
  (biopsyDetails, biopsyDetailsIds) =>
    biopsyDetailsIds.map((id) => biopsyDetails[id])
);

export const unassignedBiopsyDetailsSelector = createSelector(
  biopsyDetailsSelector,
  (biopsyDetailsList) => {
    return biopsyDetailsList.filter((biopsyDetail) => {
      let { lesionId } = biopsyDetail;
      return lesionId === null || lesionId === undefined;
    });
  }
);
