import * as io from "socket.io-client";

export class NotaExporterSocketClient {
  constructor(endpoint, path, onProgress, onError, onComplete) {
    if (!onProgress || !onError || !onComplete) {
      throw new Error("Socket onProgress/onComplete not defined");
    }

    this.onProgress = onProgress;
    this.onError = onError;
    this.onComplete = onComplete;

    this.client = io(endpoint, {
      path,
      reconnection: false,
      autoConnect: false,
      transports: ["polling"],
    });

    this.client.on("connect_failed", (reason) => {
      this.onError(reason);
    });

    this.client.io.on("connect_error", (reason) => {
      this.onError(reason);
    });

    this.client.on("disconnect", (reason) => {
      console.log(`nota-exporter connection disconnected: ${reason}`);
      this.onError(reason);
    });

    this.client.on("progress", (progress) => {
      this.onProgress(progress);
    });

    this.client.on("complete", (data) => {
      this.onComplete(data);
    });

    this.client.on("error", (error) => {
      this.onError(error);
    });
  }

  isConnected() {
    return this.client.connected;
  }

  export(payload) {
    if (!this.isConnected()) {
      this.client.open();
    }

    this.client.on("connect", () => {
      this.client.emit("export_case", payload);
    });
  }

  cancel() {
    this.onProgress("Cancelled by user");
    this.disconnect();
  }

  disconnect() {
    this.client.disconnect();
  }
}
