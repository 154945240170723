import RuleViolationDialog from "../../TumourResponseRulesValidators/RuleViolationDialog";
import { lesionEvaluationTypes } from "shared-api";
import useRuleCheck from "../../TumourResponseRulesValidators/tumourResponseRulesValidator";
import {
  MAX_TARGET_LESIONS_CHECK,
  MAX_TARGET_LESIONS_ORGAN_CHECK,
  MEASUREABLE_LESION_CHECK,
  NODAL_PATHOLOGICAL_CHECK,
} from "../../../tumourResponseRules/ruleNames";
import React from "react";
import { useSelector } from "react-redux";
import { seriesSelector } from "../../../../store/annotationPage/series/selectors";

const RuleViolationDialogs = ({
  lesion,
  isMeasurable,
  classification,
  measurements,
  setMeasurable,
  setTarget,
  setClassification,
  setPendingDeleteLesion,
  target,
}) => {
  const series = useSelector(seriesSelector);

  const measurableCheckFail = useRuleCheck(MEASUREABLE_LESION_CHECK, {
    measurable: isMeasurable,
    classification,
    measurements,
    seriesId: series[0].id,
  });
  const maxTargetFail = useRuleCheck(MAX_TARGET_LESIONS_CHECK);
  const maxTargetOrganFail = useRuleCheck(MAX_TARGET_LESIONS_ORGAN_CHECK, {
    currentLocation: lesion.location,
  });
  const nodeSizeFail = useRuleCheck(NODAL_PATHOLOGICAL_CHECK, {
    measurable: isMeasurable,
    classification,
    measurements,
  });
  const onAcceptNonMeasurable = () => {
    setMeasurable(false);
    setTarget(lesionEvaluationTypes.NONTARGET);
  };
  const onAcceptNonTarget = () => {
    setTarget(lesionEvaluationTypes.NONTARGET);
  };
  const onAcceptNonNodal = () => {
    setClassification(null);
  };

  const onDeleteLesion = () => {
    setPendingDeleteLesion(lesion);
  };

  return (
    <div>
      <RuleViolationDialog
        message={measurableCheckFail}
        show={measurableCheckFail !== null}
        onAccept={onAcceptNonMeasurable}
        acceptText="Mark as Non-Measurable"
        onDeleteLesion={onDeleteLesion}
      />
      <RuleViolationDialog
        show={target === lesionEvaluationTypes.TARGET && maxTargetFail !== null}
        message={maxTargetFail}
        onAccept={onAcceptNonTarget}
        acceptText="Mark as Non-Target"
        onDeleteLesion={onDeleteLesion}
      />
      <RuleViolationDialog
        show={
          target === lesionEvaluationTypes.TARGET && maxTargetOrganFail !== null
        }
        message={maxTargetOrganFail}
        onAccept={onAcceptNonTarget}
        acceptText="Mark as Non-Target"
        onDeleteLesion={onDeleteLesion}
      />
      <RuleViolationDialog
        show={nodeSizeFail !== null}
        message={nodeSizeFail}
        onAccept={onAcceptNonNodal}
        acceptText="Clear staging classification"
        onDeleteLesion={onDeleteLesion}
      />
    </div>
  );
};

export default RuleViolationDialogs;
