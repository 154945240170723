import { gql } from "@apollo/client/core";
import { ClientProps } from "../types/ClientProps";
import { parsedMutation } from "../../../../utilities/graphql/parsedMutation";

const MUTATION = gql`
  mutation DeleteRoiMeasurement($roi_id: Int!, $instance_id: Int!) {
    delete_roi_measurement(
      where: {
        _and: [
          { roi_id: { _eq: $roi_id } }
          { instance_id: { _eq: $instance_id } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  roiId: number;
  instanceId: number;
};

export async function deleteRoiMeasurement({
  client,
  ...input
}: ClientProps & Input): Promise<void> {
  return await parsedMutation({
    client,
    mutation: MUTATION,
    input,
    parseVariables: ({ roiId: roi_id, instanceId: instance_id }) => ({
      roi_id,
      instance_id,
    }),
    parseData: () => undefined,
  });
}
