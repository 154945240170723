import { ANNOTATE } from "../Tools";

import { ClipboardDataTypes } from "shared-api";
import { isPasteAvailableForCurrentLesionSlice } from "./isPasteAvailableForCurrentLesionSlice";

export function isContourPasteAvailable(
  clipboardData,
  roi,
  currentImageId,
  selectedLesion,
  activeTool
) {
  if (!clipboardData) {
    return false;
  }

  let { copiedToolData, copiedDataType } = clipboardData;

  if (activeTool && activeTool !== ANNOTATE) {
    return false;
  }

  if (copiedDataType !== ClipboardDataTypes.CONTOUR) {
    return false;
  }

  if (!copiedToolData || copiedToolData.length === 0) {
    return false;
  }

  return isPasteAvailableForCurrentLesionSlice(
    clipboardData,
    roi,
    currentImageId,
    selectedLesion
  );
}
