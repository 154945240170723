import {
  IS_AUTH_LOADED,
  SET_API,
  SET_USER,
  SET_USER_CASE_ACTION_TYPE_ROLES,
  SET_GLOBAL_APPLICATION_ERROR,
  SET_CASE_ACTION_TYPE_ATTRIBUTE,
  SET_CASE_ACTION_TYPE_RULE,
  SET_USER_ROLES,
} from "../actions/types/app";

let defaultState = {
  api: undefined,
  isAuthInitialized: false,
  userId: undefined,
  userCaseActionTypeRoles: undefined,
  roles: [],
  globalApplicationError: null,
  metadata: { caseActionTypeAttribute: null, caseActionTypeRule: null },
};

const app = (state = defaultState, action) => {
  switch (action.type) {
    case IS_AUTH_LOADED:
      return {
        ...state,
        isAuthInitialized: action.isLoaded,
      };
    case SET_API:
      return {
        ...state,
        api: action.api,
      };
    case SET_USER:
      return {
        ...state,
        userId: action.user,
      };
    case SET_USER_CASE_ACTION_TYPE_ROLES:
      return {
        ...state,
        userCaseActionTypeRoles: action.roles,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case SET_GLOBAL_APPLICATION_ERROR:
      return {
        ...state,
        globalApplicationError: action.error,
      };
    case SET_CASE_ACTION_TYPE_ATTRIBUTE:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          caseActionTypeAttribute: action.caseActionTypeAttribute,
        },
      };
    case SET_CASE_ACTION_TYPE_RULE:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          caseActionTypeRule: action.caseActionTypeRule,
        },
      };
    default:
      return state;
  }
};

export default app;
