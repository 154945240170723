import { normalize, schema } from "normalizr";

function normalizeBiopsyDetails(biopsyDetails) {
  const biopsyDetailsSchema = new schema.Entity(
    "biopsyDetails",
    {},
    { idAttribute: "id" }
  );
  return normalize(biopsyDetails, [biopsyDetailsSchema]);
}

export { normalizeBiopsyDetails };
