import { normalize, schema } from "normalizr";

function normalizeMasks(masks) {
  const maskInstanceSchema = new schema.Entity(
    "instances",
    {},
    { idAttribute: "labelmapId" }
  );
  const maskSeriesSchema = new schema.Entity(
    "series",
    {
      instances: [maskInstanceSchema],
    },
    { idAttribute: "seriesId" }
  );
  const maskGroupSchema = new schema.Entity(
    "groups",
    {
      series: [maskSeriesSchema],
    },
    { idAttribute: "groupId" }
  );
  return normalize(masks, [maskGroupSchema]);
}

export { normalizeMasks };
