const ANNOTATE = "ANNOTATE";
const WINDOWLEVEL = "Wwwc";
const ZOOM = "Zoom";
const PAN = "Pan";
const MEASURE = "Length";
const ROI_SCULPTOR = "FreehandRoiSculptor";
const SYNCED_SCROLL = "SYNC_SCROLL";
const BRUSH = "Brush";
const ELLIPTICAL_PROBE_TOOL = "EllipticalProbe";
const FREEHAND_SCISSORS = "FreehandScissors";

export {
  ANNOTATE,
  WINDOWLEVEL,
  ZOOM,
  PAN,
  MEASURE,
  ROI_SCULPTOR,
  SYNCED_SCROLL,
  BRUSH,
  ELLIPTICAL_PROBE_TOOL,
  FREEHAND_SCISSORS,
};
