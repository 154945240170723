export function getEllipsePointsFromStartEnd(start, end, resolution) {
  const center = {
    x: (end.x - start.x) / 2 + start.x,
    y: (end.y - start.y) / 2 + start.y,
  };
  const radii = {
    x: Math.abs(center.x - start.x),
    y: Math.abs(center.y - start.y),
  };

  const shape = [];
  for (let i = 0; i < resolution; i++) {
    const val = 2 * Math.PI * (i / resolution);
    const x = center.x + radii.x * Math.cos(val);
    const y = center.y + radii.y * Math.sin(val);
    shape.push([y, x]);
  }
  return shape;
}
