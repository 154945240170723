import styled from "styled-components";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";

type StyledButtonProps = {
  $withColor?: boolean;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  width: 100%;
  text-transform: none;
  ${(props) =>
    props.$withColor
      ? `background-color: ${blue[600]};
    :hover {
      background-color: ${blue[500]};
    }`
      : null}
`;

export const StyledActionButton = styled(Button)`
  width: 100%;
  height: 32px;
  text-transform: none;
  background-color: ${blue[600]};
  :hover {
    background-color: ${blue[500]};
  }
`;

export const StyledContentActionButton = styled(StyledActionButton)`
  margin-top: 8px;
`;
