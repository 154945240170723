import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { newLesionsSelector } from "../../../store/evaluationDetails/selectors";
import { selectedLesionSelector } from "../../../../store/annotationPage/lesions/selectors";
import { createLesion } from "../../../../actions/actionCreators/annotationPage/thunks";
import { nextLesionNameSelector } from "../../../../store/annotationPage/lesions/selectors";
import PropTypes from "prop-types";

import { StyledTableCellContainer } from "../StyledComponents/StyledTableCellContainer";
import { StyledActionButton } from "../StyledComponents/StyledButton";
import TableGroupingHeader from "./TableGroupingHeader";
import TableGroupingCollapse from "./TableGroupingCollapse";
import NewRecistItem from "../NewRecistItem/NewRecistItem";
import { seriesFollowUpExistsInRecistCaseSelector } from "../../../../store/annotationPage/series/selectors";
import { lesionTypes } from "shared-api";

const NewTableGrouping = ({
  selectedSeries,
  selectedLesion,
  newLesions,
  createLesion,
  nextLesionName,
}) => {
  const [groupingOpen, setGroupingOpen] = useState(false);
  const newLesionsExist = newLesions.length > 0;
  const seriesFollowUpExists = useSelector(
    seriesFollowUpExistsInRecistCaseSelector
  );

  useEffect(() => {
    setGroupingOpen(newLesionsExist);
  }, [newLesionsExist]);

  const addNewLesion = (event) => {
    if (!seriesFollowUpExists) {
      throw new Error(
        "Prohibited to create lesion in series with no follow up"
      );
    }
    event.stopPropagation();
    createLesion(nextLesionName);
  };

  return (
    <>
      <TableGroupingHeader
        name="New"
        colSpan={selectedSeries.length - 1}
        count={newLesions.length}
        expanded={groupingOpen}
        setExpanded={setGroupingOpen}
      >
        <StyledTableCellContainer align="right">
          <StyledActionButton
            disabled={!seriesFollowUpExists}
            onClick={addNewLesion}
          >
            New Lesion
          </StyledActionButton>
        </StyledTableCellContainer>
      </TableGroupingHeader>
      <TableGroupingCollapse
        colSpan={selectedSeries.length + 1}
        expanded={groupingOpen}
      >
        {newLesions.map((lesion) => {
          let selected = selectedLesion === lesion;
          return (
            <NewRecistItem
              lesion={lesion}
              selected={selected}
              key={lesion.id}
            />
          );
        })}
      </TableGroupingCollapse>
    </>
  );
};

NewTableGrouping.propTypes = {
  selectedSeries: PropTypes.array.isRequired,
  selectedLesion: PropTypes.object,
  newLesions: PropTypes.array.isRequired,
  createLesion: PropTypes.func.isRequired,
  nextLesionName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  selectedLesion: selectedLesionSelector(state),
  newLesions: newLesionsSelector(state),
  nextLesionName: nextLesionNameSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createLesion: (name) =>
    dispatch(createLesion(name, null, lesionTypes.LESION)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTableGrouping);
